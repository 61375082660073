import { Moment } from 'moment';

export interface FilterProps {
  search?: string,
  so_id?: string,
  sales_channel_number?: number,
  sales_channel_name?: string,
  sales_team?: string,
  invoice_number?: string,
  so_name?: string,
  client_name?: string,
  client_phone?: string,
  client_movil?: string,
  client_mail?: string,
  client_address?: string,
  invoiceDate?: Moment | null,
  shipmentDate?: Moment | null,
  shipment_tracking?: string,
  id_ml?: string,
  state?: string,
  credit_note_number?: string,
  path?: string,
  value?: string | number,
  method?: string,
}

type AccumulatorType = {
  path?: string,
  value?: string | number,
  method?: string,
};

type Filter = {
  shipmentDate?: Moment | [object],
  invoiceDate?: Moment | [object],
} | [];

export const MakeFilter = ({
  search,
  invoiceDate,
  shipmentDate,
}: FilterProps) => {
  const filter: Filter = !search ? {} : search.split(' ').reduce((accumulator: Record<string, AccumulatorType[]>, item, index) => {
    accumulator[index] = [{
      path: 'sales_channel_number',
      value: item,
      method: 'includes',
    }, {
      path: 'sales_channel_name',
      value: item,
      method: 'includes',
    }, {
      path: 'invoice_number',
      value: item,
      method: 'includes',
    }, {
      path: 'so_name',
      value: item,
      method: 'includes',
    }, {
      path: 'client_name',
      value: item,
      method: 'includes',
    }, {
      path: 'client_phone',
      value: item,
      method: 'includes',
    }, {
      path: 'client_address',
      value: item,
      method: 'includes',
    }, {
      path: 'client_mail',
      value: item,
      method: 'includes',
    }, {
      path: 'client_mail',
      value: item,
      method: 'includes',
    }, {
      path: 'shipment_tracking',
      value: item,
      method: 'includes',
    }, {
      path: 'credit_note_number',
      value: item,
      method: 'includes',
    }, {
      path: 'id_ml',
      value: item,
      method: 'includes',
    }, {
      path: 'state',
      value: item,
      method: 'includes',
    }];

    const numberSearch = parseInt(item, 10);
    if (numberSearch) {
      accumulator[index].concat([{
        path: 'so_id',
        value: numberSearch,
      }, {
        path: 'sales_channel_number',
        value: numberSearch,
      }]);
    }
    return accumulator;
  }, {});

  if (invoiceDate) {
    filter.invoiceDate = [
      {
        path: 'invoice_date',
        value: invoiceDate.set({ second: 59, millisecond: 999 }).toISOString(),
        method: 'equals',
      },
    ];
  }

  if (shipmentDate) {
    filter.shipmentDate = [
      {
        path: 'shipment_date',
        value: shipmentDate.set({ second: 59, millisecond: 999 }).toISOString(),
        method: 'equals',
      },
    ];
  }

  return filter;
};
