import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { AccountErrorMessages } from './AccountErrorMessages';
import { ActionButtons } from './ActionButtons';
import { InputsForm } from './InputsForm';
import LoadingData from '../../../../components/LoadingData';
import { AnyObject, LibbyObject } from '../../../../../types/types';

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    maxWidth: 400,
    marginTop: theme.spacing(1),
  },
}));

type AccountCreateFormProps ={
  libby: LibbyObject,
  account: AnyObject | null,
  setAccountCreated: (data: object)=>void,
  handleSubmit: Function,
  onSearchChange: ()=>void,
  username : AnyObject,
  email:AnyObject,
  password : AnyObject,
  account_role ? : AnyObject,
  submitError: string,
  duplicateAccount: boolean,
  isSearching : boolean
  onCancelClick:(data: object)=>void,
};

export const AccountCreateForm = ({
  libby,
  account,
  setAccountCreated,
  handleSubmit,
  onSearchChange,
  username,
  password,
  email,
  account_role,
  submitError,
  duplicateAccount,
  isSearching,
  onCancelClick,
}: AccountCreateFormProps) => {
  const classes = useStyles();
  return (
    <form
      noValidate
      className={classes.form}
      onSubmit={async (event) => {
        const accountSaved = await handleSubmit(event);
        if (accountSaved) {
          // we use setTimeout because we must set the account after libby has saved the user
          setTimeout(() => {
            setAccountCreated(accountSaved);
          }, 0);
        }
      }}
    >
      <InputsForm
        account={account}
        account_role={account_role}
        password={password}
        username={username}
        email={email}
        duplicateAccount={duplicateAccount}
      />
      <AccountErrorMessages
        duplicateAccount={duplicateAccount}
        submitError={submitError}
      />
      <ActionButtons
        account={account}
        duplicate={duplicateAccount}
        working={libby.working || isSearching}
        onSearchChange={onSearchChange}
        onCancelClick={onCancelClick}
      />
      <LoadingData working={libby.working || isSearching} />
    </form>
  );
};
