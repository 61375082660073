import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles({
  loading: {
    height: '30px',
    margin: '10px 0 10px 0',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
});

interface LoadingDataProps {
  label?: string;
  working: boolean | undefined;
  cant?: number;
}

const LoadingData = ({ label, working, cant }: LoadingDataProps) => {
  const classes = useStyles();
  return (
    <div className={classes.loading}>
      {!working ? <div>{label ? `${label}: ${cant}` : ''}</div>
        : <CircularProgress color="secondary" size={24} />}
    </div>
  );
};

LoadingData.defaultProps = {
  label: null,
  cant: 0,
};

export default React.memo(LoadingData);
