import React, {
  RefObject,
  useImperativeHandle,
  useMemo,
} from 'react';
import { DatabaseConnector } from '@phinxlab/libby-rest-web';
import { TextFieldProps } from '@material-ui/core';
import { AnyObject, LibbyObject } from '../../types/types';
import { GeneralSelect } from '../../components/GeneralSelect';
import { useLibbyFetch } from '../../hooks';
import { makeFilter } from '../Orders/routes/OrdersList/utils/makeFilter';

type SelectImportTypeProps = {
  inputProps: TextFieldProps;
  optionAll?: boolean;
  typeCourierSearch?: string;
};
interface SelectImportRawTypeProps extends SelectImportTypeProps {
  libby: LibbyObject;
  dataRef: RefObject<unknown>;
}

export const SelectOrderShipmentRaw = ({
  typeCourierSearch,
  libby,
  inputProps = {},
  optionAll = false,
  dataRef,
}: SelectImportRawTypeProps) => {
  const filter = useMemo(
    () => makeFilter({ typeCourierSearch }),
    [typeCourierSearch],
  );

  const paramsFetch = useMemo(
    () => ({
      daoName: 'courier_service',
      filter,
    }),
    [filter],
  );

  const disable = useMemo(
    () => typeCourierSearch === '0',
    [typeCourierSearch],
  );

  const { data: dataRaw, working }: { data: AnyObject; working: boolean } = useLibbyFetch(libby, paramsFetch);

  const data = useMemo(
    () => dataRaw.map(({ courier_service_id, name }: AnyObject) => ({
      id: courier_service_id,
      value: name,
    })),
    [dataRaw],
  );

  useImperativeHandle(
    dataRef,
    () => ({
      data,
    }),
    [data],
  );

  return (
    <GeneralSelect
      disabled={disable}
      data={data}
      working={working}
      label="Service"
      optionAll={optionAll}
      inputProps={inputProps}
    />
  );
};

const SelectOrderShipmentWithoutRef = DatabaseConnector(SelectOrderShipmentRaw)(
  'courier_service',
);

export const SelectOrderShipment = React.forwardRef(
  (props: SelectImportTypeProps, ref) => (
    <SelectOrderShipmentWithoutRef {...props} dataRef={ref} />
  ),
);
