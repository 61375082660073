import { useLibbyCall } from './useLibbyCall';
import { LibbyObject } from '../types/types';

export type useLogsByOrderIdOptions = {
  daoName: string;
  id: number;
};

export const useLogsByOrderId = (libby: LibbyObject, { daoName, id }: useLogsByOrderIdOptions) => {
  const { data, working, recall } = useLibbyCall(libby, {
    daoName,
    methodName: 'getAllByOrderId',
    params: [id],
  });

  return { data, working, recall };
};
