import React from 'react';
import {
  TableCell, TableHead, TableRow, TableSortLabel,
} from '@material-ui/core';
import { useTranslation } from '../../services/translation';
import { AnyObject } from '../../types/types';

// TODO: AnyObject!?
export function EnhancedTableHead({
  order, orderBy, onRequestSort, headCells,
}: AnyObject) {
  const createSortHandler = (property: any) => (event: AnyObject) => {
    onRequestSort(event, property);
  };
  const { t } = useTranslation();
  return (
    <TableHead>
      <TableRow>
        {headCells.map(({
          id, label, icon, render, orderById, noSort, ...styles
        }: AnyObject) => (
          <TableCell
            key={id}
            align="left"
            sortDirection={orderBy === id ? order : false}
            {...styles}
          >
            <TableSortLabel
              active={!noSort && (orderBy === id || orderBy === orderById)}
              direction={orderBy === id || orderBy === orderById ? order : 'asc'}
              onClick={!noSort ? createSortHandler(orderById || id) : undefined}
              hideSortIcon={!!noSort}
            >
              {icon ? icon() : t(label)}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}
