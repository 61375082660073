import { AnyObject } from '../../../../../types/types';

type MakeFilterProps = {
  zipCodeSearch?: string,
  citySearch?: string,
  stateSearch?: string,
};

export const makeFilter = ({ zipCodeSearch, citySearch, stateSearch }: MakeFilterProps) => {
  let filter: AnyObject = {};

  if (citySearch) {
    filter = citySearch.split(' ').reduce((accumulator: AnyObject, item, index) => {
      accumulator[index] = [
        {
          path: 'name',
          value: item,
          method: 'includes',
        },
        {
          path: 'matches',
          value: item,
          method: 'includes',
        },
        {
          path: 'state.name',
          value: item,
          method: 'includes',
        },
        {
          path: 'list',
          value: item,
          method: 'includes',
        },
      ];

      const numberSearch = parseInt(item, 10);
      if (numberSearch) {
        accumulator[index].push({
          path: 'city_id',
          value: numberSearch,
        });
        accumulator[index].push({
          path: 'zip',
          value: numberSearch,
        });
      }
      return accumulator;
    }, {});
  }

  if (zipCodeSearch) {
    filter.zipCodeSearch = [
      {
        path: 'zip',
        value: zipCodeSearch,
      },
    ];
  }

  if (stateSearch && stateSearch !== '0') {
    filter.stateSearch = [
      {
        path: 'state.state_id',
        value: stateSearch,
      },
    ];
  }

  return filter;
};
