export enum ACCOUNT_ROLE {
  NOT_ROLE = '1',
  ADMINISTRATOR = '2',
  MANAGEMENT = '3',
  LOGISTICS_OPERATOR = '4',
  DRIVER = '5',
  AGENT = '7',
  FINANCE = '8',
  LOGISTICS = '9',
  HEAD_MANAGEMENT = '10',
}
