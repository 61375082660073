import React from 'react';
import { DatabaseConnector } from '@phinxlab/libby-rest-web';
import { LibbyObject } from '../../types/types';
import { RowsType } from '../../utils/tables';
import { SearchDialogSearchData } from '../../components/SearchDialogSearchData';
import { useLibbyCall } from '../../hooks';
import { ItemsTable } from './ItemsTable';

type ItemsTableProps = {
  order_id: string;
  libby: LibbyObject;
};

const ItemsTableRaw = ({ order_id, libby }: ItemsTableProps) => {
  const { data, working } = useLibbyCall(libby, {
    daoName: 'ster_order_item',
    methodName: 'getAllByOrderId',
    params: [order_id],
  });

  return (
    <SearchDialogSearchData
      working={working}
      properties={['name', 'sku']}
      label="Item/Sku"
      dataProp={data}
      renderItem={(dataProp: { data: Array<RowsType> }) => (
        <ItemsTable items={dataProp.data} />
      )}
    />
  );
};

export const ItemsTableSearch = DatabaseConnector(ItemsTableRaw)('ster_order_item');
