import { LibbyFetchDAO } from './LibbyFetchDAO';

export class OrderSourceDAO extends LibbyFetchDAO {
  constructor() {
    super('ster_order_source', 'orderSource_id');
  }

  getAll() {
    return this.query()
      .limit(0, 10000)
      .run();
  }
}
