import React from 'react';
import { Box } from '@material-ui/core';
import { OrderState, OrderStateColors } from '../../../../../const/OrderState';
import { OrderType } from '../../../../../types/types';
import { formatDate } from '../../../../../utils';

export const columns = [
  {
    id: 'order_log_id',
    label: 'Log ID',
  },
  {
    id: 'order',
    label: 'Order Number',
    render: ({ order: { order_id } }: OrderType) => (
      <Box>{order_id}</Box>
    ),
  },
  {
    id: 'order_state_id',
    label: 'State',
    render: ({ order_state_id }: OrderType, item: any, t: (str: string) => string) => (
      <Box color={OrderStateColors[order_state_id ?? '']}>{t(OrderState[order_state_id ?? ''])}</Box>
    ),
  },
  {
    id: 'updated_by',
    label: 'Updated by',
    render: ({ updated_by }: OrderType) => (
      <Box>{updated_by !== null && updated_by.username}</Box>
    ),
  },
  {
    id: 'updated_at',
    label: 'Change date',
    render: ({ updated_at }: OrderType) => (
      <Box>{formatDate(updated_at, true, false)}</Box>
    ),
  },
  {
    id: 'created_at',
    label: 'Origin date',
    render: ({ created_at }: OrderType) => (
      <Box>{formatDate(created_at, true, false)}</Box>
    ),
  },
];
