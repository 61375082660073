import React from 'react';
import { FormConfig } from 'react-final-form-hooks';
import Dialog from '@material-ui/core/Dialog';
import { ThemeProvider } from '@material-ui/styles';
import { DialogContentText } from '@material-ui/core';
import { useTranslation } from '../../services/translation';
import { InformationSection } from '../InformationSection';

interface ExportDialogType extends FormConfig<any> {
  open: boolean;
  onCancel: any;
  customTheme: string | null;
  title: string;
  render?: Function;
  maxWidth: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | false;
}
const dummyFunc = () => { };
export const ExportDialogComponent = ({
  open = false,
  onCancel = dummyFunc,
  customTheme = null,
  title,
  render,
  maxWidth = 'xs',
}: ExportDialogType) => {
  const { t } = useTranslation();

  const dialog = (
    <Dialog
      open={open}
      onClose={onCancel}
      fullWidth
      maxWidth={maxWidth}
      aria-labelledby="form-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <InformationSection title={t(title)} noPaddingChildren>
        <DialogContentText>{render && render()}</DialogContentText>
      </InformationSection>
    </Dialog>
  );

  return !customTheme ? (
    dialog
  ) : (
    <ThemeProvider theme={customTheme}>{dialog}</ThemeProvider>
  );
};
