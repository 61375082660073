export enum DISPATCH_ITEM_STATE {
  PREPARING_PACKAGE = '1',
  ASSIGNED = '2',
  ASSIGENED_TO_COLLECT = '3',
  PREPARED = '4',
  COLLECTING = '5',
  SENT = '6',
}

export const DISPATCH_ITEM_STATE_NAME: { [k: string]: string } = {
  1: 'PreparingPackage',
  2: 'Assigned',
  3: 'AssignedToCollect',
  4: 'Prepared',
  5: 'Collecting',
  6: 'Sent',
};
