import { Moment } from 'moment';
import { AnyObject } from '../../../types/types';
import { endDateFormatUtc, startDateFormatUtc } from '../../../utils';

type MakeFilterProps = {
  orderId?: string;
  search?: string;
  marketplace?: string;
  orderMarketPlaceSource?: string;
  source?: string;
  shipment?: string;
  typeCourierSearch?: string;
  typeOrderCourierSearch?: string;
  orderStatus?: string;
  startDate?: Moment | null;
  endDate?: Moment | null;
  ownerId?: string;
  orderPrintState?: string;
  dataStatusPrint?: AnyObject;
};

export const makeFilter = ({
  orderId,
  search,
  ownerId,
  marketplace,
  orderMarketPlaceSource,
  source,
  shipment,
  orderStatus,
  startDate,
  endDate,
  typeCourierSearch,
  typeOrderCourierSearch,
  orderPrintState,
  dataStatusPrint,
}: MakeFilterProps) => {
  let filter: AnyObject = {};

  if (search) {
    filter = search.split(' ').reduce((accumulator: AnyObject, item, index) => {
      accumulator[index] = [
        {
          path: 'buyer.first_name',
          value: search,
          method: 'includes',
        },
        {
          path: 'so_number',
          value: item,
          method: 'includes',
        },
        {
          path: 'buyer.first_name',
          value: item,
          method: 'includes',
        },
        {
          path: 'buyer.last_name',
          value: item,
          method: 'includes',
        },
        {
          path: 'buyer.phone_number',
          value: item,
          method: 'includes',
        },
        {
          path: 'buyer.document',
          value: item,
          method: 'includes',
        },
        {
          path: 'shipment.service.courier.name',
          value: item,
          method: 'includes',
        },
        {
          path: 'shipment.service.name',
          value: item,
          method: 'includes',
        },
        {
          path: 'state.name',
          value: item,
          method: 'includes',
        },
        {
          path: 'source.marketplace.name',
          value: item,
          method: 'includes',
        },
        {
          path: 'owner_id',
          value: item,
          method: 'includes',
        },
        {
          path: 'order_id',
          value: item,
          method: 'includes',
        },
        {
          path: 'buyer.zip',
          value: item,
          method: 'includes',
        },
      ];

      const numberSearch = parseInt(item, 10);
      if (numberSearch) {
        accumulator[index].concat([
          {
            path: 'amount',
            value: numberSearch,
          },
        ]);
      }
      return accumulator;
    }, {});
  }

  if (orderStatus && orderStatus !== '0') {
    filter.orderStatus = [
      {
        path: 'state.order_state_id',
        value: orderStatus,
      },
    ];
  } else {
    filter.orderStatus = [];
    dataStatusPrint?.map((accumulator: AnyObject) => {
      filter.orderStatus.push({
        path: 'state.order_state_id',
        value: accumulator.id,
      });
    });
  }

  if (orderPrintState && orderPrintState !== '0') {
    filter.orderPrintState = [
      {
        path: 'print',
        value: JSON.parse(orderPrintState.toLowerCase()),
      },
    ];
  }

  if (ownerId) {
    filter.owner_id = [
      {
        path: 'owner_id',
        value: ownerId,
        method: 'includes',
      },
    ];
  }

  if (orderId) {
    filter.order_id = [
      {
        path: 'order_id',
        value: orderId,
      },
    ];
  }

  if (source && source !== '0') {
    filter.source = [
      {
        path: 'shipment.service.courier.courier_id',
        value: source,
      },
    ];
  }

  if (typeCourierSearch && typeCourierSearch !== '0') {
    filter.typeCourierSearch = [
      {
        path: 'courier.courier_id',
        value: typeCourierSearch,
      },
    ];
  }

  if (typeOrderCourierSearch && typeOrderCourierSearch !== '0') {
    filter.typeOrderCourierSearch = [
      {
        path: 'marketplace.marketplace_id',
        value: typeOrderCourierSearch,
      },
    ];
  }

  if (shipment && shipment !== '0') {
    filter.shipment = [
      {
        path: 'shipment.service.courier_service_id',
        value: shipment,
      },
    ];
  }

  if (marketplace && marketplace !== '0') {
    filter.marketplace = [
      {
        path: 'source.marketplace.marketplace_id',
        value: marketplace,
      },
    ];
  }

  if (orderMarketPlaceSource && orderMarketPlaceSource !== '0') {
    filter.orderMarketPlaceSource = [
      {
        path: 'source.order_source_id',
        value: orderMarketPlaceSource,
      },
    ];
  }
  if (startDate) {
    filter.startDate = [
      {
        path: 'created_at',
        value: startDateFormatUtc(startDate),
        method: 'higherOrEquals',
      },
    ];
  }
  if (endDate) {
    filter.endDate = [
      {
        path: 'created_at',
        value: endDateFormatUtc(endDate),
        method: 'lowerOrEquals',
      },
    ];
  }
  return filter;
};
