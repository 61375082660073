import React, { useMemo, useEffect } from 'react';
import { DatabaseConnector } from '@phinxlab/libby-rest-web';
import { Grid } from '@material-ui/core';
import moment from 'moment';
import { Screen } from '../../../../components/Screen';
import InfoTable, { Column } from '../../../components/InfoTable';
import LoadingData from '../../../components/LoadingData';
import { useTranslation } from '../../../../services/translation';
import { useLibbyCall } from '../../../../hooks';
import { LibbyObject } from '../../../../types/types';
import { ScreenTitle } from '../../../../components/ScreenTitle';
import { ChartPie } from '../../../../chart/ChartPie';
import { format } from '../../../../util';
import { FilterBar, FilterBarSelection } from './FilterBar';

export type ReportingListProps = { libby: LibbyObject };

type AggregateRow = {
  [k: string]: any;
};

type TypeColumn = {
  name: string;
  total: number;
  count: number;
  columns: Array<Column>;
};

const direction = 'asc';
const orderBy = 'order_id';

const filterInit: FilterBarSelection = {
  marketplaces: [],
  courier: [],
  from: moment().subtract(1, 'months').toDate(),
  to: moment().toDate(),
};

const ReportingListRaw = ({ libby }: ReportingListProps) => {
  const { t } = useTranslation();

  // Build the columns, depends on t function for translations
  const columns = useMemo<Array<Column>>(
    () => [
      {
        id: 'name',
        label: 'State',
        render: (value: TypeColumn) => (value.name === 'Total' ? <b>{t(value.name)}</b> : t(value.name)),
      },
      {
        id: 'count',
        label: 'Count',
        render: (value: TypeColumn) => (value.name === 'Total' ? (
          <b>{format(value.count, 'Integer')}</b>
        ) : (
          format(value.count, 'Integer')
        )),
        align: 'right',
      },
      {
        id: 'total',
        label: 'Total',
        render: (value: TypeColumn) => (value.name === 'Total' ? (
          <b>{format(value.total, 'Currency')}</b>
        ) : (
          format(value.total, 'Currency')
        )),
        align: 'right',
      },
    ],
    [t],
  );

  // eslint-disable-next-line arrow-body-style

  const {
    data: dataGroup,
    recall,
    working,
  } = useLibbyCall(libby, {
    daoName: 'ster_order_table',
    methodName: 'getByState',
    params: [filterInit],
  });

  // FIXME CHINO-SDK IS NOT TAKING THE ALIAS, REMOVE THIS WHEN IS OK
  const statistics = useMemo(
    () => dataGroup.reduce((result: any[], element: AggregateRow) => {
      if (element.state_name) {
        result.push({
          count: parseInt(element.countofstate_name, 10),
          total: parseFloat(element.sumofamount),
          name: element.state_name,
        });
      }
      return result;
    }, []),
    [dataGroup],
  );

  const withTotal = useMemo(() => {
    const newArray: Array<any> = [...statistics];
    newArray.push(
      newArray.reduce(
        (transport: AggregateRow, element: AggregateRow) => {
          transport.count += element.count;
          transport.total += element.total;
          return transport;
        },
        { name: 'Total', count: 0, total: 0 },
      ),
    );
    return newArray;
  }, [statistics]);

  const [filter, setFilter] = React.useState<FilterBarSelection>(filterInit);

  useEffect(() => {
    recall?.(filter);
  }, [filter, recall]);

  return (
    <Screen>
      <ScreenTitle title="Orders by state" />
      <Grid container direction="row" justify="center" alignItems="center">
        <FilterBar
          filter={filter}
          onFilter={(barFilter: FilterBarSelection) => {
            setFilter?.(barFilter);
          }}
          initExpanded
        />
        <ChartPie
          data={statistics}
          type="Pie"
          key="state_name"
          value="count"
          height={500}
          format="Integer"
          xs={6}
        />
        <ChartPie
          data={statistics}
          type="Pie"
          key="state_name"
          value="total"
          height={500}
          format="Currency"
          xs={6}
        />
        <InfoTable
          columns={columns}
          rows={withTotal}
          onBottomScroll={() => {}}
          onSortChange={() => {}}
          direction={direction}
          orderBy={orderBy}
          rowIdKey="name"
          height="auto"
          xs={12}
        />
        `
      </Grid>
      <LoadingData working={working} cant={withTotal.length} />
    </Screen>
  );
};

// El dao a usar debe de heredar de LibbyFetchDAO para que funcione
export const ReportingList = DatabaseConnector(ReportingListRaw)('ster_order_table');
