import React, { useMemo, useState, useEffect } from 'react';
import { DatabaseConnector } from '@phinxlab/libby-rest-web';
import {
  TextField,
} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { AnyObject, LibbyObject } from '../../types/types';
import { GeneralSelect } from '../../components/GeneralSelect';
import { useLibbyFetch } from '../../hooks/useLibbyFetch';
import { makeFilter } from '../Orders/routes/OrdersList/utils/makeFilter';

type SelectImportTypeProps = {
  multiple: boolean,
  typeOrderCourierSearch: string,
  inputProps: AnyObject,
  optionAll?: boolean,
  [k: string]: any,
  libby: LibbyObject,
  disabled?: boolean,
};

export const SelectOrderCourierRaw = ({
  multiple = false, typeOrderCourierSearch, libby, inputProps = {}, optionAll = false, disabled = false, ...styles
}: SelectImportTypeProps) => {
  const [disable, setDisable] = useState<boolean>(disabled);
  const filter = useMemo(() => {
    const typeOrderCourierMarketplaceSearch = typeOrderCourierSearch;
    return makeFilter({ typeOrderCourierMarketplaceSearch });
  }, [typeOrderCourierSearch]);

  const paramsFetch = useMemo(() => ({
    daoName: 'courier_order',
    filter,
  }), [filter]);

  const {
    data: dataRaw, working,
  }: { data: AnyObject; working: boolean } = useLibbyFetch(libby, paramsFetch);

  const data = useMemo(
    () => dataRaw.map(({ courier_id, name }: AnyObject) => ({
      id: courier_id, value: name,
    })), [dataRaw],
  );

  useEffect(() => {
    if (typeOrderCourierSearch === '0') {
      setDisable(true);
    }
  }, [typeOrderCourierSearch, disabled, setDisable]);

  return (
    <>
      {multiple ? (
        <Autocomplete
          style={{ display: 'inline-block', width: '100%' }}
          multiple
          id="tags-outlined"
          options={data}
          getOptionLabel={(option: { id: string; value: string }) => option.value}
          filterSelectedOptions
          onChange={inputProps.onChange}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="outlined"
              label="All the courier"
              placeholder=""
            />
          )}
        />
      ) : (
        <GeneralSelect
          disabled={disable}
          working={working}
          label="Courier"
          optionAll={optionAll}
          data={data}
          inputProps={inputProps}
          {...styles}
        />
      )}
    </>
  );
};

export const SelectOrderCourier = DatabaseConnector(SelectOrderCourierRaw)(
  'courier_order',
);
