import React from 'react';
import {
  Switch, Route,
  useRouteMatch,
} from 'react-router-dom';
import { ReportingSalesList } from './routes';

export const ReportingSales = () => {
  const match = useRouteMatch();

  return (
    <Switch>
      <Route exact path={`${match.path}`}>
        <ReportingSalesList />
      </Route>
    </Switch>
  );
};
