import React, { Children, ReactNode } from 'react';
import {
  Typography, Grid, Breadcrumbs, useMediaQuery, Theme,
} from '@material-ui/core';
import { Link } from 'react-router-dom';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { useStyles, breadcrumbsStyles } from './style';
import { useTranslation } from '../../services/translation';
import { TitleBreadcrumbs } from '../../interfaces';

type ScreenTitleProps = {
  title: string | TitleBreadcrumbs[];
  children?: ReactNode;
  centeredTitle?: boolean;
};
type TitleRenderProps = {
  title: string | TitleBreadcrumbs[];
  isDownSm: boolean
};

const TitleRender = ({ title, isDownSm } : TitleRenderProps) => {
  const classes = useStyles();
  return (
    <Typography variant="h1" className={isDownSm ? classes.titleMobile : classes.title}>
      {title}
    </Typography>
  );
};

export const ScreenTitle = ({
  children: buttons,
  title,
  centeredTitle,
}: ScreenTitleProps) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const classesBreadcrumbs = breadcrumbsStyles();
  const isDownSm = useMediaQuery<Theme>((theme) => theme.breakpoints.down('sm'));
  let count = 0;
  return (
    <>
      <Grid
        container
        justify={centeredTitle ? 'center' : 'space-between'}
        alignItems="center"
        className={isDownSm ? classes.titleContainerDownMd : classes.titleContainer}
      >
        <Grid item className={isDownSm ? '' : classes.divTitleIcon}>
          <Breadcrumbs
            aria-label="breadcrumb"
            className={classesBreadcrumbs.root}
          >
            { Array.isArray(title)

              ? isDownSm ? (
                (title.map(({ href, name }, index) => {
                  const goBackTitle = [...title];
                  goBackTitle.splice(-1, 1);
                  goBackTitle.splice(-1, 1);

                  return (
                    title.length - 2 === index
                      && (
                      <Grid key={name}>
                        <Link
                          to={{
                            pathname: href,
                            state: {
                              goBack: goBackTitle,
                            },
                          }}
                        >
                          <ArrowBackIcon className={classes.arrowIcon} />
                        </Link>
                      </Grid>
                      )
                  );
                }))
              ) : (
                (title.map(({ name, href, additionalName }, index) => {
                  const titleShow = `${t(name)} ${additionalName || ''}`;
                  const goBackTitle = [...title];
                  goBackTitle.splice(-1, 1);
                  goBackTitle.splice(-1, 1);
                  return (
                    title.length === 1
                      ? ('') : (
                        title.length - 1 === index ? (
                          <Grid key={name}>
                            <Typography variant="h1" className={isDownSm ? classes.titleBackMobile : classes.bread}>
                              {`${t(titleShow)}`}
                            </Typography>
                          </Grid>
                        ) : (
                          <Grid key={name}>
                            <Link
                              to={{
                                pathname: href,
                                state: {
                                  goBack: goBackTitle,
                                },
                              }}
                            >
                              <Typography variant="h1" className={isDownSm ? classes.titleBackMobile : classes.bread}>
                                {`${t(titleShow)}`}
                              </Typography>
                            </Link>
                          </Grid>
                        )
                      )
                  );
                }))
              ) : ('')}
          </Breadcrumbs>
          {Array.isArray(title)
            ? (title.map(({ name, additionalName }, index) => {
              const titleShow = `${t(name)} ${additionalName || ''}`;
              return (
                title.length - 1 === index
                  ? (
                    <Grid key={name} className={isDownSm ? classes.divTitle : ''}>
                      <TitleRender isDownSm={isDownSm} title={titleShow} />
                    </Grid>
                  ) : (
                    title.length === 1
                    && (
                    <Grid key={name}>
                      <TitleRender isDownSm={isDownSm} title={titleShow} />
                    </Grid>
                    )
                  )

              );
            })
            ) : (
              <Grid>
                <TitleRender isDownSm={isDownSm} title={title} />
              </Grid>
            )}
        </Grid>
        {!!buttons && (
          <Grid item>
            <Grid container spacing={1}>
              {Children.map(buttons, (button: any) => {
                count += 1;
                return (
                  <Grid key={count} item>{button}</Grid>
                );
              })}
            </Grid>
          </Grid>
        )}
      </Grid>
    </>
  );
};
