import React, { useCallback } from 'react';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import { useTranslation } from '../../../../../services/translation';
import { ORDER_STATE } from '../../../../../const/OrderState';
import { AnyObject } from '../../../../../types/types';
import confirmDialog from '../../../../../services/confirmDialog';
import { useGlobalContext } from '../../../../../hooks';

export const ACTIVE_STATES = [ORDER_STATE.DELIVERED, ORDER_STATE.BILL_INFORMED];

export type OrderNeedDeliveryNotificationProps = {
  data?: AnyObject,
  onStateClick: (stateId: number) => void | Promise<void>;
};

export const OrderNeedDeliveryNotification = ({ data, onStateClick }: OrderNeedDeliveryNotificationProps) => {
  const { t } = useTranslation();
  const { userInfo } = useGlobalContext();
  const notActive = !data || !ACTIVE_STATES.includes(+data.state?.order_state_id);
  const stateId = +data?.state?.order_state_id;
  const setBillInformed = useCallback(async () => {
    const confirm = await confirmDialog.show({
      title: t('Confirmation'),
      content: `${t('Do you want set the status to')} "${t('Bill Informed')}" ?`,
      confirmText: `${t('Set it')}!`,
      cancelText: t('No'),
    });
    if (confirm) {
      onStateClick(ORDER_STATE.BILL_INFORMED);
    }
  }, [onStateClick, t]);
  const setTrackerInformed = useCallback(async () => {
    const confirm = await confirmDialog.show({
      title: t('Confirmation'),
      content: `${t('Do you want set the status to')} "${t(stateId === ORDER_STATE.DELIVERED ? 'Bill and Tracker Informed' : 'Bill Informed')}" ?`,
      confirmText: `${t('Set it')}!`,
      cancelText: t('No'),
    });
    if (confirm) {
      onStateClick(ORDER_STATE.TRACKER_INFORMED);
    }
  }, [onStateClick, t, stateId]);
  return notActive || (userInfo.groups[0].id !== '2' && userInfo.groups[0].id !== '8') ? null : (
    <>
      {stateId === ORDER_STATE.DELIVERED && (
        <Grid item>
          <Button
            type="button"
            variant="contained"
            color="primary"
            onClick={setBillInformed}
          >
            {t('Notify Bill')}
          </Button>
        </Grid>
      )}
      <Grid item>
        <Button
          type="button"
          variant="contained"
          color="primary"
          onClick={setTrackerInformed}
        >
          {t(stateId === ORDER_STATE.DELIVERED ? 'Notify Bill and Tracker' : 'Notify Tracker')}
        </Button>
      </Grid>
    </>
  );
};
