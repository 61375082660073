import React from 'react';
import { useParams } from 'react-router-dom';
import { DatabaseConnector } from '@phinxlab/libby-rest-web';
import { CityDetailData } from './components/CityDetailData';
import { useLibbyFetchById, useBreadcrumbs } from '../../../../hooks';
import { LibbyObject } from '../../../../types/types';
import { ScreenAligned } from '../../../../components/ScreenAligned/ScreenAligned';
import { TitleBreadcrumbs } from '../../../../interfaces';

type OrderDetailProps = { libby: LibbyObject; title: TitleBreadcrumbs[] };

const CitiesDetailRaw = ({ libby, title }:OrderDetailProps) => {
  const { id } = useParams<{id:string}>();
  const { working, data } = useLibbyFetchById(libby, { daoName: 'ster_city', id });
  const titleShow = useBreadcrumbs('City', title, `#${id}`);

  return (
    <ScreenAligned
      title={titleShow}
      working={working}
      showData={!!data}
    >
      <CityDetailData data={data} />
    </ScreenAligned>
  );
};

export const CitiesDetail = DatabaseConnector(CitiesDetailRaw)('ster_city');
