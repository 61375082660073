import React from 'react';
import { Box, Card } from '@material-ui/core';
import { useTranslation } from '../../services/translation';
import { format } from '../../util/format';

interface CustomTooltipType {
  active?: boolean;
  payload?: Array<{
    payload: {
      total: string;
      name: string;
    };
  }>;
}

export const CustomTooltip = ({ active, payload }: CustomTooltipType) => {
  const { t } = useTranslation();
  if (active && payload?.length) {
    return (
      <Card>
        <Box bgcolor="white" p={2}>
          <p className="label">
            {`${t('Total')}: ${format(
              payload[0]?.payload?.total,
              'Currency',
              t,
            )}`}
          </p>
          <p className="label">
            {`${t('Name')}: ${t(payload[0]?.payload?.name)}`}
          </p>
        </Box>
      </Card>
    );
  }
  return null;
};
