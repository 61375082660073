import { useRef, useState } from 'react';
import { ButtonsCopyResetTypes } from '../types/types';

export const ButtonsCopyReset = ({
  buyerDepartment,
  buyerFloor,
  buyerStreet,
  buyerStreetNumber,
  buyerExtraAddress,
  buyerZip,
  buyerCity,
  buyerProvince,
  street,
  street_number,
  zip,
  floor,
  department,
  extra_address,
  city,
  province,
  formInitialValues,
  cityInputRef,
}: ButtonsCopyResetTypes) => {
  const [copiedCity, setCopiedCity] = useState<null | string>();
  const timeout = useRef<NodeJS.Timeout>();
  const handleCancelCopy = () => {
    if (formInitialValues) {
      street.input.onChange(formInitialValues.street);
      street_number.input.onChange(formInitialValues.street_number);
      zip.input.onChange(formInitialValues.zip);
      floor.input.onChange(formInitialValues.floor);
      department.input.onChange(formInitialValues.department);
      extra_address.input.onChange(formInitialValues.extra_address);
      city.input.onChange(formInitialValues.city);
      province.input.onChange(formInitialValues.province);
    }
  };

  const handleCopyAddress = () => {
    province.input.onChange(buyerProvince.input.value);
    street.input.onChange(buyerStreet.input.value);
    street_number.input.onChange(buyerStreetNumber.input.value);
    zip.input.onChange(buyerZip.input.value);
    floor.input.onChange(buyerFloor.input.value);
    department.input.onChange(buyerDepartment.input.value);
    extra_address.input.onChange(buyerExtraAddress.input.value);
    city.input.onChange(buyerCity.input.value);
    setCopiedCity(buyerCity.input.value);

    if (!cityInputRef || !cityInputRef.current || !cityInputRef.current.value) return;
    timeout.current = setTimeout(() => {
      cityInputRef.current.value = buyerCity.input.value;
      clearTimeout(timeout.current as NodeJS.Timeout);
    }, 2000);
  };

  return {
    handleCancelCopy,
    handleCopyAddress,
    copiedCity,
    setCopiedCity,
  };
};
