export const openInNewTab = (url: string = '', base64?: string) => {
  const pdfWindow = base64
    ? window.open(url)
    : window.open(url, '_blank', 'noopener,noreferrer');
  if (pdfWindow) {
    if (base64) {
      pdfWindow.document.write(
        `<iframe width='100%' height='100%' src='data:application/pdf;base64, ${encodeURI(
          base64,
        )}'></iframe>`,
      );
    }
    pdfWindow.opener = null;
  }
};
