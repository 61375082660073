import React, { useCallback, useState } from 'react';
import { cloneDeep, flatten } from 'lodash';
import { useSnackbar } from 'notistack';
import { ExportDialogComponent } from '../../../components/ExportDialog';
import { DISPATCH_ITEM_STATE, ORDER_DOCUMENT_TYPE, ORDER_STATE } from '../../../const';
import { Dispatch, Dispatch_item, Order_document, Order_item, Product, Order, Collect_item_product } from '../../../interfaces/business';
import { AnyObject, LibbyObject } from '../../../types';
import CustomModal from '../../../services/customFormDialog';
import { DocumentsTable, documentsColumns } from '../../components/DocumentsTable';
import { filterDispatchItemsByState } from '../utils/filter';
import { generatorUrlBarcode } from '../../../utils';
import { OddoOrder, sendDataToOddo } from '../utils/oddo';
import { useTranslation } from '../../../services/translation';
import { OrderWithUrl } from '../../../interfaces';
import ConfirmDialog from '../../../components/ConfirmDialog';
import { ModalTitle } from '../../Collection/common';
import { TagMangerViewPdf } from '../../TagManager/routes/TagManagerViewPdf/TagManagerViewPdf';
import { addProducts } from '../../TagManager/functions/AddProducts';

interface Checked {
  all: Dispatch_item[] | AnyObject;
}

interface prepareOrderHook {
  checked: Checked;
  dispatchData: Dispatch;
  checkIfDispatchIsReady: (dispatch: Dispatch) => void;
  setNewData: (dispatch: Dispatch) => void;
  setOrderItems: (items: Dispatch_item[]) => void;
}

const SearchDialogModal = CustomModal(ExportDialogComponent);
const ConfirmModal = CustomModal(ConfirmDialog);

export const usePrepareOrder = (libby: LibbyObject, { checked, dispatchData, checkIfDispatchIsReady, setNewData, setOrderItems }: prepareOrderHook) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const [tagView, setTagView] = useState(<></>);
  const [showModalTagManagerView, setShowModalTagManagerView] = useState(false);
  const [preparingOrder, setPreparingOrder] = useState(false);

  const showReminderPrint = useCallback(async () => {
    await ConfirmModal.show({
      title: <ModalTitle title="Reminder" />,
      content: `${t('Please print documents and put them in its correct ubication')}`,
      confirmText: t('Accept'),
      oneButton: true
    });
  }, [t]);

  const showReminderCheck = useCallback(
    async ({ items }: { items: number }) => {
      if (items === checked.all.length) return;
      await ConfirmModal.show({
        title: <ModalTitle title="Reminder" />,
        content: `${t('From $fromItems items this will just take $toItems').replace('$fromItems', checked.all.length).replace('$toItems', items.toString())}`,
        confirmText: t('Accept'),
        oneButton: true
      });
    },
    [t, checked]
  );

  /*
  it is very possible that data inside checked.all is unupdated so
  this function returns me the data updated. the right approach is update "checked.all" every time new data comes from API
  I tried to do that but  there is a lot of code that I don't understand and I don't have enough time
  */
  const giveMeCheckDataUpdated = useCallback(
    () =>
      checked.all.map((item: Dispatch_item) => {
        const updatedItem = dispatchData.items.find(({ dispatch_item_id }: Dispatch_item) => dispatch_item_id === item.dispatch_item_id);
        return updatedItem || item;
      }),
    [checked, dispatchData]
  );

  const getDocuments = useCallback(
    async (data: { order_id: number }[]) => {
      try {
        const allDocuments = await Promise.all(data.map(({ order_id }: { order_id: number }) => libby.ster_order_document.getAllByOrderId(order_id)));
        return flatten(allDocuments);
      } catch (err) {
        enqueueSnackbar(err.toString(), { variant: 'error' });
        return [];
      }
    },
    [libby, enqueueSnackbar]
  );

  const onPrepareOrder = useCallback(async () => {
    if (preparingOrder) {
      enqueueSnackbar(t('Some orders are being prepared right now, please wait'), { variant: 'info' });
      return;
    }
    setPreparingOrder(true);
    enqueueSnackbar(t('Preparing orders, please wait'), {
      variant: 'info'
    });
    const dispatchItemsToPrepare = filterDispatchItemsByState({
      dispatchItems: giveMeCheckDataUpdated(),
      orderState: ORDER_STATE.WAITING_FOR_PREPARATION.toString(),
      dispatchItemState: DISPATCH_ITEM_STATE.PREPARING_PACKAGE
    });
    await showReminderCheck({ items: dispatchItemsToPrepare.length });
    const dataForOddo: OddoOrder[] = [];
    /* eslint-disable no-await-in-loop */
    for (let i = 0; i < dispatchItemsToPrepare.length; i++) {
      const dispatchItem = dispatchItemsToPrepare[i];
      const {
        order: { order_id, so_number: so, items }
      } = dispatchItem;
      const serial_numbers = [];
      /* eslint-disable no-await-in-loop */
      for (let j = 0; j < items.length; j++) {
        const { sku, order_item_id } = items[j];
        const collectItemsProduct = await libby.ster_collect_item_product.getByOrderItemId(order_item_id);
        serial_numbers.push({
          SKU: sku,
          SN: collectItemsProduct.map((collectItemProduct: Collect_item_product) => collectItemProduct.stock?.serial_number)
        });
      }
      dataForOddo.push({
        order_id: Number(order_id),
        so: so || '',
        serial_numbers
      });
    }

    try {
      await sendDataToOddo({ orders: dataForOddo });
      let response = await Promise.all(
        dispatchItemsToPrepare.map((item: Dispatch_item) =>
          libby.ster_order_table.save({
            ...item.order,
            state: {
              order_state_id: ORDER_STATE.READY_FOR_DELIVERY
            }
          })
        )
      );
      const updatedDispatch = cloneDeep(dispatchData);
      response = flatten(response);
      response.forEach((order: Order) => {
        const index = dispatchData.items.findIndex((item: Dispatch_item) => item.order.order_id === order.order_id);
        updatedDispatch.items[index] = {
          ...updatedDispatch.items[index],
          order: { ...updatedDispatch.items[index].order, ...order }
        };
      });
      setNewData(updatedDispatch);
      setOrderItems(updatedDispatch.items || []);
      enqueueSnackbar(t('Saved changes'), { variant: 'success' });
    } catch (err) {
      if (Array.isArray(err)) {
        const [error] = err;
        enqueueSnackbar(error?.error || error.toString(), {
          variant: 'error'
        });
        return;
      }
      enqueueSnackbar(err.toString(), { variant: 'error' });
    } finally {
      setPreparingOrder(false);
    }
  }, [libby, enqueueSnackbar, t, giveMeCheckDataUpdated, showReminderCheck, dispatchData, setNewData, setOrderItems, preparingOrder]);

  // TODO: cada vez que presionan este boton le estoy pegando a la base, tengo que cachear data
  const handleOpenInvoiceList = useCallback(async () => {
    const ordersReadyForDelivery = filterDispatchItemsByState({
      dispatchItems: giveMeCheckDataUpdated(),
      orderState: ORDER_STATE.READY_FOR_DELIVERY.toString(),
      dispatchItemState: DISPATCH_ITEM_STATE.PREPARING_PACKAGE
    }).map(({ order: { order_id } }: Dispatch_item) => ({
      order_id: Number(order_id)
    }));
    await showReminderCheck({ items: ordersReadyForDelivery.length });
    const allDocuments = await getDocuments(ordersReadyForDelivery);
    await showReminderPrint();
    const invoicesAndRemitos = allDocuments.filter(({ type: { order_document_type_id } }: Order_document) => order_document_type_id === ORDER_DOCUMENT_TYPE.INVOICE || order_document_type_id === ORDER_DOCUMENT_TYPE.REMITO);
    try {
      await SearchDialogModal.show({
        title: 'Documents',
        id: 'order_document_id',
        properties: ['documentNumber', 'type.name'],
        label: 'Document',
        data: invoicesAndRemitos,
        maxWidth: 'md',
        render: () => (
          <DocumentsTable
            documents={invoicesAndRemitos}
            columns={[
              {
                id: 'orderId',
                label: 'Order',
                render: (row: any) => row?.order?.order_id || ''
              },
              ...documentsColumns
            ]}
          />
        )
      });
    } catch (error) {
      // nothing
    }
  }, [getDocuments, showReminderPrint, giveMeCheckDataUpdated, showReminderCheck]);

  // TODO: cada vez que presionan este boton le estoy pegando a la base, tengo que cachear data

  const handleOpenLabels = useCallback(async () => {
    const ordersReadyForDelivery = filterDispatchItemsByState({
      dispatchItems: giveMeCheckDataUpdated(),
      orderState: ORDER_STATE.READY_FOR_DELIVERY.toString(),
      dispatchItemState: DISPATCH_ITEM_STATE.PREPARING_PACKAGE
    }).map(({ order: { order_id } }: Dispatch_item) => order_id);

    const allOrderID = ordersReadyForDelivery.reduce((accumOrder: string[], order_id: string) => {
      accumOrder.push(order_id);
      return accumOrder;
    }, []);

    const response = await libby.ster_order_so.fetchAllById(allOrderID);

    const allResponse = flatten(response) as OrderWithUrl[];

    const ordersSource = allResponse.map((item: OrderWithUrl) => ({
      ...item,
      urlBarcode: generatorUrlBarcode(item.order_id)
    }));

    showReminderCheck({ items: ordersSource.length });
    await showReminderPrint();
    const component = <TagMangerViewPdf dataChecked={{ all: ordersSource }} resetCheck={() => {}} title={[]} savePrint={() => {}} disableScreenType />;
    setShowModalTagManagerView(true);
    setTagView(component);
  }, [libby, showReminderPrint, giveMeCheckDataUpdated, showReminderCheck]);

  const updateDataWithProducts = useCallback(
    (product: Product) => {
      const resultDatas = dispatchData.items.filter(({ order }: Dispatch_item) => order.items.filter((item: Order_item) => item.sku === product.sku).length > 0);

      resultDatas.forEach((itemUpdate: Dispatch_item) => {
        const newDispatchItem = { ...itemUpdate };
        const items = [...dispatchData.items];
        const orderItems = newDispatchItem.order.items;

        const itemModify = newDispatchItem.order.items.findIndex((item: Order_item) => item.sku === product.sku);

        orderItems[itemModify] = { ...orderItems[itemModify], product };

        const searchItemId = dispatchData.items.findIndex((dispatch_item: Dispatch_item) => dispatch_item.dispatch_item_id === itemUpdate.dispatch_item_id);

        items[searchItemId] = {
          ...items[searchItemId],
          order: {
            ...items[searchItemId].order,
            items: orderItems
          }
        };

        setNewData({
          ...dispatchData,
          items
        });
        setOrderItems(dispatchData.items || []);
      });
    },
    [dispatchData, setNewData, setOrderItems]
  );

  const handleOpenLabelsModalProducts = useCallback(() => {
    addProducts({
      orders: checked.all.map(({ order }: Dispatch_item) => order),
      updateDataWithProducts,
      redirectPrint: handleOpenLabels
    });
  }, [checked, handleOpenLabels, updateDataWithProducts]);

  const onCompletePackage = useCallback(async () => {
    const dispatchItems: Dispatch_item[] = filterDispatchItemsByState({
      dispatchItems: giveMeCheckDataUpdated(),
      orderState: ORDER_STATE.READY_FOR_DELIVERY.toString(),
      dispatchItemState: DISPATCH_ITEM_STATE.PREPARING_PACKAGE
    });
    showReminderCheck({ items: dispatchItems.length });
    const updatedDispatchItems = dispatchItems.map((item: Dispatch_item) => ({
      ...item,
      dispatch_item_state: {
        dispatch_item_state_id: DISPATCH_ITEM_STATE.PREPARED
      }
    }));
    try {
      const dispatch = await libby.ster_dispatch_details_update.aspect('list_dispatch_details').save({ ...dispatchData, items: updatedDispatchItems });
      const dispatchUpdated = { ...dispatchData, ...dispatch };
      setNewData(dispatchUpdated);
      setOrderItems(dispatchUpdated.items || []);
      checkIfDispatchIsReady(dispatchUpdated);
    } catch (err) {
      enqueueSnackbar(err.toString(), { variant: 'error' });
    }
  }, [libby.ster_dispatch_details_update, checkIfDispatchIsReady, enqueueSnackbar, giveMeCheckDataUpdated, showReminderCheck, dispatchData, setNewData, setOrderItems]);

  return {
    onPrepareOrder,
    handleOpenInvoiceList,
    handleOpenLabels,
    onCompletePackage,
    tagView,
    setTagView,
    showModalTagManagerView,
    setShowModalTagManagerView,
    checkIfDispatchIsReady,
    handleOpenLabelsModalProducts
  };
};
