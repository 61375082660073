import { useMemo, useCallback } from 'react';
import { useLibbyFetch, OptionsLibbyFetch } from '../../../hooks/useLibbyFetch';
import { AnyObject, LibbyObject } from '../../../types';
import { RowsType } from '../../../utils/tables';
import { columns } from '../utils/columns';

type useDispatchesLogicProps = {
  libby: LibbyObject;
  paramsFetch: OptionsLibbyFetch;
};

type sterConfigType = {
  active?: boolean;
  frequency?: number;
  lastState?: string;
  last_execution?: string;
  metadata?: AnyObject;
  name?: string;
  ster_config_id: string;
  success?: string | null;
};

export const useSterConfigLogic = ({
  libby,
  paramsFetch,
}: useDispatchesLogicProps) => {
  const { data, working, fetchMore } = useLibbyFetch(libby, paramsFetch);

  const fetchMoreMemo = useCallback(() => {
    if (!working) {
      fetchMore();
    }
  }, [working, fetchMore]);

  const rows: RowsType[] = useMemo(() => {
    const dataSterConfig = data as sterConfigType[];

    return dataSterConfig.reduce(
      (
        allSterConfigRows: RowsType[],
        {
          active,
          frequency,
          lastState,
          last_execution,
          metadata,
          name,
          ster_config_id,
          success,
        }: sterConfigType,
      ) => {
        allSterConfigRows.push({
          id: ster_config_id,
          active,
          frequency,
          lastState,
          last_execution,
          metadata: JSON.stringify(metadata, null, 2),
          name,
          ster_config_id,
          success,
        });

        return allSterConfigRows;
      },
      [],
    );
  }, [data]);

  return {
    working,
    data,
    rows,
    columns,
    fetchMoreMemo,
  };
};
