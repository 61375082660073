import React, { useCallback, MouseEventHandler } from 'react';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/styles';
import { useTranslation } from '../../../services/translation';

type MenuDrawerItemProps = {
  label: string;
  icon?: React.ReactNode;
  disabled?: boolean;
  path?: string;
  onItemClick?: MouseEventHandler | null;
  className?: string;
};

const useStyles = makeStyles(() => ({
  marginTextItemText: {
    textAlign: 'left',
  },
}));

const MenuDrawerItem = ({
  label,
  icon: Icon,
  disabled = false,
  path = '/',
  onItemClick = null,
  className,
}: MenuDrawerItemProps) => {
  const history = useHistory();
  const classes = useStyles();
  const { t }: { t: Function } = useTranslation();
  const onClick: MouseEventHandler = useCallback(() => {
    history.push(path);
  }, [path, history]);
  return (

    <ListItem disabled={disabled} button onClick={onItemClick || onClick} className={className}>
      {Icon && <ListItemIcon>{Icon}</ListItemIcon>}
      <ListItemText className={classes.marginTextItemText} primary={t(label)} />
    </ListItem>
  );
};

export default MenuDrawerItem;
