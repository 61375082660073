/* eslint-disable max-len */
export default {
  Menu: 'Menú',
  'Our Conferences': 'Nuestras Conferencias',
  'Network Error': 'Error de conexión',
  Conferences: 'Conferencias',
  Export: 'Exportar',
  Dispatch: 'Despacho',
  'Update Dispatch': 'Actualizar Despacho',
  'Dispatch saved': 'Despacho guardado',
  'Edit Dispatch': 'Editar Despacho',
  'Dispatch Inquiry': 'Consulta de Despachos',
  'Dispatches Inquiry': 'Consulta de Despachos',
  'Dispatch Detail': 'Detalle del Despacho',
  'Create Dispatch': 'Crear Despacho',
  'Order id': 'Id de Orden',
  'Total days': 'Días totales',
  'Days per area': 'Días por área',
  'Total Price': 'Precio Total',
  Delete: 'Borrar',
  Settings: 'Ajustes',
  'of total orders': 'del total de órdenes',
  'Status according to time since the order was created':
    'Estado según tiempo desde creación de la orden',
  'State according to time in each area': 'Estado según tiempo en cada área',
  Logout: 'Cerrar Sesión',
  'Aging report': 'Antigüedad de órdenes',
  'ID Order': 'ID de Orden',
  'Detail by orders': 'Detalle por órdenes',
  'Generate Report': 'Generar Reporte',
  'CSV Delimiter': 'Delimitador CSV',
  'What delimiter does the uploaded file use?':
    '¿Qué delimitador utiliza el archivo cargado?',
  Delimiter: 'Delimitador',
  Institute: 'Instituto',
  Login: 'Iniciar Sesión',
  English: 'Inglés',
  Spanish: 'Español',
  Conference: 'Conferencia',
  'CSV Export': 'Exportación CSV',
  "To print the tags you must upload the file to Andreani's portal.":
    'Para imprimir las etiquetas debe subir el archivo al portal de Andreni.',
  Search: 'Buscar',
  Reset: 'Reiniciar',
  'Delimiter Required': 'Delimitador Requerido',
  'Start Date': 'Fecha desde',
  'Enter a type': 'Ingrese un tipo',
  'End Date': 'Fecha hasta',
  'New Conference': 'Nueva Conferencia',
  'Create it': 'Crear',
  'Number of': 'Cantidad de',
  'Rows per page:': 'Filas por página:',
  'Log ID': 'ID de Historial',
  'Order Number': 'Número de Orden',
  Sales: 'Ventas',
  'Old State Record': 'Viejo Estado',
  'New State Record': 'Nuevo Estado',
  'Modded From': 'Modificado Desde',
  'Created At': 'Fecha del Cambio',
  'Order Logs': 'Historial de Orden',
  Logs: 'Historial',
  'Order time report': 'Reporte de tiempos de órdenes',
  Orders: 'Órdenes',
  Name: 'Nombre',
  Source: 'Origen',
  State: 'Estado',
  Order: 'Orden',
  Pulled: 'Retirado',
  Match: 'Coincidencia',
  Close: 'Cerrar',
  'Update Location': 'Actualizar Localidad',
  'Edit Location': 'Editar Localidad',
  'Create Location': 'Crear Localidad',
  'State Name': 'Nombre de la provincia',
  'Province Name': 'Nombre de la provincia',
  'City Name': 'Nombre de la localidad',
  'City Information': 'Información de la localidad',
  'State Information': 'Información provincial',
  'City Matchs': 'Partidos de la provincia',
  'State Matchs': 'Coincidencias provinciales',
  'Cities List': 'Listado de Localidades',
  Cities: 'Ciudades',
  Evaluated: 'Evaluada',
  OK: 'OK',
  Province: 'Provincia',
  'Province Id': 'Id de Provincia',
  Problem: 'Con Problemas',
  'Manually Fixed': 'Corregida manualmente',
  Provinces: 'Provincias',
  Created: 'Creado',
  Sold: 'Vendido',
  'Location updated': 'Localidad Actualizada',
  'Location created': 'Localidad Creada',
  'On queue': 'En cola',
  'Number of Documents': 'Cantidad de documentos',
  'Number of Items': 'Cantidad de ítems',
  'Waiting for preparation': 'Esperando Preparación',
  'Waiting For Preparation': 'Esperando Preparación',
  Prepared: 'Preparado',
  Documents: 'Documentos',
  'Ready for delivery': 'Listo para la entrega',
  'Ready For Delivery': 'Listo para la entrega',
  'Out for delivery': 'En camino',
  'Out For Delivery': 'En camino',
  Delivered: 'Entregado',
  Cancelled: 'Cancelado',
  Production: 'Producción',
  'Ready to resend': 'Listo para reenviar',
  'Ready To Resend': 'Listo para reenviar',
  'Ready to Resend': 'Listo para reenviar',
  'Do you want to accept the order?': '¿Quieres aceptar la orden?',
  'Reject Order': 'Rechazar Orden',
  'Do you want to reject the order?': '¿Quieres rechazar la orden?',
  'Do you want to process the import?': '¿Querés procesar la importación?',
  Date: 'Fecha',
  Participants: 'Participantes',
  'Delete File': 'Borrar archivo',
  'Do you want to delete this file?': '¿Quieres borrar este archivo?',
  Description: 'Descripción',
  'Person to visit': 'Persona a visitar',
  'Please fill out this field.': 'Por favor complete este campo.',
  'The value must be a positive number': 'El valor debe ser un número positivo',
  'Edit Conference': 'Editar Conferencia',
  'Save it': 'Guardar',
  'Sign in': 'Entrar',
  'Sign In': 'Entrar',
  "Don't have an account? Sign Up": '¿No tenés una cuenta? Registrate',
  'Already have an account? Sign in': '¿Ya tenés una cuenta? Inicia Sesión',
  'Sign up': 'Registrar',
  'Sign Up': 'Registrar',
  Enroll: 'Inscribirse',
  'Do you want to enroll to': '¿Querés inscribirte a',
  Profile: 'Perfil',
  'Request failed with status code 404': 'Falló de Petición: 404.',
  'Request failed with status code 400': 'Falló de Petición: 400.',
  Email: 'Correo Electrónico',
  Password: 'Contraseña',
  'The provided authorization grant (e.g., authorization code, resource owner credentials) or refresh token is invalid, expired, revoked, does not match the redirection URI used in the authorization request, or was issued to another client.':
    'Credenciales Inválidas',
  'Available positions': 'Cupo disponible',
  'Signed Users': 'Usuarios Inscriptos',
  'First Name': 'Nombre',
  'Saved files': 'Archivos guardados',
  'Updated status': 'Estado actualizado',
  'Deleted file': 'Archivo borrado',
  'Import created': 'Importación creada',
  'Last Name': 'Apellido',
  'Repeat Password': 'Repita la Contraseña',
  'Passwords must match!': 'Las Contraseñas deben coincidir!',
  'The email has already been taken.':
    'El Correo Electrónico ingresado ya existe.',
  'The value must be a valid email':
    'El valor debe ser un Correo Electrónico válido',
  'Remove Confirmation': 'Confirmación de Eliminación',
  'Do you want to remove': '¿Querés eliminar',
  'Remove it': 'Eliminar',
  No: 'No',
  Saved: 'Guardado',
  'SQLSTATE[22P02]: Invalid text representation: 7 ERROR:  invalid input syntax for integer: "new" (SQL: select * from "conferences" where "id" = new limit 1)':
    'Se produjo un error SQLSTATE[22P02] al generar o editar un registro. Posiblemente haya un dato vacío o mal ingresado. Por favor, vuelva a intentar ingresarlo en unos minutos.',
  'SQLSTATE[22P02]: Invalid text representation: 7 ERROR:  invalid input syntax for integer: "[object Object]" (SQL: select * from "conferences" where "id" = [object Object] limit 1)':
    'Se produjo un error SQLSTATE[22P02] al editar un registro. El objeto que se quería modificar no existe o no se encuentra. Posiblemente haya sido dado de baja o cambiado de estado. Por favor, vuelva a intentarlo en unos minutos.',
  'Permission denied!': '¡Permiso Denegado!',
  'Sign Up Confirmation': 'Confirmación de Inscripción',
  'Do you want to sign up to': '¿Querés inscribirte a',
  'Unsubscribe Confirmation': 'Confirmación de Desinscripción',
  'Do you want to unsubscribe': '¿Querés desinscribirte de',
  Unsubscribe: 'Desinscribir',
  'Unauthenticated.': 'No autenticado.',
  'SQLSTATE[22P02]: Invalid text representation: 7 ERROR:  invalid input syntax for integer: "undefined" (SQL: select * from "conferences" where "id" = undefined limit 1)':
    'Se produjo el error SQLSTATE[22P02]. El dato no se ingresó correctamente y no es posible autentificarse. Por favor, vuelva a ingresarlo en unos minutos.',
  'Expired conference': 'Conferencia expirada',
  'Do you want to remove your inscription for': '¿Querés desinscribirte de ',
  'No query results for model [App\\Conference].': 'No hay datos para mostrar',
  'Upload File': 'Subir Archivo',
  'Select the source': 'Seleccione la fuente',
  'The name field is required.': 'El campo Nombre es requerido.',
  'This conference already has full places':
    'La conferencia no tiene plazas disponibles',
  'Visits List': 'Listado de Visitas',
  'People List': 'Listado de Personas',
  'No match': 'Sin coincidencias',
  Loading: 'Cargando',
  People: 'Personas',
  Visits: 'Visitas',
  Username: 'Nombre de usuario',
  Locations: 'Ubicaciones',
  Localities: 'Localidades',
  User: 'Usuario',
  Type: 'Tipo',
  Capture: 'Captura',
  'Invalid user': 'Usuario inválido',
  'Philsen App': 'Aplicación Philsen',
  'New Visit': 'Nueva Visita',
  Document: 'Documento',
  Restore: 'Restaurar',
  Scan: 'Escanear',
  Dashboard: 'Tablero',
  Genre: 'Genero',
  Logistics: 'Logística',
  Operations: 'Operaciones',
  'Birth Date': 'Fecha de Nacimiento',
  'Card Number': 'Número de Tarjeta',
  Cancel: 'Cancelar',
  Save: 'Guardar',
  Female: 'Femenino',
  Male: 'Masculino',
  All: 'Todos',
  'Date should not be before minimal date':
    'La fecha no debe ser anterior a la fecha mínima',
  'Date should not be after maximal date':
    'La fecha no debe ser posterior a la fecha máxima',
  'Something is wrong': 'Algo salió mal',
  'Please try again later': 'Por favor vuelva a intentarlo más tarde',
  'Visit saved': 'Visita guardada satisfactoriamente',
  'Searching Person': 'Buscando persona',
  Yes: 'Si',
  'Confirm new import': 'Confirmar nueva importación',
  'Change Password': 'Cambiar Contraseña',
  'Export Orders': 'Exportar Órdenes',
  'New external data will be imported into the system, are you sure to proceed with type $$$$?':
    'Se importaran nuevos datos externos al sistema.¿Está seguro de proceder con el tipo $$$$?',
  'Do you want to change the account password of the user $$$$?':
    '¿Quieres cambiar la contraseña del usuario $$$$?',
  'Do you want to change the account role of the user $$$$?':
    '¿Quieres cambiar el rol del usuario $$$$?',
  'Do you want to change the account name of the user $$$$?':
    '¿Quieres cambiar el nombre del usuario $$$$?',
  'Do you want to disable the account of the user $$$$?':
    '¿Quieres deshabilitar al usuario $$$$?',
  'Do you want to enable the account of the user $$$$?':
    '¿Quieres habilitar al usuario $$$$?',
  Role: 'Rol',
  Administrator: 'Administrador',
  'No Role': 'Sin rol',
  Disable: 'Deshabilitar',
  Enable: 'Habilitar',
  Enabled: 'Habilitado',
  Disabled: 'Deshabilitado',
  Accounts: 'Cuentas',
  Status: 'Estado',
  Create: 'Crear',
  'Creation Day': 'Fecha de Creación',
  'Import Type': 'Tipo de Importación',
  'Import type selection': 'Selección de tipo de importación',
  'Select type of import': 'Seleccione el tipo de importación',
  'Enable Account': 'Habilitar Cuenta',
  'Disable Account': 'Deshabilitar Cuenta',
  'Search User': 'Buscar usuario',
  'Check Availability': 'Verificar Disponibilidad',
  'El usuario ya existe': 'User already exists',
  'Create Account': 'Crear Cuenta',
  'No coincidences': 'Sin coincidencias',
  'Create Import': 'Crear Importación',
  'Imports List': 'Listado de Importaciones',
  'Orders List': 'Listado de Órdenes',
  'Inbox Orders List': 'Listado de Órdenes de Entrada',
  'Orders Entry': 'Entrada de Órdenes',
  'Inbox Order State ID': 'ID de Estado de Orden de Entrada',
  'Inbox Order': 'Orden de Entrada',
  Inbox: 'Entradas',
  Reference: 'Referencia',
  Imports: 'Importaciones',
  Import: 'Importación',
  'File deleted successfully': 'Archivo eliminado exitoxamente',
  'Updated Password': 'Contraseña Actualizada',
  'Updated Username': 'Nombre de Usuario Actualizado',
  'Updated Role': 'Rol Actualizado',
  Matches: 'Coincidencias',
  'comma delimited': 'delimitados por coma',
  Update: 'Actualizar',
  'Disabled User': 'Usuario Deshabilitado',
  'Upload Files': 'Cargar Archivos',
  'Process Import': 'Procesar importación',
  Detail: 'Detalle',
  Accept: 'Aceptar',
  Owner: 'Propietario',
  'Failed to delete': 'No se pudo borrar',
  'Uploaded Files': 'Archivos cargados',
  'Save Files': 'Guardar Archivos',
  'Pending Processing': 'Procesamiento pendiente',
  Pending: 'Pendiente',
  Processing: 'Procesando',
  'Pending approbation': 'Pendiente de aprobación',
  Accepted: 'Aceptado',
  Rejected: 'Rechazado',
  Failure: 'Error',
  Example: 'Ejemplo',
  Example2: 'Ejemplo2',
  'Last Update': 'Última modificación',
  'Cannot upload file, reason:': 'No se pudo subir el archivo, razon:',
  'Marketplace ID': 'Id del Marketplace',
  Amount: 'Monto',
  Tax: 'Impuesto',
  Exit: 'Salir',
  Total: 'Total',
  created: 'Creado',
  Selled: 'Vendido',
  Paid: 'Cobrado',
  'Upload to collections': 'Subido a cobranzas',
  'waiting for preparation': 'esperando preparación',
  Onqueue: 'En Cola',
  'ready for delivery': 'Listo para la entrega',
  'out for delivery': 'en camino',
  delivered: 'Entregado',
  cancelled: 'Cancelado',
  'Order Detail': 'Detalle de la Órden',
  'Basic Information': 'Información Básica',
  Buyer: 'Comprador',
  'Document Type': 'Tipo de Documento',
  'Phone Number': 'Número de Teléfono',
  Address: 'Dirección',
  'Zip Code': 'Código Postal',
  'Shipment Cost': 'Costo de envío',
  Ster: 'Ster',
  Marketplace: 'Marketplace',
  Shipment: 'Envío',
  Service: 'Servicio',
  Courier: 'Mensajería',
  'Shipment Address': 'Dirección de envío',
  Comments: 'Comentarios',
  Receiver: 'Receptor',
  Tracking: 'Seguimiento',
  Items: 'Ítems',
  Sku: 'Sku',
  Zip: 'Código Postal',
  Quantity: 'Cantidad',
  Price: 'Precio',
  Subtotal: 'Subtotal',
  Number: 'Número',
  Location: 'Localidad',
  'Creation Date': 'Fecha de Creación',
  Link: 'Vínculo',
  'Owner ID': 'ID del Propietario',
  'Go to file': 'Ir al archivo',
  Phone: 'Teléfono',
  Actions: 'Acciones',
  'Made in': 'Hecho en',
  action: 'acción',
  Action: 'Acción',
  Where: 'Dónde',
  'Track Information': 'Información de rastreo',
  'Affiliate ID': 'ID de afiliado',
  'Authorized Date': 'Fecha de autorización',
  'Client ID': 'ID del cliente',
  'Client email': 'Email del cliente',
  'Client phone': 'Teléfono del cliente',
  'Follow-up Email': 'Email de seguimiento',
  'Checked-in': 'Chequeo de Ingreso',
  Completed: 'Completado',
  'Marketplace URL': 'URL del Marketplace',
  'Status description': 'Descripción del estado',
  'Generated Order Detail': 'Detalles de la Orden Generada',
  'The edition will be mark this record to be reprocessed. Do you want to proceed?':
    'La edición marcará este registro para ser reprocesado. ¿Quiere proceder?',
  Proceed: 'Proceder',
  'Edition Confirmation': 'Confirmación de Edición',
  ID: 'ID',
  'Import type': 'Tipo de Importación',
  'Market place': 'Marketplace',
  'Owner id': 'ID del Propietario',
  Results: 'Resultados',
  Row: 'Fila',
  Message: 'Mensaje',
  Success: 'Exitosos',
  Fails: 'Fallidos',
  Errors: 'Errores',
  'Order ID': 'Id de orden',
  'Other fields': 'Otros filtros',
  'Import ID': 'Id de importación',
  Metrics: 'Métrica',
  'Order Imports': 'Importaciones de Órdenes',
  Clean: 'Limpiar',
  'Inbox ID': 'ID de orden',
  'City ID': 'ID de ciudad',
  'State ID': 'ID de provincia',
  'Location ID': 'ID de Localidad',
  'Province ID': 'ID de Provincia',
  'NOTIFY MARKETPLACE': 'NOTIFICAR MARKETPLACE',
  'PERSIST ORDER': 'PERSISTIR ORDEN',
  'PROCESS ITEMS': 'PROCESAR ÍTEM',
  'LOOK FOR STATUS': 'BUSCAR POR ESTADO',
  'LOOK FOR COURIER': 'BUSCA POR CORREO',
  'LOCATION:LOOK FOR CITY': 'UBICACIÓN: BUSCAR POR LOCALIDAD',
  'LOCATION:LOOK FOR PROVINCE': 'UBICACIÓN: BUSCAR POR PROVINCIA',
  'PROCESS SHIPMENT': 'PROCESAR ENVÍO',
  'LOOK FOR DOCUMENT TYPE': 'BUSCAR POR TIPO DE DOCUMENTO',
  'PROCESS BUYER': 'PROCESAR COMPRADOR',
  'LOOK FOR SOURCE': 'BUSCAR POR FUENTE',
  'GRAB DATA FROM SERVER': 'OBTENER DATOS DEL SERVIDOR',
  'start-handling': 'iniciar-manejo',
  ORDER: 'ÓRDEN',
  SHIPPING: 'ENVÍO',
  'Province updated': 'Provincia actualizada',
  BUYER: 'COMPRADOR',
  'Request user': 'Solicitar usuario',
  'Please wait for Admin approval': 'Espere a que el administrador apruebe',
  'Account ID': 'ID de la cuenta',
  'Accounts List': 'Listado de Cuentas',
  Shipments: 'Envíos',
  'Edit Province': 'Editar provincia',
  Printing: 'Impresión',
  Shipping: 'Envíos',
  Edit: 'Editar',
  localities: 'localidades',
  Domicile: 'Domicilio',
  Print: 'Imprimir',
  'Order status': 'Estado de la orden',
  'Printing status': 'Estado de impresión',
  'Shipment id': 'Id de envío',
  Seller: 'Vendedor',
  Sender: 'Remitente',
  'Customer name': 'Nombre del cliente',
  Observation: 'Observación',
  'No description': 'Sin descripción ',
  Product: 'Producto',
  'Label to stick on the package': 'Etiqueta para pegar en el paquete',
  'Information for assembling the package':
    'Información para el armado del paquete',
  'Order and Order Shipment state has changed':
    'Los estados de la orden y la orden de entrega han cambiado',
  'Delivery Confirmation': 'Confirmación de Entrega',
  Send: 'Enviar',
  'Confirm if the order number': 'Confirme si la orden número',
  'was delivered': 'fue entregada',
  'The status of the order was modified ':
    'El status de la orden fue modificado',
  'This order already has status out for delivery':
    'Este pedido ya tiene el estado fuera para la entrega',
  'Shipping data': 'Datos de envío',
  'Customer code': 'Código de cliente',
  Customer: 'Cliente',
  Recipient: 'Destinatario',
  Department: 'Departamento',
  Printed: 'Impreso',
  'Not printed': 'No impreso',
  Returned: 'Devuelto',
  'Updated by': 'Actualizado por',
  'Origin date': 'Fecha de Origen',
  'Change date': 'Fecha de Cambio',
  'Invalid date': 'Fecha Inválida',
  'Notify Bill': 'Notificar Factura',
  'Notify Bill and Tracker': 'Notificar Factura y Remito',
  'Notify Tracker': 'Notificar Remito',
  'Status changed': 'Estado cambiado',
  Confirmation: 'Confirmación',
  'Do you want set the status to': '¿Quieres cambiar el estado a',
  'Bill informed': 'Factura informada',
  'Tracker informed': 'Remito informado',
  'Bill and Tracker Informed': 'Factura y Remito Informados',
  'Set it': 'Cambiarlo',
  System: 'Sistema',
  'Number so': 'Número de SO',
  'Order Comment': 'Comentario de la orden',
  Dispatches: 'Despachos',
  'Please select an item in the list.': 'Seleccione un elemento de la lista.',
  selled: 'vendido',
  'not set': 'no establecido',
  'Magento-OrderPuller': 'Magento-Extractor de pedidos',
  From: 'Desde',
  To: 'Hasta',
  'All the marketplaces': 'Todos los mercados',
  Count: 'Conteo',
  'Orders by state': 'Órdenes por estado',
  scheduled: 'programado',
  preparation: 'preparacion',
  'out to delivery': 'a la entrega',
  'Sale Order': 'Orden de venta',
  'Delivery Note': 'Remito',
  Bill: 'Factura',
  Receipt: 'Recibo',
  'ready to resend': 'listo para reenviar',
  preapred: 'preparado',
  onqueue: 'en cola',
  production: 'producción',
  'upload to collections': 'subido a cobranzas',
  paid: 'cobrado',
  returned: 'devuelto',
  'bill informed': 'factura informada',
  'tracker informed': 'remito informado',
  prepared: 'preparado',
  filters: 'filtros',
  'Andreani shipments': 'Andreani envios',
  'Fravega shipments': 'Fravega envios',
  'Home delivery': 'Envíos a domicilio',
  'FravegaShipments - Home delivery': 'FravegaEnvios - Envio a domicilio',
  'Creation date': 'Fecha de Creación',
  'Delivery date': 'Fecha de Envío',
  'Do you want to delete the order of the dispatch $$$$?':
    '¿Querés eliminar la orden del despacho $$$$?',
  'Order Delete': 'Eliminación de orden',
  'Order assigned in another dispatch': 'Orden asignada en otro despacho',
  'There is no selected order': 'No hay un pedido seleccionado',
  "The order is canceled, can't be dispatched":
    'La orden está cancelada, no se puede despachar',
  'The order is not in printed status': 'El pedido no está en estado impreso',
  'The order does not belong to the courier service':
    'El pedido no pertenece al servicio de mensajería',
  Details: 'Detalles',
  'Are you sure to Change de Courier Service? If you proceed with this action you will be lost all the order data in this dispatch':
    '¿Está seguro de cambiar el servicio de mensajería? Si continúa con esta acción, perderá todos los datos relacionados con órdenes en este despacho',
  'Send Dispatch': 'Enviar despacho',
  'Search by ID': 'Buscar por ID',
  'You can not send a Dispatch without orders. Orders':
    'No puede enviar un Despacho sin pedidos. Órdenes',
  'Are you sure to Cancel this Dispatch?, The Order Quantity is':
    '¿Está seguro de cancelar este envío?, La cantidad de pedido es',
  'Cancel Dispatch': 'Cancelar Despacho',
  Information: 'Información',
  'The action was cancelled, maybe by some error':
    'La accion fue cancelada, puede que por algun error',
  'Dispath Cancelled': 'Despacho Cancelado',
  'Add Order': 'Agregar orden',
  'The actual order belongs to $$$$': 'La orden pertenece a $$$$',
  'It is not possible to add the order': 'No es posible añadir la orden',
  'The order is assigned to the dispatch $$$$':
    'La orden está asignado al despacho $$$$',
  'Order $$$$ is not registered': 'Orden $$$$ no está registrado',
  'Reason for cancellation': 'Razon de la Cancelación',
  'The order is not printed': 'La orden no está impresa',
  Sent: 'Enviado',
  'Saved changes': 'Cambios guardados',
  'Order delete': 'Eliminación de orden',
  'Are you sure to send this Dispatch?, The Order Quantity is':
    '¿Está seguro de enviar este Despacho?, La Cantidad de orden es',
  'It is about to dispatch $$$$ orders. Do you want to continue?':
    'Está por despachar $$$$ órdenes. ¿Desea continuar?',
  'You are about to cancel a Dispatch with $$$$ orders, do you want to continue?':
    'Está a punto de cancelar un despacho con $$$$ órdenes, ¿quieres continuar?',
  'Confirm Dispatch': 'Confirmar Despacho',
  Confirm: 'Confirmar',
  Add: 'Agregar',
  'Dispatch Sent': 'Despacho enviado',
  'Dispatch Cancelled': 'Despacho cancelado',
  'The order $$$ belongs to the courier $$$$':
    'La orden $$$ pertenece a la mensajería $$$$',
  'It is not possible add the order $$$$':
    'No es posible agregar la orden $$$$',
  'Dispatch ($$$$)': 'Despacho ($$$$)',
  Management: 'Gestión',
  Driver: 'Conductor',
  'ERP Orders': 'Órdenes ERP',
  'Sales Channel Name': 'Nombre Canal de Ventas',
  'Sales Channel Number': 'Numero Canal de Ventas',
  'Sales Team': 'Equipo de Ventas',
  'Invoice Date': 'Fecha de Factura',
  'Invoice Number': 'Número de Factura',
  'Client Name': 'Nombre Cliente',
  'Client Phone': 'Telefono Cliente',
  'Client Mobile': 'Movil Cliente',
  'Client Email': 'Email Cliente',
  'Client Address': 'Dirección Cliente',
  'Shipment Date': 'Fecha de Envio',
  'Shipment Tracking': 'Rastreo de Envio',
  'ID ML': 'ID ML',
  'Credit Note Number': 'Nro. Nota de Credito',
  'ERP Order': 'Orden ERP',
  'Serial Numbers': 'Numeros de Serie',
  Agent: 'Agente',
  Finance: 'Finanzas',
  Delivery: 'Entrega',
  Sale: 'Venta',
  Invoice: 'Factura',
  sent: 'Enviado',
  Reports: 'Reportes',
  'SO Name': 'Nombre SO',
  'So Name': 'Nombre SO',
  Frequency: 'Frecuencia',
  'Last execution': 'Última ejecución',
  'last states': 'Últimos estados',
  Metadata: 'Metadatos',
  Crashed: 'Colgado',
  'Process monitor': 'Monitor de procesos',
  'Change Role': 'Cambiar rol',
  Liquidation: 'Liquidación',
  Warning: 'Advertencia',
  Critical: 'Crítico',
  Client: 'Cliente',
  Ignored: 'Ignorada',
  Ignore: 'Ignorar',
  Reprocess: 'Reprocesar',
  'Ignored State Confirmation': 'Confirmación del Estado Ignorado',
  'You are about to pass the State to Ignored. Do you want to proceed?':
    'Estas a punto de pasar al Estado Ignorado, ¿Quieres proceder?',
  'Saved, The Order Passed to Ignored': 'Guardado, La Order Paso a Ignorada',
  'Reprocess Confirmation': 'Confirmación de Reporcesar',
  'You are about to reprocess the order. Do you want to proceed?':
    'Estas a punto de reprocesar la orden, ¿Quieres proceder?',
  'Saved, The Order will be reprocessed': 'Guardado, La orden sera reprocesada',
  'Status by area': 'Estado por área',
  'Status since creation': 'Estado desde creación',
  Area: 'Área',
  'Clear Filters': 'Limpiar Filtros',
  'Massive Uploads': 'Carga Masiva',
  'Zip List': 'Lista de Código Postales',
  Upload: 'Carga',
  City: 'Ciudad',
  'City Matches': 'Coincidencias por ciudad',
  'State Matches': 'Coincidencias por estado',
  'Loaded registers': 'Registros cargados',
  'Process quantity': 'Cantidad de procesos',
  'Pdf download': 'Descarga de PDF',
  'Downloading PDF': 'Descargando PDF',
  of: 'de',
  'All the courier': 'Todas las mensajerías',
  'Adding order: $$$$': 'Añadiendo orden: $$$$',
  'Removing order: $$$$': 'Removiendo  orden: $$$$',
  'Execution SP': 'Ejecución SP',
  'Created by': 'Creado por',
  'Assign Dispatch Zone': 'Asigne Zona de Despacho',
  Floor: 'Piso',
  'Location data': 'Datos de ubicación',
  'Report pending andreani income': 'Pendientes de ingreso en Andreani',
  'Courier state': 'Estado de mensajerías',
  'Courier detailed state': 'Estado detallado de mensajerías',
  'Dispatch date': 'Fecha de despacho',
  'Total Purchase': 'Total de la Compra',
  'Purchase Date': 'Fecha de la Compra',
  Priority: 'Prioridad',
  'Selected Orders': 'Órdenes Seleccionadas',
  'SO Number': 'Número de SO',
  'Dispatch Zone': 'Zona de Despacho',
  'Assigned Orders': 'Órdenes Asignadas',
  'Updated By': 'Modificado Por',
  'No Date Record': 'Sin Fecha Registrada',
  'Zone 1': 'Zona 1',
  'Zone 2': 'Zona 2',
  'Zone 3': 'Zona 3',
  Collect: 'Colecta',
  Assigned: 'Asignado',
  Observations: 'Observaciones',
  'Dispatch Name': 'Nombre del Despacho',
  'Orders Added': 'Órdenes Agregadas',
  PreparingPackage: 'Preparando Paquete',
  AssignedToCollect: 'Asignada a la Colecta',
  Collecting: 'Colectando',
  'Dispatch date from': 'Fecha de despacho desde',
  'Dispatch Date to': 'Fecha de despacho hasta',
  'Shipping Date': 'Fecha de Entrega',
  'Assigned To': 'Asignado a',
  Products: 'Productos',
  'Pick up': 'Pickear',
  Start: 'Comenzar',
  'Dispatch Zone $$$$ was able to use':
    'La Zona de Despacho $$$$ esta disponible para su uso',
  "The dispatch zone $$$$ can't back able to use for some reason":
    'Por alguna razon no se puede reestablecer el uso de la zona de Despacho $$$$',
  'Add collect': 'Agregar colecta',
  'Updated sku $$$ product': 'Producto del sku $$$ actualizado',
  'Save product': 'Guardar producto',
  'Updated product': 'Producto actualizado',
  'To generate the label please enter the measurements and weight of the product':
    'Para generar la etiqueta por favor ingrese las medidas y peso del producto',
  'Width (cm)': 'Ancho (cm)',
  'Height (cm)': 'Alto (cm)',
  'Depth (cm)': 'Profundo (cm)',
  'Weight (grms)': 'Peso (grms)',
  'Name/Description': 'Nombre/Descripción',
  'Name/Sku': 'Nombre/Sku',
  'Pick the Cart Box and place in it': 'Pickea el Cajón y colocalo en el',
  'Cart Box Picked': 'Cajón pickeado',
  'Product Picked': 'Producto pickeado',
  Pick: 'Pickeá',
  'Incorrect cart box': 'Cajón incorrecto',
  'Incorrect product': 'Producto incorrecto',
  'The scanned cart box does not match the one requested. Check and rescan.':
    'El cajón escaneado no coindice con el solicitado. Controlá y volvé a escanear.',
  'The scanned product does not match the requested one. Check and rescan.':
    'El producto escaneado no coindice con el solicitado. Controlá y volvé a escanear.',
  'Error picking the Cart Box': 'Error pickeando el cajón',
  'Error picking the Product': 'Error pickeando el producto',
  'Critical amount of logistics': 'Cantidad crítica de logística',
  Collects: 'Colectas',
  'Logistics Operator': 'Operador de logística',
  Cart: 'Carro',
  'Assigned to': ' Asignado a',
  'Collect saved': 'Colecta guardada',
  'Modified collect': 'Colecta modificada',
  'Create Collect': 'Crear colecta',
  'Update Collect': 'Actualizar colecta',
  'Delete orders': 'Eliminar ordenes',
  'You want to remove the following $$$$ orders from the collect?':
    '¿Desea eliminar los siguientes órdenes $$$$ de la colecta?',
  'Order $$$$ removed': 'Orden $$$$ eliminada',
  'Do you want to delete the order of the collect $$$$?':
    '¿Desea eliminar la orden $$$$ de la colecta ?',
  'It has $$$$ positions available in the cart. You cannot select greater than the available quantity':
    'Tiene disponible $$$$ posiciones en el carro. No puede seleccionar mayor a la cantidad disponible',
  Percentage: 'Porcentaje',
  'No collects pending': 'No hay colectas pendientes',
  'Delete collects': 'Eliminar colectas',
  'Collect id': 'Id de colecta',
  'Orders saved': 'Órdenes guardadas',
  'Order loaded to add in the collection':
    'Orden cargadas para agregar en la colecta',
  'Delete selection': 'Eliminar seleccion',
  See: 'Ver',
  'Close collect': 'Cerrar colecta',
  'Collect Closed': 'Colecta cerrada',
  'Warning: The following processes should be checked as soon as possible.':
    'Advertencia: Los siguientes procesos deben comprobarse lo antes posible.',
  'Name process': 'Nombre de procesos',
  'Error consulting process monitor alert.':
    'Error al consultar alerta monitor de procesos.',
  'Data failed to display alert modal':
    'Error con los datos al mostrar modal de alerta',
  'You cannot select the number of orders greater than the number of available items in the cart.':
    'No se puede seleccionar cantidad de órdenes mayores al numero de posiciones disponible del carro.',
  'Pick up the Serial of': 'Pickéa el Serial de',
  'Pick up the SKU of': 'Pickea el SKU de',
  'Without Info': 'Sin Información',
  Corridor: 'Pasillo',
  Shelves: 'Estantería',
  Shelf: 'Altura',
  Position: 'Ubicación',
  'Product Collected': 'Producto Colectado',
  'Order State': 'Estado de la Orden',
  Low: 'Baja',
  Medium: 'Media',
  High: 'Alta',
  'Not collected': 'No colectado',
  Collected: 'Colectado',
  "You haven't permissions to see this section.":
    'No tienes permisos para ver esta sección.',
  "You haven't permissions to see this collect.":
    'No tienes permisos para ver esta colecta.',
  'Let the Products in the Dispatch Zone.':
    'Deja los productos en la zona de despacho',
  'All products have been collected. Close collect?':
    'Se han recolectado todos los productos. ¿Cerrar colecta?',
  'You finished the Collect': 'Terminaste la colecta',
  'Please, leave the products in the dispatch zone ($$$$).':
    'Por favor, deja los productos en la zona de despacho ($$$$).',
  'Pick and place it in box': 'Pickeá y colócalo en el cajón',
  Manifest: 'Manifiesto',
  'Invoice and Tracker': 'Factura y Remito',
  Labels: 'Etiquetas',
  Finish: 'Completar',
  'Please print documents and put them in its correct ubication':
    'Imprima los documentos y colóquelos dentro de su ubicación correspondiente.',
  Reminder: 'Recordar',
  'Error deleting order': 'Error al eliminar la orden',
  Box: 'Cajón',
  Return: 'Devolver',
  '$$$ returned product': '$$$ producto devuelto',
  'Error returning product: $$$': 'Error al devolver producto',
  "There're products without location inf in this collect. Please notify to your supervisor or fill the Location info in the system.":
    'Hay productos en esta colecta sin información de ubicación. Notifique a su supervisor o complete la información de ubicación en el sistema.',
  'Without Location': 'Sin Ubicación',
  'Not Started': 'No iniciado',
  Complete: 'Completado',
  'All the status': 'Todo los estados',
  'From $fromItems items this will just take $toItems':
    'De $fromItems ítems solo serán tomados $toItems ítems',
  'Serial number already used': 'Número de serie ya utilizado',
  'Please, validate the serial number and try again':
    'Por favor, valide el número de serie e inténtelo de nuevo',
  'Error checking the serial number': 'Error chequeando el número de serie',
  'You can then close it in the collect summary view via the "Close collect" button':
    'Puede cerrarla luego desde la vista del resumen de la colecta a través del botón "Cerrar colecta"',
  Undivided: 'Sin divisiones',
  'This order $$$ is already in $$$$ status, you are sure you want to remove it from dispatch?':
    'Esta orden $$$ está en estado $$$$, usted está seguro de que desea eliminarlo de despacho?',
  'Cars are available below this selected amount.':
    'Existen carros disponibles inferiores a esta cantidad seleccionada.',
  Capacity: 'Capacidad',
  'No cars available for capacity greater than $$$$.':
    'No hay carros disponibles para capacidad mayor a $$$$.',
  'Error sending': 'Error al enviar',
  'Failed to cancel': 'Error al cancelar',
  'Error printing manifest': 'Error imprimiendo el manifiesto',
  'Do you want to print the manifest?': '¿Quieres imprimir el manifiesto?',
  'Print manifest': 'Imprimir manifiesto',
  'Serial number has no stock': 'Número de serie no tiene stock',
  'The cart $$$$ was able to use': 'El carro $$$$ esta disponible para su uso',
  'Collect cancelled': 'Colecta cancelada',
  'You are about to cancel a collect with $$$$ orders, do you want to continue?':
    'Está a punto de cancelar una colecta con $$$$ órdenes, ¿quieres continuar?',
  'Cancel collect': 'Cancelar colecta',
  'the dispatch is not ready to be sent':
    'el despacho no esta listo para ser enviado',
  'Error modifying collect': 'Error modificando colecta',
  'Error creating collect': 'Error creando colecta',
  'Error adding orders in dispatch': 'Error al agregar órdenes en despacho',
  'Logistics Manager': 'Jefe de Logística',
  'Please, delete all orders($$$$) with canceled status':
    'Por favor, elimine las órdenes($$$$) con estado cancelado',
  'You have orders in a canceled state': 'Tiene órdenes en estado cancelado',
  'You can not send an office with canceled orders':
    'No puede enviar un despacho con órdenes canceladas',
  'Order $$$ canceled': 'Orden $$$ cancelada',
  'Order $$$ received': 'Orden $$$ recibida',
  'Are you sure you received the following orders: $$$':
    'Esta seguro que recibiste las siguientes órdenes: $$$',
  'Cancel order': 'Cancelar orden',
  'Are you sure you want to cancel the order $$$$?':
    '¿Estás seguro que deseás cancelar la orden $$$$?.',
  'Error modifying order status: $$$$':
    'Error modificando estado de la orden: $$$$',
  'Returning to cancel': 'Recuperando para cancelar',
  'Change the status of orders': 'Cambiar el estado de las órdenes',
  'Are you sure you want to move the following orders ($$$$) to the Waiting for Preparation state?.':
    '¿Está seguro de que desea mover las siguiente órdenes ($$$$) al estado Esperando preparación?.',
  Prepare: 'Preparar',
  'Order $$$ waiting for preparation': 'Orden $$$ a la espera de preparación',
  'Preparing orders, please wait': 'Preparando órdenes, por favor espere',
  'Some orders are being prepared right now, please wait':
    'Algunas órdenes se estan preparando, por favor espere',
  'Are you sure you want to move the following orders ($$$$) to the $$$ state?.':
    '¿Está seguro de que desea mover las siguiente órdenes ($$$$) al estado $$$?.',
  'Order $$$ ready for deliver': 'Orden $$$ listo para la entrega',
  Refer: 'Remitir',
  'The dispatch item is in $$$$ state and does not belong to the status prepared':
    'El despacho está en estado $$$$ y no pertenece al estado preparado',
  'Send order': 'Enviar orden',
  'The order $$$$ is already in the sent state':
    'La orden $$$$ ya se encuentra en estado enviado',
  'An error occurred sending the order $$$$':
    'Ocurrió un error enviando la orden $$$$',
  'You do not have permission to pick order ready for delivery. Please check with the administrator':
    'No posee permiso para pickear orden listo para la entrega.  Por favor chequee con el administrador',
  'Dispatch successfully sent': 'Despacho enviado exitosamente',
  'Sales by marketplace': 'Ventas por marketplace',
  'Waiting for approval': 'Esperando aprobación',
  'Waiting to cancel': 'Esperando cancelar',
  'Returned completed': 'Devolución completada',
  'Waiting for external delivery': 'Esperando entrega externa',
  'External delivery ready': 'Listo para entrega externa',
  'Ready for preparation': 'Listo para preparación',
  'Top 10 products': 'Top 10 productos',
  'Total sales': 'Total ventas',
  'Amount sales': 'Cantidad ventas',
  'No data for the chart': 'No hay datos para la gráfica',
  'en-US': 'es-AR',
  Download: 'Descargar',
  Days_per_area: 'Dias_por_area',
  'Historic by Marketplace': 'Histórico por Marketplace',
  'Last 30 days': 'Últimos 30 días',
  'Edit Order': 'Editar Orden',
  'Buyer information': 'Información del comprador',
  'Buyer Address': 'Dirección del comprador',
  'Shipment address': 'Dirección de envío',
  Street: 'Calle',
  'Street Number': 'Número de Calle',
  'Extra Address': 'Dirección Extra',
  'Reset values': 'Resetear valores',
  'Copy buyer Address': 'Copiar dirección del comprador',
  Receive: 'Recibir',
  'Canal Off Line': 'Canal fuera de línea',
  'Time to run': 'Tiempo para ejecutar',
  'To upload': 'Por subir',
  'Process for change order status':
    'Proceso para el cambio estado de la orden',
  'Import process': 'Proceso de importación',
  'The value is not a valid number.': 'El valor no es un número válido.',
  'The value must be only letters and spaces.':
    'El valor debe ser solo letras y espacios.',
  'The value do not match the city zip codes':
    'El valor no coincide con los codigos postales de la ciudad',
  'There are some errors on the fields, please fix them':
    'Hay algunos errores en los campos, por favor corríjalos',
  'The value cant have special caracters':
    'El valor no puede tener caracteres especiales',
  'The following processes prevent the import from being processed. Please, notify the administrator':
    'Los siguientes procesos impiden que se procese  la importación. Por favor, Notifique al administrador',
  'Error executing process. Please, notify the administrator':
    'Error al ejecutar el proceso. Por favor, notifique al administrador',
  'Canal Off line': 'Canal Fuera de línea',
  Channels: 'Canales',
  Online: 'En línea',
  Offline: 'Sin conexión',
  Depot: 'Almacén',
  'Order $$$ delivered': 'Orden $$$ entregada',
  Deliver: 'Entregar',
  'Confirm delivery of selected orders':
    'Confirmar entrega de las órdenes seleccionadas',
};
