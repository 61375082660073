import React, {
  useMemo, useState, useCallback, useEffect,
} from 'react';
import { capitalize } from '@material-ui/core';
import AssignmentIcon from '@material-ui/icons/Assignment';
import { LibbyObject, ReportOrder } from '../../../../../types';
import { useOrderToRows } from '../../../../../models/orders/rows';
import { RowsType } from '../../../../../utils/tables';
import { useBreadcrumbs, useLibbyFetch } from '../../../../../hooks';
import { MakeCell } from '../../../../components/MakeCell';

type useOrderReportLogicProps = {
  libby: LibbyObject;
  path: string;
};

type LibbyOrderReport = {
  data: ReportOrder[];
  working?: Boolean;
  fetchMore?: () => void;
};

export const useOrderReportLogic = ({
  libby,
  path,
}: useOrderReportLogicProps) => {
  const [filter, setFilter] = useState({});
  const [filterSave, setfilterSave] = useState(filter);

  useEffect(() => {
    if (JSON.stringify(filterSave) !== JSON.stringify(filter)) {
      setfilterSave({ ...filter });
    }
  }, [filterSave, filter]);

  const [direction, setDirection] = useState<'asc' | 'desc'>('asc');
  const [orderBy, setOrderBy] = useState('order_id');

  const pathDetails = useCallback(
    (order_id: string): string => `/aging-report/orders/detail/${order_id}`,
    [],
  );
  const title = useBreadcrumbs('Order time report');

  const handleRequestSort = (
    newOrderBy: string,
    newDirection: 'asc' | 'desc',
  ) => {
    setOrderBy(newOrderBy);
    setDirection(newDirection);
  };
  const paramsFetchTime = useMemo(
    () => ({
      daoName: 'ster_order_report',
      filter: filterSave,
      direction,
      orderBy,
    }),
    [filterSave, direction, orderBy],
  );

  const { data, working, fetchMore }: LibbyOrderReport = useLibbyFetch(
    libby,
    paramsFetchTime,
  );

  const getCommonRows = useOrderToRows(path);

  const rows: RowsType[] = useMemo(
    () => data.map((order: ReportOrder) => ({
      ...getCommonRows(order),
      buyer: `${capitalize(order?.first_name ?? '')} ${capitalize(
          order?.last_name ?? '',
      )}`,
      total_time: order.total_time,
      days_meta_state: order.days_meta_state,
      total_price: order.total_price,
      meta_state: order.meta_state,
      marketplace: order?.marketplace,
      state: order?.order_state_id,
      order_state_id: order?.order_state_id,
      created_at: order?.created_at,
      total_alert: order?.total_alert,
      meta_state_alert: order?.meta_state_alert,
      courier_id: order?.courier_id,
      detail: (
        <MakeCell
          label=""
          icon={AssignmentIcon}
          path={pathDetails(order?.order_id || '')}
          params={{
            goBack: title,
          }}
          onClick={(e) => {
            e.stopPropagation();
          }}
        />
      ),
    })),
    [data, getCommonRows, title, pathDetails],
  );

  const searchFilter = useCallback(
    (value: object) => {
      if (JSON.stringify(filter) !== JSON.stringify(value)) {
        setFilter({ ...value });
      }
    },
    [filter],
  );

  return {
    searchFilter,
    rows,
    working,
    fetchMore,
    handleRequestSort,
    filter,
    title,
    setFilter,
    orderBy,
    direction,
    pathDetails,
  };
};
