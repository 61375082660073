import React, { useState, useCallback } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { makeStyles } from '@material-ui/styles';
import { Button, TextField } from '@material-ui/core';
import { DatabaseConnector } from '@phinxlab/libby-rest-web';
import { Screen } from '../../../../components/Screen';
import { ScreenTitle } from '../../../../components/ScreenTitle';
import { useTranslation } from '../../../../services/translation';
import { LibbyObject } from '../../../../types/types';
import { useLibbyFetchById } from '../../../../hooks';
import { LoadingTable } from '../../../../components/LoadingTable';
import { buyerDirection } from '../../../components/OrderDetailData';
import {
  ORDER_DOCUMENT_SOURCE,
  ORDER_DOCUMENT_TYPE,
  ORDER_SHIPMENT_STATE,
  ORDER_STATE,
} from '../../../../const';

const useStyles = makeStyles({
  containerForm: {
    maxWidth: 'inherit',
    margin: 'auto',
    padding: '15px 0',
  },
  dialogBox: {
    display: 'grid',
    width: 300,
  },
  dialogText: {
    display: 'flex',
  },
  h3: {
    fontWeight: 400,
  },
  orderDataInfo: {
    display: 'grid',
    marginBottom: 15,
  },
  orderDataName: {
    fontSize: 14,
    textDecoration: 'underline',
  },
  dialogButtons: {
    display: 'grid',
    gridTemplateColumns: 'repeat(2, 120px)',
    gridGap: 15,
  },
  reasonInput: {
    width: 280,
    marginBottom: 15,
  },
});

type OrderQRDialogProps = {
  libby: LibbyObject;
};

const OrderQRDialogRaw = ({ libby }: OrderQRDialogProps) => {
  const { id } = useParams();
  const classes = useStyles();
  const history = useHistory();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const [loadingSend, setLoadingSend] = useState(false);
  const { working, data } = useLibbyFetchById(libby, {
    daoName: 'ster_order_so',
    aspect: 'list_with_buyer',
    id,
  });

  const [reasonText, setReasonText] = useState('');
  const [orderCancelled, setOrderCancelled] = useState(false);

  const OrderDeliveryConfirmation = useCallback(
    async (event: React.MouseEvent<HTMLButtonElement>) => {
      event.preventDefault();
      setLoadingSend(true);
      try {
        await libby.ster_order_document.aspect('order_document_save').save({
          order: {
            ...data,
            state: { order_state_id: ORDER_STATE.DELIVERED.toString() },
            shipment: {
              ...data.shipment,
              state: {
                order_shipment_state_id:
                  ORDER_SHIPMENT_STATE.DELIVERED.toString(),
              },
            },
          },
          documentNumber: data.so_number,
          type: {
            order_document_type_id: ORDER_DOCUMENT_TYPE.RECIBO,
          },
          source: {
            order_document_source_id: ORDER_DOCUMENT_SOURCE.COURIER.toString(),
          },
        });
        history.push('/orders');
        enqueueSnackbar(t('Order and Order Shipment state has changed'), {
          variant: 'success',
        });
        setLoadingSend(false);
        return null;
      } catch (e) {
        setLoadingSend(false);
        enqueueSnackbar(t('Something is wrong'), { variant: 'error' });
        return null;
      }
    },
    [data, enqueueSnackbar, history, t, libby.ster_order_document],
  );

  const OrderDeliveryCancel = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    setOrderCancelled(true);
  };

  const OrderCancelReason = useCallback(
    async (event: React.MouseEvent<HTMLButtonElement>) => {
      event.preventDefault();
      try {
        const orderData = {
          ...data,
          returned_reason: reasonText,
          state: { order_state_id: ORDER_STATE.READY_TO_RESEND.toString() },
        };

        const updateOrder = await libby.ster_order_table
          .aspect('list_with_reason')
          .save(orderData);

        const shipment = updateOrder?.shipment;
        console.log('hola');
        const orderShipmentData = {
          ...shipment,
          state: {
            order_shipment_state_id: ORDER_SHIPMENT_STATE.RETURNED.toString(),
          },
        };
        const saveShipment = await libby.ster_order_shipment
          .aspect('save_state')
          .save(orderShipmentData);

        console.log('guardo', saveShipment);
        history.push('/orders');
        enqueueSnackbar(t('Order and Order Shipment state has changed'), {
          variant: 'success',
        });
        return null;
      } catch (e) {
        enqueueSnackbar(t('Something is wrong'), { variant: 'error' });
        return null;
      }
    },
    [
      data,
      reasonText,
      enqueueSnackbar,
      history,
      libby.ster_order_table,
      libby.ster_order_shipment,
      t,
    ],
  );

  return (
    <Screen>
      <ScreenTitle title="Delivery Confirmation" />
      <div className={classes.dialogBox}>
        <div className={classes.dialogText}>
          <h3 className={classes.h3}>
            {t('Confirm if the order number')}
            {` ${id} `}
            {t('was delivered')}
            :
          </h3>
        </div>
        {working ? (
          <LoadingTable />
        ) : (
          !!data && (
            <>
              <div className={classes.orderDataInfo}>
                <span>
                  <span className={classes.orderDataName}>
                    {t('Buyer')}
                    :
                  </span>
                  {` ${data.buyer.first_name} ${data.buyer.last_name}`}
                </span>
              </div>
              <div className={classes.orderDataInfo}>
                <span>
                  <span className={classes.orderDataName}>
                    {t('Address')}
                    :
                  </span>
                  {buyerDirection(
                    data?.buyer.street,
                    data?.buyer.street_number,
                    data?.buyer.city?.name,
                    data?.buyer.department,
                    data?.buyer.floor,
                    data?.buyer.extra_address,
                    data?.buyer.comments,
                    data?.buyer.city?.state?.name,
                  )}
                </span>
              </div>
            </>
          )
        )}
        <div className={classes.dialogButtons}>
          <Button
            type="reset"
            variant="contained"
            color="primary"
            onClick={OrderDeliveryConfirmation}
          >
            {loadingSend ? <LoadingTable /> : t('Yes')}
          </Button>
          <Button
            type="reset"
            variant="contained"
            onClick={OrderDeliveryCancel}
          >
            {loadingSend ? <LoadingTable /> : t('No')}
          </Button>
          {orderCancelled && (
            <form>
              <div className={classes.reasonInput}>
                <TextField
                  style={{ width: '100%' }}
                  name="reason"
                  label="Razón de no Entrega"
                  variant="outlined"
                  multiline
                  rows={4}
                  defaultValue={reasonText}
                  fullWidth
                  size="medium"
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => setReasonText(event.target.value)}
                />
              </div>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                onClick={OrderCancelReason}
              >
                {t('Send')}
              </Button>
            </form>
          )}
        </div>
      </div>
    </Screen>
  );
};

export const OrderQRDialog = DatabaseConnector(OrderQRDialogRaw)(
  'ster_order_so',
  'ster_order_shipment',
  'ster_order_document',
  'ster_order_table',
);
