import React from 'react';
import {
  Switch, Route, useRouteMatch,
} from 'react-router-dom';
import { CitiesDetail } from './routes/CitiesDetail';
import { CitiesList } from './routes/CitiesList';
import { useBreadcrumbs } from '../../hooks';

export const Cities = () => {
  const match = useRouteMatch();
  const title = useBreadcrumbs('Localities');
  return (
    <Switch>
      <Route path={`${match.path}/detail/:id`}>
        <CitiesDetail title={title} />
      </Route>
      <Route path={`${match.path}`}>
        <CitiesList title={title} />
      </Route>
    </Switch>
  );
};
