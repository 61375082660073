import { useMemo, useState } from 'react';
import { INBOX_ORDER_STATE } from '../const/InboxOrderState';
import { makeFilter } from '../routes/InboxOrders/routes/InboxOrdersList/utils/makeFilter';
import { LibbyObject } from '../types/types';
import { useLibbyFetch } from './useLibbyFetch';

export const useOrderInboxTableLogic = (libby: LibbyObject) => {
  const [direction, setDirection] = useState<'asc' | 'desc'>('asc');
  const [orderBy, setOrderBy] = useState('inbox_order_id');

  const [filter, setFilter] = useState(
    makeFilter({
      startDate: null,
      endDate: null,
      status: INBOX_ORDER_STATE.PROBLEM,
    }),
  );

  const handleRequestSort = (
    newOrderBy: string,
    newDirection: 'asc' | 'desc',
  ) => {
    setOrderBy(newOrderBy);
    setDirection(newDirection);
  };

  const paramsFetch = useMemo(
    () => ({
      daoName: 'ster_inbox_order',
      filter,
      direction,
      orderBy,
    }),
    [filter, direction, orderBy],
  );

  const {
    data, working, fetchMore, reFetch,
  } = useLibbyFetch(
    libby,
    paramsFetch,
  );

  return {
    working,
    data,
    fetchMore,
    reFetch,
    setFilter,
    handleRequestSort,
    direction,
    orderBy,
    filter,
  };
};
