import React from 'react';
import {
  Theme, useMediaQuery,
} from '@material-ui/core';
import {
  BarChart,
  Bar,
  Cell,
  Legend,
  ResponsiveContainer,
  XAxis,
  YAxis,
  Tooltip,
} from 'recharts';
import { CHART_COLORS } from '../../chart/types';
import LoadingData from '../../routes/components/LoadingData';
import { useTranslation } from '../../services/translation';
import { format } from '../../util/format';
import { CustomTooltip } from './CustomTooltip';
import { CustomizedCursor } from './CustomizedCursor';
import { RenderCusomizedLegend } from './RenderCusomizedLegend';
import { ChartEmpty } from '../ChartEmpty';

interface BarChartProps {
  working: boolean;
  dataChart: any;
  onChange?: (payload: any) => void;
}

export const BarChartComponent = ({
  working,
  dataChart,
  onChange,
}: BarChartProps) => {
  const { t } = useTranslation();

  const isDownXs = useMediaQuery<Theme>((theme) => theme.breakpoints.down('xs')); // 400

  const isDownMd = useMediaQuery<Theme>((theme) => theme.breakpoints.down('md'));

  return (
    <>
      {!working ? (
        dataChart.length ? (
          <ResponsiveContainer
            width="100%"
            height="100%"
            minHeight={isDownXs ? 400 : isDownMd ? 400 : 200}
          >
            <BarChart
              data={dataChart}
              margin={{
                right: 30,
                left: 20,
              }}
            >
              <YAxis
                dataKey="total"
                allowDecimals
                tickFormatter={(label) => format(label, 'Integer', t)}
              />
              <XAxis dataKey="count" />
              <Tooltip
                content={<CustomTooltip />}
                cursor={<CustomizedCursor onChange={onChange} />}
              />
              <Legend
                payload={dataChart.map((item: any, index: number) => ({
                  id: item.name,
                  type: 'circle',
                  value: `${item.name} `,
                  color: CHART_COLORS[index % CHART_COLORS.length],
                }))}
                content={<RenderCusomizedLegend />}
              />
              <Bar dataKey="total">
                {dataChart.map((data: any, index: number) => (
                  <Cell
                    fill={CHART_COLORS[index % CHART_COLORS.length]}
                    onClick={() => {
                      if (onChange) {
                        onChange(data);
                      }
                    }}
                  />
                ))}
              </Bar>
            </BarChart>
          </ResponsiveContainer>
        ) : (
          <ChartEmpty />
        )
      ) : (
        <LoadingData working={!!working} />
      )}
    </>
  );
};
