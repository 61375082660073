import { useMemo } from 'react';
import { useTranslation } from '../../../services/translation';
import { FormConfigTypes } from '../../../types/FormConfig';
import { makeGetErrorAndHelperText } from '../../../utils';

export const FormConfig = ({
  first_name,
  last_name,
  document,
  email,
  phone_number,
  buyerDepartment,
  buyerFloor,
  buyerStreet,
  buyerStreetNumber,
  buyerExtraAddress,
  buyerZip,
  buyerCity,
  street,
  street_number,
  zip,
  floor,
  department,
  extra_address,
  city,
  classes,
  province,
  buyerProvince,
}: FormConfigTypes) => {
  const { t } = useTranslation();
  const getErrorAndHelperText = makeGetErrorAndHelperText(t);

  const errorDocument = getErrorAndHelperText(document);
  const errorPhoneNumber = getErrorAndHelperText(phone_number);
  const errorStreetNumber = getErrorAndHelperText(street_number);
  const errorFloor = getErrorAndHelperText(floor);
  const errorDepartment = getErrorAndHelperText(department);
  const errorEmail = getErrorAndHelperText(email);
  const errorFirstName = getErrorAndHelperText(first_name);
  const errorLastName = getErrorAndHelperText(last_name);
  const errorBuyerStreetNumber = getErrorAndHelperText(buyerStreetNumber);
  const errorBuyerFloor = getErrorAndHelperText(buyerFloor);
  const errorBuyerDepartment = getErrorAndHelperText(buyerDepartment);
  const errorBuyerCity = getErrorAndHelperText(buyerCity);
  const errorCity = getErrorAndHelperText(city);
  const errorProvince = getErrorAndHelperText(province);
  const errorBuyerProvince = getErrorAndHelperText(buyerProvince);

  const formFieldsBuyer = [
    {
      field: first_name,
      errorField: errorFirstName,
      label: 'First Name',
      name: 'first_name',
      class: classes && classes.halfTextFieldLeft,
    },
    {
      field: last_name,
      errorField: errorLastName,
      label: 'Last Name',
      name: 'last_name',
      class: classes && classes.halfTextField,
    },
    {
      field: document,
      errorField: errorDocument,
      label: 'Document',
      name: 'document',
      class: classes && classes.textField,
    },
    {
      field: email,
      errorField: errorEmail,
      label: 'Email',
      name: 'email',
      class: classes && classes.textField,
    },
    {
      field: phone_number,
      errorField: errorPhoneNumber,
      label: 'Phone Number',
      name: 'phone_number',
      class: classes && classes.textField,
    },
  ];

  const formFieldsShipment = [
    {
      field: zip,
      errorField: null,
      label: 'Zip',
      name: 'zip',
      class: classes && classes.textField,
      customeValid: true,
    },
    {
      field: street,
      errorField: null,
      label: 'Street',
      name: 'street',
      class: classes && classes.textField,
    },
    {
      field: street_number,
      errorField: errorStreetNumber,
      label: 'Street Number',
      name: 'street_number',
      class: classes && classes.textField,
    },
    {
      field: floor,
      errorField: errorFloor,
      label: 'Floor',
      name: 'floor',
      class: classes && classes.halfTextFieldLeft,
    },
    {
      field: department,
      errorField: errorDepartment,
      label: 'Department',
      name: 'department',
      class: classes && classes.halfTextField,
    },
    {
      field: extra_address,
      errorField: null,
      label: 'Extra Address',
      name: 'extra_address',
      class: classes && classes.textField,
    },
  ];

  const formFieldsBuyerAddress = [
    {
      field: buyerZip,
      errorField: null,
      label: 'Zip',
      name: 'buyerZip',
      class: classes && classes.textField,
      customeValid: true,
    },
    {
      field: buyerStreet,
      errorField: null,
      label: 'Street',
      name: 'buyerStreet',
      class: classes && classes.textField,
    },
    {
      field: buyerStreetNumber,
      errorField: errorBuyerStreetNumber,
      label: 'Street Number',
      name: 'buyerStreetNumber',
      class: classes && classes.textField,
    },
    {
      field: buyerFloor,
      errorField: errorBuyerFloor,
      label: 'Floor',
      name: 'buyerFloor',
      class: classes && classes.halfTextFieldLeft,
    },
    {
      field: buyerDepartment,
      errorField: errorBuyerDepartment,
      label: 'Department',
      name: 'buyerDepartment',
      class: classes && classes.halfTextField,
    },
    {
      field: buyerExtraAddress,
      errorField: null,
      label: 'Extra Address',
      name: 'buyerExtraAddress',
      class: classes && classes.textField,
    },
  ];

  const errorBuyerAddress = useMemo(
    () => errorBuyerDepartment.error
      || errorBuyerFloor.error
      || errorBuyerStreetNumber.error
      || errorBuyerCity.error
      || errorBuyerProvince.error,
    [
      errorBuyerDepartment,
      errorBuyerFloor,
      errorBuyerStreetNumber,
      errorBuyerCity,
      errorBuyerProvince,
    ],
  );
  const errorForm = useMemo(
    () => errorDocument.error
      || errorPhoneNumber.error
      || errorStreetNumber.error
      || errorFloor.error
      || errorDepartment.error
      || errorEmail.error
      || errorFirstName.error
      || errorLastName.error
      || errorBuyerStreetNumber.error
      || errorBuyerFloor.error
      || errorBuyerDepartment.error
      || errorBuyerCity.error
      || errorCity.error
      || errorBuyerProvince.error
      || errorProvince.error,
    [
      errorDocument,
      errorPhoneNumber,
      errorStreetNumber,
      errorFloor,
      errorDepartment,
      errorEmail,
      errorFirstName,
      errorLastName,
      errorBuyerStreetNumber,
      errorBuyerFloor,
      errorBuyerDepartment,
      errorBuyerCity,
      errorCity,
      errorBuyerProvince,
      errorProvince,
    ],
  );

  return {
    formFieldsBuyer,
    formFieldsBuyerAddress,
    formFieldsShipment,
    errorBuyerCity,
    errorCity,
    errorForm,
    errorBuyerAddress,
    errorProvince,
    errorBuyerProvince,
  };
};
