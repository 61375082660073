import React, { useCallback } from 'react';
import { ThemeProvider } from '@material-ui/styles';
import {
  Dialog,
  DialogActions,
  DialogContent,
  TextField,
  Typography,
  Button,
} from '@material-ui/core';
import { useField, useForm } from 'react-final-form-hooks';
import { useTranslation } from '../../../../../services/translation';
import {
  makeGetErrorAndHelperText,
  textFieldProps,
} from '../../../../../utils/materialHelpers';
import { ResetForm } from '../../../../../components/ResetForm';
import { FormProps } from '../../../../../types';
import { useModalFormStyles } from '../../../../../theme';

export const CourierForm = ({
  open = false,
  onCancel = () => {},
  onConfirm = () => {},
  title = 'Confirmation Dialog',
  cancelText = 'Cancel',
  confirmText = 'confirmText',
  customTheme = null,
  validate,
  formInitialValues = {},
}: FormProps) => {
  const { t } = useTranslation();
  const styles = useModalFormStyles();
  const getErrorAndHelperText = makeGetErrorAndHelperText(t);

  const onSubmit = useCallback(async ({ name }) => onConfirm({ name }), [
    onConfirm,
  ]);

  const { form, handleSubmit, submitError } = useForm({
    initialValues: formInitialValues,
    onSubmit,
    validate,
  });
  const name = useField('name', form);

  const dialog = (
    <Dialog
      open={open}
      onClose={onCancel}
      fullWidth
      aria-labelledby="form-dialog-title"
    >
      <DialogContent className={styles.title}>
        <Typography variant="h3">{title}</Typography>
      </DialogContent>
      <form noValidate onSubmit={handleSubmit}>
        <DialogContent className={styles.content}>
          <TextField
            {...textFieldProps(`${t('Courier Name')}`)}
            onChange={name.input.onChange}
            value={name.input.value}
            variant="standard"
            multiline
            InputLabelProps={{
              shrink: true,
              ...getErrorAndHelperText(name),
            }}
          />
          {submitError && (
            <Typography
              style={{ display: 'block' }}
              variant="overline"
              color="error"
            >
              {t(submitError)}
            </Typography>
          )}
        </DialogContent>
        <DialogActions>
          <ResetForm form={form} open={open} />
          <Button
            onClick={() => {
              onCancel();
            }}
          >
            {cancelText}
          </Button>
          <Button color="primary" type="submit">
            {confirmText}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );

  return !customTheme ? (
    dialog
  ) : (
    <ThemeProvider theme={customTheme}>{dialog}</ThemeProvider>
  );
};
