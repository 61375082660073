import React, { useState, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { Grid, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { createStyles } from '@material-ui/core/styles';
import { AnyObject, LibbyObject } from '../../../../../types/types';
import { useTranslation } from '../../../../../services/translation';
import { InformationSection } from '../../../../../components/InformationSection';
import { ScreenTitle } from '../../../../../components/ScreenTitle';
import { PaginatedTable } from '../../../../../components/PaginatedTable';
import { OrderDetailData } from '../../../../components/OrderDetailData';
import { JsonEditor } from '../../../../../components/JsonEditor';
import { INBOX_ORDER_STATE } from '../../../../../const/InboxOrderState';
import confirmDialog from '../../../../../services/confirmDialog';
import { useInboxOrderMappers } from '../hooks';
import { ErrorInboxOrder } from '../../components/ErrorInboxOrder';

const itemsColumns = [
  {
    id: 'action',
    label: 'Action',
    translate: true,
  },
  {
    id: 'reference',
    label: 'Reference',
    translate: true,
  },
  {
    id: 'where',
    label: 'Where',
    translate: true,
  },
];

const useStyles = makeStyles(() => createStyles({
  buttonsBox: {
    '& button': {
      marginLeft: 15,
    },
  },
}));

type InboxOrderDetailDataProps = {
  libby: LibbyObject,
  data: AnyObject,
  reFetch: () => void,
  recall: ((...newParams: any[]) => Promise<any>) | undefined
};

export const InboxOrderDetailData = ({
  libby,
  data,
  recall,
  reFetch,
}: InboxOrderDetailDataProps) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const classes = useStyles();

  const [editRaw, setEditRaw] = useState(false);
  const [editableRaw, setEditableRaw] = useState<AnyObject>({});
  const [editableRawPristine, setEditableRawPristine] = useState(true);

  const { basicMapper } = useInboxOrderMappers();

  const historyRefetch = useCallback(async () => {
    history.push('/inbox-orders');
    await reFetch();
  }, [history, reFetch]);

  const starEditJson = () => {
    setEditableRaw(data.raw);
    setEditRaw(true);
    setEditableRawPristine(true);
  };

  const onJsonEdit = (newJson: AnyObject) => {
    setEditableRawPristine(false);
    setEditableRaw(newJson);
  };

  const changeToIgnoredState = async () => {
    try {
      const confirm = await confirmDialog.show({
        title: t('Ignored State Confirmation'),
        content: `${t('You are about to pass the State to Ignored. Do you want to proceed?')}`,
        confirmText: `${t('Proceed')}!`,
        cancelText: t('cancel'),
      });
      if (confirm) {
        const newData = { ...data, state: { inbox_order_state_id: INBOX_ORDER_STATE.IGNORED } };
        await libby.ster_inbox_order.save(newData);
        if (recall) {
          await recall();
        }
        enqueueSnackbar(t('Saved, The Order Passed to Ignored'), { variant: 'success' });
        historyRefetch();
      }
    } catch (e) {
      enqueueSnackbar(t('Something is wrong'), { variant: 'error' });
    }
  };

  const reprocessOrder = async () => {
    try {
      const confirm = await confirmDialog.show({
        title: t('Reprocess Confirmation'),
        content: `${t('You are about to reprocess the order. Do you want to proceed?')}`,
        confirmText: `${t('Proceed')}!`,
        cancelText: t('Cancel'),
      });
      if (confirm) {
        const newData = { ...data, state: { inbox_order_state_id: INBOX_ORDER_STATE.MANUALLY_FIXED } };
        await libby.ster_inbox_order.save(newData);
        if (recall) {
          await recall();
        }
        enqueueSnackbar(t('Saved, The Order will be reprocessed'), { variant: 'success' });
        historyRefetch();
      }
    } catch (e) {
      enqueueSnackbar(t('Something is wrong'), { variant: 'error' });
    }
  };

  const handleIgnoredOption = () => {
    changeToIgnoredState();
  };

  const handleReprocessOption = () => {
    reprocessOrder();
  };

  const saveEditableRaw = async () => {
    try {
      const confirm = await confirmDialog.show({
        title: t('Edition Confirmation'),
        content: `${t('The edition will be mark this record to be reprocessed. Do you want to proceed?')}`,
        confirmText: `${t('Proceed')}!`,
        cancelText: t('No'),
      });
      if (confirm) {
        const newData = { ...data, raw: editableRaw, state: { inbox_order_state_id: INBOX_ORDER_STATE.MANUALLY_FIXED } };
        await libby.ster_inbox_order.save(newData);
        setEditRaw(false);
        if (recall) {
          await recall();
        }
        enqueueSnackbar(t('Saved'), { variant: 'success' });
        historyRefetch();
      }
    } catch (e) {
      enqueueSnackbar(t('Something is wrong'), { variant: 'error' });
    }
  };

  return (
    <>
      {data.state.inbox_order_state_id === INBOX_ORDER_STATE.PROBLEM && data.situation.exception && (
        <InformationSection title={t('Error')}>
          <ErrorInboxOrder data={data} />
        </InformationSection>
      )}
      <InformationSection title={t('Basic Information')} noPadding>
        {basicMapper(data)}
      </InformationSection>
      <InformationSection title={t('Track Information')} noPadding>
        <PaginatedTable headCells={itemsColumns} rows={data.situation?.track.slice().reverse() || []} rowIdKey="action" />
      </InformationSection>
      <InformationSection title={t('Raw')} noPadding>
        <>
          {(data.state.inbox_order_state_id === INBOX_ORDER_STATE.PROBLEM || data.state.inbox_order_state_id === INBOX_ORDER_STATE.IGNORED) && (
            <div style={{ textAlign: 'right', paddingBottom: 16 }}>
              {!editRaw ? (
                <Grid className={classes.buttonsBox}>
                  {data.state.inbox_order_state_id === INBOX_ORDER_STATE.PROBLEM && (
                    <>
                      <Button variant="contained" color="primary" onClick={handleReprocessOption}>
                        {t('Reprocess')}
                      </Button>
                      <Button variant="contained" color="primary" onClick={handleIgnoredOption}>
                        {t('Ignore')}
                      </Button>
                    </>
                  )}
                  <Button variant="contained" color="primary" onClick={starEditJson}>
                    {t('Edit')}
                  </Button>
                </Grid>
              ) : (
                <>
                  <Button variant="contained" onClick={() => setEditRaw(false)} style={{ marginRight: 16 }}>
                    {t('Cancel')}
                  </Button>
                  <Button variant="contained" color="primary" onClick={saveEditableRaw} disabled={editableRawPristine}>
                    {t('Save')}
                  </Button>
                </>
              )}
            </div>
          )}
          <JsonEditor
            key={`json-editable-${editRaw}`}
            value={data.raw}
            onChange={onJsonEdit}
            readOnly={!editRaw}
          />
        </>
      </InformationSection>
      {!!data.order && (
        <>
          <ScreenTitle title={t('Generated Order Detail')} />
          <OrderDetailData data={data.order} />
        </>
      )}
    </>
  );
};
