import { useCallback } from 'react';
import { useSnackbar } from 'notistack';
import { useHistory } from 'react-router-dom';
import { useTranslation } from '../../../services/translation';
import { LibbyObject, AnyObject, userInfoType } from '../../../types';
import { formInitialValues, validate, validateWithAdmin } from '../utils/model';
import CustomModal from '../../../services/customFormDialog';
import { CollectionCreateForm } from '../components/CollectionCreateForm';
import { ACCOUNT_ROLE } from '../../../const/accountRole';
import { TitleBreadcrumbs } from '../../../interfaces';
import { useGlobalContext } from '../../../hooks';
import { Collect } from '../../../interfaces/business';

const CreateCollectionModal = CustomModal(CollectionCreateForm);

type CreateCollectionType = {
  cart_id: number;
  dispatch_id: number;
  account_id: number;
};

type useCreateUpdateCollectModalProps = {
  libby: LibbyObject;
  dispatchId?: string;
  updateData?: (Collect: Collect, nameId: string) => void;
  addCreate?: (Collect: Collect) => void;
};

export const useCreateUpdateCollectModal = ({
  libby,
  dispatchId,
  updateData,
  addCreate,
}: useCreateUpdateCollectModalProps) => {
  const { t } = useTranslation();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const {
    userInfo,
  }: {
    userInfo: userInfoType;
  } = useGlobalContext();

  const rolAdminLogistics = userInfo?.groups?.[0].id === ACCOUNT_ROLE.ADMINISTRATOR
    || userInfo?.groups?.[0].id === ACCOUNT_ROLE.LOGISTICS;

  const handleOnOpenCollectModal = useCallback(
    async (
      currentCollect?: AnyObject,
      addDispatch: Boolean = false,
      title: TitleBreadcrumbs[] = [],
    ) => {
      const statusCollect = currentCollect && !addDispatch;

      const daoName = addDispatch && currentCollect?.items?.length
        ? 'ster_dispatch_collect_details'
        : 'ster_dispatch_collect_close';

      const libbySave = daoName;
      const init = currentCollect
        ? {
          ...currentCollect,
          cart_id: currentCollect?.cart?.cart_id,
          dispatch_id: dispatchId || currentCollect?.dispatch?.dispatch_id,
          account_id: currentCollect?.account?.account_id?.toString(),
        }
        : formInitialValues;

      try {
        const updateValidate = currentCollect?.account?.account_id?.toString();
        const newCollectionInfo = (await CreateCollectionModal.show({
          confirmText: t(
            !!updateValidate && !addDispatch ? 'Update' : 'Create',
          ),
          cancelText: t('Cancel'),
          title: t(
            !!updateValidate && !addDispatch
              ? 'Update Collect'
              : 'Create Collect',
          ),
          validate: rolAdminLogistics ? validateWithAdmin : validate,
          validateDispatch: addDispatch || !!dispatchId,
          rolAdminLogistics,
          data: {
            ...currentCollect,
            dispatch: {
              ...currentCollect?.dispatch,
              dispatch_id: dispatchId || currentCollect?.dispatch?.dispatch_id,
            },
          },
          formInitialValues: init,
        })) as CreateCollectionType;
        if (newCollectionInfo) {
          const { cart_id, dispatch_id, account_id } = newCollectionInfo;
          const messageSave = statusCollect
            ? 'Modified collect'
            : 'Collect saved';
          try {
            const newCollectSaved: Collect = await libby[libbySave]
              .aspect('update_dispatch_cart_account')
              .save({
                ...(currentCollect && { ...currentCollect }),
                dispatch: { dispatch_id },
                cart: { cart_id },
                account: { account_id },
              });

            if (currentCollect?.collect_id && updateData) {
              updateData(newCollectSaved, 'collect_id');
            } else {
              if (addCreate) { addCreate(newCollectSaved); }

              const redirectionDispatch = `/dispatches/detail/${dispatch_id}/collects/detail/${newCollectSaved.collect_id}`;
              const redirection = addDispatch && currentCollect?.items?.length
                ? redirectionDispatch
                : `${redirectionDispatch}/add-orders`;

              history.push({
                pathname: addDispatch ? redirection : `/collect/add-orders/${newCollectSaved.collect_id}`,
                state: {
                  goBack: title,
                },
              });
            }
            enqueueSnackbar(
              t(messageSave),
              { variant: 'success' },
            );
          } catch (e) {
            const status = statusCollect ? 'modifying' : 'creating';
            const message = `Error ${status} collect`;
            enqueueSnackbar(
              `${t(message)}: ${e}`,
              {
                variant: 'error',
              },
            );
          }
        }
      } catch (error) {
        // nothing
      }
    },
    [
      t,
      libby,
      enqueueSnackbar,
      updateData,
      addCreate,
      rolAdminLogistics,
      history,
      dispatchId,
    ],
  );

  return {
    handleOnOpenCollectModal,
  };
};
