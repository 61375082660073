import React, { useLayoutEffect, useRef } from 'react';
import TextField from '@material-ui/core/TextField';
import { SelectRole } from '../../components/SelectRole';
import { Account } from '../../../types/Account';
import { useTranslation } from '../../../../../services/translation';
import { AnyObject } from '../../../../../types/types';
import { makeGetErrorAndHelperText, textFieldProps } from '../../../../../utils/materialHelpers';

type InputsFormProps = {
  account: Account;
  username: AnyObject;
  password: AnyObject;
  email: AnyObject;
  account_role ?: AnyObject;
  duplicateAccount: boolean;
};

export const InputsForm = ({
  account, username, password, email, account_role, duplicateAccount,
}: InputsFormProps) => {
  const { t } = useTranslation();
  const getErrorAndHelperText = makeGetErrorAndHelperText(t);
  const accountInput = useRef<HTMLHeadingElement>(null);
  const passwordInput = useRef<HTMLHeadingElement>(null);
  const emailInput = useRef<HTMLHeadingElement>(null);

  useLayoutEffect(() => {
    if (!account) {
      setTimeout(() => {
        if (accountInput.current != null) {
          accountInput.current.focus();
        }
      }, 0);
    } else if (passwordInput && passwordInput.current) {
      setTimeout(() => {
        if (passwordInput.current != null) {
          passwordInput.current.focus();
        }
      }, 0);
    }
  }, [account]);

  return (
    <>
      <TextField
        {...username.input}
        disabled={!!account}
        {...textFieldProps(t('Username'))}
        inputRef={accountInput}
      />

      {!duplicateAccount && !!account && (
        <div>
          <TextField
            {...email.input}
            {...textFieldProps(t('Email'))}
            inputRef={emailInput}
            type="email"
          />

          <TextField
            {...textFieldProps(t('Password'))}
            {...password.input}
            {...getErrorAndHelperText(password)}
            type="password"
            inputRef={passwordInput}
            autoComplete="new-password"
          />
          {account_role && (
          <SelectRole
            inputProps={{ ...account_role.input, ...getErrorAndHelperText(account_role) }}
          />
          )}
        </div>
      )}
    </>
  );
};

InputsForm.defaultProps = {
  // eslint-disable-next-line react/default-props-match-prop-types
  account: {},
};
