import { AnyObject } from '../types/types';

// TODO: fix this aberration!!!!!!!!!!!
// this is only for the dialog boxes.
export const importationsTypes: AnyObject = {
  1: 'BNA',
  2: 'Andreani BNA',
  3: 'BNA Inscripciones',
  4: 'BNA Cobros',
  5: 'Fravega Cobros',
  6: 'PAMI',
  7: 'Fravega Cancelled',
  8: 'Correo compras',
  9: 'Tienda BNA',
  10: 'Mercado Pago Cobros',
  11: 'Tarjeta Naranja Cobros',
  12: 'BANCOR',
};

export const IMPORTATION_TYPES_NAME = {
  BNA: 1,
  ANDREANI_BNA: 2,
  BNA_INSCRIPCIONES: 3,
  BNA_COBROS: 4,
  FRAVEGA_COBROS: 5,
  PAMI: 6,
  FRAVEGA_CANCELLED: 7,
  CORREO_COMPRAS: 8,
  TIENDA_BNA: 9,
  MERCADO_PAGO_COBROS: 10,
  TARJETA_NARANJA_COBROS: 11,
  BANCOR: 12,
};
