import React, { useMemo } from 'react';
import LocalShippingIcon from '@material-ui/icons/LocalShipping';
import LinearProgress from '@material-ui/core/LinearProgress';
import { withStyles } from '@material-ui/core/styles';
import { useTranslation } from '../../../../../services/translation';
import { CollectMobile } from './CollectMobile';
import { percentageComplete, percentageColor } from '../../../../../utils';
import { CollectState } from '../../../../../const/CollectState';

type CollectListMobileProps = {
  row: any;
};

export const CollectListMobile = ({ row }: CollectListMobileProps) => {
  const { t } = useTranslation();

  const itemsState = row.items.filter(
    (item: any) => item.collectItemState.collect_item_state_id === CollectState.COLLECTING,
  ).length || 0;
  const complete = percentageComplete(itemsState, row.allItems);
  const color = percentageColor(Number(complete));
  const language = localStorage.getItem('language') || 'es';

  const BorderLinearProgress = withStyles(() => ({
    root: {
      height: 4,
      borderBottomLeftRadius: '4px',
      borderBottomRightRadius: '4px',
    },
    colorPrimary: {
      backgroundColor: '#C4C2C4',
    },
    bar: {
      backgroundColor: `${color}`,
    },
  }))(LinearProgress);

  const data = useMemo(
    () => (row
      ? [
        [
          {
            title: `${t('Assigned To')}: ${row.assigned} `,
          },
          {
            title: `${t(row.state)}`,
            titleSize: true,
            xs: 4,
            align: 'right',
            justify: 'flex-end',
          },
        ],
        [
          {
            title: row.collect,
            titleSize: true,
            colorType: true,
          },
          {
            title: row.updated_at || row.created_at,
            titleSize: true,
            xs: 4,
            align: 'right',
            justify: 'flex-end',
          },
        ],
        [
          {
            title: t(row.service),
            icon: <LocalShippingIcon />,
          },
          {
            title: `${row.allItems} ${' '} ${t('Orders')}`,
            xs: 4,
            align: 'right',
            button: true,
            justify: 'flex-end',
          },
        ],
      ]
      : []),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [row, t, language],
  );

  return (
    <CollectMobile datas={data}>
      <BorderLinearProgress variant="determinate" value={complete} />
    </CollectMobile>
  );
};
