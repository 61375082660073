import React, { useMemo } from 'react';
import { DatabaseConnector } from '@phinxlab/libby-rest-web';
import { GeneralSelect } from '../../components/GeneralSelect';
import { AnyObject, LibbyObject } from '../../types/types';
import { OptionsLibbyFetch, useLibbyFetch } from '../../hooks';

type SelectMarketPlaceTypeProps = {
  inputProps: AnyObject,
  optionAll?: boolean,
  [k: string]: any,
  libby: LibbyObject,
};

const paramsFetch: OptionsLibbyFetch = {
  daoName: 'ster_marketplace',
};

export const SelectMarketPlaceRaw = ({
  libby, inputProps = {}, optionAll = false, ...styles
}: SelectMarketPlaceTypeProps) => {
  const {
    data: dataRaw, working,
  }: { data: AnyObject; working: boolean } = useLibbyFetch(libby, paramsFetch);

  const data = useMemo(
    () => dataRaw.map(({ marketplace_id, name }: AnyObject) => ({
      id: marketplace_id, value: name,
    })), [dataRaw],
  );

  return (
    <GeneralSelect working={working} label="Marketplace" optionAll={optionAll} data={data} inputProps={inputProps} {...styles} />
  );
};

export const SelectMarketPlace = DatabaseConnector(SelectMarketPlaceRaw)(
  'ster_marketplace',
);
