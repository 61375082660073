import { makeValidator, ValidatorType, ValidatorRule } from '../../../../utils';

export const formInitialValues = {
  import_type: '0',
};

const validations: ValidatorRule[] = [
  { key: 'import_type', validators: [ValidatorType.SELECT] },
];

export const validate = makeValidator(validations);

export const getValidate = (keys: Array<string>) => makeValidator(
  keys.length > 0 ? validations.filter((val) => keys.includes(val.key)) : validations,
);
