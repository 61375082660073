import React, {
  useMemo,
} from 'react';
import { DatabaseConnector } from '@phinxlab/libby-rest-web';
import { AnyObject, LibbyObject } from '../../../../types/types';
import { GeneralSelect } from '../../../../components/GeneralSelect';
import { useLibbyFetch } from '../../../../hooks';
import { importationsStatus } from '../../../../const';
import { useTranslation } from '../../../../services/translation';

type SelectImportTypeProps = {
  libby: LibbyObject,
  inputProps: AnyObject,
  optionAll?: boolean,
  [k: string]: any,
};

const paramsFetch = {
  daoName: 'public_importation_status',
};

export const SelectImportStatusRaw = ({
  libby, inputProps = {}, optionAll = false, ...styles
}:SelectImportTypeProps) => {
  const { t } = useTranslation();

  const { data: importStatus, working } = useLibbyFetch(libby, paramsFetch);
  const data = useMemo(() => importStatus.map(({ importation_status_id }: AnyObject) => ({
    id: importation_status_id,
    value: t(importationsStatus[importation_status_id]),
  })), [importStatus, t]);

  return (
    <GeneralSelect working={working} label="Status" optionAll={optionAll} data={data} inputProps={inputProps} {...styles} />
  );
};
export const SelectImportStatus = DatabaseConnector(SelectImportStatusRaw)('public_importation_status');
