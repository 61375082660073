import { AnyObject } from '../types/types';

export enum ORDER_STATE {
  CREATED = 1,
  SOLD = 2,
  ON_QUEUE = 3,
  WAITING_FOR_PREPARATION = 4,
  PREPARED = 5,
  READY_FOR_DELIVERY = 6,
  OUT_FOR_DELIVERY = 8,
  DELIVERED = 9,
  CANCELLED = 10,
  PRODUCTION = 11,
  PAID = 12,
  UPLOAD_TO_COLLECTIONS = 13,
  READY_TO_RESEND = 14,
  RETURNED = 15,
  TRACKER_INFORMED = 16,
  BILL_INFORMED = 17,
  COLLECTED = 18,
  WAITING_FOR_EXTERNAL_DELIVERY = 19,
  EXTERNAL_DELIVERY_READY = 20,
  WAITING_FOR_APPROVAL = 21,
  WAITING_TO_CANCEL = 22,
  RETURNED_COMPLETED = 23,
  RETURNING_TO_CANCEL = 24,
  READY_FOR_PREPARATION = 25,
}

export const OrderState: AnyObject = {
  1: 'Created',
  2: 'Sold',
  3: 'On queue',
  4: 'Waiting for preparation',
  5: 'Prepared',
  6: 'Ready for delivery',
  8: 'Out for delivery',
  9: 'Delivered',
  10: 'Cancelled',
  11: 'Production',
  12: 'Paid',
  13: 'Upload to collections',
  14: 'Ready to resend',
  15: 'Returned',
  16: 'Tracker Informed',
  17: 'Bill Informed',
};

export const OrderStateColors: AnyObject = {
  1: 'purple',
  2: 'blue',
  3: 'grey',
  4: 'orange',
  5: 'black',
  6: 'Lilac',
  8: 'Plum',
  9: 'green',
  10: 'red',
  11: 'violet',
};
