import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { AnyObject } from '../../types/types';
import { useTranslation } from '../../services/translation';
import { getComparator, RowsType, stableSort } from '../../utils/tables';
import { EnhancedTableHead } from '../EnhancedTableHead/EnhancedTableHead';

export const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  tableContainer: {
    maxHeight: '500px',
  },
  row: {
    cursor: 'pointer',
  },
}));

type HeadCellsType = {
  id: string;
  label: string;
  translate?: boolean;
  render?: (row: any, item: any, t: (str: string) => string) => React.ReactNode;
  [k: string]: any;
};

type PaginatedTableType = {
  rows: Array<RowsType>;
  headCells: Array<HeadCellsType>;
  onRowClick?: (row: { id: string } & AnyObject) => void;
  rowIdKey?: string;
  sortByDefaultBy?: string;
  isAsc?: boolean;
};

export const PaginatedTable = ({
  rows,
  headCells,
  onRowClick,
  rowIdKey = 'id',
  sortByDefaultBy = 'id',
  isAsc = false,
}: PaginatedTableType) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [order, setOrder] = React.useState<'asc' | 'desc'>(
    isAsc ? 'asc' : 'desc',
  );
  const [orderBy, setOrderBy] = React.useState(sortByDefaultBy);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  useEffect(() => {
    setPage(0);
  }, [rows]);

  const handleRequestSort = (event: AnyObject, property: any) => {
    setOrder(order === 'asc' ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
    newPage: number,
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: AnyObject) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <TableContainer className={classes.tableContainer}>
          <Table
            aria-labelledby="tableTitle"
            aria-label="enhanced table"
            stickyHeader
          >
            <EnhancedTableHead
              classes={classes}
              headCells={headCells}
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
            />
            <TableBody>
              {stableSort(rows, getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row: any) => (
                  <TableRow
                    key={row[rowIdKey]}
                    hover
                    onClick={() => (onRowClick ? onRowClick(row) : undefined)}
                    tabIndex={-1}
                    className={onRowClick && classes.row}
                  >
                    {headCells.map((item: AnyObject) => {
                      const { id, translate, render } = item;
                      return (
                        <TableCell key={`${id}${row[id]}`} align="left">
                          {render
                            ? render(row, item, t)
                            : translate
                              ? t(row[id])
                              : row[id]}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 25, 50]}
          component="div"
          count={rows.length}
          labelRowsPerPage={t('Rows per page:')}
          rowsPerPage={rowsPerPage}
          page={page > rows.length / rowsPerPage - 1 ? 0 : page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Paper>
    </div>
  );
};
