import React, { useState, useCallback } from 'react';
import { TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import { useTranslation } from '../../../../../services/translation';
import { replaceNonNumericCharacters } from '../../../../../functions';

const useStyles = makeStyles({
  buttonLargeWidth: {
    padding: '5px 18px',
    minWidth: 100,
  },
  inputContainer: {
    minWidth: 280,
    marginRight: 10,
  },
});

export interface AddOrderItemProp {
  addItemInOrderItems: (orderId: string, resetInputOrder: () => void) => void;
  working?: boolean
}
export const AddOrderItem = ({ addItemInOrderItems, working = false }: AddOrderItemProp) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [orderId, setOrderId] = useState('');
  const resetOrderInput = useCallback(() => {
    setOrderId('');
  }, []);
  return (
    <form onSubmit={(e) => {
      e.preventDefault();
      addItemInOrderItems(orderId, resetOrderInput);
      setOrderId('');
    }}
    >
      <Grid container direction="row" alignItems="center">
        <Grid item className={classes.inputContainer}>
          <TextField
            id="orderId"
            label={t('Order id')}
            type="text"
            autoFocus
            value={orderId}
            onChange={(event) => setOrderId(replaceNonNumericCharacters(event.target.value))}
            fullWidth
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Grid>
        <Grid item>
          <Button
            className={classes.buttonLargeWidth}
            disabled={orderId.length === 0 || working}
            variant="outlined"
            color="primary"
            type="submit"
          >
            {t('Add')}
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};
