import React, { ReactNode, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import IconButton from '@material-ui/core/IconButton';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { makeStyles } from '@material-ui/styles';
import { PropTypes } from '@material-ui/core';

type BackButtonProps = {
  to?: string;
  onBackClick?: (goBackHandler: () => void) => void;
  icon?: ReactNode;
  color?: PropTypes.Color;
};

const useStyles = makeStyles({
  buttonBox: {
    display: 'flex',
    flexWrap: 'nowrap',
  },
});

const defaultIcon = <ArrowBackIcon />;

export const BackButton = ({
  to, onBackClick, icon = defaultIcon,
  color = 'inherit',
}: BackButtonProps) => {
  const history = useHistory();
  const classes = useStyles();

  const onClick = useCallback(() => {
    const goBack = () => (to ? history.replace(to) : history.goBack());
    if (onBackClick) onBackClick(goBack);
    else goBack();
  }, [to, history, onBackClick]);

  return (
    <IconButton
      color={color}
      aria-label="Go back"
      onClick={onClick}
      className={classes.buttonBox}
    >
      {icon}
    </IconButton>
  );
};
