export enum CollectState {
  NOT_STARTED = '1',
  COLLECTING = '2',
  COMPLETE = '3',
  CANCELLED = '4'
}

export const Collect_State: { [k: string]: string } = {
  1: 'Not started',
  2: 'Collecting',
  3: 'Complete',
};
