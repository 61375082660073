import React, { useMemo } from 'react';
import { Switch, Redirect } from 'react-router-dom';
import { Accounts } from './Accounts';
import { Cities } from './Cities';
import { PrivateRoute } from './components/PrivateRoute';
import { AccountProvider } from '../context/AccountProvider';
import { Imports } from './Imports';
import { InboxOrders } from './InboxOrders';
import { ERPOrder } from './ERPOrder';
import { Orders } from './Orders';
import { States } from './States';
import { TagManager } from './TagManager';
import { Dispatches } from './Dispatches';
import { Collection } from './Collection';
import { Reporting } from './Reporting';
import { useGlobalContext } from '../hooks';
import { authorizeMenu } from '../utils';
import { userInfoType } from '../types';
import { OrderReport } from './OrderReport';
import { SterConfig } from './SterConfig';
import { Home } from './Home';
import { OrderDeliveryConfirmation } from './OrderDeliveryConfirmation';
import { ReportingPendingAndreaniIncome } from './ReportingAndreani';
import { MENU } from '../const';
import { CanalOffline } from './CanalOffOnline';
import { Couriers } from './Couriers';
import { ReportingSales } from './ReportingSales';

interface RouteProps {
  isLogged: boolean;
}
const AccountsWithProvider = () => (
  <AccountProvider>
    <Accounts />
  </AccountProvider>
);
export const Routes = ({ isLogged }: RouteProps) => {
  const {
    userInfo,
  }: {
    userInfo: userInfoType;
  } = useGlobalContext();

  const privateRouterArray = useMemo(
    () => [
      {
        path: '/orders/changestate',
        id: [MENU.DELIVERY_CONFIRMATION],
        authorize: () => authorizeMenu(userInfo, MENU.DELIVERY_CONFIRMATION),
        component: OrderDeliveryConfirmation,
      },
      {
        path: '/orders',
        id: [MENU.ORDER],
        authorize: () => authorizeMenu(userInfo, MENU.ORDER),
        component: Orders,
      },
      {
        path: '/inbox-orders',
        id: [MENU.INBOX],
        authorize: () => authorizeMenu(userInfo, MENU.INBOX),
        component: InboxOrders,
      },
      {
        path: '/tag-manager',
        id: [MENU.SHIPPING],
        authorize: () => authorizeMenu(userInfo, MENU.SHIPPING),
        component: TagManager,
      },
      {
        path: '/dispatches',
        id: [MENU.DISPATCH, MENU.DISPATCH_INQUIRY],
        authorize: () => authorizeMenu(userInfo, MENU.DISPATCH)
          || authorizeMenu(userInfo, MENU.DISPATCH_INQUIRY),
        component: Dispatches,
      },
      {
        path: '/collect',
        id: [MENU.COLLECTS],
        authorize: () => authorizeMenu(userInfo, MENU.COLLECTS),
        component: Collection,
      },
      {
        path: '/states',
        id: [MENU.PROVINCES],
        authorize: () => authorizeMenu(userInfo, MENU.PROVINCES),
        component: States,
      },
      {
        path: '/cities',
        id: [MENU.LOCALITIES],
        authorize: () => authorizeMenu(userInfo, MENU.LOCALITIES),
        component: Cities,
      },
      {
        path: '/imports/:importationTargetId',
        id: [MENU.IMPORTS],
        authorize: () => authorizeMenu(userInfo, MENU.IMPORTS),
        component: Imports,
      },
      {
        path: '/accounts',
        id: [MENU.ACCOUNTS],
        authorize: () => authorizeMenu(userInfo, MENU.ACCOUNTS),
        component: AccountsWithProvider,
      },
      {
        path: '/reporting',
        id: [MENU.REPORTING],
        authorize: () => authorizeMenu(userInfo, MENU.REPORTING),
        component: Reporting,
      },
      {
        path: '/aging-report',
        id: [MENU.REPORTING],
        authorize: () => authorizeMenu(userInfo, MENU.REPORTING),
        component: OrderReport,
      },
      {
        path: '/report_pending_andreani_income',
        id: [MENU.REPORT_PENDING_ANDREANI_INCOME],
        authorize: () => authorizeMenu(userInfo, MENU.REPORT_PENDING_ANDREANI_INCOME),
        component: ReportingPendingAndreaniIncome,
      },
      {
        path: '/erp-orders',
        id: [MENU.ERP_ORDERS],
        authorize: () => authorizeMenu(userInfo, MENU.ERP_ORDERS),
        component: ERPOrder,
      },
      {
        path: '/process_monitor',
        id: [MENU.PROCESS_MONITOR],
        authorize: () => authorizeMenu(userInfo, MENU.PROCESS_MONITOR),
        component: SterConfig,
      },
      {
        path: '/canal-off-line',
        id: [MENU.CANAL_OFF_LINE],
        authorize: () => authorizeMenu(userInfo, MENU.CANAL_OFF_LINE),
        component: CanalOffline,
      },
      {
        path: '/couriers',
        id: [MENU.COURIERS],
        authorize: () => authorizeMenu(userInfo, MENU.COURIERS),
        component: Couriers,
      },
      {
        path: '/reporting-sales',
        id: [MENU.REPORTING],
        authorize: () => authorizeMenu(userInfo, MENU.REPORTING),
        component: ReportingSales,
      },
    ],
    [userInfo],
  );

  const render = useMemo(
    () => (userInfo?.menu?.length
      ? privateRouterArray.find(
        (router) => userInfo?.menu?.length
              && router.id.includes(userInfo?.menu[0].menu.menu_id),
      )?.path
      : '/'),
    [userInfo, privateRouterArray],
  );

  return !isLogged ? (
    <Switch>
      <Home />
    </Switch>
  ) : (
    <Switch>
      {privateRouterArray.map((data) => (
        <PrivateRoute
          key={`${data.path}`}
          path={data.path}
          authorize={data.authorize}
          component={data.component}
          redirect={render}
        />
      ))}
      <Redirect to={`${render}`} />
    </Switch>
  );
};
