import { LibbyFetchDAO } from './LibbyFetchDAO';

export class DispatchItemDAO extends LibbyFetchDAO {
  constructor() {
    super('ster_dispatch_item', 'dispatch_item_id');
  }

  getAll() {
    return this.query()
      .limit(0, 10000)
      .run();
  }
}
