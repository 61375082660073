import React from 'react';
import { Box, capitalize } from '@material-ui/core';
import { percentageComplete, percentageColor } from '../../../utils';
import { CollectState } from '../../../const/CollectState';
import { HeadCellsType } from '../../../types';

export const columnCollect: HeadCellsType[] = [
  {
    id: 'actions',
    label: '',
    noSort: true,
  },
  {
    id: 'collect',
    label: 'Collect',
    minWidth: 170,
    orderById: 'collect_id',
  },
  {
    id: 'dispatch',
    label: 'Dispatch',
    minWidth: 170,
    orderById: 'dispatch.name',
  },
  {
    id: 'cart',
    label: 'Cart',
    minWidth: 170,
    orderById: 'cart.name',
  },
  {
    id: 'courier',
    label: 'Courier',
    minWidth: 170,
    orderById: 'dispatch.courier_service.courier.name',
    render: (
      { courier }: { courier: string },
      item: any,
      t: (str: string) => string,
    ) => (
      <Box whiteSpace="nowrap" textAlign="center">
        {t(capitalize(courier))}
      </Box>
    ),
  },
  {
    id: 'service',
    label: 'Service',
    minWidth: 170,
    orderById: 'dispatch.courier_service.name',
    render: (
      { service }: { service: string },
      item: any,
      t: (str: string) => string,
    ) => (
      <Box whiteSpace="nowrap" textAlign="center">
        {t(capitalize(service))}
      </Box>
    ),
  },
  {
    id: 'allItems',
    label: 'Orders',
    minWidth: 170,
    noSort: true,
  },
  {
    id: 'collected',
    label: 'Collected',
    minWidth: 170,
    noSort: true,
    render: ({ items, allItems }: any) => {
      const itemsState = items.filter(
        (item: any) => item.collectItemState.collect_item_state_id
            === CollectState.COLLECTING,
      ).length || 0;
      const complete = percentageComplete(itemsState, allItems);
      const color = percentageColor(Number(complete));
      return (
        <Box color={color} whiteSpace="nowrap">
          {`${itemsState}/${allItems}`}
        </Box>
      );
    },
  },
  {
    id: 'state',
    label: 'State',
    orderById: 'collectState.collect_state_id',
    render: (
      { state }: { state: string },
      item: any,
      t: (str: string) => string,
    ) => (
      <Box whiteSpace="nowrap" textAlign="center">
        {t(capitalize(state))}
      </Box>
    ),
  },
  {
    id: 'assigned',
    label: 'Assigned',
    orderById: 'account.username',
  },
  {
    id: 'delivery_date',
    label: 'Delivery date',
    orderById: 'dispatch.delivery_date',
    style: {
      whiteSpace: 'nowrap',
    },
  },
  {
    id: 'updated_by',
    label: 'Updated By',
    minWidth: 170,
    orderById: 'updated_by.username',
    style: {
      whiteSpace: 'nowrap',
    },
  },
  {
    id: 'created_at',
    label: 'Creation date',
    minWidth: 170,
    style: {
      whiteSpace: 'nowrap',
    },
  },
  {
    id: 'updated_at',
    label: 'Last Update',
    minWidth: 170,
    orderById: 'updated_at',
    style: {
      whiteSpace: 'nowrap',
    },
  },
];
