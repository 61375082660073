import { LibbyFetchDAO } from './LibbyFetchDAO';

export class OrderCourierDAO extends LibbyFetchDAO {
  constructor() {
    super('courier_order', 'courier_id');
  }

  getAll() {
    return this.query()
      .limit(0, 10000)
      .run();
  }
}
