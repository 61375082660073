import React from 'react';
import { Rectangle, TooltipPayload } from 'recharts';

interface CustomizedCursorProps {
  x: number;
  y: number;
  width: number;
  height: number;
  payload: TooltipPayload[]
  onChange?: (payload: any)=>void;
}

export const CustomizedCursor = (props: any) => {
  const {
    x, y, width, height, payload, onChange,
  }: CustomizedCursorProps = props;
  return (
    <Rectangle
      fill="#cecece"
      onClick={() => {
        if (onChange) {
          onChange(payload[0].payload);
        }
      }}
      stroke="#cecece"
      x={x}
      y={y}
      width={width}
      height={height}
    />
  );
};
