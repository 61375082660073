export enum MARKETPLACE {
  Fravega = '1',
  NOVATECH_STORE = '2',
  ICBC = '3',
  BNA = '4',
  BNA_UNIVERSITARIOS = '5',
  TARJETA_NARANJA = '6',
  PAMI = '7',
  CORREO_COMPRAS = '8'
}

export const Marketplace: { [k: string]: string } = {
  1: 'Fravega',
  2: 'NovatechStore',
  3: 'ICBC',
  4: 'BNA',
  5: 'BNA Universitarios',
  6: 'Tarjeta Naranja',
  7: 'PAMI',
  8: 'Correo Compras',
  9: 'Banco Provincia',
};
