import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { useTranslation } from '../../../../../services/translation';

const useStyles = makeStyles(() => ({
  messageError: {
    display: 'block',
  },
}));

type AccountErrorMessagesProps = {
  duplicateAccount: boolean,
  submitError: string,
};

export const AccountErrorMessages = ({ duplicateAccount, submitError }: AccountErrorMessagesProps) => {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <div>
      {duplicateAccount && (
        <Typography className={classes.messageError} color="error">
          {t('El usuario ya existe')}
        </Typography>
      )}
      {submitError && (
        <Typography className={classes.messageError} color="error">
          {t(submitError)}
        </Typography>
      )}
    </div>
  );
};
