import { LibbyFetchDAO } from './LibbyFetchDAO';
import { DISPATCH_STATE } from '../../../const';

export class DispatchStateDAO extends LibbyFetchDAO {
  constructor() {
    super('ster_dispatch_state', 'dispatch_state_id');
  }

  getAll() {
    return this.query().limit(0, 10000).run();
  }

  getAllInquiry() {
    return this.query()
      .equals('dispatch_state_id', DISPATCH_STATE.SENT)
      .or()
      .equals('dispatch_state_id', DISPATCH_STATE.CANCELLED)
      .limit(0, 10000)
      .run();
  }
}
