import React from 'react';
import {
  Switch,
  Route,
} from 'react-router-dom';
import { AddAccountPublic } from './routes/AddAccount';
import { Login } from '../Login';
import { useBreadcrumbs } from '../../hooks';

export const Home = () => {
  const title = useBreadcrumbs('Home');
  return (
    <Switch>
      <Route path="/add">
        <AddAccountPublic title={title} />
      </Route>
      <Route path="/">
        <Login />
      </Route>
    </Switch>
  );
};
