import React from 'react';
import { DatabaseConnector } from '@phinxlab/libby-rest-web';
import { LibbyObject } from '../../types/types';
import { RowsType } from '../../utils/tables';
import { SearchDialogSearchData } from '../../components/SearchDialogSearchData';
import { useDocumentsByOrderId } from '../../hooks/useDocumentsByOrderId';
import { DocumentsTable } from './DocumentsTable';

type DocumentsTableProps = {
  orderId: string | number;
  libby: LibbyObject;
};

export const DocumentsTableRaw = ({ libby, orderId }: DocumentsTableProps) => {
  const { documents, loadingDocs } = useDocumentsByOrderId(libby, {
    daoName: 'ster_order_document',
    id: orderId,
  });

  return (
    <SearchDialogSearchData
      working={loadingDocs}
      properties={['documentNumber', 'type.name']}
      label="Documents"
      dataProp={documents}
      renderItem={(dataProp: { data: Array<RowsType> }) => (
        <DocumentsTable
          documents={dataProp.data}
        />
      )}
    />
  );
};

export const DocumentsTableSearch = DatabaseConnector(DocumentsTableRaw)(
  'ster_order_document',
);
