import React, { useMemo, useState, useCallback } from 'react';
import { Checkbox } from '@material-ui/core';
import LaunchIcon from '@material-ui/icons/Launch';
import ChatIcon from '@material-ui/icons/Chat';
import { AnyObject, LibbyObject } from '../types/types';
import CustomModal from '../services/customFormDialog';
import { useLibbyFetch } from './useLibbyFetch';
import { ExportDialogComponent } from '../components/ExportDialog/ExportDialogComponent';
import { MakeCell, MakeCellChildren } from '../routes/components/MakeCell';
import { DomicileTable } from '../routes/TagManager/routes/TagMangerList/components/DomicileTable';
import { makeFilter } from '../routes/TagManager/utils/makeFilter';
import { columnsTagmanager } from '../models/orders/columns';
import { useOrderToRows } from '../models/orders/rows';
import { RowsType } from '../utils/tables';
import { ORDER_STATE } from '../const';
import { Order } from '../interfaces/business';

const SearchDialogModal = CustomModal(ExportDialogComponent);

const handleOpenDomicileModal = async (e: AnyObject, order_id: string) => {
  e.stopPropagation();
  try {
    await SearchDialogModal.show({
      title: 'Domicile',
      properties: ['documentNumber', 'type.name'],
      id: ' order_id',
      label: '',
      render: () => <DomicileTable order_id={order_id} />,
    });
  } catch (error) {
    // nothing
  }
};
const handleOpenCommentsModal = async (e: AnyObject, comment?: string) => {
  e.stopPropagation();
  try {
    await SearchDialogModal.show({
      title: 'Reason for cancellation',
      render: () => <div>{comment}</div>,
    });
  } catch (error) {
    // nothing
  }
};

export const useTagManagerTableLogic = (libby: LibbyObject, path: string) => {
  const [direction, setDirection] = useState<'asc' | 'desc'>('asc');
  const [orderBy, setOrderBy] = useState('order_id');
  const [checked, setChecked] = useState({
    all: [] as AnyObject[],
  });

  const [filter, setFilter] = useState(
    makeFilter({
      orderStatus: '6',
    }),
  );

  const handleRequestSort = (
    newOrderBy: string,
    newDirection: 'asc' | 'desc',
  ) => {
    setOrderBy(newOrderBy);
    setDirection(newDirection);
  };

  const paramsFetch = useMemo(
    () => ({
      daoName: 'ster_order_so',
      filter,
      orderBy,
      direction,
      aspect: 'list_order_so_print',
    }),
    [filter, orderBy, direction],
  );

  const {
    data, working, fetchMore, updateData, searchData, removeData,
  } = useLibbyFetch(libby, paramsFetch);

  const updateDataWithProducts = useCallback(
    (product) => {
      const resultDatas = data.filter(
        (order: any) => order.items.filter((item: any) => item.sku === product.sku).length > 0,
      );

      resultDatas.forEach((itemUpdate: any) => {
        const itemsData = [...itemUpdate.items];
        const searchItem = itemsData.findIndex(
          (item: any) => item.sku === product.sku,
        );
        if (searchItem >= 0) {
          itemsData[searchItem] = { ...itemUpdate.item, product };
          const newDispatchSaved = { ...itemUpdate, items: itemsData };
          updateData(newDispatchSaved, 'order_id');
        }
      });
    },
    [updateData, data],
  );

  const savePrint = useCallback(() => {
    checked.all.forEach(async (value: AnyObject) => {
      const dataNew = { ...value };
      delete dataNew.urlBarcode;
      if (!value.print) {
        const result = { ...dataNew, print: true };
        updateData(result, 'order_id');
      }
    });
  }, [checked, updateData]);

  const handleCheckChieldElement = useCallback((event: AnyObject) => {
    setChecked((prev) => {
      let copy = { ...prev };

      const all = [...prev.all];
      const search = all.findIndex(
        (dataOrder: any) => dataOrder.order_id === event.order_id,
      );

      if (search === -1) {
        all.push({
          ...event,
        });
      } else {
        all.splice(search, 1);
      }
      copy = Object.assign(copy, { all });
      return copy;
    });
  }, []);

  const resetCheck = useCallback(() => {
    setChecked({ all: [] });
  }, [setChecked]);

  const handleCheckChildElementAll = useCallback(() => {
    setChecked((prev) => {
      let copy = { ...prev };

      const all = [...prev.all];

      if (all.length !== data.length) {
        data.forEach((value: any) => {
          if (
            all.findIndex(
              (valueCopy) => valueCopy.order_id === value.order_id,
            ) === -1
          ) {
            all.push({
              ...value,
            });
          }
        });
      } else {
        all.length = 0;
      }
      copy = Object.assign(copy, { all });

      return copy;
    });
  }, [data]);

  const columns = [
    {
      id: 'select_order_id',
      label: '',
      icon: () => (
        <MakeCellChildren label="">
          <Checkbox
            checked={checked.all.length === data.length && data.length > 0}
            onChange={handleCheckChildElementAll}
            inputProps={{ 'aria-labelledby': 'transfer-list-item-all-label' }}
            color="primary"
          />
        </MakeCellChildren>
      ),
      noSort: true,
    },
    ...columnsTagmanager,
  ];

  const getCommonRows = useOrderToRows(path);
  const dataOrder = data as Order[];

  const checkedOrder = checked.all as Order[];

  const rows: RowsType[] = useMemo(
    () => dataOrder.reduce((result: RowsType[], order: Order, index: number) => {
      result.push({
        ...getCommonRows(order),
        id: order.order_id.toString(),
        select_order_id: (
          <MakeCellChildren label="">
            <Checkbox
              checked={
                checkedOrder.findIndex(
                  (value: Order) => value.order_id === order?.order_id,
                ) !== -1
              }
              onChange={() => handleCheckChieldElement(dataOrder[index])}
              inputProps={{
                'aria-labelledby': `transfer-list-item-${order?.order_id}-label`,
              }}
              color="primary"
            />
          </MakeCellChildren>
        ),
        domicile: (
          <MakeCell
            label=""
            icon={LaunchIcon}
            onClick={(e) => handleOpenDomicileModal(e, order.order_id)}
          />
        ),
        returned_reason:
          order?.shipment?.state?.name === 'returned' ? (
            <MakeCell
              label=""
              icon={ChatIcon}
              onClick={(e) => handleOpenCommentsModal(e, order?.returned_reason ?? '')}
            />
          ) : (
            '-'
          ),
      });
      return result;
    }, []),
    [dataOrder, getCommonRows, checkedOrder, handleCheckChieldElement],
  );

  const updateDataField = useCallback(
    (order: Order) => {
      if (
        filter?.orderStatus?.[0].value
        !== ORDER_STATE.RETURNING_TO_CANCEL.toString()
      ) {
        const searchOrder = searchData(order, 'order_id');
        const dataOrderAll = data as Order[];

        if (searchOrder > -1) {
          const orderUpdate: Order = { ...dataOrderAll[searchOrder] };
          updateData({ ...orderUpdate, ...order }, 'order_id');
        }
      } else {
        removeData(order.order_id, 'dispatch_id');
      }
      resetCheck();
    },
    [updateData, data, searchData, removeData, filter, resetCheck],
  );

  return {
    working,
    data,
    rows,
    columns,
    checked,
    fetchMore,
    direction,
    orderBy,
    filter,
    savePrint,
    setFilter,
    handleRequestSort,
    resetCheck,
    updateData,
    updateDataWithProducts,
    updateDataField,
  };
};
