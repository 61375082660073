import React, { useCallback, MouseEventHandler } from 'react';
import { toUpper } from 'ramda';
import { DatabaseConnector } from '@phinxlab/libby-rest-web';
import Drawer from '@material-ui/core/Drawer';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import LogoutIcon from '@material-ui/icons/ExitToApp';
import CreditCardIcon from '@material-ui/icons/CreditCard';
// import DashboardIcon from '@material-ui/icons/Dashboard';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import Avatar from '@material-ui/core/Avatar';
import Collapse from '@material-ui/core/Collapse';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import DesktopWindowsOutlinedIcon from '@material-ui/icons/DesktopWindowsOutlined';
import { makeStyles } from '@material-ui/styles';
import { createStyles, Theme } from '@material-ui/core/styles';
import { pink } from '@material-ui/core/colors';
import LocalShippingOutlinedIcon from '@material-ui/icons/LocalShippingOutlined';
import MenuDrawerItem from './components/MenuDrawerItem';
import { useGlobalContext } from '../../hooks';
import { groupType } from '../../types';
import { useTranslation } from '../../services/translation';
import { authorizeMenu } from '../../utils';
import { ImportationsTargets, MENU } from '../../const';

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    width: '100%',
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
  nested2: {
    paddingLeft: theme.spacing(0),
  },
  marginSub: {
    margin: '0px',
    marginLeft: '17%',
  },
  nested: {
    paddingLeft: theme.spacing(2),
  },
  list: {
    width: 250,
  },
  toolbar: {
    ...theme.mixins.toolbar,
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(2),
  },
  nameText: {
    marginTop: theme.spacing(2),
  },
  profile: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    minHeight: 'fit-content',
    paddingTop: '1em',
    paddingBottom: '1em',
  },
  title: {
    textAlign: 'center',
  },

  avatar: {
    color: theme.palette.getContrastText(pink[500]),
    backgroundColor: pink[500],
    marginRight: 20,
    width: 45,
    height: 45,
  },
  userContainer: {
    padding: 10,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
    marginLeft: 16,
  },
  paddingSubMenu: { padding: '0px 16px' },
}));

type MenuDrawerProps = {
  libby: any;
};

const MenuDrawerRaw = ({ libby }: MenuDrawerProps) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [open, setOpen] = React.useState<string[]>([]);

  const {
    isOpen,
    set,
    userInfo,
    isLogged,
  }: {
    isOpen: boolean;
    set: Function;
    userInfo: object;
    isLogged: boolean;
  } = useGlobalContext();
  // Handlers
  const onCloseDrawer: MouseEventHandler = useCallback(() => {
    setOpen([]);
    set(false);
  }, [set]);

  const {
    username = '',
    groups = [],
  }: { username?: string; groups?: groupType[] } = userInfo || {};

  const getInitials = `${
    (username && username.length && username[0].charAt(0)) || ''
  }${(username && username.length && username[1].trim().charAt(0)) || ''}`;
  const handleClick = (value: string) => {
    setOpen((prev: any[]) => {
      const copy = [...prev];
      const result = copy.indexOf(value);

      if (result === -1) {
        copy.push(value);
      } else {
        copy.splice(result, 1);
      }
      return copy;
    });
  };

  return (
    <Drawer open={isOpen} onClose={onCloseDrawer}>
      <div role="presentation">
        <div className={classes.list}>
          <div className={classes.toolbar}>
            <Typography variant="h6">{t('Menu')}</Typography>
          </div>
          <Divider />
          {isLogged && (
            <div className={classes.userContainer}>
              <Avatar className={classes.avatar}>{toUpper(getInitials)}</Avatar>
              <div className={classes.profile}>
                <Typography variant="h5">{username}</Typography>
                <Typography variant="h6">
                  {groups.length > 0 && t(groups[0].name)}
                </Typography>
              </div>
            </div>
          )}
          <Divider />

          {(authorizeMenu(userInfo, MENU.ORDER)
            || authorizeMenu(userInfo, MENU.INBOX)
            || authorizeMenu(userInfo, MENU.IMPORTS)
            || authorizeMenu(userInfo, MENU.CANAL_OFF_LINE)) && (
            <>
              <ListItem button onClick={() => handleClick('order')}>
                <ListItemIcon>
                  <CreditCardIcon />
                </ListItemIcon>
                <ListItemText primary={t('Orders')} />
                {open.includes('order') ? <ExpandLess /> : <ExpandMore />}
              </ListItem>
              <Collapse
                in={open.includes('order')}
                timeout="auto"
                unmountOnExit
              >
                <List
                  component="div"
                  disablePadding
                  className={classes.marginSub}
                >
                  {authorizeMenu(userInfo, MENU.ORDER) && (
                    <ListItem
                      className={classes.nested}
                      onClick={onCloseDrawer}
                    >
                      <MenuDrawerItem
                        className={classes.title}
                        label="Orders"
                        path="/orders"
                      />
                    </ListItem>
                  )}
                  {authorizeMenu(userInfo, MENU.INBOX) && (
                    <ListItem
                      className={classes.nested}
                      onClick={onCloseDrawer}
                    >
                      <MenuDrawerItem
                        className={classes.title}
                        label="Inbox"
                        path="/inbox-orders"
                      />
                    </ListItem>
                  )}
                  {authorizeMenu(userInfo, MENU.IMPORTS) && (
                    <ListItem
                      className={classes.nested}
                      onClick={onCloseDrawer}
                    >
                      <MenuDrawerItem
                        className={classes.title}
                        label="Massive Uploads"
                        path={`/imports/${ImportationsTargets.INBOX_ORDER}`}
                      />
                    </ListItem>
                  )}
                  {authorizeMenu(userInfo, MENU.CANAL_OFF_LINE) && (
                    <ListItem
                      className={classes.nested}
                      onClick={onCloseDrawer}
                    >
                      <MenuDrawerItem
                        className={classes.title}
                        label="Canal off line"
                        path="/canal-off-line"
                      />
                    </ListItem>
                  )}
                </List>
              </Collapse>
            </>
          )}
          {(authorizeMenu(userInfo, MENU.SHIPPING)
            || authorizeMenu(userInfo, MENU.DISPATCH)
            || authorizeMenu(userInfo, MENU.DISPATCH_INQUIRY)
            || authorizeMenu(userInfo, MENU.COLLECTS)
            || authorizeMenu(userInfo, MENU.IMPORTS)) && (
            <>
              <ListItem button onClick={() => handleClick('logistics')}>
                <ListItemIcon>
                  <LocalShippingOutlinedIcon />
                </ListItemIcon>
                <ListItemText primary={t('Logistics')} />
                {open.includes('logistics') ? <ExpandLess /> : <ExpandMore />}
              </ListItem>
              <Collapse
                in={open.includes('logistics')}
                timeout="auto"
                unmountOnExit
              >
                <List
                  component="div"
                  disablePadding
                  className={classes.marginSub}
                >
                  {authorizeMenu(userInfo, MENU.SHIPPING) && (
                    <ListItem
                      className={classes.nested}
                      onClick={onCloseDrawer}
                    >
                      <MenuDrawerItem
                        className={classes.title}
                        label="Shipping"
                        path="/tag-manager"
                      />
                    </ListItem>
                  )}
                  {authorizeMenu(userInfo, MENU.DISPATCH) && (
                    <ListItem
                      className={classes.nested}
                      onClick={onCloseDrawer}
                    >
                      <MenuDrawerItem
                        className={classes.title}
                        label="Dispatches"
                        path="/dispatches"
                      />
                    </ListItem>
                  )}
                  {authorizeMenu(userInfo, MENU.DISPATCH_INQUIRY) && (
                    <ListItem
                      className={classes.nested}
                      onClick={onCloseDrawer}
                    >
                      <MenuDrawerItem
                        className={classes.title}
                        label="Dispatches Inquiry"
                        path="/dispatches/inquiry"
                      />
                    </ListItem>
                  )}
                  {authorizeMenu(userInfo, MENU.COLLECTS) && (
                    <ListItem
                      className={classes.nested}
                      onClick={onCloseDrawer}
                    >
                      <MenuDrawerItem
                        className={classes.title}
                        label="Collects"
                        path="/collect"
                      />
                    </ListItem>
                  )}

                  {authorizeMenu(userInfo, MENU.IMPORTS) && (
                    <ListItem
                      className={classes.nested}
                      onClick={onCloseDrawer}
                    >
                      <MenuDrawerItem
                        className={classes.title}
                        label="Massive Uploads"
                        path={`/imports/${ImportationsTargets.INBOX_COURIER_EVENT}`}
                      />
                    </ListItem>
                  )}
                </List>
              </Collapse>
            </>
          )}

          {(authorizeMenu(userInfo, MENU.IMPORTS)
            || authorizeMenu(userInfo, MENU.PROVINCES)
            || authorizeMenu(userInfo, MENU.LOCALITIES)
            || authorizeMenu(userInfo, MENU.PROCESS_MONITOR)) && (
            <>
              <ListItem button onClick={() => handleClick('system')}>
                <ListItemIcon>
                  <DesktopWindowsOutlinedIcon />
                </ListItemIcon>
                <ListItemText primary={t('System')} />
                {open.includes('system') ? <ExpandLess /> : <ExpandMore />}
              </ListItem>
              <Collapse
                in={open.includes('system')}
                timeout="auto"
                unmountOnExit
              >
                <List
                  component="div"
                  disablePadding
                  className={classes.marginSub}
                >
                  {authorizeMenu(userInfo, MENU.PROVINCES)
                    && authorizeMenu(userInfo, MENU.LOCALITIES) && (
                      <li>
                        <ListItem button onClick={() => handleClick('cities')}>
                          <ListItemText
                            primary={t('Cities')}
                            className={classes.paddingSubMenu}
                          />
                          {open.includes('cities') ? (
                            <ExpandLess />
                          ) : (
                            <ExpandMore />
                          )}
                        </ListItem>
                        <Collapse
                          in={open.includes('cities')}
                          timeout="auto"
                          unmountOnExit
                        >
                          <List
                            component="div"
                            disablePadding
                            className={classes.marginSub}
                          >
                            {authorizeMenu(userInfo, MENU.PROVINCES) && (
                              <ListItem
                                className={classes.nested}
                                onClick={onCloseDrawer}
                              >
                                <MenuDrawerItem
                                  className={classes.title}
                                  label="Provinces"
                                  path="/states"
                                />
                              </ListItem>
                            )}
                            {authorizeMenu(userInfo, MENU.LOCALITIES) && (
                              <ListItem
                                className={classes.nested}
                                onClick={onCloseDrawer}
                              >
                                <MenuDrawerItem
                                  className={classes.title}
                                  label="Localities"
                                  path="/cities"
                                />
                              </ListItem>
                            )}
                          </List>
                        </Collapse>
                      </li>
                  )}

                  {authorizeMenu(userInfo, MENU.COURIERS) && (
                    <ListItem
                      className={classes.nested}
                      onClick={onCloseDrawer}
                    >
                      <MenuDrawerItem
                        className={classes.title}
                        label="Courier"
                        path="/couriers"
                      />
                    </ListItem>
                  )}

                  {authorizeMenu(userInfo, MENU.PROCESS_MONITOR) && (
                    <ListItem
                      className={classes.nested}
                      onClick={onCloseDrawer}
                    >
                      <MenuDrawerItem
                        className={classes.title}
                        label="Process monitor"
                        path="/process_monitor"
                      />
                    </ListItem>
                  )}
                </List>
              </Collapse>
            </>
          )}

          {(authorizeMenu(userInfo, MENU.ACCOUNTS)
            || authorizeMenu(userInfo, MENU.REPORTING)
            || authorizeMenu(userInfo, MENU.ERP_ORDERS)
            || authorizeMenu(userInfo, MENU.REPORT_PENDING_ANDREANI_INCOME)) && (
            <>
              <ListItem button onClick={() => handleClick('reports')}>
                <ListItemIcon>
                  <DesktopWindowsOutlinedIcon />
                </ListItemIcon>
                <ListItemText primary={t('Reports')} />
                {open.includes('reports') ? <ExpandLess /> : <ExpandMore />}
              </ListItem>
              <Collapse
                in={open.includes('reports')}
                timeout="auto"
                unmountOnExit
              >
                <List
                  component="div"
                  disablePadding
                  className={classes.marginSub}
                >
                  {authorizeMenu(userInfo, MENU.ERP_ORDERS) && (
                    <ListItem
                      className={classes.nested}
                      onClick={onCloseDrawer}
                    >
                      <MenuDrawerItem
                        className={classes.title}
                        label="ERP Orders"
                        path="/erp-orders"
                      />
                    </ListItem>
                  )}

                  {authorizeMenu(userInfo, MENU.REPORTING) && (
                    <>
                      <ListItem
                        className={classes.nested}
                        onClick={onCloseDrawer}
                      >
                        <MenuDrawerItem
                          label="Orders by state"
                          className={classes.title}
                          path="/reporting"
                        />
                      </ListItem>
                      <ListItem
                        className={classes.nested}
                        onClick={onCloseDrawer}
                      >
                        <MenuDrawerItem
                          label="Sales"
                          className={classes.title}
                          path="/reporting-sales"
                        />
                      </ListItem>
                      <ListItem
                        className={classes.nested}
                        onClick={onCloseDrawer}
                      >
                        <MenuDrawerItem
                          label="Aging report"
                          className={classes.title}
                          path="/aging-report"
                        />
                      </ListItem>
                    </>
                  )}
                  ´
                  {authorizeMenu(
                    userInfo,
                    MENU.REPORT_PENDING_ANDREANI_INCOME,
                  ) && (
                    <ListItem
                      className={classes.nested}
                      onClick={onCloseDrawer}
                    >
                      <MenuDrawerItem
                        className={classes.title}
                        label="Report pending andreani income"
                        path="/report_pending_andreani_income"
                      />
                    </ListItem>
                  )}
                </List>
              </Collapse>
            </>
          )}

          {authorizeMenu(userInfo, MENU.ACCOUNTS) && (
            <>
              <ListItem className={classes.nested2} onClick={onCloseDrawer}>
                <MenuDrawerItem
                  label="Accounts"
                  icon={<AccountCircleIcon />}
                  path="/accounts"
                />
              </ListItem>
            </>
          )}

          {/* {authorizeMenu(userInfo, 9) && (
            <>
              <ListItem className={classes.nested2} onClick={onCloseDrawer}>
                <MenuDrawerItem
                  label="Refer"
                  icon={<AccountCircleIcon />}
                  path="/refer"
                />
              </ListItem>
            </>
          )} */}

          <Divider />
          {isLogged && (
            <ListItem className={classes.nested2} onClick={onCloseDrawer}>
              <MenuDrawerItem
                label="Logout"
                icon={<LogoutIcon />}
                onItemClick={() => {
                  libby.session.logout();
                }}
              />
            </ListItem>
          )}
        </div>
      </div>
    </Drawer>
  );
};

export const MenuDrawer = DatabaseConnector(MenuDrawerRaw)();
