import React from 'react';
import { DatabaseConnector } from '@phinxlab/libby-rest-web';
import { AnyObject, LibbyObject } from '../../types/types';
import { GeneralSelect } from '../../components/GeneralSelect';
import { useOrderState } from '../Orders/routes/OrdersList/hook/useOrderState';

type SelectImportTypeProps = {
  title: string;
  inputProps: AnyObject;
  optionAll?: boolean;
  [k: string]: any;
  libby: LibbyObject;
  listStateInit: number[];
};

export type OrderStateType = {
  order_state_id: string | number;
  name: string;
};

export const SelectOrderStateRaw = ({
  libby,
  inputProps = {},
  optionAll = false,
  title,
  listStateInit,
  ...restProps
}: SelectImportTypeProps) => {
  const { data, working } = useOrderState(libby, listStateInit);
  return (
    <GeneralSelect
      working={working}
      label={title}
      optionAll={optionAll}
      data={data}
      inputProps={inputProps}
      {...restProps}
    />
  );
};

export const SelectOrderState = DatabaseConnector(SelectOrderStateRaw)('order_state');
