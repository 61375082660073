import React from 'react';
import { PaginatedTable } from '../PaginatedTable';
import { columns } from '../../routes/SterConfig/utils/columns';
import { HeadCellsType, RowsType } from '../../types';

interface ProcessTableProps {
  items: Array<RowsType>;
  columnsEnabled?: string[];
}

export const ProcessTable = ({
  items = [],
  columnsEnabled = [],
}: ProcessTableProps) => {
  const processColumns = columns.reduce(
    (allColumns: Array<HeadCellsType>, column: HeadCellsType) => {
      const copyAllColumns = [...allColumns];
      if (
        (columnsEnabled.length && columnsEnabled.includes(column.id))
        || !columnsEnabled.length
      ) {
        copyAllColumns.push({
          id: column.id,
          label: column.label,
          render: column.render,
          style: column.style,
        });
      }
      return copyAllColumns;
    },
    [],
  );
  return (
    <PaginatedTable headCells={processColumns} rows={items} rowIdKey="name" />
  );
};
