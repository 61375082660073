import { LibbyFetchDAO } from './LibbyFetchDAO';

export class ImportationDAO extends LibbyFetchDAO {
  constructor() {
    super('app_importation', 'importation_id');
  }

  getAll() {
    return this.query()
      .limit(0, 10000)
      .run();
  }
}
