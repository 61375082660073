import { useCallback, useMemo } from 'react';
import { useSnackbar } from 'notistack';
import moment, { Moment } from 'moment';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { columns } from '../utils/columns';
import {
  DispatchCreateForm,
  CreateDispatchType,
} from '../routes/DispatchesList/components/DispatchCreateForm';
import { Dispatch } from '../../../interfaces/business';
import { formInitialValues, validate } from '../utils/model';
import { useLibbyFetch } from '../../../hooks/useLibbyFetch';
import { useTranslation } from '../../../services/translation';
import ConfirmDialog from '../../../components/ConfirmDialog';
import CustomModal from '../../../services/customFormDialog';
import { LibbyObject } from '../../../types';
import { useFilerParamsId } from '../../../hooks';
import { makeFilter } from '../utils/makeFilter';
import { DISPATCH_STATE } from '../../../const/DispatchState';
import { sendDispatch } from '../utils/sendDispatch';
import { useIsReadOnly } from '../utils/isReadOnly';
import { filterDispatchItemsByState } from '../utils/filter';
import { ORDER_STATE } from '../../../const';

const CreateDispatchModal = CustomModal(DispatchCreateForm);
const ConfirmModal = CustomModal(ConfirmDialog);

type useDispatchesLogicProps = {
  libby: LibbyObject;
};

export const useDispatchesLogic = ({ libby }: useDispatchesLogicProps) => {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const history = useHistory();
  const match = useRouteMatch();
  const isReadOnly = useIsReadOnly();

  const initFilter = useMemo(
    () => makeFilter({
      status: isReadOnly
        ? [{ path: '', value: DISPATCH_STATE.SENT }]
        : [
          { path: '', value: DISPATCH_STATE.PENDING },
          { path: '', value: DISPATCH_STATE.PREPARING },
          { path: '', value: DISPATCH_STATE.READY },
        ],
    }),
    [isReadOnly],
  );

  const {
    filter,
    orderBy,
    direction,
    paramsFetch,
    setFilter: handleOnChangeFilter,
    handleRequestSort,
    resetFilter,
  } = useFilerParamsId({
    orderInit: 'dispatch_id',
    daoName: 'ster_dispatch_details',
    aspect: 'list_dispatch_details',
    init: initFilter,
  });

  const {
    data,
    working,
    updateData,
    addCreate,
    fetchMore,
    reFetch,
    removeData,
  } = useLibbyFetch(libby, paramsFetch);

  const saveTheNewData = useCallback(
    async (
      courier_id: number = -1,
      courier_service_id: number = -1,
      observation: string = '',
      delivery_date: Moment = moment().utc(),
      dispatch_zone_id: number = -1,
      name: string = '',
      currentDispatch?: Dispatch,
    ) => {
      const libbyName = !currentDispatch
        ? 'ster_dispatch'
        : 'ster_dispatch_update';

      try {
        const newDispatchSaved: Dispatch = await libby[libbyName].save({
          ...(currentDispatch && { ...currentDispatch }),
          courier_service: { courier_service_id },
          source: { courier_id },
          delivery_date,
          name,
          dispatch_zone: { dispatch_zone_id },
          observation,
        });

        if (currentDispatch?.dispatch_id) {
          updateData(newDispatchSaved, 'dispatch_id');
        } else {
          addCreate(newDispatchSaved);
          history.push(
            `${match.path}/detail/${newDispatchSaved.dispatch_id}`,
          );
        }

        enqueueSnackbar(t('Dispatch saved'), { variant: 'success' });
      } catch (e) {
        enqueueSnackbar(t('Something is wrong'), { variant: 'error' });
      }
    },
    [addCreate, enqueueSnackbar, history, libby, match.path, t, updateData],
  );

  const handleOnOpenDispatchModal = useCallback(
    async (currentDispatch?: Dispatch) => {
      try {
        const newDispatchInfo = (await CreateDispatchModal.show({
          confirmText: t(currentDispatch ? 'Update' : 'Create'),
          cancelText: t('Cancel'),
          title: t(currentDispatch ? 'Update Dispatch' : 'Create Dispatch'),
          validate,
          formInitialValues: currentDispatch
            ? {
              ...currentDispatch,
              delivery_date: currentDispatch?.delivery_date
                ? moment(currentDispatch?.delivery_date)
                : moment(),
              courier_service:
                  currentDispatch?.courier_service?.courier_service_id,
              courier: currentDispatch?.courier_service?.courier?.courier_id,
              dispatch_zone: currentDispatch?.dispatch_zone?.dispatch_zone_id,
              dispatch_zone_name: currentDispatch?.dispatch_zone?.name,
            }
            : formInitialValues,
        })) as CreateDispatchType;

        if (newDispatchInfo) {
          const {
            courier,
            courier_service,
            observation,
            delivery_date,
            dispatch_zone,
            name,
          } = newDispatchInfo;
          if (
            currentDispatch?.items?.length
            && courier_service
              !== parseInt(currentDispatch?.courier_service?.courier_service_id, 10)
          ) {
            const confirm = await ConfirmModal.show({
              title: t('Update Dispatch'),
              content: `${t(
                'Are you sure to Change de Courier Service? If you proceed with this action you will be lost all the order data in this dispatch',
              )}`,
              confirmText: t('Yes'),
              cancelText: t('No'),
            });
            if (confirm) {
              saveTheNewData(
                courier,
                courier_service,
                observation,
                delivery_date,
                dispatch_zone,
                name,
                currentDispatch,
              );
            }
          } else {
            saveTheNewData(
              courier,
              courier_service,
              observation,
              delivery_date,
              dispatch_zone,
              name,
              currentDispatch,
            );
          }
        }
      } catch (error) {
        // nothing
      }
    },
    [saveTheNewData, t],
  );

  const handleSendModal = async (dispatch?: Dispatch) => {
    try {
      if (
        dispatch?.items.length
        && !filterDispatchItemsByState({
          dispatchItems: dispatch?.items,
          orderState: ORDER_STATE.CANCELLED.toString(),
        }).length
      ) {
        const confirm = await ConfirmModal.show({
          title: t('Dispatch ($$$$)').replace('$$$$', String(dispatch?.name)),
          content: `${t(
            'It is about to dispatch $$$$ orders. Do you want to continue?',
          )}`.replace('$$$$', String(dispatch?.items.length)),
          confirmText: t('Confirm'),
          cancelText: t('Cancel'),
        });
        if (confirm) {
          const { success, error } = await sendDispatch(
            dispatch,
            libby,
          );
          if (!success) {
            enqueueSnackbar(t(error as string), {
              variant: 'error',
            });
            return;
          }
          removeData(dispatch.dispatch_id, 'dispatch_id');
          enqueueSnackbar(t('Dispatch Sent'), { variant: 'success' });
          history.push(`${match.path}`);
        }
      } else {
        await ConfirmModal.show({
          title: t('Information'),
          content: `${t(
            'You can not send a Dispatch without orders. Orders',
          )}: ${dispatch?.items.length}`,
          confirmText: t('Accept'),
          oneButton: true,
        });
      }
    } catch (error) {
      if (error) {
        enqueueSnackbar(`${t('Error sending')}: ${error}`, {
          variant: 'info',
        });
      }
    }
  };

  const actionsDispatch = useCallback(
    (dataUpdate: Dispatch, id: string, type: string) => {
      switch (type) {
        case 'update':
          updateData(dataUpdate, id);
          break;
        case 'remove':
          removeData(dataUpdate.dispatch_id, id);
          break;
        default:
          break;
      }
    },
    [updateData, removeData],
  );

  return {
    working,
    data,
    columns,
    fetchMore,
    updateData,
    removeData,
    handleOnOpenDispatchModal,
    reFetch,
    filter,
    orderBy,
    direction,
    handleSendModal,
    handleOnChangeFilter,
    handleRequestSort,
    resetFilter,
    actionsDispatch,
  };
};
