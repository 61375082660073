import React from 'react';
import { AnyObject } from '../../types/types';

type AccountProviderType = {
  accountTypes: AnyObject;
  working: boolean;
  a?: AnyObject;
};

const initialValue:AccountProviderType = {
  accountTypes: [],
  working: true,
  a: [],
};

export const AccountProviderContext = React.createContext(initialValue);
