import React, { useMemo, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { DatabaseConnector } from '@phinxlab/libby-rest-web';
import { Button } from '@material-ui/core';
import axios from 'axios';
import { pdfStyles, ViewTagManager } from '../../components/pdf/ViewTagManager';
import { LibbyObject } from '../../../../types/types';
import { ScreenAligned } from '../../../../components/ScreenAligned/ScreenAligned';
import { TitleBreadcrumbs } from '../../../../interfaces';
import { useBreadcrumbs, useGlobalContext } from '../../../../hooks';
import { useLibbyCall } from '../../../../hooks/useLibbyCall';
import { OrderProp } from '../../../../interfaces/order';
import { LoadingProgress } from '../../../../components/LoadingProgress';
import { useTranslation } from '../../../../services/translation';
import CustomModal from '../../../../services/customFormDialog';
import { FailurePdfTable } from './components/FailureTable';
import { SearchDialog } from '../../../../components/SearchDialog';
import { URL_GENERATE_PDF } from '../../../../const';
import { typeDocumentsType } from '../../type';

type TagMangerViewPdfProps = {
  dataChecked: { all: OrderProp };
  resetCheck: () => void;
  title: TitleBreadcrumbs[];
  libby: LibbyObject;
  disableScreenType?: boolean;
};

type TagFailureViewPdfProps = {
  msg: string;
  id: string;
};

const SearchDialogModal = CustomModal(SearchDialog);

const handleOpenFailureModal = async (items: TagFailureViewPdfProps[]) => {
  try {
    await SearchDialogModal.show({
      title: 'Failure',
      id: 'order_id',
      properties: ['id', 'msg'],
      label: 'Order id',
      data: items,
      renderItem: ({ data }: { data: TagFailureViewPdfProps[] }) => (
        <FailurePdfTable items={data} />
      ),
    });
  } catch (error) {
    // nothing
  }
};

export const TagMangerViewPdfRaw = ({
  dataChecked,
  resetCheck,
  title,
  disableScreenType,
  libby,
}: TagMangerViewPdfProps) => {
  const history = useHistory();

  const {
    userInfo,
  }: {
    userInfo: {
      id: string;
    };
  } = useGlobalContext();

  const [id, setid] = useState(0);
  const [dataErrorPdf, setError] = useState<any>([]);
  const progress = useMemo(
    () => (id * 100) / dataChecked.all.length,
    [id, dataChecked],
  );

  const { data, working, recall } = useLibbyCall(libby, {
    daoName: 'ster_shipping_tags_custom',
    methodName: 'getAllShippingTagsByOrderId',
    params: [
      dataChecked.all[id]?.order_id ? dataChecked.all[id].order_id : null,
    ],
  });

  const allOrderId: string = useMemo(
    () => dataChecked.all.map((dataAll) => dataAll.order_id).toString(),
    [dataChecked.all],
  );

  const [typeDocuments, setTypeDocuments] = useState<typeDocumentsType[]>([]);

  useEffect(() => {
    if (!!Object.entries(data).length && !working) {
      if (data?.error?.length > 0) {
        setError((prev: any) => {
          const copyDataError = [...prev];
          return copyDataError.concat(data.error);
        });
      }

      if (data?.data) {
        setTypeDocuments((prevSetTypeDocuments: typeDocumentsType[]) => {
          const copyPrevSetTypeDocuments = [...prevSetTypeDocuments];
          data?.data.forEach((newTypeDocuments: string) => {
            if (
              !copyPrevSetTypeDocuments.find(
                (prevSetTypeDocument) => prevSetTypeDocument.id === newTypeDocuments,
              )
            ) {
              copyPrevSetTypeDocuments.push({
                id: newTypeDocuments,
                working: true,
              });
            }
          });

          return copyPrevSetTypeDocuments;
        });
      }
      if (id + 1 < dataChecked.all.length) {
        setid((prev) => prev + 1);
        if (recall) {
          recall?.(dataChecked.all[id + 1].order_id);
        }
      }
    }
  }, [data, setid, dataChecked, id, recall, working]);

  const [loadingPdf, setLoadingPdf] = useState(true);

  const enablePdf = useMemo(
    () => !(working || (id + 1 < dataChecked.all.length - 1)),
    [id, dataChecked, working],
  );

  useEffect(() => {
    if (enablePdf) {
      setLoadingPdf(false);
      if (dataErrorPdf.length) handleOpenFailureModal(dataErrorPdf);
    }
  }, [enablePdf, dataErrorPdf]);

  useEffect(() => {
    if (allOrderId.length === 0) {
      history.push('/tag-manager');
    }
    return () => {
      resetCheck();
      axios.delete(`${URL_GENERATE_PDF}/${userInfo?.id}`);
    };
  }, [history, resetCheck, userInfo, allOrderId]);

  const titleShow = useBreadcrumbs('Pdf', title);
  const classesPdf = pdfStyles();
  const { t } = useTranslation();

  const content = (
    <div className={classesPdf.root}>
      {!enablePdf ? (
        <div className={classesPdf.loading}>
          <LoadingProgress
            progress={progress}
            all={dataChecked.all.length}
            success={id - dataErrorPdf.length}
            error={dataErrorPdf.length}
          />
        </div>
      ) : (
        !loadingPdf && (
          <div className={classesPdf.containerPdf}>
            {dataErrorPdf.length > 0 && (
              <div className={classesPdf.buttonError}>
                <Button
                  variant="contained"
                  onClick={(e) => {
                    e.stopPropagation();
                    handleOpenFailureModal(dataErrorPdf ?? []);
                  }}
                  color="primary"
                >
                  {`${t('Failure')} ${dataErrorPdf.length}`}
                </Button>
              </div>
            )}

            <ViewTagManager
              idUseInfo={userInfo?.id}
              typeDocuments={typeDocuments}
              allOrderId={allOrderId}
              onLoad={(typeDocumentId: string) => {
                setTypeDocuments(
                  (prevSetTypeDocuments: typeDocumentsType[]) => {
                    const copyPrevSetTypeDocuments = [...prevSetTypeDocuments];

                    const indexType = copyPrevSetTypeDocuments.findIndex(
                      (prevSetTypeDocument) => prevSetTypeDocument.id === typeDocumentId,
                    );
                    if (indexType > -1) {
                      copyPrevSetTypeDocuments[indexType] = {
                        ...copyPrevSetTypeDocuments[indexType],
                        working: false,
                      };
                    }

                    return copyPrevSetTypeDocuments;
                  },
                );
              }}
            />
          </div>
        )
      )}
    </div>
  );

  return !disableScreenType ? (
    <ScreenAligned title={titleShow}>{content}</ScreenAligned>
  ) : (
    content
  );
};

export const TagMangerViewPdf = DatabaseConnector(TagMangerViewPdfRaw)(
  'ster_order_print_tags',
  'ster_shipping_tags_custom',
);
