import { useForm } from 'react-final-form-hooks';
import { useCallback } from 'react';
import { FORM_ERROR } from 'final-form';
import { useSnackbar } from 'notistack';
import { getErrorMessage, makeValidator, ValidatorType } from '../../../utils/validator';
import { useTranslation } from '../../../services/translation';
import { LibbyObject } from '../../../types/types';

const validate = makeValidator([
  { key: 'username', validators: [ValidatorType.REQUIRED] },
  { key: 'password', validators: [ValidatorType.REQUIRED] },
]);

export const useLoginForm = (libby: LibbyObject) => {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();

  const onSubmit = useCallback(async ({ username, password }) => {
    try {
      const result = await libby.session.login(username, password, { username, password });
      if (!result) {
        throw new Error('Invalid user');
      }
      return {};
    } catch (e) {
      const message = getErrorMessage(e);
      enqueueSnackbar(t(message || 'Something is wrong'), { variant: 'error' });
      return { [FORM_ERROR]: message };
    }
  }, [t, enqueueSnackbar, libby]);

  return useForm({
    initialValues: { email: '', password: '' },
    onSubmit,
    validate,
  });
};
