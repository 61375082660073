import React from 'react';
import { useInboxOrderMappers } from '../InboxOrderDetail/hooks';
import { AnyObject } from '../../../../types/types';

export const ErrorInboxOrder = ({ data }:AnyObject) => {
  const { errorMapper } = useInboxOrderMappers();
  return (
    <>
      {errorMapper(data)}
    </>
  );
};
