import React, { useMemo } from 'react';
import { Grid } from '@material-ui/core';
import { DatabaseConnector } from '@phinxlab/libby-rest-web';
import { ButtonComponent } from '../../../../../components/Button';
import { LibbyObject } from '../../../../../types/types';
import { useCartEnabled } from '../../../hook';
import LoadingData from '../../../../components/LoadingData';
import { ButtonDetailsType } from '../../../../../types/Button';
import { useStylesButtonDispatch } from '../../../../Dispatches/routes/DispatchDetail';

type OrderAddButtonType = {
  libby: LibbyObject;
  addOrdersStep: () => void;
  cart_id: string;
  onCloseCollect?: () => void;
  disabledAdd: boolean;
};

export const OrderAddButtonRaw = ({
  addOrdersStep,
  libby,
  cart_id,
  onCloseCollect,
  disabledAdd,
}: OrderAddButtonType) => {
  const { data: dataCart, working } = useCartEnabled({
    libby,
    id: cart_id,
  });

  const classes = useStylesButtonDispatch();

  const buttonDetails = useMemo((): ButtonDetailsType[] => {
    const buttons: ButtonDetailsType[] = [];

    if (!disabledAdd && dataCart.length) {
      buttons.push({
        id: 'collectDetailAddOrde',
        onClick: addOrdersStep,
        title: 'Add Order',
      });
    }

    if (onCloseCollect) {
      buttons.push({
        id: 'collectDetailClose',
        onClick: onCloseCollect,
        title: 'Close collect',
        className: classes.disabled,
      });
    }

    return buttons;
  }, [addOrdersStep, dataCart, onCloseCollect, disabledAdd, classes]);

  return (
    <Grid container justify="flex-end">
      {working ? (
        <LoadingData working={working} />
      ) : (
        <>
          {buttonDetails.map((dataButton) => (
            <ButtonComponent
              key={dataButton.id}
              title={dataButton.title}
              onClick={dataButton.onClick}
              className={dataButton.className}
            />
          ))}
        </>
      )}
    </Grid>
  );
};

export const OrderAddButton = DatabaseConnector(OrderAddButtonRaw)(
  'ster_dispatch_cart_box',
);
