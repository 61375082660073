import React, { useMemo, useEffect } from 'react';
import { DatabaseConnector } from '@phinxlab/libby-rest-web';
import InfoTable from '../../../components/InfoTable';
import { ScreenAligned } from '../../../../components/ScreenAligned/ScreenAligned';
import { useTranslation } from '../../../../services/translation';
import LoadingData from '../../../components/LoadingData';
import { LibbyObject } from '../../../../types/types';
import { useSterConfigLogic } from '../../hook/useSterConfigLogic';
import { OptionsLibbyFetch } from '../../../../hooks/useLibbyFetch';

type SterConfigProps = {
  libby: LibbyObject;
};

export const SterConfigListRaw = ({ libby }: SterConfigProps) => {
  const { t } = useTranslation();
  const [direction, setDirection] = React.useState<'asc' | 'desc'>('asc');
  const [orderBy, setOrderBy] = React.useState('ster_config_id');
  const handleRequestSort = (newOrderBy: string, newDirection: 'asc' | 'desc') => {
    setOrderBy(newOrderBy);
    setDirection(newDirection);
  };

  const paramsFetch: OptionsLibbyFetch = useMemo(() => ({
    daoName: 'ster_ster_config',
    limit: 10,
    orderBy,
    direction,
  }), [orderBy, direction]);

  const {
    working, columns, rows, fetchMoreMemo,
  } = useSterConfigLogic({ libby, paramsFetch });

  useEffect(() => {
    const interval = setInterval(() => {
      fetchMoreMemo();
    }, 30000);
    return () => clearInterval(interval);
  }, [fetchMoreMemo]);

  return (
    <ScreenAligned title="Process monitor">
      <InfoTable
        columns={columns}
        rows={rows}
        rowIdKey="ster_config_id"
        onSortChange={handleRequestSort}
        onBottomScroll={fetchMoreMemo}
        orderBy={orderBy}
        direction={direction}
      />

      <LoadingData
        label={`${t('Process quantity')}`}
        working={working}
        cant={rows.length}
      />
    </ScreenAligned>
  );
};

export const SterConfigList = DatabaseConnector(SterConfigListRaw)(
  'ster_ster_config',
);
