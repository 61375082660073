import { LibbyFetchDAO } from './LibbyFetchDAO';

export class CollectItemProductSearchDAO extends LibbyFetchDAO {
  constructor() {
    super('ster_collect_item_product_search', 'collect_item_product_id');
  }

  getAll() {
    return this.query().limit(0, 10000).run();
  }

  getProduct(id: string) {
    return this.aspect('limit_updated_by')
      .query()
      .equals('product_serial_number_id', id)
      .run();
  }
}
