import React, { useMemo, useCallback } from 'react';
import { DatabaseConnector } from '@phinxlab/libby-rest-web';
import { Autocomplete } from '@material-ui/lab';
import { TextField } from '@material-ui/core';
import { AnyObject, LibbyObject } from '../../../types/types';
import { OptionsLibbyFetch, useLibbyFetch } from '../../../hooks';
import { useTranslation } from '../../../services/translation';
import { AllMarketplace } from '../../../models/allMarketplace';
import { subFilter } from '../../../types';
import { Collect_state } from '../../../interfaces/business';

type SelectAllStatusTypeProps = {
  onChange: (value: subFilter[]) => void;
  libby: LibbyObject;
  allState: subFilter[];
};

const paramsFetch: OptionsLibbyFetch = {
  daoName: 'ster_dispatch_collect_state',
};

export const SelectAllStatusRaw = ({
  onChange,
  libby,
  allState,
}: SelectAllStatusTypeProps) => {
  const {
    data: dataRaw,
  }: { data: AnyObject; working: boolean } = useLibbyFetch(libby, paramsFetch);

  const { t } = useTranslation();

  const data = useMemo(
    () => dataRaw.map(({ collect_state_id, name }: Collect_state) => ({
      id: collect_state_id,
      name: t(name),
    })),
    [dataRaw, t],
  );
  const onChangeAutoComplete = useCallback(
    (event, newValue: AllMarketplace[]) => {
      onChange(
        newValue.map((marketplace: AllMarketplace) => ({
          value: marketplace.id,
          path: '',
        })),
      );
    },
    [onChange],
  );

  const autocompleteValue = useMemo(
    () => (allState?.length && data.length
      ? allState.map((marketplace: subFilter) => {
        const searchMarketplace = data.find(
          (marketplaceFind: AllMarketplace) => marketplaceFind.id === marketplace.value,
        );
        return searchMarketplace;
      })
      : []),
    [allState, data],
  );

  const getOptionLabel = useCallback((option: any) => t(option.name), [t]);

  return (
    <Autocomplete
      value={autocompleteValue}
      style={{ display: 'inline-block', width: '100%' }}
      multiple
      id="tags-outlined"
      options={data}
      getOptionLabel={getOptionLabel}
      filterSelectedOptions
      onChange={onChangeAutoComplete}
      getOptionSelected={(option: any, _value: any) => option.id === _value.id}
      renderInput={(params) => (
        <TextField
          {...params}
          label={t('All the status')}
          placeholder=""
        />
      )}
    />
  );
};

export const SelectAllStatus = DatabaseConnector(SelectAllStatusRaw)(
  'ster_dispatch_collect_state',
);
