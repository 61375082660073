import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { DatabaseConnector } from '@phinxlab/libby-rest-web';
import { Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { SearchFilters } from './components/SearchFilters';
import { useAccountModals } from './hooks/useAccountModals';
import { makeFilter } from './utils/makeFilter';
import InfoTable from '../../../components/InfoTable';
import LoadingData from '../../../components/LoadingData';
import { Screen } from '../../../../components/Screen';
import { ScreenTitle } from '../../../../components/ScreenTitle';
import { useTranslation } from '../../../../services/translation';
import { AnyObject, LibbyObject } from '../../../../types/types';
import { TitleBreadcrumbs } from '../../../../interfaces';

const useStyles = makeStyles(() => ({
  containerForm: {
    maxWidth: 'inherit',
    margin: 'auto',
    padding: '15px 0',
  },
}));

const columns = [{
  id: 'account_id',
  label: 'Account ID',
  minWidth: 170,
}, {
  id: 'username',
  label: 'Username',
  minWidth: 170,
}, {
  id: 'role',
  label: 'Role',
  minWidth: 170,
}, {
  id: 'status',
  label: 'Status',
  minWidth: 170,
  orderById: 'account_enabled',
}];

type AccountsListProps = {
  account_id: string;
  working: boolean;
  fetchMoreMemo: () => void;
  setFilter: (makeFilter: object) => void;
  rows: Array<{ id: string } & AnyObject>;
  libby: LibbyObject;
  updateData: (data: object) => void;
  onSortChange?: (orderBy: string, direction: 'asc' | 'desc') => void;
  orderBy?: string;
  direction?: 'asc' | 'desc';
  initFetch?: AnyObject;
  title: TitleBreadcrumbs[];
};

const AccountsListRaw = ({
  working,
  rows,
  setFilter,
  fetchMoreMemo,
  account_id,
  libby,
  updateData,
  onSortChange,
  orderBy,
  direction,
  initFetch,
  title,
}: AccountsListProps) => {
  const classes = useStyles();

  const { t } = useTranslation();
  const history = useHistory();
  const { id, type } = useParams();
  const [selectModalStatus, setselectModalStatus] = useState(false);
  const reFetch = () => {};

  const {
    createAccountEditModal,
    createAccountDisableModal,
  } = useAccountModals({
    libby,
    reFetch,
    updateData,
  });

  useEffect(() => {
    const modalOptionAccounts = async () => {
      if (id > 0) {
        const [data] = await libby.admin_account.fetch({
          filter: makeFilter({ account_id: id }),
          daoName: 'admin_account',
        });

        if (data) {
          switch (type) {
            case '1':
              createAccountEditModal(data, 'password', 'password');
              break;
            case '2':
              createAccountEditModal(data, 'account_role', 'account_role');
              break;
            case '3':
              createAccountDisableModal(data);
              break;
            default:
              break;
          }
        }
      }
    };

    if (id && type && !selectModalStatus) {
      setselectModalStatus(true);
      modalOptionAccounts();
    } else if (!id && !type && selectModalStatus) {
      setselectModalStatus(false);
    }
  }, [
    id,
    type,
    createAccountEditModal,
    createAccountDisableModal,
    libby.admin_account,
    libby,
    working,
    selectModalStatus,
  ]);

  return (
    <Screen>
      <div className={classes.containerForm}>
        <ScreenTitle title={title}>
          <Button
            type="button"
            onClick={() => {
              history.push('/accounts/add');
            }}
            variant="contained"
            color="primary"
          >
            {t('Create Account')}
          </Button>
        </ScreenTitle>
        <SearchFilters
          initialSearch={account_id}
          initFetch={initFetch}
          onFilterChange={setFilter}
        />
        <InfoTable
          columns={columns}
          rows={rows}
          onBottomScroll={fetchMoreMemo}
          onSortChange={onSortChange}
          orderBy={orderBy}
          direction={direction}
        />
        <LoadingData
          label={`${t('Number of')} ${t('Accounts')}`}
          working={working}
          cant={rows.length}
        />
      </div>
    </Screen>
  );
};

export const AccountsList = DatabaseConnector(AccountsListRaw)('admin_account');
