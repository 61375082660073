import React, { useMemo } from 'react';
import { DatabaseConnector } from '@phinxlab/libby-rest-web';
import { GeneralSelect } from '../../../components/GeneralSelect';
import { SelectType } from '../../../types';
import { useLibbyCall } from '../../../hooks';
import { Collect_state } from '../../../interfaces/business';

export const SelectCollectStateRaw = ({
  libby,
  inputProps = {},
  optionAll = false,
  valueInit,
  label = 'State',
  ...styles
}: SelectType) => {
  const { data: dataRaw, working = true } = useLibbyCall(libby, {
    daoName: 'ster_dispatch_collect_state',
    methodName: 'getAll',
  });

  const data = useMemo(
    () => dataRaw.map(({ collect_state_id, name }: Collect_state) => ({
      id: collect_state_id.toString(),
      value: name,
    })),
    [dataRaw],
  );

  return (
    <GeneralSelect
      working={working}
      label={label}
      optionAll={optionAll}
      data={data}
      inputProps={inputProps}
      {...styles}
    />
  );
};

export const SelectCollectState = DatabaseConnector(SelectCollectStateRaw)(
  'ster_dispatch_collect_state',
);
