export enum MENU {
  ORDER = 1,
  INBOX = 2,
  SHIPPING = 3,
  DISPATCH = 4,
  DISPATCH_INQUIRY = 5,
  PROVINCES = 6,
  LOCALITIES = 7,
  IMPORTS = 8,
  ACCOUNTS = 9,
  REPORTING = 10,
  ERP_ORDERS = 11,
  PROCESS_MONITOR = 12,
  REPORT_PENDING_ANDREANI_INCOME = 13,
  COLLECTS = 14,
  DELIVERY_CONFIRMATION = 15,
  CANAL_OFF_LINE = 16,
  COURIERS = 16,
}
