import React, {
  useEffect, useState, useCallback,
} from 'react';
import SearchIcon from '@material-ui/icons/Search';
import { TextField, Grid } from '@material-ui/core';
import { makeFilter } from '../utils/makeFilter';
import { SelectState } from '../../components/SelectState';
import useDebounce from '../../../../../hooks/useDebounce';
import { useTranslation } from '../../../../../services/translation';
import { FilterBar } from '../../../../../components/FilterBar';
import { gridBreakpoints } from '../../../../../const/gridBreakpoints';

type SearchFiltersProps = {
  resetFilter: boolean,
  setResetFilter: Function,
  onFilterChange: Function,
  working: boolean,
};

const SearchFiltersRaw = ({ resetFilter, setResetFilter, onFilterChange }: SearchFiltersProps) => {
  const { t } = useTranslation();

  const [citySearch, setCitySearch] = useState<string>('');
  const [stateSearch, setStateSearch] = useState<string>('0');
  const citySearchDebounced = useDebounce(citySearch, 500);

  const handleChangeProvince = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setStateSearch(event.target.value);
  }, []);

  const handleResetFilters = useCallback(() => {
    setCitySearch('');
    setStateSearch('0');
    setResetFilter(false);
  }, [setCitySearch, setStateSearch, setResetFilter]);

  useEffect(() => {
    const filters = makeFilter({
      citySearch: citySearchDebounced,
      stateSearch,
    });
    onFilterChange(filters);
  }, [citySearchDebounced, stateSearch, onFilterChange]);

  useEffect(() => {
    if (resetFilter) {
      handleResetFilters();
    }
  }, [resetFilter, handleResetFilters]);

  return (
    <FilterBar
      defaultFilter={(
        <Grid item {...gridBreakpoints}>
          <TextField
            id="standard-search"
            placeholder={t('Search')}
            type="search"
            value={citySearch}
            onChange={(event) => setCitySearch(event.target.value)}
            fullWidth
            InputLabelProps={{
              shrink: true,
            }}
            InputProps={{
              endAdornment: <SearchIcon />,
            }}
          />
        </Grid>
      )}
      handleReset={handleResetFilters}
    >
      <Grid item {...gridBreakpoints}>
        <SelectState
          inputProps={{
            value: stateSearch,
            onChange: handleChangeProvince,
          }}
          optionAll
        />
      </Grid>
    </FilterBar>
  );
};

export const SearchFilters = React.memo(SearchFiltersRaw);
