import React, { useMemo } from 'react';
import { DatabaseConnector } from '@phinxlab/libby-rest-web';
import { GeneralSelect } from '../../../components/GeneralSelect';
import { AnyObject, LibbyObject } from '../../../types/types';
import { useLibbyCall } from '../../../hooks';
import { Account } from '../../Accounts/types/Account';

type SelectDispatchProps = {
  inputProps: AnyObject;
  optionAll?: boolean;
  [k: string]: any;
  libby: LibbyObject;
  valueInit: Account;
  label?: string;
};

export const SelectAccountRaw = ({
  libby,
  inputProps = {},
  optionAll = false,
  valueInit,
  label = 'Account',
  ...styles
}: SelectDispatchProps) => {
  const { data: dataRaw, working = true } = useLibbyCall(libby, {
    daoName: 'admin_account',
    methodName: 'getAccountLogisticsOperator',
  });

  const data = useMemo(
    () => dataRaw.map(({ account_id, username }: AnyObject) => ({
      id: account_id.toString(),
      value: username,
    })),
    [dataRaw],
  );

  return (
    <GeneralSelect
      working={working}
      label={label}
      optionAll={optionAll}
      data={data}
      inputProps={inputProps}
      {...styles}
    />
  );
};

export const SelectAccount = DatabaseConnector(SelectAccountRaw)(
  'admin_account',
);
