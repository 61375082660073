import React, {
  useState,
  useCallback,
  KeyboardEvent,
  useRef,
  useLayoutEffect,
  useEffect,
} from 'react';
import { DatabaseConnector, useLibby } from '@phinxlab/libby-rest-web';
import { Grid, Typography, TextField } from '@material-ui/core';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { useSnackbar } from 'notistack';
import { AnyObject, LibbyObject } from '../../../../../types';
import {
  CollectState,
  DEFAULT_IMAGE_URL,
  DISPATCH_ITEM_STATE,
} from '../../../../../const';
import { CollectItemState } from '../../../../../const/CollectItemState';
import { useTranslation } from '../../../../../services/translation';
import CustomModal from '../../../../../services/customFormDialog';
import ConfirmDialog from '../../../../../components/ConfirmDialog';
import { ModalTitle, LabelProduct } from '../../../common';
import { getClassNames } from '../../../utils/getClassNames';
import { useStyles } from './CollectProductStyle';
import { LoadingTable } from '../../../../../components/LoadingTable';
import {
  Collect_item,
  Collect_item_product,
} from '../../../../../interfaces/business';
import { reducerQuantity } from '../../../utils/quantity';
import { replaceNonNumericCharacters } from '../../../../../utils';
import { stockType } from '../../../types/Collect';
import { LoadingHandler } from '../../../components/LoadingHandler';
import { useSearchStock } from '../../../hook/useSearchStock';

export interface Data_Product {
  inItemsProducts: boolean;
  product: AnyObject;
  collectItem: Collect_item;
}

type CollectProductProps = {
  libby: LibbyObject;
  data: Data_Product;
  onNextCard: (a: number, b: number) => void;
  actualCard: number;
  carouselLength: number;
  id: string;
  updateData: (collectItemProduct: Collect_item_product) => void;
};

const ConfirmModal = CustomModal(ConfirmDialog);

const CollectProductRaw = ({
  libby,
  data,
  onNextCard,
  actualCard,
  carouselLength,
  id,
  updateData,
}: CollectProductProps) => {
  const { t } = useTranslation();
  const [productPicked, setProductPicked] = useState<boolean>(false);
  const [cartPicked, setCartPicked] = useState<boolean>(true);
  const [loading, setLoading] = useState<boolean>(false);
  const classes = useStyles();
  const productInput = useRef<HTMLInputElement>(null);
  const cartInput = useRef<HTMLInputElement>(null);
  const { enqueueSnackbar } = useSnackbar();
  const collectItemState: string = data.collectItem.collectItemState.collect_item_state_id;
  const collectItemStateConst: string = CollectItemState.COLLECTED;
  const orderItem = data.product.order_item_id;

  const { dataStock, searchStock } = useSearchStock({
    libby,
    product_id: data?.product?.product?.product_id,
  });
  const stock = dataStock as stockType[] | undefined;

  const collectItemId = data?.collectItem?.collect_item_id;
  const [stockId, setstockId] = useState<string>();
  const { libby: collectItemProductDAO } = useLibby([
    'ster_collect_item_product',
  ]);

  useEffect(() => {
    searchStock();
  }, [searchStock]);

  const {
    skuText,
    textCart,
    productLabel,
    progressIcon,
    progressIconCart,
    checkGreyCircle,
    checkGreyCircleCart,
    borderedBox,
  } = getClassNames({
    productPicked,
    cartPicked,
    collectItemState,
    collectItemStateConst,
    classes,
  });

  const focusProductInput = () => {
    const inputProduct = productInput.current;
    if (inputProduct) {
      inputProduct.focus();
    }
  };

  const focusCartInput = () => {
    const inputCart = cartInput.current;
    if (inputCart) {
      inputCart.focus();
    }
  };

  useLayoutEffect(() => {
    if (!productPicked) {
      focusProductInput();
    }
    if (!cartPicked && productPicked) {
      focusCartInput();
    }
  });

  const onProcessCollectItem = useCallback(
    async (
      orderItemId: string,
      saveCollectItemId: string,
      stockIdParameter: string,
    ) => {
      setLoading(true);
      try {
        const productsWithQuantity = data.collectItem?.dispatch_item?.order
          ? data.collectItem.dispatch_item.order.items.reduce(
            reducerQuantity,
            0,
          )
          : 1;
        let collect_item_state = {};

        if (
          data?.collectItem.items_product.length + 1
          === productsWithQuantity
        ) {
          collect_item_state = {
            collectItemState: {
              collect_item_state_id: CollectItemState.COLLECTED,
            },
          };
        }

        const saveCollectItemProduct = {
          order_item: {
            order_item_id: orderItemId,
          },
          collect_item: {
            collect_item_id: saveCollectItemId,
            ...collect_item_state,
            dispatch_item: {
              ...data?.collectItem.dispatch_item,
              dispatch_item_state: {
                dispatch_item_state_id: DISPATCH_ITEM_STATE.COLLECTING,
              },
            },
            collect: {
              collect_id: id,
              collectState: { collect_state_id: CollectState.COLLECTING },
            },
          },
          stock: {
            stock_id: stockIdParameter,
          },
        };

        const collectItemProduct = await collectItemProductDAO.ster_collect_item_product
          .aspect('limit_updated_by')
          .save({ ...saveCollectItemProduct });
        updateData(collectItemProduct);

        if (collectItemProduct) {
          setLoading(false);
          enqueueSnackbar(t('Product Collected'), { variant: 'success' });
          onNextCard(actualCard, carouselLength);
        }
      } catch (error) {
        enqueueSnackbar(t(`Error${error}`), { variant: 'error' });
      }
    },
    [
      t,
      onNextCard,
      actualCard,
      carouselLength,
      enqueueSnackbar,
      data,
      updateData,
      id,
      collectItemProductDAO.ster_collect_item_product,
    ],
  );

  const onProccessProduct = useCallback(
    async (value: string, sku: string) => {
      try {
        setProductPicked(true);
        const [checkProductSerial] = stock?.[0]?.serial_number
          ? await libby.ster_dispatch_collect_details
            .aspect('remove_data')
            .query()
            .equals('collect_id', id)
            .and()
            .groupStart()
            .equals(
              'items.dispatch_item.order.items.product.stock.serial_number',
              value,
            )
            .equals('items.dispatch_item.order.items.sku', sku)
            .or()
            .equals(
              'items.dispatch_item.order.items.products_c.component.sku',
              sku,
            )
            .equals(
              'items.dispatch_item.order.items.products_c.component.stock.serial_number',
              value,
            )
            .groupEnd()
            .run()
          : await libby.ster_dispatch_collect_details
            .aspect('remove_data')
            .query()
            .equals('collect_id', id)
            .and()
            .groupStart()
            .equals('items.dispatch_item.order.items.sku', value)
            .or()
            .equals(
              'items.dispatch_item.order.items.products_c.component.sku',
              value,
            )
            .groupEnd()
            .run();

        if (checkProductSerial) {
          const [items] = checkProductSerial.items[0].dispatch_item.order.items;
          setCartPicked(false);
          enqueueSnackbar(t('Product Picked'), { variant: 'success' });
          setstockId(
            items?.products_c?.[0]?.component.stock[0].stock_id
              || items.product.stock[0].stock_id,
          );
        } else {
          setProductPicked(false);
          await ConfirmModal.show({
            title: <ModalTitle title="Incorrect product" error />,
            content: `${t(
              'The scanned product does not match the requested one. Check and rescan.',
            )}`,
            confirmText: t('Accept'),
            oneButton: true,
          });
        }
      } catch (error) {
        if (error) {
          enqueueSnackbar(`${t('Error picking the Product')}: ${error.message}`, { variant: 'error' });
        }
      }
    },
    [libby.ster_dispatch_collect_details, enqueueSnackbar, t, id, stock],
  );

  const onProcessCart = useCallback(
    async (value: string) => {
      try {
        setCartPicked(true);
        const [checkCartSerial] = await libby.ster_dispatch_collect_details
          .aspect('search_cart_stock')
          .query()
          .equals('collect_id', id)

          .equals('items.collect_item_id', collectItemId)
          .equals('items.cartBox.cart_box_id', value)
          .and()
          .groupStart()
          .equals(
            'items.dispatch_item.order.items.product.stock.stock_id',
            stockId,
          )
          .equals('items.dispatch_item.order.items.sku', data.product.sku)
          .or()
          .equals(
            'items.dispatch_item.order.items.products_c.component.sku',
            data.product.sku,
          )
          .equals(
            'items.dispatch_item.order.items.products_c.component.stock.stock_id',
            stockId,
          )
          .groupEnd()
          .run();

        if (checkCartSerial) {
          if (stockId) {
            onProcessCollectItem(orderItem, collectItemId, stockId);
          }
          enqueueSnackbar(t('Cart Box Picked'), { variant: 'success' });
        } else {
          setCartPicked(false);
          await ConfirmModal.show({
            title: <ModalTitle title="Incorrect cart box" error />,
            content: `${t(
              'The scanned cart box does not match the one requested. Check and rescan.',
            )}`,
            confirmText: t('Accept'),
            oneButton: true,
          });
        }
      } catch (error) {
        if (error) {
          enqueueSnackbar(t('Error picking the Cart Box'), {
            variant: 'error',
          });
        }
      }
    },
    [
      libby.ster_dispatch_collect_details,
      enqueueSnackbar,
      t,
      id,
      onProcessCollectItem,
      orderItem,
      collectItemId,
      stockId,
      data,
    ],
  );

  const validateStock = useCallback(
    async (value: string, sku: string) => {
      try {
        setProductPicked(true);
        const checkProductSerial = await libby.ster_collect_item_product_search
          .aspect('search_stock')
          .query()
          .equals('stock.serial_number', value)
          .run();

        let capacityEnabled = false;
        if (!checkProductSerial.length) {
          const [
            validateCapacity,
          ] = await libby.ster_product_stock
            .query()
            .equals('serial_number', value)
            .run();

          if (validateCapacity?.quantity > 0) {
            capacityEnabled = true;
          }
        }

        if (!checkProductSerial.length && capacityEnabled) {
          onProccessProduct(value, sku);
        } else {
          setProductPicked(false);
          await ConfirmModal.show({
            title: (
              <ModalTitle
                title={
                  !checkProductSerial?.length
                    ? 'Serial number has no stock'
                    : 'Serial number already used'
                }
                error
              />
            ),
            content: `${t(
              'Please, validate the serial number and try again',
            )}.`,
            confirmText: t('Accept'),
            oneButton: true,
          });
        }
      } catch (error) {
        setProductPicked(false);
        if (error) {
          enqueueSnackbar(
            `${t('Error checking the serial number')}: ${error}`,
            {
              variant: 'error',
            },
          );
        }
      }
    },
    [onProccessProduct, enqueueSnackbar, t, libby],
  );

  const validateButton = (event: KeyboardEvent<HTMLInputElement>) => event.key === 'Enter' && event.currentTarget.value.length;

  const onPickUpProduct = (
    event: KeyboardEvent<HTMLInputElement>,
    sku: string,
  ) => validateButton(event)
    && (stock?.[0]?.serial_number
      ? validateStock(event.currentTarget.value, sku)
      : onProccessProduct(event.currentTarget.value, sku));

  const onPickUpCart = (event: KeyboardEvent<HTMLInputElement>) => validateButton(event) && onProcessCart(event.currentTarget.value);

  return (
    <>
      <Grid className={classes.cardBox}>
        <Grid>
          <img
            className={classes.pictureLarge}
            src={data.product.url || DEFAULT_IMAGE_URL}
            alt="Product Avatar"
          />
          <Grid className={classes.collectProductInfoBox}>
            <LoadingHandler loading={typeof stock === 'undefined'}>
              <>
                <Grid className={borderedBox}>
                  <div className={checkGreyCircle}>
                    <CheckCircleIcon className={progressIcon} />
                  </div>
                  <div>
                    <LabelProduct
                      data={data}
                      labelStyle={productLabel}
                      locationStyle={classes.location}
                      skuAndSerialBoxStyle={classes.skuAndSerial}
                      skuStyle={skuText}
                      whiteSpace={classes.whiteSpace}
                      position={classes.position}
                    />
                    <TextField
                      disabled={data.inItemsProducts || productPicked}
                      className={classes.textField}
                      label=""
                      variant="filled"
                      size="small"
                      id={`productSerial-${data.product.product_id || ''}`}
                      name={`productSerial-${data.product.product_id || ''}`}
                      InputProps={{
                        inputRef: productInput,
                        onKeyDown: (
                          event: React.KeyboardEvent<HTMLInputElement>,
                        ) => {
                          onPickUpProduct(event, data.product.sku);
                        },
                      }}
                    />
                  </div>
                </Grid>
                <Grid className={classes.cartBox}>
                  <div className={checkGreyCircleCart}>
                    <CheckCircleIcon className={progressIconCart} />
                  </div>
                  <div className={classes.cartBoxText}>
                    <label
                      htmlFor={`cartSerial-${data.product.product_id || ''}`}
                      className={textCart}
                    >
                      <Typography variant="h6" color="initial">
                        {`${t('Pick and place it in box')} ${
                          data.collectItem.cartBox.name
                        }`}
                      </Typography>
                    </label>
                    <TextField
                      disabled={data.inItemsProducts || cartPicked}
                      className={classes.textField}
                      label=""
                      variant="filled"
                      size="small"
                      id={`cartSerial-${data.product.product_id || ''}`}
                      name={`cartSerial-${data.product.product_id || ''}`}
                      onChange={(event) => {
                        const { value } = event.target;
                        event.target.value = replaceNonNumericCharacters(value);
                        return event;
                      }}
                      InputProps={{
                        inputRef: cartInput,
                        onKeyDown: (
                          event: React.KeyboardEvent<HTMLInputElement>,
                        ) => onPickUpCart(event),
                      }}
                    />
                  </div>
                </Grid>
              </>
            </LoadingHandler>
          </Grid>
          <Grid />
        </Grid>
        {data.inItemsProducts && (
          <Grid className={classes.collected}>
            <div className={classes.collectCircle}>
              <CheckCircleIcon className={classes.itemProductChecked} />
            </div>
          </Grid>
        )}
      </Grid>
      {loading && (
        <Grid className={classes.collected}>
          <LoadingTable />
        </Grid>
      )}
    </>
  );
};

export const CollectProduct = DatabaseConnector(CollectProductRaw)(
  'ster_dispatch_collect_details',
  'ster_dispatch_collect_item',
  'ster_collect_item_product',
  'ster_collect_item_product_search',
  'ster_product_stock',
);
