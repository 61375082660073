import React, { useEffect, useState } from 'react';
import { DatabaseConnector } from '@phinxlab/libby-rest-web';
import { makeStyles } from '@material-ui/styles';
import { orderBy } from 'lodash';
import { GeneralSelect } from '../../../components/GeneralSelect';
import { AnyObject, LibbyObject } from '../../../types/types';
import { useLibbyCall } from '../../../hooks';
import { Cart } from '../../../interfaces/business';
import { useTranslation } from '../../../services/translation';
import { LabelValueTypography } from '../../../components/LabelValue/LabelValueTypography';

type SelectDispatchProps = {
  inputProps: AnyObject;
  optionAll?: boolean;
  [k: string]: any;
  libby: LibbyObject;
  validateCartBox?: Function;
  disabled: boolean;
  allCart: boolean;
  valueInit: Cart;
  capacitySearch?: Number;
};

const useStyles = makeStyles(() => ({
  msg: {
    textAlign: 'center',
    whiteSpace: 'nowrap',
  },
}));

export const SelectDispatchCartRaw = ({
  libby,
  inputProps = {},
  optionAll = false,
  validateCartBox,
  disabled,
  allCart = false,
  valueInit,
  capacitySearch = 0,
  ...styles
}: SelectDispatchProps) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [maxCapacity, setMaxCapacity] = useState<string>('0');

  const [dataSelect, setDataSelect] = useState<
  Array<{ id: number; value: any; name?: string; extra?: string }>
  >(
    inputProps.value && valueInit?.name
      ? [
        {
          id: inputProps.value,
          value: valueInit.name,
          extra: `(${
            valueInit.capacity === -1 ? t('Undivided') : valueInit.capacity
          })`,
        },
      ]
      : [],
  );

  const { data: dataRaw, working = true } = useLibbyCall(libby, {
    daoName: allCart ? 'ster_dispatch_cart' : 'ster_dispatch_cart_enabled',
    methodName: 'getAll',
  });

  useEffect(() => {
    setDataSelect((prev) => {
      const copyDataSelect = [...prev];

      const cartEnabled = dataRaw.filter(({ capacity }: Cart) => capacity >= capacitySearch || capacity === -1);
      cartEnabled.forEach(({ cart_id, name, capacity }: Cart) => {
        copyDataSelect.push({
          id: parseInt(cart_id, 10),
          value: name,
          extra: `(${capacity === -1 ? t('Undivided') : capacity})`,
        });
      });
      return copyDataSelect;
    });
  }, [dataRaw, t, capacitySearch]);

  useEffect(() => {
    if (dataRaw.length && validateCartBox) {
      validateCartBox(dataRaw);
    }
    return () => {
      if (validateCartBox) validateCartBox([]);
    };
  }, [dataRaw, validateCartBox]);

  useEffect(() => {
    if (!dataSelect.length && dataRaw.length) {
      const orderData = orderBy(dataRaw, (o: Cart) => Number(o.capacity), [
        'desc',
      ]);

      const maxCapacitySearch = orderData.find(
        ({ capacity }: Cart) => capacity < capacitySearch,
      );
      if (maxCapacitySearch) {
        setMaxCapacity(maxCapacitySearch.capacity.toString());
      }
    }
  }, [dataSelect, dataRaw, capacitySearch]);

  return (
    <>
      <GeneralSelect
        working={working}
        label={`${t('Cart')} (${t('Capacity')})`}
        optionAll={optionAll}
        data={dataSelect}
        inputProps={inputProps}
        disabled={disabled}
        {...styles}
      />
      {dataSelect.length === 0
        && dataRaw.length > 0
        && dataSelect.length !== dataRaw && (
          <LabelValueTypography
            name={t(
              'No cars available for capacity greater than $$$$.',
            ).replace('$$$$', maxCapacity)}
            className={classes.msg}
          />
      )}
    </>
  );
};

export const SelectDispatchCart = DatabaseConnector(SelectDispatchCartRaw)(
  'ster_dispatch_cart_enabled',
  'ster_dispatch_cart',
);
