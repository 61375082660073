import Moment from 'moment';
import { FilterBarSelection } from '../../../../routes/Reporting/routes/ReportingList/FilterBar';
import { LibbyFetchDAO } from '../LibbyFetchDAO';

export class HistoryByMarketplaceDAO extends LibbyFetchDAO {
  constructor() {
    super('ster_order_history_marketplace', 'order_id');
  }

  getAll() {
    return this.query().limit(0, 10000).run();
  }

  async getByDate(filter: FilterBarSelection = { marketplaces: [], courier: [] }) {
    let ls = this.query();
    // FIXME QUERY BUILDER HAVE TO DECIDE IF HAVE TO AUTOMATICALLY INSERT AN AND
    let needsAnd: boolean = false;

    if (filter.marketplaces.length > 0) {
      if (needsAnd) ls = ls.and();
      ls = ls.groupStart();
      let first: boolean = true;
      filter.marketplaces.forEach((marketplace) => {
        if (!first) ls = ls.or();
        ls = ls.equals('source.marketplace.marketplace_id', marketplace.id);
        first = false;
      });
      ls = ls.groupEnd();
      needsAnd = true;
    }
    if (filter.from && filter.to) {
      if (needsAnd) ls = ls.and();
      ls = ls
        .groupStart()
        .higherOrEquals(
          'created_at',
          Moment(filter.from).format('YYYY-M-D HH:mm:ss'),
        )
        .lowerOrEquals(
          'created_at',
          Moment(filter.to).format('YYYY-M-D HH:mm:ss'),
        )
        .groupEnd();
    }
    if (typeof filter.canal !== 'undefined') {
      if (needsAnd) ls = ls.and();
      ls = ls.groupStart();
      ls = ls.equals('source.canal_of_line', filter.canal.toString());
      ls = ls.groupEnd();
    }
    ls.run();

    return ls;
  }
}
