import { AnyObject } from '../types/types';

export const ReportingCreationAreaStatus: AnyObject = {
  OK: 'OK',
  Warning: 'Warning',
  Critical: 'Critical',
};

export const ReportingCreationAreaStatusColors: AnyObject = {
  OK: '#77F511',
  Warning: '#FFC324',
  Critical: '#F45724',
};
