import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles({
  loading: {
    height: '30px',
    margin: '10px 0 10px 0',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
});

export const LoadingTable = ({ size = 24 }: {size?: number}) => {
  const classes = useStyles();

  return (
    <div className={classes.loading}>
      <CircularProgress color="secondary" size={size} />
    </div>
  );
};
