import { Moment } from 'moment';
import { Filter, subFilter } from '../../../../../types';
import { endDateFormatUtc, startDateFormatUtc } from '../../../../../utils';

type MakeFilterProps = {
  search?: string;
  orderStatus?: string;
  area?: string;
  creationStatus?: string;
  areaStatus?: string;
  orderId?: string;
  startDate?: Moment | null;
  endDate?: Moment | null;
  customer?: string;
  courier?: string;
  marketplace?: subFilter[];
};

export const makeFilter = ({
  search,
  orderStatus,
  marketplace,
  area,
  creationStatus,
  areaStatus,
  courier,
  orderId,
  startDate,
  endDate,
  customer,
}: MakeFilterProps) => {
  const filter: Filter = {};
  const searchPath = [
    'first_name',
    'last_name',
    'marketplace',
    'order_state',
    'meta_state',
    'so_number',
  ];

  if (search) {
    const valueSeach = search
      .split(' ')
      .reduce((accumulator: Filter, item, index) => {
        accumulator[index] = searchPath.reduce(
          (result: subFilter[], path: string) => {
            result.push({
              path,
              value: item,
              method: 'includes',
            });
            return result;
          },
          [],
        );

        const numberSearch = parseInt(item, 10);
        if (numberSearch) {
          accumulator[index].push({
            path: 'order_id',
            value: numberSearch,
          });
        }
        return accumulator;
      }, {});
    filter.search = [...valueSeach[0]];
  }

  if (orderStatus && orderStatus !== '0') {
    filter.orderStatus = [
      {
        path: 'order_state_id',
        value: orderStatus,
      },
    ];
  }

  if (marketplace?.length) {
    filter.marketplace = marketplace?.map((marketplaceValue) => ({
      path: 'marketplace_id',
      value: marketplaceValue?.value,
    }));
  }

  if (courier && courier !== '0') {
    filter.courier = [
      {
        path: 'shipment.service.courier.courier_id',
        value: courier,
      },
    ];
  }

  if (area && area !== '0') {
    filter.area = [
      {
        path: 'meta_state',
        value: area,
      },
    ];
  }

  if (creationStatus && creationStatus !== '0') {
    filter.creationStatus = [
      {
        path: 'total_alert',
        value: creationStatus,
      },
    ];
  }

  if (areaStatus && areaStatus !== '0') {
    filter.areaStatus = [
      {
        path: 'meta_state_alert',
        value: areaStatus,
      },
    ];
  }

  if (orderId && orderId !== '') {
    filter.orderId = orderId.split(',').map((orderIdSearch) => ({
      path: 'order_id',
      value: orderIdSearch,
    }));
  }

  if (startDate) {
    filter.startDate = [
      {
        path: 'created_at',
        value: startDateFormatUtc(startDate),
        method: 'higherOrEquals',
      },
    ];
  }
  if (endDate) {
    filter.endDate = [
      {
        path: 'created_at',
        value: endDateFormatUtc(endDate),
        method: 'lowerOrEquals',
      },
    ];
  }

  if (customer) {
    filter.customer = customer.split(' ').flatMap((customSearch) => [
      {
        path: 'first_name',
        value: customer,
        method: 'includes',
      },
      {
        path: 'first_name',
        value: customSearch,
        method: 'includes',
      },
      {
        path: 'last_name',
        value: customSearch,
        method: 'includes',
      },
    ]);
  }

  return filter;
};
