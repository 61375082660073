import Moment from 'moment';
import { LibbyFetchDAO } from './LibbyFetchDAO';
import { FilterBarSelection } from '../../../routes/Reporting/routes/ReportingList/FilterBar';

export class OrdersTableUpdateDAO extends LibbyFetchDAO {
  constructor() {
    super('ster_order_table_update', 'order_id');
  }

  getAll() {
    return this.query().limit(0, 10000).run();
  }

  async getByState(
    filter: FilterBarSelection = { marketplaces: [], courier: [] },
  ) {
    let ls = this.query();
    // FIXME QUERY BUILDER HAVE TO DECIDE IF HAVE TO AUTOMATICALLY INSERT AN AND
    let needsAnd: boolean = false;

    if (filter.courier.length > 0) {
      if (needsAnd) ls = ls.and();
      ls = ls.groupStart();
      let first: boolean = true;
      filter.courier.forEach((courier) => {
        if (!first) ls = ls.or();
        ls = ls.equals('shipment.service.courier.courier_id', courier.id);
        first = false;
      });
      ls = ls.groupEnd();
      needsAnd = true;
    }

    if (filter.marketplaces.length > 0) {
      if (needsAnd) ls = ls.and();
      ls = ls.groupStart();
      let first: boolean = true;
      filter.marketplaces.forEach((marketplace) => {
        if (!first) ls = ls.or();
        ls = ls.equals(
          'source.marketplace.marketplace_id',
          marketplace.marketplace_id,
        );
        first = false;
      });
      ls = ls.groupEnd();
      needsAnd = true;
    }
    if (filter.from && filter.to) {
      if (needsAnd) ls = ls.and();
      ls = ls
        .groupStart()
        .higherOrEquals('updated_at', Moment(filter.from).format('YYYY-M-D'))
        .lowerOrEquals('updated_at', Moment(filter.to).format('YYYY-M-D'))
        .groupEnd();
    }
    ls = await ls
      .aggregate()
      .count('state.name', 'name')
      .sum('amount', 'amount')
      .groupBy('state.name')
      .run();

    return ls;
  }

  async getByMarketplace(
    filter: FilterBarSelection = { marketplaces: [], courier: [] },
  ) {
    let ls = this.query();
    // FIXME QUERY BUILDER HAVE TO DECIDE IF HAVE TO AUTOMATICALLY INSERT AN AND
    let needsAnd: boolean = false;

    if (filter.courier.length > 0) {
      if (needsAnd) ls = ls.and();
      ls = ls.groupStart();
      let first: boolean = true;
      filter.courier.forEach((courier) => {
        if (!first) ls = ls.or();
        ls = ls.equals('shipment.service.courier.courier_id', courier.id);
        first = false;
      });
      ls = ls.groupEnd();
      needsAnd = true;
    }

    if (filter.marketplaces.length > 0) {
      if (needsAnd) ls = ls.and();
      ls = ls.groupStart();
      let first: boolean = true;
      filter.marketplaces.forEach((marketplace) => {
        if (!first) ls = ls.or();
        ls = ls.equals(
          'source.marketplace.marketplace_id',
          marketplace.marketplace_id,
        );
        first = false;
      });
      ls = ls.groupEnd();
      needsAnd = true;
    }
    if (filter.from && filter.to) {
      if (needsAnd) ls = ls.and();
      ls = ls
        .groupStart()
        .higherOrEquals(
          'created_at',
          Moment(filter.from).format('YYYY-M-D HH:mm:ss'),
        )
        .lowerOrEquals(
          'created_at',
          Moment(filter.to).format('YYYY-M-D HH:mm:ss'),
        )
        .groupEnd();
    }
    ls = await ls
      .notEquals('state.order_state_id', 10)
      .aggregate()
      .count('source.marketplace.marketplace_id', 'name')
      .sum('amount', 'amount')
      .groupBy('source.marketplace.marketplace_id')
      .run();

    return ls;
  }

  async getByProducts(
    filter: FilterBarSelection = { marketplaces: [], courier: [] },
  ) {
    let ls = this.query();
    // FIXME QUERY BUILDER HAVE TO DECIDE IF HAVE TO AUTOMATICALLY INSERT AN AND
    let needsAnd: boolean = false;

    if (filter.courier.length > 0) {
      if (needsAnd) ls = ls.and();
      ls = ls.groupStart();
      let first: boolean = true;
      filter.courier.forEach((courier) => {
        if (!first) ls = ls.or();
        ls = ls.equals('shipment.service.courier.courier_id', courier.id);
        first = false;
      });
      ls = ls.groupEnd();
      needsAnd = true;
    }

    if (filter.marketplaces.length > 0) {
      if (needsAnd) ls = ls.and();
      ls = ls.groupStart();
      let first: boolean = true;
      filter.marketplaces.forEach((marketplace) => {
        if (!first) ls = ls.or();
        ls = ls.equals(
          'source.marketplace.marketplace_id',
          marketplace.marketplace_id,
        );
        first = false;
      });
      ls = ls.groupEnd();
      needsAnd = true;
    }
    if (filter.from && filter.to) {
      if (needsAnd) ls = ls.and();
      ls = ls
        .groupStart()
        .higherOrEquals(
          'created_at',
          Moment(filter.from).format('YYYY-M-D HH:mm:ss'),
        )
        .lowerOrEquals(
          'created_at',
          Moment(filter.to).format('YYYY-M-D HH:mm:ss'),
        )
        .groupEnd();
    }
    ls = await ls
      .notEquals('state.order_state_id', 10)
      .aggregate()
      .count('items.name')
      .sum('amount', 'amount')
      .groupBy('items.name')
      .run();

    return ls;
  }
}
