import React, {
  useMemo,
} from 'react';
import { DatabaseConnector } from '@phinxlab/libby-rest-web';
import { TextFieldProps } from '@material-ui/core';
import { AnyObject, LibbyObject } from '../../../../types';
import { GeneralSelect } from '../../../../components/GeneralSelect';
import { useLibbyFetch, OptionsLibbyFetch, useGlobalContext } from '../../../../hooks';
import { ImportationsTargets } from '../../../../const';

type SelectImportTypeProps = {
  libby: LibbyObject,
  inputProps: TextFieldProps,
  optionAll?: boolean,
  customAll?: string,
  importationTargetId: string | number,
};

const paramsFetch: OptionsLibbyFetch = {
  daoName: 'public_importation_type',
};

export const SelectImportTypeRaw = ({
  libby, inputProps = {}, optionAll = false, customAll, importationTargetId, ...styles
}:SelectImportTypeProps) => {
  const { userInfo } = useGlobalContext();
  const rol_id = userInfo?.groups[0]?.id;
  const params = useMemo(() => {
    const paramsToUse = { ...paramsFetch };
    const importationTarget = [{ path: 'importation_target.importation_target_id', value: importationTargetId }];

    if (importationTargetId === '1') {
      importationTarget.push(
        {
          path: 'importation_target.importation_target_id',
          value: ImportationsTargets.INBOX_ORDER_COLLECTED,
        },
        {
          path: 'importation_target.importation_target_id',
          value: ImportationsTargets.INBOX_ORDER_CANCELLED,
        },
      );
    }

    if (importationTargetId) {
      paramsToUse.filter = { importation_target: importationTarget };
    }

    return paramsToUse;
  }, [importationTargetId]);

  const { data: importTypes, working } = useLibbyFetch(libby, params);
  const data = useMemo(() => importTypes.map(({ importation_type_id, name }: AnyObject) => ({
    id: importation_type_id,
    value: name,
  })), [importTypes]);

  return (
    <GeneralSelect
      working={working}
      label="Type"
      optionAll={optionAll}
      customAll={customAll}
      data={rol_id !== '8' || rol_id !== '2' ? data.filter((item) => item.id !== '4') : data}
      inputProps={inputProps}
      {...styles}
    />
  );
};

export const SelectImportType = DatabaseConnector(SelectImportTypeRaw)('public_importation_type');
