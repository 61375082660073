import React from 'react';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/styles';
import { AnyObject } from '../../../../../types/types';
import { useTranslation } from '../../../../../services/translation';

const useStyles = makeStyles(() => ({
  title: {
    textAlign: 'left',
    marginBottom: 15,
  },
}));

export const Matches = ({ data }: AnyObject) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return data?.matches?.map((match: any, idx: number) => (
    <Typography key={idx} className={classes.title} variant="h5">
      {`${t('Match')} ${idx + 1}: ${match}`}
    </Typography>
  )) || <></>;
};
