import { useMemo } from 'react';
import { capitalize } from '@material-ui/core';
import { AnyObject, LibbyObject } from '../../../../../types/types';
import { useLibbyFetch } from '../../../../../hooks/useLibbyFetch';

import { makeFilterState } from '../utils/makeFilterState';

export type OrderStateType = {
  order_state_id: string | number;
  name: string;
};

export const useOrderState = (libby: LibbyObject, listStateInit?: number[]) => {
  const paramsFetch = useMemo(
    () => ({
      daoName: 'order_state',
      filter: makeFilterState({
        status: listStateInit,
      }),
    }),
    [listStateInit],
  );

  const {
    data: dataRaw,
    working,
  }: { data: OrderStateType[] | AnyObject; working: boolean } = useLibbyFetch(
    libby,
    paramsFetch,
  );

  const data = useMemo(
    () => dataRaw.map(({ order_state_id, name }: OrderStateType) => ({
      id: order_state_id,
      value: capitalize(name),
    })),
    [dataRaw],
  );

  return { data, working };
};
