import { Filter } from '../../../../../types';

type MakeFilterStateProps = {
  status?: number[];
};

export const makeFilterState = ({ status }: MakeFilterStateProps) => {
  const filter: Filter = {};

  if (status) {
    filter.status = status?.map((statusValue: number) => ({
      path: 'order_state_id',
      value: statusValue,
    }));
  }

  return filter;
};
