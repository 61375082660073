import { useLibbyCall } from './useLibbyCall';
import { LibbyObject } from '../types/types';

export type useDocumentsByOrderIdOptions = {
  daoName: string;
  id?: string | number;
};

export const useDocumentsByOrderId = (libby: LibbyObject, { daoName, id }: useDocumentsByOrderIdOptions) => {
  const { data, working, recall } = useLibbyCall(libby, {
    daoName,
    methodName: 'getAllByOrderId',
    params: [id],
  });

  const documents = data;
  const loadingDocs = working;

  return { documents, loadingDocs, recall };
};
