import React, { useCallback, useEffect, useRef } from 'react';
import moment, { Moment } from 'moment';
import { useField, useForm } from 'react-final-form-hooks';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { ThemeProvider } from '@material-ui/styles';
import { FormProps } from '../../../../../types';
import { SelectOrderShipment } from '../../../../components/SelectOrderShipment';
import { SelectDispatchZone } from '../../../../components/SelectDispatchZone';
import { DateClose } from '../../../../../components/DateClose';
import {
  makeGetErrorAndHelperText,
  textFieldProps,
} from '../../../../../utils/materialHelpers';
import { useTranslation } from '../../../../../services/translation';
import useDebounce from '../../../../../hooks/useDebounce';
import { formatDate } from '../../../../../utils';
import { SelectOrderCourier } from '../../../../components/SelectOrderCourier';
import { ResetForm } from '../../../../../components/ResetForm';
import { useModalFormStyles } from '../../../../../theme';

export type CreateDispatchType = {
  courier: number;
  courier_service: number;
  dispatch_zone: number;
  observation: string;
  delivery_date: Moment;
  name: string;
};

export const DispatchCreateForm = ({
  open = false,
  onCancel = () => {},
  onConfirm = () => {},
  title = 'Confirmation Dialog',
  cancelText = 'Cancel',
  confirmText = 'confirmText',
  customTheme = null,
  validate,
  formInitialValues = {},
}: FormProps) => {
  const { t } = useTranslation();
  const styles = useModalFormStyles();
  const getErrorAndHelperText = makeGetErrorAndHelperText(t);

  const childCourierService = useRef<{
    data: Array<{ id: string; value: string }>;
  }>();

  const onSubmit = useCallback(
    async ({
      courier,
      dispatch_zone,
      courier_service,
      observation,
      delivery_date,
      name,
    }) => {
      onConfirm({
        courier_service,
        courier,
        dispatch_zone,
        observation,
        delivery_date,
        name,
      });
    },
    [onConfirm],
  );

  const { form, handleSubmit, submitError } = useForm({
    initialValues: formInitialValues,
    onSubmit,
    validate,
  });

  const name = useField('name', form);
  const nameDebounced = useDebounce(name.input.value, 700);
  const observation = useField('observation', form);
  const courier_service = useField('courier_service', form);
  const dispatch_zone = useField('dispatch_zone', form);
  const courier = useField('courier', form);
  const delivery_date = useField('delivery_date', form);

  useEffect(() => {
    const courierService = childCourierService.current?.data?.find(
      ({ id }) => id === courier_service.input.value
    )?.value;
    if (courierService && delivery_date.input.value && !nameDebounced) {
      form.change(
        'name',
        `${courierService} - ${formatDate(delivery_date.input.value, false)}`,
      );
    }
  }, [
    nameDebounced,
    form,
    courier_service.input.value,
    dispatch_zone.input.value,
    delivery_date.input.value,
  ]);

  const handleOnChangeCourier = useCallback(
    (e: any) => {
      courier.input.onChange(e);
      form.change('courier_service', '');
    },
    [courier.input, form],
  );

  const disabled = formInitialValues?.number_items > 0;

  const dialog = (
    <Dialog
      open={open}
      onClose={onCancel}
      fullWidth
      aria-labelledby="form-dialog-title"
    >
      <DialogContent className={styles.title}>
        <Typography variant="h3">{title}</Typography>
      </DialogContent>
      <form noValidate onSubmit={handleSubmit}>
        <DialogContent className={styles.content}>
          {open && (
            <>
              <SelectOrderCourier
                disabled={disabled}
                inputProps={{
                  onChange: handleOnChangeCourier,
                  value: courier.input.value || '',
                  ...getErrorAndHelperText(courier),
                }}
              />
              <SelectOrderShipment
                ref={childCourierService}
                typeCourierSearch={courier.input.value}
                inputProps={{
                  onChange: courier_service.input.onChange,
                  value: courier_service.input.value || '',
                  ...getErrorAndHelperText(courier_service),
                }}
              />
            </>
          )}
          <DateClose
            name="Delivery date"
            minValue={moment().utc()}
            value={
              delivery_date.input.value
                ? moment(delivery_date.input.value).utc()
                : null
            }
            onChange={delivery_date.input.onChange}
            disableFuture={false}
            {...getErrorAndHelperText(delivery_date)}
          />
          <TextField
            {...textFieldProps(`${t('Dispatch Name')}`)}
            onChange={name.input.onChange}
            value={name.input.value}
            variant="standard"
            multiline
            InputLabelProps={{
              shrink: true,
              ...getErrorAndHelperText(name),
            }}
          />
          <SelectDispatchZone
            inputProps={{
              onChange: dispatch_zone.input.onChange,
              value: dispatch_zone.input.value || '',
              ...getErrorAndHelperText(dispatch_zone),
            }}
            initName={formInitialValues?.dispatch_zone_name}
          />
          <TextField
            {...textFieldProps(`${t('Observations')}`)}
            onChange={observation.input.onChange}
            value={observation.input.value || ''}
            variant="standard"
            multiline
          />
          {submitError && (
            <Typography
              style={{ display: 'block' }}
              variant="overline"
              color="error"
            >
              {t(submitError)}
            </Typography>
          )}
        </DialogContent>
        <DialogActions>
          <ResetForm form={form} open={open} />
          <Button
            onClick={() => {
              onCancel();
            }}
          >
            {cancelText}
          </Button>
          <Button color="primary" type="submit">
            {confirmText}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );

  return !customTheme ? (
    dialog
  ) : (
    <ThemeProvider theme={customTheme}>{dialog}</ThemeProvider>
  );
};
