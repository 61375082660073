import React, { useMemo } from 'react';
import { Button, Grid } from '@material-ui/core';
import AssignmentIcon from '@material-ui/icons/Assignment';
import { MakeCell } from '../../../../components/MakeCell';
import { importationsStatus } from '../../../../../const';
import { useTranslation } from '../../../../../services/translation';
import { AnyObject, OrderType } from '../../../../../types/types';
import { formatDecimal, formatDate } from '../../../../../utils';

const columns = [
  {
    id: 'importation_id',
    label: 'ID',
  },
  {
    id: 'type',
    label: 'Type',
    render: ({ type }: OrderType) => type,
    orderById: 'importation_type.importation_type_id',
  },
  {
    id: 'status',
    label: 'Status',
    render: ({ status }: OrderType, item: any, t: (str: string) => string) => t(importationsStatus[status ?? '']),
    orderById: 'importation_status.name',
  },
  {
    id: 'metrics',
    label: 'Metrics',
    noSort: true,
  },
  {
    id: 'creation_date',
    label: 'Creation Day',
    orderById: 'creation_date',
  },
  {
    id: 'created_by',
    label: 'Created by',
    orderById: 'account.username',
  },
  {
    id: 'detail',
    label: 'Detail',
    noSort: true,
  },
];

export const useImportInfo = (
  data: AnyObject,
  path: string,
  refSearchFilters: AnyObject,
) => {
  const { t } = useTranslation();

  const ImportButtons = (
    <Grid item>
      <Button
        type="button"
        variant="contained"
        color="primary"
        onClick={() => refSearchFilters?.current?.saveNewImport?.()}
      >
        {t('Create Import')}
      </Button>
    </Grid>
  );

  const rows = useMemo(
    () => data.map((imp: any) => ({
      detail: (
        <MakeCell
          label=""
          icon={AssignmentIcon}
          path={`${path}/detail/${imp.importation_id}`}
        />
      ),
      id: formatDecimal(imp.importation_id),
      importation_id: formatDecimal(imp.importation_id),
      type: imp.importation_type.name,
      status: imp.importation_status.importation_status_id,
      metrics: Object.keys(imp.result).length
        ? `${t('Success')}: ${imp.result.success} ${t('Fails')}: ${
          imp.result.fails
        }`
        : '-',
      creation_date: formatDate(imp.creation_date, true, false),
      created_by: imp.account.username,
    })),
    [data, path, t],
  );

  return { columns, rows, ImportButtons };
};
