import React from 'react';
import {
  Typography,
  Paper,
  Grid,
  GridSize,
  PropTypes,
  GridItemsAlignment,
  GridJustification,
} from '@material-ui/core';

import { makeStyles } from '@material-ui/core/styles';
import { white, mainlight } from '../../../../../theme/colors';

type propMakeStyles = {
  select : boolean
};

const useStyles = makeStyles(() => ({
  mobileCollectHeaderBack: {
    backgroundColor: (props: propMakeStyles) => (!props.select ? white : mainlight),
    marginBottom: '5%',
    border: (props: propMakeStyles) => (!props.select ? '1px transparent' : '2px solid #FF5179'),
  },
  mobileCollectHeader: {
    padding: '12px 12px',
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
    boxShadow: 'none',
    width: '100%',
    top: '-1px',
    height: '95%',
    display: 'inline-grid',
    position: 'absolute',
  },

  collectName: {
    fontSize: 14,
    lineHeight: '20px',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontFamily: 'Lato',
    color: '#27272B;',
    width: '100%',
  },
  collectNameLight: {
    fontSize: 14,
    lineHeight: '20px',
    fontStyle: 'normal',
    fontWeight: 400,
    fontFamily: 'Lato',
    color: '#949499',
    width: '100%',
  },
  bottomCollectName: {
    fontSize: 14,
    fontFamily: 'Lato',
    lineHeight: '20px',
    fontWeight: 400,
    fontStyle: 'normal',
    color: '#949499',
  },
  mobileCollectHeaderItem: {
    height: '96%',
    display: 'flex',
    alignItems: 'center',
  },
  mobileCollectHeaderItemRow: {
    margin: '5px 12px 0px',
  },
  buttonContainer: {
    flex: 1,
    justifyContent: 'flex-end',
    display: 'flex',
  },
  button: {
    fontSize: 14,
    color: '#FF5179',
    background: mainlight,
    textTransform: 'lowercase',
    marginBottom: '6px',
    padding: '3px 8px',
    borderRadius: '4px',
  },
  icon: {
    color: '#949499',
    marginRight: '5px',
  },
  flex: {
    flex: 1,
  },
  marginEnd: {
    height: '10px',
  },
}));

type CollectMobileProps = {
  datas: any;
  children?: React.ReactNode;
  select?: boolean;
};

export const CollectMobile = ({
  datas,
  children: childrenComponent,
  select = false,
}: CollectMobileProps) => {
  const props: propMakeStyles = { select };
  const classes = useStyles(props);

  const FormRow = ({ children }: { children: React.ReactNode }) => (
    <Grid container item xs={12} className={classes.mobileCollectHeaderItemRow}>
      {children}
    </Grid>
  );

  const FormRowLeft = ({
    title,
    titleSize = false,
    xs = 8,
    colorType = false,
    align = 'left',
    icon,
    button = false,
    alignItems = 'center',
    justify = 'flex-start',
  }: {
    title: string;
    titleSize?: boolean;
    xs?: GridSize;
    colorType?: boolean;
    align?: PropTypes.Alignment;
    icon?: React.ReactNode;
    button?: Boolean;
    alignItems?: GridItemsAlignment;
    justify?: GridJustification;
  }) => (
    <Grid item xs={xs} className={classes.mobileCollectHeaderItem}>
      <Grid container alignItems={alignItems} justify={justify}>
        {icon && <div className={classes.icon}>{icon}</div>}

        <Typography
          align={align}
          className={
            button
              ? classes.button
              : titleSize
                ? colorType
                  ? classes.collectName
                  : classes.collectNameLight
                : classes.bottomCollectName
          }
        >
          {title}
        </Typography>
      </Grid>
    </Grid>
  );

  return (
    <Paper className={classes.mobileCollectHeaderBack}>
      <Grid container className={classes.mobileCollectHeaderItem}>
        {datas.map((data: any) => (
          <FormRow>
            {data.map((show: any) => (
              <FormRowLeft
                title={show.title}
                titleSize={show.titleSize}
                xs={show.xs}
                align={show.align}
                justify={show.justify}
                icon={show.icon}
                button={show.button}
                colorType={show.colorType}
              />
            ))}
          </FormRow>
        ))}
      </Grid>
      {childrenComponent || <div className={classes.marginEnd} />}
    </Paper>
  );
};
