import React from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from '../../../../../services/translation';
import LoadingData from '../../../../components/LoadingData';
import { SearchFilters } from './SearchFilters';
import InfoTable from '../../../../components/InfoTable';
import { Filter } from '../../../../../types';
import { RowsType } from '../../../../../utils/tables';
import { TitleBreadcrumbs } from '../../../../../interfaces';
import { DataFilterDownloadInterface, DataFilterDownloadInterfaceSpanish } from '../../../../../interfaces/business/general/DataFilterDownload';

type HeadCellsType = {
  id: string;
  label: string;
  translate?: boolean;
  render?: (row: any, item: any, t: (str: string) => string) => React.ReactNode;
};

type OrderTableProps = {
  setFilter: (makeFilter: object) => void;
  filter: Filter;
  working: boolean;
  rows: Array<RowsType>;
  columns: Array<HeadCellsType>;
  fetchMore: () => void;
  onSortChange?: (orderBy: string, direction: 'asc' | 'desc') => void;
  orderBy?: string;
  direction?: 'asc' | 'desc';
  title: TitleBreadcrumbs[];
  pathDetails: (id: string) => string;
  download: boolean;
  data: DataFilterDownloadInterface[] | DataFilterDownloadInterfaceSpanish[];
};

export const OrderTable = ({
  columns,
  rows,
  working,
  filter,
  setFilter,
  fetchMore,
  onSortChange,
  orderBy,
  direction,
  title,
  pathDetails,
  download,
  data,
}: OrderTableProps) => {
  const { t } = useTranslation();
  const history = useHistory();

  const goToDetails = (row: RowsType) => {
    history.push({
      pathname: pathDetails(row.order_id),
      state: {
        goBack: title,
      },
    });
  };

  return (
    <>
      <SearchFilters onFilterChange={setFilter} initFetch={filter} />
      <InfoTable
        columns={columns}
        rows={rows}
        onBottomScroll={fetchMore}
        rowIdKey="order_id"
        onRowClick={goToDetails}
        onSortChange={onSortChange}
        orderBy={orderBy}
        direction={direction}
        download={download}
        data={data}
      />

      <LoadingData
        label={`${t('Loaded registers')}`}
        working={working}
        cant={rows.length}
      />
    </>
  );
};
