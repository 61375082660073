import { Theme, makeStyles } from '@material-ui/core/styles';
import { red, green } from '../../../theme/colors';

export const useStyles = makeStyles((theme: Theme) => ({
  titleIcon: {
    width: 16,
    height: 16,
    background: theme.palette.common.white,
    borderRadius: '50%',
    display: 'block',
  },
  modalTitle: {
    display: 'grid',
    gridTemplateColumns: '28px 1fr',
  },
  iconStyleError: {
    color: red,
  },
  iconStyleSuccess: {
    color: green,
  },
  titleText: {
    fontSize: 16,
    textTransform: 'none',
  },
}));
