import React from 'react';
import { Grid } from '@material-ui/core';
import { ButtonComponent } from '../../../components/Button';

type DeleteCollectsProps = {
  checked: any;
  deleteItemsInOrdersItemsModal: () => void
};

export const DeleteCollects = ({
  checked,
  deleteItemsInOrdersItemsModal,
}: DeleteCollectsProps) => (
  <Grid xs={6} sm={6} md={2} lg={2} xl={2}>
    {!!checked?.length && (
      <ButtonComponent
        variant="text"
        title="Delete selection"
        onClick={deleteItemsInOrdersItemsModal}
      />
    )}
  </Grid>
);
