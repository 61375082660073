import React, { useMemo, useCallback } from 'react';
import { useSnackbar } from 'notistack';
import { capitalize } from '@material-ui/core';
import { useTranslation } from '../../../services/translation';
import { formatDate, deleteColumn } from '../../../utils';
import { useLibbyFetch, OptionsLibbyFetch } from '../../../hooks/useLibbyFetch';
import { LibbyObject, AnyObject, userInfoType } from '../../../types';
import { columnCollect } from '../utils/columnCollect';
import { ACCOUNT_ROLE } from '../../../const';
import { useCreateUpdateCollectModal } from './useCreateUpdateCollectModal';
import { ColumnActionsCollect } from '../components/ColumnActionsCollect';
import { Collect } from '../../../interfaces/business';
import ConfirmDialog from '../../../components/ConfirmDialog';
import CustomModal from '../../../services/customFormDialog';
import { RowsType } from '../../../utils/tables';

const ConfirmModal = CustomModal(ConfirmDialog);

type useCollectLogicProps = {
  libby: LibbyObject;
  userInfo: userInfoType;
  dispatchId?: string;
  paramsFetch: OptionsLibbyFetch;
  goToDetails: (row: RowsType)=> void
};

export const useCollectLogic = ({
  libby,
  userInfo,
  dispatchId,
  paramsFetch,
  goToDetails,
}: useCollectLogicProps) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const rolAdminLogistics = userInfo?.groups?.[0].id === ACCOUNT_ROLE.ADMINISTRATOR
    || userInfo?.groups?.[0].id === ACCOUNT_ROLE.LOGISTICS;

  const {
    data,
    working,
    addCreate,
    updateData,
    fetchMore,
    removeData,
    reFetch,
  } = useLibbyFetch(libby, paramsFetch);

  const actionsCollect = useCallback(
    (dataUpdate: Collect, id: string, type: string) => {
      switch (type) {
        case 'update':
          updateData(dataUpdate, id);
          break;
        case 'remove':
          removeData(dataUpdate.collect_id, id);
          break;
        default:
          break;
      }
    },
    [updateData, removeData],
  );

  const handleCancelCollect = useCallback(
    async (collect?: Collect) => {
      const cancel = await ConfirmModal.show({
        title: t('Cancel collect'),
        content: `${t(
          'You are about to cancel a collect with $$$$ orders, do you want to continue?',
        )}`.replace('$$$$', String(collect?.items?.length)),
        confirmText: t('Confirm'),
        cancelText: t('Cancel'),
      });
      if (cancel) {
        const cancelled = await libby.ster_dispatch_collect_close
          .aspect('limit_collect_item')
          .save({
            ...collect,
            collectState: {
              collect_state_id: 4,
            },
          });

        updateData(cancelled, 'collect_id');
        enqueueSnackbar(t('Collect cancelled'), { variant: 'success' });
        enqueueSnackbar(
          `${t('Cart $$$$ was able to use')}`.replace(
            '$$$$',
            cancelled.cart.name,
          ),
          { variant: 'success' },
        );
      }
    },
    [t, libby, updateData, enqueueSnackbar],
  );

  const rows = useMemo(() => {
    const dataRow: any = [];
    const status = paramsFetch?.filter?.state?.map((state: any) => state.value);

    const assigned = paramsFetch?.filter?.assigned?.[0]?.value;
    let dataStatus = status
      ? data.filter((collect) => status.includes(collect.collectState.collect_state_id))
      : data;

    dataStatus = assigned
      ? dataStatus.filter(
        (collect: AnyObject) => collect.account.account_id.toString() === assigned,
      )
      : dataStatus;

    dataStatus.forEach((collect: AnyObject) => {
      dataRow.push({
        id: collect?.collect_id,
        actions: (
          <ColumnActionsCollect
            collect={collect}
            handleCancelCollect={handleCancelCollect}
            actionsDispatch={actionsCollect}
            goToDetails={goToDetails}
          />
        ),
        collect_id: collect?.collect_id,
        collect: `${collect.dispatch?.name} | #${collect?.collect_id}`,
        dispatch: collect.dispatch?.name,
        service: collect.dispatch?.courier_service?.name,
        allItems: collect.items.length,
        courier: collect.dispatch?.courier_service?.courier?.name,
        assigned: capitalize(collect.account?.username),
        state: collect.collectState.name,
        cart: collect.cart.name,
        delivery_date: formatDate(
          collect?.dispatch?.delivery_date ?? '',
          false,
        ),
        created_by: collect.created_by?.username,
        updated_by: collect.updated_by?.username,
        created_at: collect?.created_at
          ? formatDate(collect?.created_at, false, false)
          : '',
        updated_at: collect?.updated_at
          ? formatDate(collect?.updated_at, false, false)
          : '',
        items: collect.items,
      });
    });
    return dataRow;
  }, [data, paramsFetch, actionsCollect, handleCancelCollect, goToDetails]);

  const { handleOnOpenCollectModal } = useCreateUpdateCollectModal({
    libby,
    dispatchId,
    addCreate,
    updateData,
  });

  const columns = useMemo(() => {
    let columnsCollect = [...columnCollect];
    if (dispatchId) {
      const arrayColumnsDeleted = ['dispatch', 'courier', 'service'];
      arrayColumnsDeleted.forEach((element: string) => {
        columnsCollect = deleteColumn(element, columnsCollect);
      });
    }
    return columnsCollect;
  }, [dispatchId]);

  return {
    working,
    data,
    rows,
    columns,
    fetchMore,
    updateData,
    removeData,
    reFetch,
    handleOnOpenCollectModal,
    addCreate,
    rolAdminLogistics,
  };
};
