import { withStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import { AnyObject } from '../../types/types';

export const StyledAppBar = withStyles((theme: AnyObject) => ({
  root: {
    backgroundColor: 'white',
    borderTop: `1rem solid ${theme.palette.primary.main}`,
  },
}))(AppBar);
