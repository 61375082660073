import React from 'react';
import { Checkbox } from '@material-ui/core';
import { MakeCellChildren } from '../../routes/components/MakeCell';

export const CheckBox = ({
  validateCheck,
  onChange,
}: {
  validateCheck: boolean;
  onChange: () => void;
}) => (
  <MakeCellChildren label="">
    <Checkbox
      checked={validateCheck}
      onChange={onChange}
      inputProps={{ 'aria-labelledby': 'transfer-list-item-all-label' }}
      color="primary"
    />
  </MakeCellChildren>
);
