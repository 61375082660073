import { useMemo } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { TitleBreadcrumbs } from '../interfaces';

export const useBreadcrumbs = (
  name: string,
  data: TitleBreadcrumbs[] = [],
  additionalName: string = '',
) => {
  const match = useRouteMatch();
  const title = useMemo(() => {
    const titleProp = [...data];
    const repeated = data.find((dataTitle) => dataTitle.name === name);
    if (!repeated) {
      titleProp.push({
        name,
        additionalName,
        href: match.url,
      });
    }
    return titleProp;
  }, [data, name, additionalName, match]);
  return title;
};
