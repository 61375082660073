import { Dispatch_item } from '../../../interfaces/business';

interface Filter {
  orderState?: string;
  dispatchItemState?: string;
  dispatchItems: Dispatch_item[];
}

export const filterDispatchItemsByState = ({
  orderState,
  dispatchItemState,
  dispatchItems,
}: Filter) => dispatchItems.filter(
  ({
    order: {
      state: { order_state_id },
    },
    dispatch_item_state,
  }: Dispatch_item) => {
    const byOrderState = orderState ? orderState === order_state_id : true;
    const byItemState = dispatchItemState
      ? dispatchItemState === dispatch_item_state?.dispatch_item_state_id
      : true;
    return byOrderState && byItemState;
  },
);
