import React from 'react';
import { Switch, Route, useRouteMatch } from 'react-router-dom';
import { DatabaseConnector } from '@phinxlab/libby-rest-web';
import { LibbyObject } from '../../types/types';
import { useReportingPendingAndreaniIncomeLogic } from './hook/useReportingPendingAndreaniIncomeLogic';
import { ReportingPendingAndreaniList } from './routes/ReportingPendingAndreaniIncomeList';

type ReportingPendingAndreaniIncomeProps = {
  libby: LibbyObject;
};

const ReportingPendingAndreaniIncomeRouter = ({ libby }: ReportingPendingAndreaniIncomeProps) => {
  const match = useRouteMatch();

  const {
    working,
    filter,
    setFilter,
    fetchMore,
    handleRequestSort,
    orderBy,
    direction,
    rows,
    title,
  } = useReportingPendingAndreaniIncomeLogic({
    libby,
  });

  return (
    <Switch>
      <Route path={`${match.path}`}>
        <ReportingPendingAndreaniList
          title={title}
          working={working}
          filter={filter}
          setFilter={setFilter}
          fetchMore={fetchMore}
          onSortChange={handleRequestSort}
          orderBy={orderBy}
          direction={direction}
          rows={rows}
        />
      </Route>
    </Switch>
  );
};

export const ReportingPendingAndreaniIncome = DatabaseConnector(
  ReportingPendingAndreaniIncomeRouter,
)('ster_reporting_pending_andreani_income');
