import React, { useCallback, useMemo, useState } from 'react';
import AssignmentIcon from '@material-ui/icons/Assignment';
import { columns } from '../utils/columns';
import { useLibbyFetch } from '../../../hooks/useLibbyFetch';
import { LibbyObject } from '../../../types';
import { makeFilter } from '../utils/makeFilter';
import { RowsType } from '../../../utils/tables';
import { formatDate } from '../../../utils';
import { MakeCell } from '../../components/MakeCell';
import { useBreadcrumbs } from '../../../hooks';

type useReportingPendingAndreaniIncomeLogicProps = {
  libby: LibbyObject;
};

export const useReportingPendingAndreaniIncomeLogic = ({
  libby,
}: useReportingPendingAndreaniIncomeLogicProps) => {
  const [filter, setFilter] = useState(
    makeFilter({
      startDate: null,
      endDate: null,
    }),
  );

  const title = useBreadcrumbs('Report pending andreani income');

  const [orderBy, setOrderBy] = useState('order_id');
  const [direction, setDirection] = useState<'asc' | 'desc'>('asc');

  const paramsFetch = useMemo(
    () => ({
      daoName: 'ster_reporting_pending_andreani_income',
      filter,
      orderBy,
      direction,
    }),
    [filter, orderBy, direction],
  );

  const {
    data,
    working,
    fetchMore,
  } = useLibbyFetch(libby, paramsFetch);

  const handleRequestSort = useCallback(
    (newOrderBy: string, newDirection: 'asc' | 'desc') => {
      setOrderBy(newOrderBy);
      setDirection(newDirection);
    },
    [],
  );

  const rows: RowsType[] = useMemo(
    () => data.map((order: any) => ({
      id: order.order_id,
      order_id: order.order_id,
      owner_id: order.owner_id,
      so: order.so,
      courier: order.courier,
      courier_state: order.courier_state,
      courier_detailed_state: order.courier_detailed_state,
      dispatch_date: formatDate(order?.dispatch_date, true, false),
      detail: (
        <MakeCell
          label=""
          icon={AssignmentIcon}
          path={`orders/detail/${order?.order_id}`}
          params={{
            goBack: title,
          }}
          onClick={(e) => {
            e.stopPropagation();
          }}
        />
      ),
    })),
    [data, title],
  );

  return {
    working,
    data,
    columns,
    fetchMore,
    handleRequestSort,
    orderBy,
    direction,
    filter,
    setFilter,
    rows,
    title,
  };
};
