// TODO: Refactorizar
import React from 'react';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/styles';
import IconButton from '@material-ui/core/IconButton';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { useTranslation } from '../../../services/translation';
import { Navbar } from '../../../components/Navbar';
import { AnyObject } from '../../../types';
import { red, green, orange } from '../../../theme/colors';
import { Collect } from '../types/Collect';

const useStyles = makeStyles((theme: AnyObject) => ({
  navbarBox: {
    position: 'fixed',
    '& .MuiToolbar-regular': {
      minHeight: 70,
    },
  },
  clearIcon: {
    fill: theme.palette.common.black,
    fontSize: 36,
    cursor: 'pointer',
  },
  title: {
    fontSize: 20,
    fontWeight: 400,
    cursor: 'default',
    color: theme.palette.common.black,
  },
  contentTitle: {
    display: 'flex',
    alignItems: 'center',
  },
  actionButton: {
    marginRight: 0,
    marginLeft: 15,
    padding: '5px 20px',
    fontFamily: '"Oswald"',
  },
  progressCircleEmpty: {
    width: 20,
    height: 20,
    border: `1px solid ${red}`,
    borderRadius: '50%',
    background: 'transparent',
  },
  progressCircleRed: {
    width: 20,
    height: 20,
    border: `1px solid ${red}`,
    borderRadius: '50%',
    backgroundImage: `linear-gradient(to right, transparent 75%, ${red} 0)`,
  },
  progressCircleOrange: {
    width: 20,
    height: 20,
    border: `1px solid ${orange}`,
    borderRadius: '50%',
    backgroundImage: `linear-gradient(to right, transparent 50%, ${orange} 0)`,
  },
  progressCircleGreen: {
    width: 20,
    height: 20,
    border: `1px solid ${green}`,
    borderRadius: '50%',
    backgroundColor: green,
  },
}));

type NavbarMobileCollectProps = {
  data: Collect;
  complete: number | string;
  getCircleColor: (a: string | number, b: string, c: string, d: string) => string
};

export const NavbarMobileCollect = ({
  data,
  complete,
  getCircleColor,
}: NavbarMobileCollectProps) => {
  const classes = useStyles();
  const history = useHistory();
  const { t } = useTranslation();

  const circle = getCircleColor(
    complete,
    classes.progressCircleRed,
    classes.progressCircleOrange,
    classes.progressCircleGreen,
  );

  const left = (
    <IconButton onClick={() => history.goBack()} aria-label="arrow back" component="span">
      <ArrowBackIcon />
    </IconButton>
  );

  const title = (
    <div className={classes.contentTitle}>
      <h2 role="presentation" className={classes.title}>{`${t('Collect')} #${data?.collect_id || ''}`}</h2>
    </div>
  );

  const right = <div className={complete > 0 ? circle : classes.progressCircleEmpty} />;

  return (
    <Navbar
      className={classes.navbarBox}
      left={left}
      title={title}
      right={right}
    />
  );
};
