import React from 'react';
import { useField } from 'react-final-form-hooks';
import { useHistory } from 'react-router-dom';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import { DatabaseConnector } from '@phinxlab/libby-rest-web';
import { useLoginForm } from '../hooks';
import { useTranslation } from '../../../services/translation';
import { LibbyObject } from '../../../types/types';
import { makeGetErrorAndHelperText, textFieldProps } from '../../../utils/material';

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

type LoginFormRawProps = {libby: LibbyObject, };

const LoginFormRaw = ({ libby }: LoginFormRawProps) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const {
    form,
    handleSubmit,
    submitting,
  } = useLoginForm(libby);
  const history = useHistory();
  const username = useField('username', form);
  const password = useField('password', form);
  const getErrorAndHelperText = makeGetErrorAndHelperText(t);
  return (
    <div className={classes.paper}>
      <Avatar className={classes.avatar}>
        <LockOutlinedIcon />
      </Avatar>
      <Typography component="h1" variant="h5">
        {t('Sign in')}
      </Typography>
      <form className={classes.form} noValidate onSubmit={handleSubmit}>
        <TextField
          {...textFieldProps(t('Username'))}
          {...username.input}
          {...getErrorAndHelperText(username)}
          autoComplete="username"
          autoFocus
          color="secondary"
        />
        <TextField
          {...textFieldProps(t('Password'))}
          {...password.input}
          {...getErrorAndHelperText(password)}
          type="password"
          autoComplete="current-password"
          color="secondary"
        />
        <Button
          type="submit"
          fullWidth
          variant="contained"
          color="primary"
          disabled={submitting}
          className={classes.submit}
        >
          {submitting ? <CircularProgress size={24} /> : t('Sign In')}
        </Button>
        <Button
          type="button"
          fullWidth
          onClick={() => { history.push('/add'); }}
          variant="contained"
        >
          {t('Request user')}
        </Button>

      </form>
    </div>
  );
};

export const LoginForm = DatabaseConnector(LoginFormRaw)();
