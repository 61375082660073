import React, { useState, useMemo, useCallback } from 'react';
import {
  Switch, Route, useRouteMatch, useHistory, useLocation,
} from 'react-router-dom';
import { DatabaseConnector } from '@phinxlab/libby-rest-web';
import { OrderDetail } from './routes/OrderDetail';
import { OrdersList } from './routes/OrdersList';
import { OrderStateLogs } from './routes/OrderStateLogs';
import { useOrderTableLogic } from './routes/OrdersList/hook/useOrderTableLogic';
import { LibbyObject } from '../../types/types';
import { useBreadcrumbs } from '../../hooks';
import { makeFilter } from './routes/OrdersList/utils/makeFilter';

export const OrdersRouter = ({ libby }: LibbyObject) => {
  const { path } = useRouteMatch();
  const [direction, setDirection] = React.useState<'asc' | 'desc'>('desc');
  const [orderBy, setOrderBy] = React.useState('order_id');
  const history = useHistory();

  const detailsRedirection = useCallback(
    (order_id: string) => history.push(`orders/detail/${order_id}`),
    [history],
  );
  const { search: pathname } = useLocation();
  const searchValue = pathname.split('=');
  const keyValue = searchValue[0].split('?');
  const [filter, setFilter] = useState<object>(
    makeFilter({
      search: keyValue[1] === 'search' ? searchValue[1] : '',
    }),
  );

  const handleRequestSort = (
    newOrderBy: string,
    newDirection: 'asc' | 'desc',
  ) => {
    setOrderBy(newOrderBy);
    setDirection(newDirection);
  };

  const paramsFetch = useMemo(
    () => ({
      daoName: 'ster_order',
      filter,
      orderBy,
      direction,
      aspect: 'list_order_so_order',
    }),
    [filter, orderBy, direction],
  );

  const onChangeFilter = (value: object) => {
    if (JSON.stringify(filter) !== JSON.stringify(value)) {
      setFilter(value);
    }
  };

  const {
    rows, columns, working, fetchMore, reFetch,
  } = useOrderTableLogic(
    libby,
    paramsFetch,
    detailsRedirection,
    false,
    true,
    path,
  );

  const title = useBreadcrumbs('Orders');

  const onRowClick = (row: LibbyObject) => {
    history.push(`${path}/detail/${row.order_id}`);
  };

  return (
    <Switch>
      <Route path={`${path}/logs/:id`}>
        <OrderStateLogs title={title} />
      </Route>
      <Route path={`${path}/detail/:id`}>
        <OrderDetail title={title} />
      </Route>
      <Route path={`${path}`}>
        <OrdersList
          title={title}
          detailUrl={`${path}/detail`}
          setFilter={onChangeFilter}
          rows={rows}
          columns={columns}
          working={working}
          filter={filter}
          fetchMore={fetchMore}
          onSortChange={handleRequestSort}
          orderBy={orderBy}
          direction={direction}
          onRowClick={onRowClick}
          reFetch={reFetch}
        />
      </Route>
    </Switch>
  );
};

export const Orders = DatabaseConnector(OrdersRouter)(
  'ster_order',
  'ster_order_table',
);
