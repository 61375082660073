import { AnyObject } from '../types/types';

export const InboxOrderState: AnyObject = {
  1: 'Pulled',
  2: 'Evaluated',
  3: 'OK',
  4: 'Problem',
  5: 'Manually Fixed',
  6: 'Ignored',
};

// @ts-ignore
export const INBOX_ORDER_STATE = {
  PULLED: '1',
  EVALUATED: '2',
  OK: '3',
  PROBLEM: '4',
  MANUALLY_FIXED: '5',
  IGNORED: '6',
};
