import { TextFieldProps } from '@material-ui/core/TextField';
import { AnyObject } from '../types/types';

export const makeGetErrorAndHelperText = (t = (str:string) => str) => (field: AnyObject): {error:boolean, helperText?:string} => {
  const helperText = field.meta.touched && field.meta.error && t(field.meta.error);
  const error = !!helperText;
  if (!error) {
    return { error };
  }
  return { error, helperText };
};

export const textFieldProps = (label: string): TextFieldProps => ({
  id: label,
  label,
  fullWidth: true,
  variant: 'outlined',
  margin: 'normal',
});
