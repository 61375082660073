import { LibbyFetchDAO } from './LibbyFetchDAO';

export class DispatchCollectItemDAO extends LibbyFetchDAO {
  constructor() {
    super('ster_dispatch_collect_item', 'collect_item_id');
  }

  getAll() {
    return this.aspect('limit_for_orders').query()
      .limit(0, 10000)
      .run();
  }
}
