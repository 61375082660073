import { GridProps } from '@material-ui/core';

export const gridBreakpoints: GridProps = {
  xs: 12,
  md: 6,
  lg: 5,
};

export const gridBreakpointsFields: GridProps = {
  xs: 12,
  sm: 6,
  md: 6,
  lg: 3,
};

export const gridBreakpointsOrderDetails: GridProps = {
  xs: 12,
  sm: 12,
  md: 6,
  lg: 3,
};

export const gridBreakpointsWithTablet: GridProps = {
  xs: 12,
  sm: 6,
  md: 6,
  lg: 3,
};
