import { useSnackbar } from 'notistack';
import { useCallback } from 'react';
import { useModalWarning } from '../../../../../hooks';
import { Order } from '../../../../../interfaces/business';
import { useTranslation } from '../../../../../services/translation';
import { LibbyObject } from '../../../../../types';
import { ORDER_STATE } from '../../../../../const';
import { CANCEL_TYPE, cancelOrderInOdoo } from '../../../utils';

type useStateOrderType = {
  libby: LibbyObject;
};

export type onChangeUpdateStateType = {
  data: Order;
  updateDataField: (order: Order, field?: keyof typeof order) => void;
  title?: string;
  content?: string;
  validation?: boolean;
  typeUpdateState?: string;
  state_id: string;
};

export type onChangeUpdateStateAllType = {
  data: Order[];
  updateDataField: (order: Order, field?: keyof typeof order) => void;
  title?: string;
  content?: string;
  typeUpdateState?: string;
  state_id: string;
};

export const useStateOrder = ({ libby }: useStateOrderType) => {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();

  const { showModal } = useModalWarning({
    title: 'You have orders in a canceled state',
    content: 'Please, delete all orders($$$$) with canceled status',
    confirmText: 'Yes',
    oneButton: false
  });

  const onChangeUpdateState = useCallback(
    async ({ updateDataField, data, title, content, validation = true, typeUpdateState = 'canceled', state_id }: onChangeUpdateStateType) => {
      try {
        const validate = validation
          ? await showModal({
              newContent: content,
              newTitle: title
            })
          : true;

        if (validate) {
          const dataUpdate =
            state_id === ORDER_STATE.CANCELLED.toString()
              ? await libby.ster_order_cancelled.save({
                  id_order: data.order_id
                })
              : await libby.ster_order_table.aspect('order_state').save({
                  order_id: data.order_id,
                  state: {
                    order_state_id: (data.state.order_state_id = state_id)
                  }
                });

          updateDataField(
            {
              ...dataUpdate
            },
            'state'
          );
          enqueueSnackbar(t(`Order $$$ ${typeUpdateState}`).replace('$$$', data.order_id), {
            variant: 'success'
          });

          if (data.state.order_state_id.toString() === ORDER_STATE.CANCELLED.toString()) {
            try {
              await cancelOrderInOdoo({
                id_order: data.order_id,
                type: CANCEL_TYPE.manualReturn
              });
            } catch (err) {
              await cancelOrderInOdoo({
                id_order: data.order_id,
                type: CANCEL_TYPE.toReturn
              });
            }
          }
        }
      } catch (error) {
        if (error) {
          enqueueSnackbar(`Error ${error}`, { variant: 'error' });
        }
      }
    },
    [showModal, libby, enqueueSnackbar, t]
  );

  const onChangeUpdateStateAll = useCallback(
    async ({ updateDataField, data, title, content, state_id, typeUpdateState }: onChangeUpdateStateAllType) => {
      try {
        const validateAll = await showModal({
          newContent: content,
          newTitle: title
        });

        if (validateAll) {
          for (let index = 0; index < data.length; index++) {
            onChangeUpdateState({
              updateDataField,
              data: data[index],
              state_id,
              validation: false,
              typeUpdateState
            });
          }
        }
      } catch (error) {
        if (error) {
          enqueueSnackbar(`Error ${error}`, { variant: 'error' });
        }
      }
    },
    [showModal, enqueueSnackbar, onChangeUpdateState]
  );

  return { onChangeUpdateState, onChangeUpdateStateAll };
};
