import FormControl from '@material-ui/core/FormControl';
import React from 'react';
import MenuItem from '@material-ui/core/MenuItem';
import { makeStyles } from '@material-ui/core/styles';
import TextField, { TextFieldProps } from '@material-ui/core/TextField';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useTranslation } from '../../services/translation';

const useStyles = makeStyles({
  formControl: {
    width: '100%',
    '& > div > div > div': {
      flex: 1,
    },
  },
  pText: {
    margin: '0px 4px',
    fontWeight: 'bold',
  },
});

export type GeneralSelectProps = {
  working: boolean;
  disabled?: boolean;
  inputProps?: TextFieldProps;
  label: string;
  optionAll: boolean;
  customAll?: string;
  data: Array<{ id: number; value: any; name?: string; extra?: string }>;
  [k: string]: any;
};

export const GeneralSelect = ({
  inputProps,
  working,
  label,
  disabled,
  optionAll,
  customAll,
  data,
  ...styles
}: GeneralSelectProps) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const translatedLabel = t(label);

  return (
    <FormControl className={classes.formControl} style={styles}>
      <TextField
        {...inputProps}
        label={
          working && !disabled
            ? `${t('Loading')} ${translatedLabel} ...`
            : translatedLabel
        }
        InputLabelProps={{
          shrink: true,
        }}
        placeholder={translatedLabel}
        fullWidth
        select
        disabled={!!disabled}
      >
        {working && !disabled ? (
          <MenuItem value="0">
            <CircularProgress size={24} />
          </MenuItem>
        ) : (
          optionAll && <MenuItem value="0">{t(customAll || 'All')}</MenuItem>
        )}

        {!working
          && data.map(({
            id, value, name = false, extra,
          }) => (
            <MenuItem value={name ? String(value) : String(id)} key={id}>
              {t(name || value)}
              {extra && <p className={classes.pText}>{extra}</p>}
            </MenuItem>
          ))}
      </TextField>
    </FormControl>
  );
};
