import React, { useCallback } from 'react';
import { useSnackbar } from 'notistack';
import { useHistory } from 'react-router-dom';
import { LibbyObject } from '../../../types';
import { CollectState, DISPATCH_ITEM_STATE } from '../../../const';
import { Collect_item, Collect } from '../../../interfaces/business';
import { ModalTitle } from '../common';
import CustomModal from '../../../services/customFormDialog';
import ConfirmDialog from '../../../components/ConfirmDialog';
import { useTranslation } from '../../../services/translation';

type useCollectCloseProps = {
  libby: LibbyObject;
  updateData: (collect: Collect) => void;
  data: Collect;
};

const ConfirmModal = CustomModal(ConfirmDialog);

export const useCollectClose = ({
  libby,
  updateData,
  data,
}: useCollectCloseProps) => {
  const { t } = useTranslation();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();

  const modifyStateGlobal = useCallback(
    (collect: Collect) => {
      const updateState = {
        ...data,
        collectState: collect.collectState,
      };
      updateData(updateState);
    },
    [updateData, data],
  );

  const onCloseCollect = useCallback(async () => {
    try {
      const onCloseUpdate = await libby.ster_dispatch_collect_close
        .aspect('close_collect_with_items')
        .save({
          collect_id: data.collect_id,
          items: data.items.map((collect_item: Collect_item) => ({
            ...collect_item,
            dispatch_item: {
              ...collect_item.dispatch_item,
              dispatch_item_state: {
                dispatch_item_state_id: DISPATCH_ITEM_STATE.PREPARING_PACKAGE,
              },
            },
          })),
          collectState: { collect_state_id: CollectState.COMPLETE },
        });

      modifyStateGlobal(onCloseUpdate);
      const onConfirm = await ConfirmModal.show({
        title: <ModalTitle title="Collect Closed" />,
        content: `${t(
          'Please, leave the products in the dispatch zone ($$$$).',
        ).replace('$$$$', onCloseUpdate.dispatch.dispatch_zone.name)}`,
        confirmText: t('Accept'),
        oneButton: true,
      });
      if (onConfirm) {
        enqueueSnackbar(t('Collect Closed'), { variant: 'success' });
        history.push('/collect');
      }
    } catch (error) {
      if (error) {
        enqueueSnackbar(`Error ${error}`, { variant: 'error' });
      } else {
        history.push('/collect');
      }
    }
  }, [enqueueSnackbar, libby, history, t, data, modifyStateGlobal]);

  return {
    onCloseCollect,
  };
};
