import { LibbyFetchDAO } from './LibbyFetchDAO';

export class AccountRoleDAO extends LibbyFetchDAO {
  constructor() {
    super('admin_account_role', 'account_role_id');
  }

  getAll() {
    return this.query()
      .limit(0, 10000)
      .run();
  }
}
