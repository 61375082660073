import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import CloseIcon from '@material-ui/icons/Close';
import ReportProblemOutlinedIcon from '@material-ui/icons/ReportProblemOutlined';
import CheckOutlinedIcon from '@material-ui/icons/CheckOutlined';
import Button from '@material-ui/core/Button';
import { useTranslation } from '../../../../../services/translation';
import LoadingData from '../../../../components/LoadingData';
import { critical, success, warning } from '../utils/colors';
import { makeFilter } from '../utils/makeFilter';

const useStyles = makeStyles(() => ({
  card: {
    backgroundColor: 'white',
    display: 'flex',
    flex: 1,
    height: '140px',
    padding: '26px 24px',
    margin: '0 19px 19px 19px',
    '@media (max-width: 420px)': {
      margin: '0 0 19px 0',
      padding: '10px',
    },
    '@media (min-width: 500px)': {
      minWidth: '396px',
    },
    alignItems: 'center',
  },
  iconContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '68px',
    height: '68px',
    borderRadius: '50%',
  },
  icon: {
    color: 'white',
    height: '38px',
    width: '38px',
  },
  textContainer: {
    marginLeft: '20px',
  },
  title: {
    fontSize: '34px',
    fontWeight: 500,
    fontStyle: 'normal',
    lineHeight: '48px',
    textTransform: 'uppercase',
  },
  subtitle: {
    fontFamily: 'Lato',
    fontSize: '14px',
    fontStyle: 'normal',
    lineHeight: '20px',
    fontWeight: 700,
    textTransform: 'uppercase',
  },
  OK: {
    backgroundColor: success,
  },
  Warning: {
    backgroundColor: warning,
  },
  Critical: {
    backgroundColor: critical,
  },
}));

type SummaryCard = {
  type: 'OK' | 'Warning' | 'Critical',
  quantity: number,
  total: number,
  working?: boolean,
  setFilter: (makeFilter: object) => void;
};

export const SummaryCard = ({
  type = 'OK', quantity = 0, total, working, setFilter,
}: SummaryCard) => {
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <Button
      className={classes.card}
      onClick={() => {
        setFilter(makeFilter({ creationStatus: type }));
      }}
    >

      { !working ? (
        <>
          <Grid
            className={`${classes.iconContainer} ${classes[type]}`}
          >
            { type === 'Warning' ? (
              <ReportProblemOutlinedIcon className={classes.icon} />
            ) : type === 'Critical' ? (
              <CloseIcon className={classes.icon} />
            ) : (
              <CheckOutlinedIcon className={classes.icon} />
            )}
          </Grid>
          <Grid className={classes.textContainer}>
            <Grid>
              <Typography className={classes.title}>{`${quantity} ${t('Orders')}`}</Typography>
            </Grid>
            <Grid>
              <Typography className={classes.subtitle}>{`${((quantity * 100) / total).toFixed(1)}% ${t('of total orders')}`}</Typography>
            </Grid>
          </Grid>
        </>
      ) : (
        <LoadingData working={!!working} />
      )}
    </Button>
  );
};
