import React, { ReactNode } from 'react';
import { makeStyles } from '@material-ui/styles';
import ToolbarMat from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles({
  grow: {
    flexGrow: 1,
  },
  leftButtons: {
    marginLeft: -12,
    marginRight: 12,
  },
  buttons: {
    display: 'flex',
  },
});

type ToolbarProps = {
  left?: ReactNode,
  title?: ReactNode,
  right?: ReactNode,
};

export const Toolbar = ({ left = null, title = null, right = null }: ToolbarProps) => {
  const classes = useStyles();
  return (
    <ToolbarMat>
      <div className={classes.leftButtons}>
        {left}
      </div>
      <Typography variant="h5" color="inherit">
        {title}
      </Typography>
      <div className={classes.grow} />
      <div className={classes.buttons}>
        {right}
      </div>
    </ToolbarMat>
  );
};
