import React, { useCallback } from 'react';
import IconButton from '@material-ui/core/IconButton';
import AccountCircle from '@material-ui/icons/AccountCircle';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import { makeStyles } from '@material-ui/styles';
import { useTranslation } from '../../services/translation';

const useStyles = makeStyles(() => ({
  menuButton: {
    paddingRight: 0,
  },
}));

export type AccountButtonProps = {
  onLogoutClick: (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void,
  color?: 'inherit' | 'primary' | 'secondary' | 'default',
};

export const AccountButton = ({ onLogoutClick, color = 'inherit' }: AccountButtonProps) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleMenu = useCallback((event) => {
    setAnchorEl(event.currentTarget);
  }, [setAnchorEl]);

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, [setAnchorEl]);
  const onLogout = useCallback((event) => {
    onLogoutClick(event);
    handleClose();
  }, [onLogoutClick, handleClose]);
  return (
    <div>
      <IconButton
        aria-label="account of current user"
        aria-controls="menu-appbar"
        aria-haspopup="true"
        onClick={handleMenu}
        color={color}
        className={classes.menuButton}
      >
        <AccountCircle fontSize="large" />
      </IconButton>
      <Menu
        id="menu-appbar"
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={!!anchorEl}
        onClose={handleClose}
      >
        <MenuItem onClick={onLogout}>{t('Logout')}</MenuItem>
      </Menu>
    </div>
  );
};
