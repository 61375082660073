import React from 'react';
import { Switch, Route, useRouteMatch } from 'react-router-dom';
import { SterConfigList } from './routes/SterConfigList';

export const SterConfig = () => {
  const match = useRouteMatch();
  return (
    <Switch>
      <Route path={`${match.path}`}>
        <SterConfigList />
      </Route>
    </Switch>
  );
};
