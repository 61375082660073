import React, {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import { SearchDialog } from '../../../../../components/SearchDialog';
import { useLibbyCall } from '../../../../../hooks/useLibbyCall';
import { LibbyObject } from '../../../../../types/types';
import CustomModal from '../../../../../services/customFormDialog';
import { ProcessTable } from '../../../../../components/Alerts/ProcessTable';

export type useReferenceImportationIdOptions = {
  daoName: string;
  importation_id: number;
};

const SearchDialogModal = CustomModal(SearchDialog);

export const useReferenceImportationId = (
  libby: LibbyObject,
  { daoName, importation_id }: useReferenceImportationIdOptions,
  noAutoCall: boolean = true,
) => {
  const { data, working, recall } = useLibbyCall(libby, {
    daoName,
    methodName: 'getReference',
    params: [{ importation_id }],
    noAutoCall,
  });

  const showAlert = useCallback(async () => {
    try {
      await SearchDialogModal.show({
        title:
          'The following processes prevent the import from being processed. Please, notify the administrator',
        id: 'ster_config_id',
        properties: ['name'],
        disabledLabel: true,
        data: data.errorsGeneral,
        renderItem: ({ data: dataProcess }: any) => (
          <ProcessTable
            items={dataProcess}
            columnsEnabled={['name', 'last_execution']}
          />
        ),
      });
    } catch (error) {
      // nothing
    }
  }, [data]);

  const [dataResult, setDataResult] = useState({
    success: 0,
    fails: 0,
    toUpload: 0,
  });

  const countResult = useMemo(
    () => Object.values(dataResult).reduce(
      (allValue: number, value: number) => allValue + value,
      0,
    ),
    [dataResult],
  );

  useEffect(() => {
    if (Object.values(data).length && data?.errorsGeneral?.length) {
      showAlert();
    }
  }, [data, showAlert]);

  useEffect(() => {
    if (
      JSON.stringify(dataResult) !== JSON.stringify(data.result)
      && Object.values(data).length
      && !data.error
      && data.result
    ) {
      setDataResult(data.result);
    }
  }, [data, dataResult, showAlert]);

  const recallSearch = useCallback(() => {
    if (!working) {
      recall?.({ importation_id });
    }
  }, [working, recall, importation_id]);

  return {
    dataResult,
    data,
    working,
    recallSearch,
    countResult,
  };
};
