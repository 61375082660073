import { AnyObject } from '../../../../../types/types';

type MakeFilterProps = {
  name?: string,
};

export const makeFilter = ({ name }: MakeFilterProps) => {
  let filter: AnyObject = {};

  if (name && name !== '0') {
    filter = name.split(' ').reduce((accumulator: AnyObject, item, index) => {
      accumulator[index] = [
        {
          path: 'name',
          value: item,
          method: 'includes',
        },
        {
          path: 'matches',
          value: item,
          method: 'includes',
        },
      ];

      const numberSearch = parseInt(item, 10);
      if (numberSearch) {
        accumulator[index].push({
          path: 'state_id',
          value: numberSearch,
        });
      }

      return accumulator;
    }, {});
  }

  return filter;
};
