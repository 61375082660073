import { useLibbyCall } from './useLibbyCall';
import { LibbyObject } from '../types/types';

/*
  Shortcut to call fetchById method of LibbyFetchDAO
  params:
  - libby: Libby object provided by DatabaseConnector
  - Options object:
    - daoName: name of the dao to use (must be connected with the libby HOC)
    - id: param to be passed like argument to fetchById method
  returns an object with:
  - data: the object result of the call, if not found will return undefined
  - working: boolean, can be used like a loading state
  - recall : function to call the method again, this function can received params that
             override the passed like options. If none passed it will use the passed
             like options
  Example of use
  const { data: teacher } = useLibbyFetchById(libby, {
    daoName: 'teacher',
    id: item.teacher_id,
  });
 */
export type Options = {
  daoName: string,
  id: string | number,
  aspect?: string
};

export const useLibbyFetchById = (
  libby: LibbyObject,
  { daoName, id, aspect }: Options,
) => {
  const { data: [item] = [], working, recall } = useLibbyCall(
    libby,
    {
      daoName,
      methodName: 'fetchById',
      params: [id],
      aspect,
    },
  );

  return { data: item, working, recall };
};
