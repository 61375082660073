import { Moment } from 'moment';

export const startDateFormatUtc = (date: Moment) => date
  .utc()
  .set({
    second: 0,
    millisecond: 0,
    minute: 0,
    hour: 0,
  })
  .toISOString();

export const endDateFormatUtc = (date: Moment) => date.utc().set({ hour: 23, second: 59, minute: 59 }).toISOString();
