import React, {
  useCallback,
  useEffect,
  useState,
} from 'react';
import moment, { Moment } from 'moment';
import SearchIcon from '@material-ui/icons/Search';
import 'moment/locale/es';
import { Grid, TextField } from '@material-ui/core';
import { makeFilter } from '../utils/makeFilter';
import { SelectOrderState } from '../../../../components/SelectOrderState';
import { SelectOrderCourier } from '../../../../components/SelectOrderCourier';
import { SelectOrderShipment } from '../../../../components/SelectOrderShipment';
import { SelectMarketPlace } from '../../../../components/SelectMarketPlace';
import { DateClose } from '../../../../../components/DateClose';
import { FilterBar } from '../../../../../components/FilterBar';
import { gridBreakpoints, gridBreakpointsFields } from '../../../../../const/gridBreakpoints';
import useDebounce from '../../../../../hooks/useDebounce';
import { useTranslation } from '../../../../../services/translation';
import { AnyObject } from '../../../../../types/types';
import { replaceNonNumericCharacters } from '../../../../../functions';

const initialStartDate = null;
const initialEndDate = null;

type SearchFiltersProps = {
  onFilterChange: (makeFilter: object) => void;
  initFetch: AnyObject;
  canalOffLine?: boolean;
};

const SearchFiltersRaw = ({
  onFilterChange,
  initFetch,
  canalOffLine = false,
}: SearchFiltersProps) => {
  const { t } = useTranslation();

  const [symbolsArr] = useState(['e', 'E', '+', '-', '.', ',', '`+']);

  const [search, setSearch] = useState<string>(
    initFetch?.['0']?.[0]?.value || '',
  );

  const searchDebounced = useDebounce(search, 500);

  const [orderId, setOrderId] = useState<string>(
    initFetch?.order_id?.length > 0 ? initFetch.order_id[0].value : '',
  );

  const orderIdDebounced = useDebounce(orderId, 500);

  const [ownerId, setOwnerId] = useState<string>(
    initFetch?.owner_id?.length > 0 ? initFetch.owner_id[0].value : '',
  );

  const ownerIdDebounced = useDebounce(ownerId, 500);

  const [startDate, setStartDate] = useState<Moment | null>(
    initFetch?.startDate?.length > 0
      ? moment(initFetch.startDate[0].value)
      : initialStartDate,
  );
  const [marketplace, setMarketplace] = useState<string>(
    initFetch?.marketplace?.length > 0 ? initFetch.marketplace[0].value : '0',
  );
  const [source, setSource] = useState<string>(
    initFetch?.source?.length > 0 ? initFetch.source[0].value : '0',
  );

  const [shipment, setShipment] = useState<string>(
    initFetch?.shipment?.length > 0 ? initFetch.shipment[0].value : '0',
  );
  const [orderStatus, setOrderStatus] = useState<string>(
    initFetch?.orderStatus?.length > 0 ? initFetch.orderStatus[0].value : '0',
  );
  const [endDate, setEndDate] = useState<Moment | null>(
    initFetch?.endDate?.length > 0
      ? moment(initFetch.endDate[0].value)
      : initialEndDate,
  );

  useEffect(() => {
    onFilterChange(
      makeFilter({
        canalOffLine,
        marketplace,
        source,
        shipment,
        search: searchDebounced,
        orderStatus,
        startDate,
        endDate,
        ownerId: ownerIdDebounced,
        orderId: orderIdDebounced,
      }),
    );
  }, [
    onFilterChange,
    searchDebounced,
    marketplace,
    source,
    shipment,
    orderStatus,
    startDate,
    endDate,
    ownerIdDebounced,
    orderIdDebounced,
    canalOffLine,
  ]);

  const handleChangeType = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setOrderStatus(event.target.value);
    },
    [],
  );

  const handleChangeSource = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setSource(event.target.value);
      setShipment('0');
    },
    [],
  );

  const handleChangeShipment = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setShipment(event.target.value);
    },
    [],
  );

  const handleChangeMarketPlace = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setMarketplace(event.target.value);
    },
    [],
  );

  const handleReset = useCallback(() => {
    setOrderId('');
    setOwnerId('');
    setSearch('');
    setStartDate(initialStartDate);
    setMarketplace('0');
    setSource('0');
    setShipment('0');
    setOrderStatus('0');
    setEndDate(initialEndDate);
    onFilterChange(makeFilter({ canalOffLine }));
  }, [onFilterChange, canalOffLine]);

  return (
    <FilterBar
      defaultFilter={(
        <Grid item {...gridBreakpoints}>
          <TextField
            id="standard-search"
            placeholder={t('Search')}
            type="search"
            value={search}
            onChange={(event) => setSearch(event.target.value)}
            fullWidth
            InputLabelProps={{
              shrink: true,
            }}
            InputProps={{
              endAdornment: <SearchIcon />,
            }}
          />
        </Grid>
      )}
      handleReset={handleReset}
    >
      <Grid item {...gridBreakpointsFields}>
        <TextField
          id="generic-search"
          label={t('ID Order')}
          value={orderId}
          type="text"
          onChange={(event) => setOrderId(replaceNonNumericCharacters(event.target.value))}
          fullWidth
          onKeyDown={(e) => symbolsArr.includes(e.key) && e.preventDefault()}
          InputLabelProps={{
            shrink: true,
          }}
        />
      </Grid>
      <Grid item {...gridBreakpointsFields}>
        <SelectOrderState
          title="State"
          inputProps={{ value: orderStatus, onChange: handleChangeType }}
          optionAll
        />
      </Grid>
      <Grid item {...gridBreakpointsFields}>
        <SelectMarketPlace
          inputProps={{ value: marketplace, onChange: handleChangeMarketPlace }}
          optionAll
        />
      </Grid>
      <Grid item {...gridBreakpointsFields}>
        <TextField
          id="owner-search"
          label={t('Owner ID')}
          type="search"
          value={ownerId}
          onChange={(event) => setOwnerId(event.target.value)}
          fullWidth
          InputLabelProps={{
            shrink: true,
          }}
        />
      </Grid>
      <Grid item {...gridBreakpointsFields}>
        <SelectOrderCourier
          inputProps={{ value: source, onChange: handleChangeSource }}
          optionAll
        />
      </Grid>
      <Grid item {...gridBreakpointsFields}>
        <SelectOrderShipment
          typeCourierSearch={source.toString()}
          inputProps={{ value: shipment, onChange: handleChangeShipment }}
          optionAll

        />
      </Grid>
      <Grid item {...gridBreakpointsFields}>
        <DateClose
          name="Start Date"
          value={startDate}
          onChange={setStartDate}
        />
      </Grid>
      <Grid item {...gridBreakpointsFields}>
        <DateClose
          name="End Date"
          value={endDate}
          minValue={startDate}
          onChange={setEndDate}
        />
      </Grid>
    </FilterBar>
  );
};

export const SearchFilters = React.memo(SearchFiltersRaw);
