import { TextFieldProps } from '@material-ui/core';
import React from 'react';
import { CANALS } from '../../const';
import { GeneralSelect } from '../GeneralSelect';

type SelectCanalsTypeProps = {
  inputProps: TextFieldProps;
};

const data = [
  {
    id: CANALS.ONLINE,
    value: 'Online',
  },
  {
    id: CANALS.OFFLINE,
    value: 'Offline',
  },
];

export const SelectCanals = ({ inputProps = {} }: SelectCanalsTypeProps) => (
  <GeneralSelect
    working={false}
    label="Channels"
    optionAll
    data={data}
    inputProps={inputProps}
  />
);
