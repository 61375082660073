import { Moment } from 'moment';
import { AnyObject } from '../../../../../types/types';
import { endDateFormatUtc, startDateFormatUtc } from '../../../../../utils';

type MakeFilterCollectOrderProps = {
  search?: string;
  startDate?: Moment | null;
  endDate?: Moment | null;
  orderId?: string;
};

export const makeFilterCollectOrder = ({
  search,
  startDate,
  endDate,
  orderId,
}: MakeFilterCollectOrderProps) => {
  let filter: AnyObject = {};

  if (search) {
    filter = search.split(' ').reduce((accumulator: AnyObject, item, index) => {
      accumulator[index] = [
        {
          path: 'dispatch.items.order.buyer.first_name',
          value: search,
          method: 'includes',
        },
        {
          path: 'dispatch.items.order.so_number',
          value: search,
          method: 'includes',
        },
        {
          path: 'dispatch.items.order.buyer.first_name',
          value: search,
          method: 'includes',
        },
        {
          path: 'dispatch.items.order.buyer.last_name',
          value: search,
          method: 'includes',
        },
        {
          path: 'dispatch.items.order.state.name',
          value: search,
          method: 'includes',
        },
        {
          path: 'dispatch.items.order.order_id',
          value: parseInt(search, 10),
          method: 'includes',
        },
      ];

      const numberSearch = parseInt(search, 10);
      accumulator[index].concat([
        {
          path: 'dispatch.items.order.total',
          value: numberSearch,
        },
        {
          path: 'dispatch.items.order.order_id',
          value: numberSearch,
        },
      ]);

      return accumulator;
    }, {});
  }

  if (orderId) {
    filter.order_id = [
      {
        path: 'dispatch.items.order.order_id',
        value: parseInt(orderId, 10),
      },
    ];
  }

  if (startDate) {
    filter.startDate = [
      {
        path: 'dispatch.items.order.created_at',
        value: startDateFormatUtc(startDate),
        method: 'higherOrEquals',
      },
    ];
  }
  if (endDate) {
    filter.endDate = [
      {
        path: 'dispatch.items.order.created_at',
        value: endDateFormatUtc(endDate),
        method: 'lowerOrEquals',
      },
    ];
  }
  return filter;
};
