import LogRocket from 'logrocket';
import { LOGROCKET_PROJECT } from '../../config';

export type LogRocketUser = {
  id: string | number,
  username: string,
  groups: any[],
};

class LogRocketService {
  private enabled: boolean = false;

  initialize() {
    if (LOGROCKET_PROJECT) {
      LogRocket.init(LOGROCKET_PROJECT);
      this.enabled = true;
    }
  }

  setUser(user: LogRocketUser) {
    if (this.enabled) {
      LogRocket.identify(user.id.toString(), {
        name: user.username,
        groups: JSON.stringify(user.groups),
      });
    }
  }
}

const instance = new LogRocketService();
export { instance as LogRocketService };
