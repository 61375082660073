import { LibbyFetchDAO } from './LibbyFetchDAO';

export class OrderShipmentDAO extends LibbyFetchDAO {
  constructor() {
    super('ster_order_shipment', 'order_shipment_id');
  }

  getAll() {
    return this.query()
      .limit(0, 10000)
      .run();
  }
}
