// Material helpers
import { createMuiTheme } from '@material-ui/core/styles';

import palette from './palette';
import typography from './typography';
import overrides from './overrides';

const theme = createMuiTheme({
  palette,
  typography,
  overrides,
  breakpoints: {
    values: {
      xs: 321,
      sm: 376,
      md: 769, // mobile/tablet
      lg: 1281, // web
      xl: 1921,
    },
  },
  zIndex: {
    appBar: 1200,
    drawer: 1100,
  },
});

export * from './styles';
export default theme;
