import React, { useCallback } from 'react';
import { DatabaseConnector } from '@phinxlab/libby-rest-web';
import {
  Switch,
  Route,
  useRouteMatch,
  useLocation,
  useHistory,
} from 'react-router-dom';
import { LibbyObject } from '../../types/types';
import { useCollectLogic, useInitFilterCollect } from './hook';
import { CollectList } from './routes/CollectList';
import {
  useBreadcrumbs,
  useGlobalContext,
  useFilerParamsId,
} from '../../hooks';
import { userInfoType, subFilter } from '../../types';
import { CollectOrder } from './routes/CollectOrder';
import { CollectDetail } from './routes/CollectDetail';
import { CollectStarted } from './routes/CollectStarted';
import { TitleBreadcrumbs } from '../../interfaces';
import { CollectState } from '../../const';
import { RowsType } from '../../utils/tables';

type CollectionProps = {
  libby: LibbyObject;
  titleProp?: TitleBreadcrumbs[];
};

export const initState: Array<subFilter> = [
  { path: 'collectState.collect_state_id', value: CollectState.NOT_STARTED },
  { path: 'collectState.collect_state_id', value: CollectState.COLLECTING },
];

export const CollectionRaw = ({ libby, titleProp }: CollectionProps) => {
  const { state } = useLocation<{ goBack: TitleBreadcrumbs[] }>();
  const { path } = useRouteMatch();
  const {
    userInfo,
  }: {
    userInfo: userInfoType;
  } = useGlobalContext();

  const { initFilter, dispatchId } = useInitFilterCollect();
  const {
    filter,
    orderBy,
    direction,
    paramsFetch,
    setFilter,
    handleRequestSort,
  } = useFilerParamsId({
    orderInit: 'collect_id',
    daoName: 'ster_dispatch_collect',
    aspect: 'limit_collect_item',
    init: { state: initState, ...initFilter },
  });

  const title = useBreadcrumbs(
    'Collects',
    state && state?.goBack?.length ? state.goBack : titleProp,
  );

  const history = useHistory();

  const goToDetails = useCallback(
    (row: RowsType) => {
      history.push({
        pathname: dispatchId
          ? `/dispatches/detail/${dispatchId}/collects/detail/${row.collect_id}`
          : `${path}/detail/${row.collect_id}`,
        state: {
          goBack: title,
        },
      });
    },
    [dispatchId, history, title, path],
  );

  const {
    columns,
    rows,
    working,
    fetchMore,
    updateData,
    handleOnOpenCollectModal,
    rolAdminLogistics,
  } = useCollectLogic({
    libby,
    userInfo,
    dispatchId,
    paramsFetch,
    goToDetails,
  });

  return (
    <Switch>
      <Route
        exact
        path={[
          `${path}/detail/:id`,
          '/dispatches/detail/:dispatchId/collects/detail/:id',
        ]}
      >
        <CollectDetail
          title={title}
          path={path}
          rolAdminLogistics={!!rolAdminLogistics}
          handleUpdateCollectList={updateData}
        />
      </Route>
      <Route
        exact
        path={[
          `${path}/add-orders/:id`,
          '/dispatches/detail/:dispatchId/collects/detail/:id/add-orders',
        ]}
      >
        <CollectOrder title={title} updateData={updateData} path={path} />
      </Route>
      <Route exact path={`${path}/collect-started/:id`}>
        <CollectStarted updateData={updateData} />
      </Route>
      <Route
        exact
        path={['/collect', '/dispatches/detail/:dispatchId/collects']}
      >
        <CollectList
          filter={filter}
          title={title}
          setFilter={setFilter}
          rows={rows}
          columns={columns}
          working={working}
          fetchMore={fetchMore}
          direction={direction}
          orderBy={orderBy}
          onSortChange={handleRequestSort}
          handleOnOpenCollectModal={handleOnOpenCollectModal}
          dispatchId={dispatchId}
          goToDetails={goToDetails}
        />
      </Route>
    </Switch>
  );
};

export const Collection = DatabaseConnector(CollectionRaw)(
  'ster_dispatch_collect',
  'ster_dispatch_collect_close',
  'ster_dispatch_collect_details',
);
