import React, { useState, useMemo, useEffect } from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import { DatabaseConnector } from '@phinxlab/libby-rest-web';
import { MenuDrawer } from '../MenuDrawer';

import GlobalContext from '../MenuDrawer/context';
import { Routes } from '../../routes';
import { AppNavBar } from './components/AppNavBar';
import { LoadingModal } from '../LoadingModal';
import { DatabaseStore } from '../../platform/libby/DatabaseStore';
import { SessionChangeEmitter } from '../../platform/libby/session';
import './style.css';
import { LibbyObject } from '../../types/types';
import { makeFilter } from '../../routes/Accounts/routes/AccountsList/utils/makeFilter';
import { userInfoType } from '../../types';
import { LogRocketService, LogRocketUser } from '../../services/logRocket';

// Initialize Libby
DatabaseStore.init();

// TODO: mejorar el preflight y esta APP
export const AppRaw = ({ libby }: LibbyObject) => {
  const [userInfo, setUserInfo] = useState<userInfoType | null>(null);
  const [isOpen, set] = useState(false);
  const [isLogged, setIsLogged] = useState(false);
  const [preflightDone, setPreflightDone] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [userInfoFilling, setUserInfoFilling] = useState(false);

  const provider = useMemo(
    () => ({
      isOpen,
      set,
      isLogged,
      setIsLogged,
      userInfo,
      setUserInfo,
      isLoading,
      setIsLoading,
    }),
    [
      isOpen,
      set,
      isLogged,
      setIsLogged,
      userInfo,
      setUserInfo,
      isLoading,
      setIsLoading,
    ],
  );

  // Setting listener for login success
  useEffect(() => {
    const initDataUser = async (user: userInfoType, libbyIsLogged: boolean) => {
      try {
        const [data] = await libby.admin_account.fetch({
          filter: makeFilter({
            account_id: user?.id?.length ? user?.id.toString() : '-1',
          }),
          daoName: 'admin_account',

          orderBy: 'role.account_role_menu.menu.menu_id',
        });
        setUserInfo((prev: userInfoType | null) => ({
          ...prev,
          menu: data.role.account_role_menu,
          data: true,
          actions: data.role.menu_action_account_role,
        }));
        setIsLogged(libbyIsLogged);
      } catch (error) {
        console.log('error', error);
      }
      setPreflightDone(true);
    };

    const unsubscribe = SessionChangeEmitter.subscribe(
      ({
        isLogged: libbyIsLogged,
        user,
        state,
      }: {
        isLogged: boolean;
        user: userInfoType;
        state: string;
      }) => {
        if (
          (!preflightDone || isLogged !== libbyIsLogged)
          && state !== 'Working'
        ) {
          setUserInfo(user);
          if (user?.id?.length && user?.id !== '-1' && !userInfoFilling) {
            setUserInfoFilling(true);
            initDataUser(user, libbyIsLogged);
            LogRocketService.setUser(user as LogRocketUser);
          } else if (!userInfoFilling || (userInfoFilling && !user?.id)) {
            setIsLogged(libbyIsLogged);
            setPreflightDone(true);
            if (userInfoFilling) {
              setUserInfoFilling(false);
            }
          }
        }
      },
    );
    return () => unsubscribe();
  }, [preflightDone, isLogged, libby, userInfoFilling]);

  return (
    <GlobalContext.Provider value={provider}>
      <CssBaseline />
      {!preflightDone ? (
        <LoadingModal />
      ) : (
        <>
          <MenuDrawer />
          <AppNavBar />
          <Routes isLogged={isLogged} />
          {isLoading && <LoadingModal />}
        </>
      )}
    </GlobalContext.Provider>
  );
};

export const App = DatabaseConnector(AppRaw)('admin_account');
