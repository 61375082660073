import React, { useState, useMemo } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { DatabaseConnector } from '@phinxlab/libby-rest-web';
import { SearchFilters } from './components/SearchFilters';
import LoadingData from '../../../components/LoadingData';
import { useLibbyFetch } from '../../../../hooks';
import { useTranslation } from '../../../../services/translation';
import { AnyObject, LibbyObject } from '../../../../types/types';
import { useMakeCityTable } from './hooks/useMakeCityTable';
import InfoTable from '../../../components/InfoTable';
import { ScreenAligned } from '../../../../components/ScreenAligned/ScreenAligned';
import { TitleBreadcrumbs } from '../../../../interfaces';

type CitiesListProps = {
  libby: AnyObject;
  title: TitleBreadcrumbs[];
};

const CitiesListRaw = ({ libby, title }: CitiesListProps) => {
  const { t } = useTranslation();
  const { path } = useRouteMatch();
  const history = useHistory();
  const [direction, setDirection] = React.useState<'asc'|'desc'>('asc');
  const [orderBy, setOrderBy] = React.useState('city_id');

  const [filter, setFilter] = useState<AnyObject>({});
  const [resetFilter, setResetFilter] = useState<boolean>(false);

  const handleRequestSort = (newOrderBy: string, newDirection: 'asc' | 'desc') => {
    setOrderBy(newOrderBy);
    setDirection(newDirection);
  };

  const paramsFetch = useMemo(() => ({
    daoName: 'ster_city',
    filter,
    orderBy,
    direction,
  }), [filter, orderBy, direction]);
  const {
    data, working, reFetch, fetchMore,
  } = useLibbyFetch(libby, paramsFetch);

  const {
    columns,
    rows,
    CityButtons,
  } = useMakeCityTable(data, libby, reFetch);

  const goToDetails = (row: LibbyObject) => {
    history.push(`${path}/detail/${row.city_id}`);
  };

  return (
    <ScreenAligned title={title} additionalTitle={CityButtons}>
      <SearchFilters
        resetFilter={resetFilter}
        setResetFilter={setResetFilter}
        onFilterChange={setFilter}
        working={working}
      />
      <InfoTable
        columns={columns}
        rows={rows}
        onBottomScroll={fetchMore}
        onSortChange={handleRequestSort}
        orderBy={orderBy}
        onRowClick={goToDetails}
        direction={direction}
        rowIdKey="city_id"
      />
      <LoadingData
        label={`${t('Loaded registers')}`}
        working={working}
        cant={data.length}
      />
    </ScreenAligned>
  );
};

export const CitiesList = DatabaseConnector(CitiesListRaw)('ster_city');
