import React, { useCallback } from 'react';
import { ThemeProvider } from '@material-ui/styles';
import {
  Dialog,
  DialogActions,
  DialogContent,
  TextField,
  Typography,
  Button, MenuItem, Checkbox, FormControlLabel,
} from '@material-ui/core';
import { useField, useForm } from 'react-final-form-hooks';
import { useTranslation } from '../../../../../services/translation';
import {
  makeGetErrorAndHelperText,
} from '../../../../../utils/materialHelpers';
import { useModalFormStyles } from '../../../../../theme';
import { FormProps } from '../../../../../types';
import { ResetForm } from '../../../../../components/ResetForm';
import { stockLocations } from '../../../../../commons/constants';

interface Location {
  id: string;
  name: string;
}

export const ReceivingConfirmationForm = ({
  open = false,
  onCancel = () => {},
  onConfirm = () => {},
  title = 'Confirmation Dialog',
  cancelText = 'Cancel',
  confirmText = 'confirmText',
  customTheme = null,
  validate,
  formInitialValues = {},
}: FormProps) => {
  const { t } = useTranslation();
  const styles = useModalFormStyles();
  const getErrorAndHelperText = makeGetErrorAndHelperText(t);

  const onSubmit = useCallback(async ({ location, rma }) => onConfirm({ location, rma }), [
    onConfirm,
  ]);

  const { form, handleSubmit, submitError } = useForm({
    initialValues: formInitialValues,
    onSubmit,
    validate,
  });
  const location = useField('location', form);
  const rma = useField('rma', form);

  const dialog = (
    <Dialog
      open={open}
      onClose={onCancel}
      fullWidth
      aria-labelledby="form-dialog-title"
    >
      <DialogContent className={styles.title}>
        <Typography variant="h3">{title}</Typography>
      </DialogContent>
      <form noValidate onSubmit={handleSubmit}>
        <DialogContent className={styles.content}>
          <TextField
            id="location"
            select
            fullWidth
            InputLabelProps={{
              shrink: true,
              ...getErrorAndHelperText(location),
            }}
            label={t('Depot')}
            onChange={location.input.onChange}
            value={location.input.value}
          >
            {
            stockLocations.map((item: Location) => (
              <MenuItem key={item.id} value={item.name}>
                {item.name}
              </MenuItem>
            ))
}
          </TextField>
          <FormControlLabel
            control={(
              <Checkbox
                color="primary"
                id={rma.input.name}
                checked={rma.input.value}
                {...rma.input}
              />
              )}
            label={t('RMA')}
          />
          {submitError && (
            <Typography
              style={{ display: 'block' }}
              variant="overline"
              color="error"
            >
              {t(submitError)}
            </Typography>
          )}
        </DialogContent>
        <DialogActions>
          <ResetForm form={form} open={open} />
          <Button
            onClick={() => {
              onCancel();
            }}
          >
            {cancelText}
          </Button>
          <Button color="primary" type="submit">
            {confirmText}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );

  return !customTheme ? (
    dialog
  ) : (
    <ThemeProvider theme={customTheme}>{dialog}</ThemeProvider>
  );
};
