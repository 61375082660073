import React, { useMemo } from 'react';
import { DatabaseConnector } from '@phinxlab/libby-rest-web';
import { GeneralSelect } from '../../../../components/GeneralSelect';
import { useLibbyFetch } from '../../../../hooks';
import { AnyObject } from '../../../../types/types';

type SelectImportTypeProps = {
  inputProps: AnyObject,
  optionAll?: boolean,
  libby: AnyObject,
  [k: string]: any,
};

const paramsFetch = {
  daoName: 'ster_state',
};

export const SelectStateRaw = ({
  libby, inputProps = {}, optionAll = false, ...styles
}: SelectImportTypeProps) => {
  const {
    data: dataRaw, working,
  }: { data: AnyObject, working: boolean } = useLibbyFetch(libby, paramsFetch);

  const data = useMemo(() => dataRaw.map(({ state_id, name }: AnyObject) => ({
    id: state_id, value: name,
  })), [dataRaw]);

  const capitalizeFirstLetter = (string: string) => string.charAt(0).toUpperCase() + string.slice(1);

  data.sort((a: any, b: any) => ((capitalizeFirstLetter(a.value) > capitalizeFirstLetter(b.value)) ? 1 : ((capitalizeFirstLetter(b.value) > capitalizeFirstLetter(a.value)) ? -1 : 0)));

  return (
    <GeneralSelect working={working} label="Province" optionAll={optionAll} data={data} inputProps={inputProps} {...styles} />
  );
};

export const SelectState = React.memo(DatabaseConnector(SelectStateRaw)('ster_state'));
