import React, {
  useMemo, useState, useCallback, useEffect,
} from 'react';
import { DatabaseConnector } from '@phinxlab/libby-rest-web';
import { orderBy } from 'lodash';
import {
  Box,
  Card,
  Grid,
  Theme,
  Tooltip,
  Typography,
  useMediaQuery,
} from '@material-ui/core';
import moment from 'moment';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import { Screen } from '../../../../components/Screen';
import InfoTable, { Column } from '../../../components/InfoTable';
import { useTranslation } from '../../../../services/translation';
import { useLibbyCall } from '../../../../hooks';
import { LibbyObject } from '../../../../types/types';
import { ScreenTitle } from '../../../../components/ScreenTitle';
import { ChartPie } from '../../../../chart/ChartPie';
import { format } from '../../../../util';
import {
  FilterBar,
  FilterBarSelection,
} from '../../../Reporting/routes/ReportingList/FilterBar';
import { BarChartComponent } from '../../../../components/BarChart';
import { HistoryMarketplace } from './components/history';

export type ReportingSalesListProps = { libby: LibbyObject };

type AggregateRow = {
  [k: string]: any;
};

type TypeColumn = {
  name: string;
  total: number;
  count: number;
  id: string;
};

const filterInit: FilterBarSelection = {
  marketplaces: [],
  courier: [],
  from: moment().toDate(),
  to: moment().add('1', 'days').toDate(),
  channelValue: '0',
};

const ReportingSalesListRaw = ({ libby }: ReportingSalesListProps) => {
  const { t } = useTranslation();

  const [direction, setDirection] = useState<'asc' | 'desc'>('desc');

  const [directionProduct, setDirectionProduct] = useState<'asc' | 'desc'>('desc');

  const isXs = useMediaQuery((theme: Theme) => theme.breakpoints.up('xs'));

  const isSm = useMediaQuery((theme: Theme) => theme.breakpoints.up('sm'));

  const isMd = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));

  // Build the columns, depends on t function for translations
  const columns = useMemo<Array<Column>>(
    () => [
      {
        id: 'name',
        label: 'Marketplace',
        style: {
          whiteSpace: 'nowrap',
          width: '150px',
        },
        render: (value: TypeColumn) => (
          <Box fontWeight={value.name === 'Total' ? 'bold' : 'regular'}>
            <Typography noWrap variant="body1" color="textSecondary">
              {t(value.name)}
            </Typography>
          </Box>
        ),
        noSort: true,
      },
      {
        id: 'count',
        label: 'Count',
        render: (value: TypeColumn) => (value.name === 'Total' ? (
          <b>{format(value.count, 'Integer')}</b>
        ) : (
          format(value.count, 'Integer')
        )),
        align: 'right',
        noSort: true,
      },
      {
        id: 'total',
        label: 'Total',
        render: (value: TypeColumn) => (value.name === 'Total' ? (
          <b>{format(value.total, 'Currency', t)}</b>
        ) : (
          format(value.total, 'Currency', t)
        )),
        align: 'right',
      },
    ],
    [t],
  );

  const columnsProducts: Array<Column> = [
    {
      id: 'name',
      label: 'Products',
      style: {
        whiteSpace: 'nowrap',
      },
      render: (
        value: TypeColumn,
        item: TypeColumn,
        tr: (str: string) => string,
      ) => (
        <Tooltip title={`${tr(value.name)}`}>
          <Box
            fontWeight={value.name === 'Total' ? 'bold' : 'regular'}
            maxWidth={isMd ? 400 : 100}
            minWidth={100}
          >
            <Typography noWrap variant="body1" color="textSecondary">
              {tr(value.name)}
            </Typography>
          </Box>
        </Tooltip>
      ),
      noSort: true,
    },
    {
      id: 'count',
      label: 'Count',
      render: (value: TypeColumn) => (value.name === 'Total' ? (
        <b>{format(value.count, 'Integer')}</b>
      ) : (
        format(value.count, 'Integer')
      )),
      align: 'right',
      noSort: true,
    },
    {
      id: 'total',
      label: 'Total',
      render: (
        value: TypeColumn,
        item: TypeColumn,
        tr: (str: string) => string,
      ) => (value.name === 'Total' ? (
        <b>{format(value.total, 'Currency', tr)}</b>
      ) : (
        format(value.total, 'Currency', tr)
      )),
      align: 'right',
    },
  ];

  // eslint-disable-next-line arrow-body-style

  const {
    data: dataGroup,
    recall,
    working = true,
  } = useLibbyCall(libby, {
    daoName: 'ster_order_reporting_sales_marketplace',
    methodName: 'getByDate',
    params: [filterInit],
  });

  // FIXME CHINO-SDK IS NOT TAKING THE ALIAS, REMOVE THIS WHEN IS OK

  const { data: allDataMarketplace } = useLibbyCall(libby, {
    daoName: 'ster_marketplace',
    methodName: 'getAll',
  });

  const searchMarketplaceName = useCallback(
    (marketplace_id_search: string) => allDataMarketplace.find(
      (dataMarketplace: any) => dataMarketplace.marketplace_id === marketplace_id_search,
    ).name,
    [allDataMarketplace],
  );

  const statistics = useMemo(
    () => dataGroup.reduce((result: any[], element: AggregateRow) => {
      if (
        element.source_marketplace_marketplace_id
          && allDataMarketplace.length
      ) {
        result.push({
          count: parseInt(
            element.countofsource_marketplace_marketplace_id,
            10,
          ),
          total: parseFloat(element.sumofamount),
          name: searchMarketplaceName(
            element.source_marketplace_marketplace_id,
          ),
          id: element.source_marketplace_marketplace_id,
        });
      }
      return result;
    }, []),
    [dataGroup, allDataMarketplace, searchMarketplaceName],
  );
  const {
    data: dataGroupProducts,
    recall: recallProducts,
    working: workingProducts = true,
  } = useLibbyCall(libby, {
    daoName: 'ster_order_items_sku',
    methodName: 'getByDate',
    params: [filterInit],
  });

  const statisticsProducts: TypeColumn[] = useMemo(
    () => dataGroupProducts.reduce((result: any[], element: AggregateRow) => {
      if (element.namep) {
        result.push({
          count: parseInt(element.countofitems_name, 10),
          total: parseFloat(element.sumofamount),
          name: element.namep,
        });
      }
      return result;
    }, []),
    [dataGroupProducts],
  );

  const withOrder = useMemo(() => {
    const copyStatistics: Array<any> = [...statistics];
    copyStatistics.push(
      copyStatistics.reduce(
        (transport: AggregateRow, element: AggregateRow) => {
          transport.count += element.count;
          transport.total += element.total;
          return transport;
        },
        { name: 'Total', count: 0, total: 0 },
      ),
    );

    const all = copyStatistics.pop();
    const rowsOrderBy = orderBy(
      copyStatistics,
      ['total', 'count'],
      [direction],
    );
    rowsOrderBy.push(all);
    return rowsOrderBy;
  }, [statistics, direction]);

  const statisticsWithTotal = useMemo(
    () => statistics.reduce(
      (transport: AggregateRow, element: AggregateRow) => {
        transport.count += element.count;
        transport.total += element.total;
        return transport;
      },
      { count: 0, total: 0 },
    ),
    [statistics],
  );

  const withOrderProducts = useMemo(() => {
    const newArray: Array<any> = [...statisticsProducts];
    newArray.push(
      newArray.reduce(
        (transport: AggregateRow, element: AggregateRow) => {
          transport.count += element.count;
          transport.total += element.total;
          return transport;
        },
        { name: 'Total', count: 0, total: 0 },
      ),
    );

    const all = newArray.pop();
    const rowsOrderBy = orderBy(
      newArray,
      ['total', 'count'],
      [directionProduct],
    );
    rowsOrderBy.push(all);
    return rowsOrderBy;
  }, [statisticsProducts, directionProduct]);

  const handleRequestSort = (
    newOrderBy: string,
    newDirection: 'asc' | 'desc',
  ) => {
    setDirection(newDirection);
  };

  const handleRequestProductSort = (
    newOrderBy: string,
    newDirection: 'asc' | 'desc',
  ) => {
    setDirectionProduct(newDirection);
  };

  const [filter, setFilter] = React.useState<FilterBarSelection>(filterInit);

  useEffect(() => {
    recall?.(filter);
    recallProducts?.(filter);
  }, [filter, recall, recallProducts]);

  const onChangeMarketplace = useCallback(
    (data: TypeColumn) => {
      const newFilter: FilterBarSelection = {
        ...filter,
        marketplaces: [
          {
            marketplace_id: data.id,
            name: data.name,
          },
        ],
      };
      setFilter(newFilter);
    },
    [filter],
  );

  return (
    <Screen>
      <ScreenTitle title="Sales" />
      <Grid container direction="row" justify="center" alignItems="center">
        <FilterBar
          canalEnable
          filter={filter}
          onFilter={(barFilter: FilterBarSelection) => {
            setFilter(barFilter);
          }}
          initExpanded
        />

        <Box my={2} display="flex" width="100%">
          <Grid container xs={12} justify="space-between">
            <Grid item md={6} sm={12} xs={12}>
              <Box my={isMd ? 2 : 1} mx={isMd ? 2 : 1}>
                <Card>
                  <Box
                    display="flex"
                    flexDirection="row"
                    p={isMd ? 3 : 1}
                    bgcolor="#fff1f4"
                    alignItems="center"
                  >
                    <Box display="flex" flexDirection="column">
                      <Box height="100%" width="100%" mb={1}>
                        <Typography
                          variant={isMd ? 'subtitle2' : 'body2'}
                          color="primary"
                        >
                          {t('Amount sales').toUpperCase()}
                        </Typography>
                      </Box>

                      <Box height="100%" width="100%">
                        <Typography
                          variant={isMd ? 'h2' : 'h4'}
                          color="primary"
                        >
                          {statisticsWithTotal.count}
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                </Card>
              </Box>
            </Grid>

            <Grid item md={6} sm={12} xs={12}>
              <Box my={isMd ? 2 : 1} mx={isMd ? 2 : 1}>
                <Card>
                  <Box
                    display="flex"
                    flexDirection="row"
                    p={isMd ? 3 : 1}
                    bgcolor="#fff1f4"
                    alignItems="center"
                  >
                    <Box
                      borderRadius="50%"
                      borderColor="primary.main"
                      border={1}
                      mr={isMd ? 2 : 1}
                    >
                      <AttachMoneyIcon
                        style={{ fontSize: 50 }}
                        color="primary"
                      />
                    </Box>
                    <Box display="flex" flexDirection="column">
                      <Box height="100%" width="100%" mb={1}>
                        <Typography
                          variant={isMd ? 'subtitle2' : 'body2'}
                          color="primary"
                        >
                          {t('Total sales').toUpperCase()}
                        </Typography>
                      </Box>
                      <Box height="100%" width="100%">
                        <Typography
                          variant={isMd ? 'h2' : 'h4'}
                          color="primary"
                        >
                          {format(statisticsWithTotal.total, 'Currency', t)}
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                </Card>
              </Box>
            </Grid>
          </Grid>
        </Box>

        <Grid container xs={12} spacing={isSm ? 4 : 2}>
          <Grid item lg={4} md={6} sm={12} xs={12}>
            <Box
              bgcolor="white"
              p={isXs ? 2 : 4}
              height="100%"
              justifyContent="center"
            >
              <Typography variant="h5">{t('Quantity')}</Typography>
              <ChartPie
                data={statistics}
                working={working}
                type="Pie"
                key="source_marketplace_name"
                value="count"
                height={300}
                format="Integer"
                xs={12}
                onChange={onChangeMarketplace}
              />
            </Box>
          </Grid>

          <Grid item lg={4} md={6} sm={12} xs={12}>
            <Box
              bgcolor="white"
              p={isXs ? 2 : 4}
              height="100%"
              justifyContent="center"
            >
              <Box height="10%">
                <Typography variant="h5">{t('Sales')}</Typography>
              </Box>
              <Box
                display="flex"
                height="90%"
                justifyContent="center"
                alignItems="center"
                py={2}
              >
                <BarChartComponent
                  working={working}
                  dataChart={orderBy(statistics, ['total', 'count'], ['desc'])}
                  onChange={onChangeMarketplace}
                />
              </Box>
            </Box>
          </Grid>

          <Grid item lg={4} md={6} sm={12} xs={12}>
            <Box bgcolor="white" p={isXs ? 2 : 4} height="100%">
              <Box height="10%">
                <Typography variant="h5">{t('Top 10 products')}</Typography>
              </Box>
              <Box
                display="flex"
                height="90%"
                justifyContent="center"
                alignItems="center"
                py={2}
              >
                <BarChartComponent
                  working={workingProducts}
                  dataChart={orderBy(
                    statisticsProducts.slice(0, 10),
                    ['total', 'count'],
                    ['desc'],
                  )}
                />
              </Box>
            </Box>
          </Grid>
        </Grid>

        <HistoryMarketplace libby={libby} filter={filter} />
        <Box my={2} display="flex" width="100%">
          <Grid
            container
            direction="row"
            justify="space-between"
            alignItems="flex-start"
            spacing={4}
          >
            <InfoTable
              padding={false}
              columns={columns}
              rows={withOrder}
              onBottomScroll={() => {}}
              onSortChange={handleRequestSort}
              direction={direction}
              rowIdKey="name"
              height="auto"
              md={6}
              sm={12}
              xs={12}
              working={working}
            />

            <InfoTable
              padding={false}
              columns={columnsProducts}
              rows={withOrderProducts}
              onBottomScroll={() => {}}
              onSortChange={handleRequestProductSort}
              direction={directionProduct}
              rowIdKey="name"
              height="auto"
              md={6}
              sm={12}
              xs={12}
              working={workingProducts}
            />
          </Grid>
        </Box>
      </Grid>
    </Screen>
  );
};

// El dao a usar debe de heredar de LibbyFetchDAO para que funcione
export const ReportingSalesList = DatabaseConnector(ReportingSalesListRaw)(
  'ster_order_table',
  'ster_order_so',
  'ster_order_table_update',
  'ster_order_history_marketplace',
  'ster_order_items_sku',
  'ster_marketplace',
  'ster_order_reporting_sales_marketplace',
);
