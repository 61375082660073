import React from 'react';
import LaunchIcon from '@material-ui/icons/Launch';
import { MakeCell } from '../../routes/components/MakeCell';
import { handleOpenItemsModal } from '../../routes/Orders/routes/OrdersList/hook/useOrderTableLogic';

export const LaunchIconComponent = ({
  order_id,
  number_items,
}: {
  order_id?: string;
  number_items?: string | number | null | undefined;
}) => (
  <MakeCell
    label={String(number_items)}
    icon={LaunchIcon}
    onClick={(e) => handleOpenItemsModal(e, order_id || '')}
  />
);
