import React, { useRef, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { Button, Grid } from '@material-ui/core';
import { ScreenAligned } from '../../../../components/ScreenAligned/ScreenAligned';
import { useTranslation } from '../../../../services/translation';
import LoadingData from '../../../components/LoadingData';
import { RowsType } from '../../../../utils/tables';
import { TitleBreadcrumbs } from '../../../../interfaces';
import { HeadCellsType, Filter } from '../../../../types';
import InfoTable from '../../../components/InfoTable';
import { useBreadcrumbs } from '../../../../hooks';
import { CollectListMobile } from './components/CollectListMobile';
import { useContainerDimensions } from '../../../../hooks/useContainerDimensions';
import { SearchFilters } from './components/SearchFilters';

type CollectListProps = {
  rows: Array<RowsType>;
  working: boolean;
  title: TitleBreadcrumbs[];
  columns: Array<HeadCellsType>;
  fetchMore: () => void;
  onSortChange?: (orderBy: string, direction: 'asc' | 'desc') => void;
  orderBy?: string;
  direction?: 'asc' | 'desc';
  handleOnOpenCollectModal: (
    k?: { dispatch: { dispatch_id?: string } },
    dispatch?: boolean,
    title?: TitleBreadcrumbs[]
  ) => void;
  dispatchId?: string;
  filter: Filter;
  setFilter: (makeFilter: object) => void;
  goToDetails: (row: RowsType) => void;
};

export const CollectList = ({
  rows,
  working,
  title,
  columns,
  fetchMore,
  onSortChange,
  orderBy,
  direction,
  handleOnOpenCollectModal,
  dispatchId,
  filter,
  setFilter,
  goToDetails,
}: CollectListProps) => {
  const { state } = useLocation<{ goBack: TitleBreadcrumbs[] }>();
  const { t } = useTranslation();
  const componentRef = useRef<HTMLInputElement>(null);
  const { width } = useContainerDimensions(componentRef);

  const titleCollect = useBreadcrumbs(
    'Collects',
    state?.goBack?.length ? state.goBack : title,
  );

  const mobileList = useMemo(() => width < 600, [width]);
  const titleSend = state?.goBack?.length ? titleCollect : title;
  return (
    <ScreenAligned
      title={titleSend}
      additionalTitle={(
        <Grid container justify="flex-end">
          <Button
            type="reset"
            variant="contained"
            onClick={() => handleOnOpenCollectModal(
              {
                dispatch: { dispatch_id: dispatchId },
              },
              !!dispatchId,
              titleSend,
            )}
            color="primary"
          >
            {t('Create')}
          </Button>
        </Grid>
      )}
    >
      <div ref={componentRef}>
        <SearchFilters onFilterChange={setFilter} initFetch={filter} />
        {rows.length > 0 && width > 0 ? (
          <>
            <InfoTable
              columns={columns}
              rows={rows}
              onBottomScroll={fetchMore}
              rowIdKey="collect_id"
              onRowClick={goToDetails}
              onSortChange={onSortChange}
              orderBy={orderBy}
              direction={direction}
              RowComponent={CollectListMobile}
              rowComponentShow={mobileList}
              rootBackground={!mobileList}
              elevation={!mobileList}
            />
          </>
        ) : (
          !working && (
            <Grid container justify="center">
              <h2>{t('No collects pending')}</h2>
            </Grid>
          )
        )}

        {(working || !!rows.length) && (
          <LoadingData
            label={`${t('Loaded registers')}`}
            working={working}
            cant={rows.length}
          />
        )}
      </div>
    </ScreenAligned>
  );
};
