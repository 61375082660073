import React, {
  memo,
  useMemo,
  useCallback,
  useRef,
  useState,
  useEffect,
} from 'react';
import { Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import {
  Bar,
  BarChart,
  CartesianGrid,
  Cell,
  XAxis,
  YAxis,
  Tooltip,
} from 'recharts';
import { useTranslation } from '../../../../../services/translation';
import LoadingData from '../../../../components/LoadingData';
import {
  warning as warningColor,
  critical as criticalColor,
  success as successColor,
} from '../utils/colors';
import { makeFilter } from '../utils/makeFilter';
import { useContainerDimensions } from '../../../../../hooks/useContainerDimensions';

const useStyles = makeStyles({
  containerResponsiveMin: {
    width: '100%',
    background: 'white',
    marginBottom: '5%',
  },
  containerResponsive: { flex: 1, width: '100%' },
  title: {
    textTransform: 'uppercase',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '16px',
    lineHeight: '28px',
    marginBottom: '15px',
  },
  barChart: {
    margin: 'auto',
  },
  barChartMin: {
    marginLeft: '-3em',
  },
  container: {
    flex: 1,
    margin: '0 19px 19px 19px',
    backgroundColor: 'white',
    padding: '12px 14px',
    '& div': {
      fontFamily: 'Lato',
      fontSize: '14px',
      fontStyle: 'normal',
      fontWeight: 400,
      lineHeight: '20px',
    },
  },
  customTooltip: {
    backgroundColor: 'white',
    padding: 5,
    boxShadow: '2px 2px 5px #999',
  },
});

interface payloadType {
  payload: {
    earnings: number;
    filter: {
      area: string;
      status: string;
    };
    quarter: number;
  };
}

interface CustomTooltipType {
  active?: boolean;
  payload?: payloadType[];
}

type StateAreaChart = {
  critical: number;
  warning: number;
  ok: number;
  title: string;
  search: string;
  working?: boolean;
  setFilter: (makeFilter: object) => void;
  children?: React.ReactNode;
};

export const StateAreaChart = memo(
  ({
    critical,
    warning,
    ok,
    title,
    search,
    working,
    setFilter,
    children,
  }: StateAreaChart) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const componentRef = useRef<HTMLInputElement>(null);
    const { width } = useContainerDimensions(componentRef);
    const [heightWidthUser, setheightWidthUser] = useState<{
      width: number;
      height?: number;
    }>(useContainerDimensions(componentRef));

    useEffect(() => {
      if (width !== heightWidthUser.width && width !== 0) {
        if (width < 370) {
          setheightWidthUser({ width });
        } else {
          setheightWidthUser({ width: 370 });
        }
      }
    }, [width, setheightWidthUser, heightWidthUser.width]);

    const total = useMemo(
      () => critical + warning + ok,
      [critical, ok, warning],
    );

    const data = useMemo(
      () => [
        {
          quarter: ((ok * 100) / total).toFixed(1),
          earnings: ok,
          filter: {
            area: search,
            status: 'OK',
          },
        },
        {
          quarter: ((warning * 100) / total).toFixed(1),
          earnings: warning,
          filter: {
            area: search,
            status: 'Warning',
          },
        },
        {
          quarter: ((critical * 100) / total).toFixed(1),
          earnings: critical,
          filter: {
            area: search,
            status: 'Critical',
          },
        },
      ],
      [critical, ok, total, warning, search],
    );

    const filter = useCallback(
      (event) => {
        const valuePayload = event.activePayload[0].payload.filter;
        const valueFilter = makeFilter({
          area: valuePayload.area,
          areaStatus: valuePayload.status,
        });
        setFilter({ ...valueFilter });
      },
      [setFilter],
    );

    const CustomTooltip = ({ active, payload }: CustomTooltipType) => {
      if (active && payload?.length) {
        return (
          <div className={classes.customTooltip}>
            <p className="label">
              {`${t('Status')}: ${t(payload[0]?.payload?.filter.status)}`}
            </p>
            <p className="label">
              {`${t('Quantity')}: ${payload[0]?.payload?.earnings}`}
            </p>
            <p className="label">
              {`${t('Percentage')}: ${payload[0]?.payload?.quarter}%`}
            </p>
          </div>
        );
      }
      return null;
    };

    return (
      <div
        ref={componentRef}
        className={
          heightWidthUser.width < 370
            ? classes.containerResponsiveMin
            : classes.containerResponsive
        }
      >
        <Grid className={classes.container}>
          <Typography className={classes.title}>{t(title)}</Typography>
          {!working ? (
            <BarChart
              width={heightWidthUser.width}
              height={241}
              data={data}
              className={
                heightWidthUser.width < 370
                  ? classes.barChartMin
                  : classes.barChart
              }
              onClick={filter}
            >
              <CartesianGrid vertical={false} stroke="#C4C2C4" />
              <YAxis dataKey="quarter" />
              <XAxis dataKey="earnings" />
              <Tooltip content={<CustomTooltip />} />
              <Bar dataKey="quarter">
                <Cell fill={successColor} />
                <Cell fill={warningColor} />
                <Cell fill={criticalColor} />
              </Bar>
            </BarChart>
          ) : (
            <LoadingData working={!!working} />
          )}
        </Grid>
        {children}
      </div>
    );
  },
);
