import React from 'react';
import { JsonEditor as Editor } from 'jsoneditor-react';
import 'jsoneditor-react/es/editor.min.css';
import { AnyObject } from '../../types/types';

export type JsonEditorProps = {
  value: AnyObject,
  onChange?: (value: AnyObject) => void,
  readOnly?: boolean,
};

export const JsonEditor = ({ value, onChange, readOnly = false }: JsonEditorProps) => (
  <Editor
    value={value}
    onChange={onChange}
    mode={readOnly ? 'view' : 'form'}
  />
);
