import React, { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { DatabaseConnector } from '@phinxlab/libby-rest-web';
import { AccountCreateForm } from '../../../Accounts/routes/AddAccount/components/AccountCreateForm';
import { useAddAccountPublicForm } from '../../../Accounts/routes/AddAccount/hooks/useAddAccountPublicForm';
import { ScreenAligned } from '../../../../components/ScreenAligned/ScreenAligned';
import { Account } from '../../../Accounts/types/Account';
import { LibbyObject } from '../../../../types/types';
import { TitleBreadcrumbs } from '../../../../interfaces';
import { useBreadcrumbs } from '../../../../hooks';

type AddAccountPublicProps = { libby: LibbyObject; title: TitleBreadcrumbs[] };

const AddAccountPublicRaw = ({ libby, title }: AddAccountPublicProps) => {
  const history = useHistory();

  const [accountCreated, setAccountCreated] = useState<Account>(null);

  // when libby finish saving the account, push /acounts
  useEffect(() => {
    if (!libby.working && !!accountCreated) {
      history.push('/');
    }
  }, [history, libby.working, accountCreated]);

  const {
    account,
    setAccount,
    handleSubmit,
    onSearchChange,
    username,
    email,
    password,
    submitError,
    duplicateAccount,
    setDuplicateAccount,
    isSearching,
  } = useAddAccountPublicForm({ libby });

  const onCancelClick = useCallback(() => {
    setAccount(null);
    setDuplicateAccount(false);
  }, [setDuplicateAccount, setAccount]);

  const titleShow = useBreadcrumbs('Request user', title);

  return (
    <ScreenAligned title={titleShow}>
      <AccountCreateForm
        libby={libby}
        account={account}
        setAccountCreated={setAccountCreated}
        handleSubmit={handleSubmit}
        onSearchChange={onSearchChange}
        username={username}
        email={email}
        password={password}
        submitError={submitError}
        duplicateAccount={duplicateAccount}
        isSearching={isSearching}
        onCancelClick={onCancelClick}
      />
    </ScreenAligned>
  );
};

export const AddAccountPublic = DatabaseConnector(AddAccountPublicRaw)('public_account');
