import { Order_item } from '../../../interfaces/business/orders/Order_item';
import { Product_component } from '../../../interfaces/business/product/Product_component';

const reducerProduct = (accumulator:number, { quantity }: Product_component) => accumulator + quantity;

export const reducerQuantity = (
  accumulator: number,
  { quantity, products_c }: Order_item,
) => {
  if (products_c?.length) {
    accumulator += products_c.reduce(reducerProduct, 0);
  } else {
    accumulator += quantity;
  }
  return accumulator;
};
