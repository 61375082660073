import React, { useContext, useEffect, useMemo } from 'react';
import { IconButton, Badge, Tooltip } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { DatabaseConnector } from '@phinxlab/libby-rest-web';
import { Notifications } from '@material-ui/icons';
import { makeStyles } from '@material-ui/styles';
import { useLibbyFetch } from '../../hooks';
import { LibbyObject } from '../../types';
import GlobalContext from '../MenuDrawer/context';

const useStyles = makeStyles(() => ({
  root: {
    '& .MuiBadge-badge': {
      top: '15%',
      right: '20%',
    },
  },
}));

const InboxOrderProblemButtonRaw = ({ libby }: {libby: LibbyObject}) => {
  const classes = useStyles();
  const paramsFetch = useMemo(() => ({ daoName: 'ster_inbox_order_custom' }), []);
  const { data, reFetch } = useLibbyFetch(libby, paramsFetch);
  const { isLogged } = useContext(GlobalContext);

  useEffect(() => {
    reFetch();
  }, [isLogged, reFetch]);

  return data && data.length
    ? (
      <Tooltip title={`${data.length} órdenes con problemas`}>
        <Badge className={classes.root} badgeContent={data.length} color="error">
          <IconButton>
            <Link to="/inbox-orders">
              <Notifications fontSize="large" color="primary" />
            </Link>
          </IconButton>
        </Badge>
      </Tooltip>
    ) : <></>;
};

export const InboxOrderProblemButton = DatabaseConnector(InboxOrderProblemButtonRaw)('ster_inbox_order_custom');
