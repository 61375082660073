import React from 'react';
import { Switch, Route, useRouteMatch } from 'react-router-dom';
import { DatabaseConnector } from '@phinxlab/libby-rest-web';
import { TagMangerList } from './routes/TagMangerList';
import { useTagManagerTableLogic } from '../../hooks/useTagManager';
import { LibbyObject } from '../../types/types';
import { TagMangerViewPdf } from './routes/TagManagerViewPdf';
import { useBreadcrumbs } from '../../hooks';

export const TagManagerRouter = ({ libby }: LibbyObject) => {
  const { path } = useRouteMatch();

  const {
    filter,
    rows,
    columns,
    working,
    checked,
    fetchMore,
    direction,
    orderBy,
    handleRequestSort,
    setFilter,
    savePrint,
    resetCheck,
    updateDataWithProducts,
    updateDataField,
  } = useTagManagerTableLogic(libby, path);

  const onChangeFilter = (value: object) => {
    if (JSON.stringify(filter) !== JSON.stringify(value)) {
      setFilter(value);
    }
  };

  const title = useBreadcrumbs('Shipping');
  return (
    <Switch>
      <Route path={`${path}/pdf`}>
        <TagMangerViewPdf
          title={title}
          dataChecked={checked}
          savePrint={savePrint}
          resetCheck={resetCheck}
        />
      </Route>
      <Route path={`${path}`}>
        <TagMangerList
          title={title}
          working={working}
          rows={rows}
          filter={filter}
          columns={columns}
          checked={checked}
          direction={direction}
          orderBy={orderBy}
          onSortChange={handleRequestSort}
          setFilter={onChangeFilter}
          fetchMore={fetchMore}
          updateDataWithProducts={updateDataWithProducts}
          updateDataField={updateDataField}
        />
      </Route>
    </Switch>
  );
};

export const TagManager = DatabaseConnector(TagManagerRouter)(
  'ster_order_so',
  'ster_order_print',
);
