import { CommonOrderColumns } from './CommonOrderColumns';
import { ReportOrderColumns } from './ReportOrderColumns';

export const columnsOrder = [
  CommonOrderColumns.orderId,
  CommonOrderColumns.soNumber,
  CommonOrderColumns.orderState,
  CommonOrderColumns.ownerId,
  CommonOrderColumns.marketplace,
  CommonOrderColumns.buyer,
  CommonOrderColumns.document,
  CommonOrderColumns.phoneNumber,
  CommonOrderColumns.documents,
  CommonOrderColumns.items,
  CommonOrderColumns.courier,
  CommonOrderColumns.service,
  CommonOrderColumns.total,
  CommonOrderColumns.updatedBy,
  CommonOrderColumns.date,
  CommonOrderColumns.created_at,
  CommonOrderColumns.detail,
  CommonOrderColumns.logs,
];

export const columnsOrderReport = [
  CommonOrderColumns.orderId,
  CommonOrderColumns.soNumber,
  ReportOrderColumns.state,
  ReportOrderColumns.StateMeta,
  ReportOrderColumns.daysMetaState,
  ReportOrderColumns.totalTime,
  ReportOrderColumns.marketplace,
  ReportOrderColumns.buyer,
  ReportOrderColumns.totalPrice,
  ReportOrderColumns.created_at,
  CommonOrderColumns.courier,
  CommonOrderColumns.detail,
];

export const columnsTagmanager = [
  CommonOrderColumns.orderId,
  CommonOrderColumns.soNumber,
  CommonOrderColumns.buyer,
  CommonOrderColumns.orderState,
  CommonOrderColumns.marketplace,
  CommonOrderColumns.phoneNumber,
  CommonOrderColumns.zipCode,
  CommonOrderColumns.courier,
  CommonOrderColumns.service,
  CommonOrderColumns.domicile,
  CommonOrderColumns.date,
  CommonOrderColumns.created_at,
  CommonOrderColumns.statePrinting,
  CommonOrderColumns.returnedReason,
];
