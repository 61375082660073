import React, { useState, useCallback } from 'react';
import { AnyObject } from '../types/types';
import { CheckBox } from '../components/CheckBok';

export const useCheckAll = (data: AnyObject, searchId: string) => {
  const [checked, setChecked] = useState({
    all: [] as AnyObject[],
  });

  const resetCheck = useCallback(() => {
    setChecked({ all: [] });
  }, [setChecked]);

  const handleCheckChieldElement = useCallback(
    (event: string) => {
      setChecked((prev) => {
        const copy = { ...prev };
        const all = [...prev.all];
        const searchIdInCheck = all.findIndex(
          (dataOrder: any) => dataOrder[searchId] === event,
        );
        if (searchIdInCheck === -1) {
          const indexInData = data.findIndex(
            (dataAll: any) => dataAll[searchId] === event,
          );
          all.push(data[indexInData]);
        } else {
          all.splice(searchIdInCheck, 1);
        }
        copy.all = all;
        return copy;
      });
    },
    [searchId, data],
  );

  const handleCheckId = useCallback(
    (idRow: string) => (
      <CheckBox
        validateCheck={
            !!checked.all.length
            && checked.all.findIndex((value: any) => value[searchId] === idRow)
              !== -1
          }
        onChange={() => {
          handleCheckChieldElement(idRow);
        }}
      />
    ),
    [checked, handleCheckChieldElement, searchId],
  );

  const handleCheckIdStatus = useCallback(
    (idRow: string) => checked.all.findIndex((value: any) => value[searchId] === idRow) !== -1,
    [checked, searchId],
  );

  const handleCheckChildElementAll = useCallback(() => {
    setChecked((prev) => {
      let copy = { ...prev };
      const all = [...prev.all];
      if (all.length !== data.length) {
        data.forEach((value: any) => {
          if (
            all.findIndex(
              (valueCopy) => valueCopy[searchId] === value[searchId],
            ) === -1
          ) {
            all.push({
              ...value,
            });
          }
        });
      } else {
        all.length = 0;
      }
      copy = { all };
      return copy;
    });
  }, [data, searchId]);

  const columnAll = {
    id: 'select_order_id',
    label: '',
    width: '60px',
    style: {
      minWidth: '50px',
    },
    icon: () => !!data?.length && (
    <CheckBox
      validateCheck={checked?.all?.length === data?.length}
      onChange={handleCheckChildElementAll}
    />
    ),
    noSort: true,
  };

  return {
    checked,
    columnAll,
    handleCheckId,
    resetCheck,
    handleCheckIdStatus,
    handleCheckChieldElement,
  };
};
