import React, { useCallback } from 'react';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import { useGlobalContext } from '../../hooks';

type MenuButtonProps = {
  color?: 'inherit' | 'primary' | 'secondary' | 'default',
};

export const MenuButton = ({ color = 'inherit' }: MenuButtonProps) => {
  const { set } = useGlobalContext();
  const openDrawer = useCallback(() => {
    set(true);
  }, [set]);
  return (
    <IconButton color={color} aria-label="Open menu" onClick={openDrawer}>
      <MenuIcon fontSize="large" />
    </IconButton>
  );
};
