// Palette
import palette from '../palette';

type MuiButtonType = { [k: string]: any };

const MuiButton: MuiButtonType = {
  outlined: {
    padding: '5px 18px',
  },
  contained: {
    padding: '5px 18px',
    backgroundColor: palette.common.white,
    '&:hover': {
      backgroundColor: palette.common.neutral,
    },
  },
};

export default MuiButton;
