import React, { useState, useCallback, useEffect } from 'react';
import { TextField, Grid } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import { makeFilter } from '../utils/makeFilter';
import { SelectRole } from '../../components/SelectRole';
import { FilterBar } from '../../../../../components/FilterBar';
import { useTranslation } from '../../../../../services/translation';
import { AnyObject } from '../../../../../types/types';
import useDebounce from '../../../../../hooks/useDebounce';
import { gridBreakpoints } from '../../../../../const/gridBreakpoints';

type SearchFiltersProps = {
  initialSearch: string,
  initFetch?: AnyObject,
  onFilterChange: (makeFilter: object) => void,
};

export const SearchFiltersRaw = ({
  initialSearch = '',
  initFetch,
  onFilterChange,
}: SearchFiltersProps) => {
  const { t } = useTranslation();

  const [search, setSearch] = useState(
    initialSearch || initFetch?.['0']?.[0]?.value || '',
  );
  const [role, setRole] = useState(
    initFetch?.role?.[0].value || '0',
  );

  const handleChangeRole = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setRole(event.target.value);
  }, []);

  const searchDebounced = useDebounce(search, 500);

  useEffect(() => {
    onFilterChange(makeFilter({ search: searchDebounced, role }));
  }, [onFilterChange, role, searchDebounced]);

  return (
    <FilterBar
      defaultFilter={(
        <Grid item {...gridBreakpoints}>
          <TextField
            id="generic-search"
            placeholder={t('Search')}
            type="search"
            value={search}
            onChange={(event) => setSearch(event.target.value)}
            fullWidth
            style={{ width: '100%' }}
            InputLabelProps={{
              shrink: true,
            }}
            InputProps={{
              endAdornment: <SearchIcon />,
            }}
          />
        </Grid>
      )}
    >
      <Grid xs={12} sm={6} md={3} item>
        <SelectRole inputProps={{ value: role, onChange: handleChangeRole }} optionAll />
      </Grid>
    </FilterBar>
  );
};

export const SearchFilters = React.memo(SearchFiltersRaw);
