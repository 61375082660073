import React, { useMemo } from 'react';
import { Link, LinkProps } from 'react-router-dom';
import IconButton, { IconButtonProps } from '@material-ui/core/IconButton';
import { makeStyles } from '@material-ui/styles';
import { TitleBreadcrumbs } from '../../interfaces';

const useStyles = makeStyles({
  label: {
    display: 'flex',
    flexWrap: 'nowrap',
    alignItems: 'center',
  },
});

interface MakeCellProps extends IconButtonProps {
  label: string;
  icon: React.ComponentType;
  path?: string;
  params?: { goBack: string | TitleBreadcrumbs[] };
}

export const MakeCellRaw = ({
  label = '',
  icon: Icon,
  onClick,
  path = '',
  params,
}: MakeCellProps) => {
  const classes = useStyles();

  const newTo = useMemo(() => ({
    pathname: path,
    state: params,
  }), [path, params]);

  const CustomLink = (props: LinkProps) => (
    <Link {...props} to={newTo} />
  );

  const iconButtonProps: Object = {
    onClick: onClick || undefined,
    component: path ? CustomLink : 'span',
  };

  return (
    <label htmlFor="icon-button-file" className={classes.label}>
      <IconButton onClick={onClick} {...iconButtonProps}>
        <Icon />
      </IconButton>
      {label}
    </label>
  );
};

interface MakeCellChildrenProps extends IconButtonProps {
  label: string;
  children: JSX.Element[] | JSX.Element
}

export const MakeCellRawChildren = ({
  label = '',
  children,
}: MakeCellChildrenProps) => {
  const classes = useStyles();

  return (
    <label htmlFor="icon-button-file" className={classes.label}>
      {children}
      {label}
    </label>
  );
};

export const MakeCellChildren = React.memo(MakeCellRawChildren);

export const MakeCell = React.memo(MakeCellRaw);
