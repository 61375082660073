import {
  useState, useMemo, useCallback, useEffect,
} from 'react';

export type useFilerParamsIdOptions = {
  orderInit: string;
  daoName: string;
  aspect?: string;
  init?: any;
};

export const useFilerParamsId = ({
  orderInit,
  daoName,
  aspect = '',
  init = {},
}: useFilerParamsIdOptions) => {
  const [initOld, setInitOld] = useState(init);
  const [filter, setFilter] = useState(initOld);
  const [filterSave, setfilterSave] = useState(filter);

  useEffect(() => {
    setFilter(initOld);
  }, [initOld]);

  useEffect(() => {
    if (JSON.stringify(init) !== JSON.stringify(initOld)) {
      setInitOld(init);
    }
  }, [initOld, init]);

  useEffect(() => {
    if (JSON.stringify(filterSave) !== JSON.stringify(filter)) {
      setfilterSave({ ...filter });
    }
  }, [filterSave, filter]);

  const [orderBy, setOrderBy] = useState(orderInit);
  const [direction, setDirection] = useState<'asc' | 'desc'>('asc');

  const paramsFetch = useMemo(
    () => ({
      daoName,
      filter,
      direction,
      orderBy,
      aspect,
    }),
    [filter, direction, orderBy, daoName, aspect],
  );

  const handleRequestSort = useCallback(
    (newOrderBy: string, newDirection: 'asc' | 'desc') => {
      setOrderBy(newOrderBy);
      setDirection(newDirection);
    },
    [],
  );

  const resetFilter = useCallback(() => {
    setFilter(filter);
  }, [filter]);

  return {
    filter: filterSave,
    orderBy,
    direction,
    paramsFetch,
    setFilter,
    handleRequestSort,
    resetFilter,
  };
};
