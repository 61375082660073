import React, { ReactNode } from 'react';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/styles';
import { AnyObject } from '../../types/types';

const useStyles = makeStyles((theme: AnyObject) => ({
  wrapper: {
    height: '100vh',
    marginTop: -theme.mixins.toolbar.minHeight,
  },
}));

type GridFullHeightProps = {
  children: ReactNode,
};

export const GridFullHeight = ({ children = null, ...props }: GridFullHeightProps) => {
  const classes = useStyles();
  return (
    <Grid container className={classes.wrapper} direction="column" justify="center" alignItems="center" {...props}>
      {children}
    </Grid>
  );
};
