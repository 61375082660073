import React, { useMemo, useCallback } from 'react';
import {
  Collapse,
  Grid,
  IconButton,
  Typography,
  TextField,
} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { ExpandLess, ExpandMore } from '@material-ui/icons';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { makeStyles } from '@material-ui/styles';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { DatabaseConnector } from '@phinxlab/libby-rest-web';
import { AnyObject, LibbyObject } from '../../../../types/types';
import { useTranslation } from '../../../../services/translation';
import { useLibbyFetch } from '../../../../hooks';
import { SelectOrderCourier } from '../../../components/SelectOrderCourier';
import { SelectCanals } from '../../../../components/SelectCanals/SelectCanals';
import { CANALS } from '../../../../const';

const useStyles = makeStyles(() => ({
  root: {
    '& > *': {
      margin: '5px',
      width: '25ch',
    },
  },
  title: {
    verticalAlign: 'baseline',
  },
}));

type Courier = {
  id: string;
  value: string;
};

export type FilterBarSelection = {
  marketplaces: any[];
  from?: Date;
  to?: Date;
  courier: Courier[];
  canal?: boolean;
  channelValue?: string
};

export type FilterBarProps = {
  libby: LibbyObject;
  onFilter: (filter: any) => void;
  initExpanded?: boolean;
  filter: FilterBarSelection;
  canalEnable?: boolean
};

function FilterBar({
  libby,
  onFilter,
  initExpanded = false,
  filter,
  canalEnable = false,
}: FilterBarProps) {
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState(initExpanded);
  /* const [filter, setFilter] = React.useState<FilterBarSelection>({
    marketplaces: [],
    from: Moment().toDate(),
    to: Moment().toDate(),
    courier: [],
  }); */
  const { t } = useTranslation();
  // FIXME POR EL AMOR DE JEBBBBBBUS
  const libbiFilter = useMemo<AnyObject>(() => ({}), []);
  const { data } = useLibbyFetch(libby, {
    daoName: 'ster_marketplace',
    filter: libbiFilter,
    direction: 'asc',
    orderBy: 'name',
  });
  // FIXME WE MUST COMPONENTIZE THE FIELDS TO AVOID SO MANY TAGS ON A SIMPLE FORM
  // FIXME THE AUTOCOMPLETE SHOULD TO HAVE THE FRAME AND ALSO THE CHIPS HAVE TO BE OF A DIFFERENT COLOR
  // FIXME WE SHOULD CHANGE THE ONCHANGES METHODS WITH USECALLBACK TO AVOID RE-RENDERS
  // FIXME DATEPICKER IS NO CLOSING ON SELECTION

  const handleChangeCanals = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const { value } = event.target;

      const newFilter: FilterBarSelection = {
        ...filter,
        canal: value === '0' ? undefined : value === CANALS.OFFLINE.toString(),
        channelValue: value,
      };
      onFilter(newFilter);
    },
    [filter, onFilter],
  );

  return (
    <Grid container direction="row" justify="flex-end" alignItems="flex-end">
      <Grid item>
        <IconButton onClick={() => setExpanded(!expanded)}>
          <Typography variant="h5" className={classes.title}>
            {t('filters')}
          </Typography>
          {expanded ? <ExpandLess /> : <ExpandMore />}
          {' '}
        </IconButton>
      </Grid>
      <Grid
        container
        direction="row"
        justify="flex-end"
        alignItems="flex-end"
        spacing={1}
      >
        <Grid item xs={12}>
          <Collapse in={expanded} timeout="auto">
            <Grid
              container
              direction="row"
              justify="space-between"
              alignItems="flex-end"
              spacing={2}
            >
              {canalEnable && (
                <Grid item md={2} sm={6} xs={6}>
                  <SelectCanals
                    inputProps={{
                      value: filter.channelValue,
                      onChange: handleChangeCanals,
                    }}
                  />
                </Grid>
              )}
              <Grid item md={2} sm={6} xs={6}>
                <KeyboardDatePicker
                  fullWidth
                  disableToolbar
                  variant="inline"
                  format="DD/MM/YYYY"
                  margin="normal"
                  id="date-picker-inline"
                  label={t('From')}
                  value={filter.from}
                  onChange={(value: MaterialUiPickersDate) => {
                    if (value) {
                      const newFilter: FilterBarSelection = {
                        ...filter,
                        from: value
                          .set({
                            hour: 0,
                            minute: 0,
                            second: 0,
                            millisecond: 0,
                          })
                          .toDate(),
                      };
                      onFilter(newFilter);
                    }
                  }}
                  KeyboardButtonProps={{
                    'aria-label': 'change date',
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
              <Grid item md={2} sm={6} xs={6}>
                <KeyboardDatePicker
                  fullWidth
                  disableToolbar
                  variant="inline"
                  format="DD/MM/YYYY"
                  margin="normal"
                  id="date-picker-inline"
                  label={t('To')}
                  value={filter.to}
                  onChange={(value: MaterialUiPickersDate) => {
                    if (value) {
                      const newFilter: FilterBarSelection = {
                        ...filter,
                        to: value
                          .set({
                            hour: 23,
                            minute: 59,
                            second: 59,
                            millisecond: 999,
                          })
                          .toDate(),
                      };
                      onFilter(newFilter);
                    }
                  }}
                  KeyboardButtonProps={{
                    'aria-label': 'change date',
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
              <Grid item md={3} sm={6} xs={12}>
                <Autocomplete
                  value={filter.marketplaces}
                  style={{ display: 'inline-block', width: '100%' }}
                  multiple
                  id="tags-outlined"
                  options={data}
                  getOptionLabel={(option) => option.name}
                  filterSelectedOptions
                  onChange={(event: React.ChangeEvent<{}>, value: any) => {
                    const newFilter: FilterBarSelection = {
                      ...filter,
                      marketplaces: value,
                    };
                    onFilter(newFilter);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      label={t('All the marketplaces')}
                      placeholder=""
                    />
                  )}
                />
              </Grid>
              <Grid item md={3} sm={6} xs={12}>
                <SelectOrderCourier
                  multiple
                  inputProps={{
                    value: filter.courier,
                    onChange: (
                      event: React.ChangeEvent<HTMLInputElement>,
                      value: Courier[],
                    ) => {
                      const newFilter: FilterBarSelection = {
                        ...filter,
                        courier: value,
                      };
                      onFilter(newFilter);
                    },
                  }}
                  optionAll
                />
              </Grid>
            </Grid>
          </Collapse>
        </Grid>
      </Grid>
    </Grid>
  );
}

const i = DatabaseConnector(FilterBar)('ster_marketplace');
export { i as FilterBar };
