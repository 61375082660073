import React from 'react';
import { OrderTable } from './components/OrderTable';
import { ScreenAligned } from '../../../../components/ScreenAligned/ScreenAligned';
import { AnyObject } from '../../../../types/types';
import { TitleBreadcrumbs } from '../../../../interfaces';

type HeadCellsType = {
  id: string;
  label: string;
  translate?: boolean;
  render?: (row: any, item: any, t: (str: string) => string) => React.ReactNode;
  [k: string]: any;
};

type RowsType = {
  id: string;
  [k: string]: any;
};

export interface OrderListProps {
  detailUrl: string;
  setFilter: (makeFilter: object) => void;
  filter: AnyObject;
  working: boolean;
  rows: Array<RowsType>;
  columns: Array<HeadCellsType>;
  fetchMore: () => void;
  reFetch?: () => void;
  onSortChange?: (orderBy: string, direction: 'asc' | 'desc') => void;
  orderBy?: string;
  direction?: 'asc' | 'desc';
  title: TitleBreadcrumbs[];
  allButtonsActions?: React.ReactElement;
  onRowClick?: (row: RowsType) => void;
  canalOffLine?: boolean;
}

export const OrdersList = ({
  setFilter,
  rows,
  columns,
  working,
  filter,
  fetchMore,
  reFetch,
  onSortChange,
  orderBy,
  direction,
  title,
  allButtonsActions,
  onRowClick,
  canalOffLine = false,
}: OrderListProps) => (
  <ScreenAligned title={title} additionalTitle={allButtonsActions}>
    <OrderTable
      canalOffLine={canalOffLine}
      columns={columns}
      rows={rows}
      working={working}
      filter={filter}
      setFilter={setFilter}
      fetchMore={fetchMore}
      onSortChange={onSortChange}
      orderBy={orderBy}
      direction={direction}
      onRowClick={onRowClick}
      reFetch={reFetch}
    />
  </ScreenAligned>
);
