import { useCallback, useEffect, useState } from 'react';
import { useField, useForm } from 'react-final-form-hooks';
import { useSnackbar } from 'notistack';
import { formInitialValues, validate } from '../utils';
import { Account } from '../../../types/Account';
import { useTranslation } from '../../../../../services/translation';
import { LibbyObject } from '../../../../../types/types';

export const useAddAccountForm = ({ libby, addCreate }:{libby: LibbyObject, addCreate: (data: object)=>void, }) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const [account, setAccount] = useState<Account>(null);
  const [isSearching, setIsSearching] = useState(false);
  const [duplicateAccount, setDuplicateAccount] = useState(false);

  const onSubmit = useCallback(
    async (values) => {
      try {
        const account_id = account?.account_id;
        const updatedAccount = {
          ...values,
          role: {
            account_role_id: values.account_role,
          },
        };

        const newAccount = await libby.admin_account.save({
          account_id,
          creation_date: new Date(),
          account_enabled: true,
          ...updatedAccount,
        });
        addCreate(newAccount);
        enqueueSnackbar(t('Account saved'), { variant: 'success' });
        return {
          account_id: String(newAccount.account_id),
        };
      } catch (e) {
        enqueueSnackbar(t('Something is wrong'), { variant: 'error' });
        return null;
      }
    },
    [libby, enqueueSnackbar, addCreate, account, t],
  );

  const {
    form, handleSubmit, submitting, submitError,
  } = useForm({
    initialValues: formInitialValues,
    onSubmit,
    validate,
  });
  const username = useField('username', form);
  const password = useField('password', form);
  const account_role = useField('account_role', form);
  const email = useField('email', form);

  const onSearchChange = useCallback(
    async () => {
      const { value } = username.input;
      if (value) {
        setIsSearching(true);
        const [fetchedAccount] = await libby.admin_account
          .query()
          .equals('username', value)
          .run();

        if (fetchedAccount) {
          setDuplicateAccount(true);
          setAccount(fetchedAccount);
        } else {
          setDuplicateAccount(false);
          setAccount(formInitialValues);
        }
        setIsSearching(false);
      }
    },
    [username, libby],
  );

  useEffect(() => {
    if (!account) {
      setTimeout(() => {
        form.reset(formInitialValues);
        form.resetFieldState('username');
        form.resetFieldState('password');
        form.resetFieldState('email');
        form.resetFieldState('account_role');
      }, 0);
    }
  }, [form, account]);

  return {
    account,
    setAccount,
    handleSubmit,
    onSearchChange,
    username,
    password,
    email,
    account_role,
    submitting,
    submitError,
    duplicateAccount,
    setDuplicateAccount,
    isSearching,
  };
};
