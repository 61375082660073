import {
  makeValidator,
  ValidatorType,
  ValidatorRule,
} from '../../../../../utils';

export const validations: ValidatorRule[] = [
  { key: 'document', validators: [ValidatorType.SPECIALS, ValidatorType.NUMBER] },
  { key: 'email', validators: [ValidatorType.EMAIL] },
  { key: 'first_name', validators: [ValidatorType.LETTERS] },
  { key: 'last_name', validators: [ValidatorType.LETTERS] },
  { key: 'buyerStreetNumber', validators: [ValidatorType.NUMBER] },
  { key: 'buyerFloor', validators: [ValidatorType.NUMBER] },
  { key: 'street_number', validators: [ValidatorType.NUMBER] },
  { key: 'floor', validators: [ValidatorType.NUMBER] },
  { key: 'city', validators: [ValidatorType.REQUIRED] },
  { key: 'buyerCity', validators: [ValidatorType.REQUIRED] },
  { key: 'buyerProvince', validators: [ValidatorType.REQUIRED] },
  { key: 'province', validators: [ValidatorType.REQUIRED] },
];

export const validate = makeValidator(validations);

export const getValidate = (keys: Array<string>) => makeValidator(
  keys.length > 0
    ? validations.filter((val) => keys.includes(val.key))
    : validations,
);
