import { HeadCellsType } from '../types';

export const deleteColumn = (
  columnName: string,
  columnsCollect: Array<HeadCellsType>,
) => {
  const searchId = columnsCollect.findIndex(
    (column: HeadCellsType) => column.id === columnName,
  );
  if (searchId > -1) {
    columnsCollect.splice(searchId, 1);
  }
  return columnsCollect;
};
