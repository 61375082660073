import React from 'react';
import { useParams } from 'react-router-dom';
import { DatabaseConnector } from '@phinxlab/libby-rest-web';
import { LibbyObject } from '../../../../types/types';
import { useLibbyFetchById } from '../../../../hooks/useLibbyFetchById';
import { useImportForm } from './hooks/useImportForm';
import { ButtonContainer } from './components/ButtonContainer';
import { ScreenAligned } from '../../../../components/ScreenAligned/ScreenAligned';
import { ImportDetailData } from './components/ImportDetailData';
import { TitleBreadcrumbs } from '../../../../interfaces';
import { useBreadcrumbs } from '../../../../hooks';

type ImportDetailProps = {
  libby: LibbyObject;
  title: TitleBreadcrumbs[];
};

const ImportDetailRaw = ({ libby, title }: ImportDetailProps) => {
  const { id } = useParams<{ id: string }>();

  const { data, working } = useLibbyFetchById(libby, {
    daoName: 'app_importation',
    id,
  });

  const {
    loading,
    currentImport,
    someFileWithoutTempAttachments,
    someFileReadyToSave,
    loadingSaveImport,
    handleOnSaveImport,
    handleOnRejectOrder,
    handleOnAcceptOrder,
    handleUpdateTempAttachments,
    handleOnProcessImport,
    handleDeleteImport,
  } = useImportForm({ libby, working: !!working, data });

  const status: number = !loading && currentImport.importation_status.importation_status_id;
  const titleShow = useBreadcrumbs('Upload', title, `#${id}`);

  return (
    <ScreenAligned
      title={titleShow}
      working={loading || working}
      showData={!!currentImport}
    >
      <ImportDetailData
        data={data}
        status={status}
        currentImport={currentImport}
        someFileWithoutTempAttachments={someFileWithoutTempAttachments}
        handleUpdateTempAttachments={handleUpdateTempAttachments}
        handleDeleteImport={handleDeleteImport}
        loadingSaveImport={loadingSaveImport}
        working={working}
      />

      <ButtonContainer
        loading={loading}
        loadingSaveImport={loadingSaveImport}
        status={status}
        someFileWithoutTempAttachments={someFileWithoutTempAttachments}
        someFileReadyToSave={someFileReadyToSave}
        handleOnProcessImport={handleOnProcessImport}
        handleOnSaveImport={handleOnSaveImport}
        handleOnRejectOrder={handleOnRejectOrder}
        handleOnAcceptOrder={handleOnAcceptOrder}
      />
    </ScreenAligned>
  );
};

export const ImportDetail = DatabaseConnector(ImportDetailRaw)(
  'app_importation',
);
