import React from 'react';
import { MenuItem, TextField } from '@material-ui/core';
import { DatabaseConnector } from '@phinxlab/libby-rest-web';
import { AnyObject, LibbyObject } from '../../types';
import { useTranslation } from '../../services/translation';
import { useLibbyFetch } from '../../hooks';

type SelectImportTypeProps = {
  inputProps: AnyObject;
  libby: LibbyObject;
};

export const SelectProvinceRaw = ({
  libby,
  inputProps = {},
}: SelectImportTypeProps) => {
  const { t } = useTranslation();

  const { data: states } = useLibbyFetch(libby, {
    daoName: 'ster_state',
    orderBy: 'name',
  });

  return (
    <TextField
      id="standard-select-currency"
      select
      fullWidth
      InputLabelProps={{ shrink: true }}
      label={t('State')}
      value={inputProps.value}
      onChange={inputProps.onChange}
      className={inputProps.classes}
      helperText={
        inputProps.error.error && `${t(inputProps.error?.helperText || '')} *`
      }
      onBlur={inputProps.onBlur}
    >
      {states
        && states.map((state: AnyObject) => (
          <MenuItem key={state.state_id} value={state.name}>
            {state.name}
          </MenuItem>
        ))}
    </TextField>
  );
};

export const SelectProvince = DatabaseConnector(SelectProvinceRaw)('ster_state');
