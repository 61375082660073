import React, { useState, ChangeEvent } from 'react';
import { Paper, Tabs, Tab } from '@material-ui/core';
import { Theme, makeStyles } from '@material-ui/core/styles';
import { TabPanel } from '../../../../../../components/TabPanel';
import { useTranslation } from '../../../../../../services/translation';
import { MobileCollectDetailsOrders } from './MobileCollectDetailsOrders';
import { LoadingTable } from '../../../../../../components/LoadingTable';
import { MobileCollectDetailItem } from './MobileCollectDetailItem';
import { DataProduct } from '../../../../types/DataProduct';
import { Order_item } from '../../../../../../interfaces/business/orders/Order_item';
import { Collect_item_product } from '../../../../../../interfaces/business/dispatch/Collect_item_product';
import { reducerQuantity } from '../../../../utils/quantity';

const useStyles = makeStyles((theme: Theme) => ({
  paper: {
    background: 'transparent',
    boxShadow: 'none',
  },
  tabs: {
    borderBottom: `1px solid ${theme.palette.grey[400]}`,
  },
  orderBox: {
    marginTop: 5,
    marginBottom: 15,
  },
  orderName: {
    fontSize: 14,
    fontWeight: 700,
    marginBottom: 5,
  },
  orderPercentage: {
    color: theme.palette.grey[400],
    marginLeft: 10,
  },
  productsTabItem: {
    padding: '5px 0 10px',
    borderBottom: `solid 1px ${theme.palette.grey[400]}`,
  },
  orderTitle: {
    display: 'flex',
    justifyContent: 'space-between',
  },
}));

interface ProductsAllType {
  product: DataProduct;
  quantity: number;
  quantityPicker: number;
}

type MobileCollectDetailsItemListProps = {
  loading: boolean;
  rows?: any;
  itemProductAddOrRemove: (type: boolean, data: Collect_item_product) => void;
  assignedUser : string
};

export const MobileCollectDetailsItemList = ({
  loading,
  rows,
  itemProductAddOrRemove,
  assignedUser,
}: MobileCollectDetailsItemListProps) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [tabs, setTabs] = useState<number>(1);
  const handleChangeTabs = (event: ChangeEvent<{}>, newValue: number) => {
    setTabs(Number(newValue));
  };

  const productsAll: ProductsAllType[] = [];

  rows.forEach((row: any) => {
    row.order_items.forEach((order_item: Order_item) => {
      const quantitiesProducts = row.items_product.filter(
        (item_product: Collect_item_product) => item_product.order_item.order_item_id === order_item.order_item_id,
      );

      const searchProduct = productsAll.findIndex(
        (products: ProductsAllType) => products?.product.sku === order_item?.sku,
      );

      if (searchProduct === -1) {
        productsAll.push({
          product: {
            name: order_item.name,
            sku: order_item.sku,
            url: order_item.product?.url,
          },
          quantity: [order_item].reduce(reducerQuantity, 0),
          quantityPicker: quantitiesProducts.length,
        });
      } else {
        productsAll[searchProduct].quantity += [order_item].reduce(reducerQuantity, 0);
        productsAll[searchProduct].quantityPicker += quantitiesProducts.length;
      }
    });
  });

  return (
    <>
      {loading ? (
        <LoadingTable />
      ) : (
        <Paper className={classes.paper} square>
          <Tabs
            className={classes.tabs}
            value={tabs}
            indicatorColor="primary"
            textColor="secondary"
            onChange={handleChangeTabs}
          >
            <Tab label={t('Products')} />
            <Tab label={t('Orders')} />
          </Tabs>
          <TabPanel value={tabs} index={0}>
            {!loading
              && productsAll.map((item: ProductsAllType) => (
                <div
                  key={item.product?.sku}
                  className={classes.productsTabItem}
                >
                  <MobileCollectDetailItem
                    data={item.product}
                    productsTab
                    itemQuantity={item.quantityPicker}
                    itemQuantityAll={item.quantity}
                    complete={item.quantity === item.quantityPicker}
                  />
                </div>
              ))}
          </TabPanel>
          <TabPanel value={tabs} index={1}>
            {rows.map((item: any) => (
              <MobileCollectDetailsOrders
                item={item}
                itemProductAddOrRemove={itemProductAddOrRemove}
                assignedUser={assignedUser}
              />
            ))}
          </TabPanel>
        </Paper>
      )}
    </>
  );
};
