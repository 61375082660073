import { useCallback } from 'react';
import CustomModal from '../services/customFormDialog';
import { useTranslation } from '../services/translation';
import ConfirmDialog from '../components/ConfirmDialog';

type useModalWarningType = {
  title: string;
  content?: string;
  confirmText: string;
  cancelText?: string;
  oneButton?: boolean;
};
const ConfirmModal = CustomModal(ConfirmDialog);

export const useModalWarning = ({
  title,
  content = '',
  confirmText,
  cancelText = 'No',
  oneButton = true,
}: useModalWarningType) => {
  const { t } = useTranslation();

  const showModal = useCallback(
    async ({
      newContent,
      newTitle,
    }: {
      newContent?: string;
      newTitle?: string;
    }) => {
      try {
        const validation = await ConfirmModal.show({
          title: t(newTitle || title),
          content: t(newContent || content),
          confirmText: t(confirmText),
          cancelText: t(cancelText),
          oneButton,
        });
        return !!validation;
      } catch (error) {
        return false;
      }
    },
    [t, content, title, confirmText, cancelText, oneButton],
  );

  return { showModal };
};
