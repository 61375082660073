import { LibbyFetchDAO } from './LibbyFetchDAO';

export class PublicAccountDAO extends LibbyFetchDAO {
  constructor() {
    super('public_account', 'account_id');
  }

  getAll() {
    return this.query()
      .limit(0, 10000)
      .run();
  }
}
