import { useCallback, useEffect } from 'react';
import { AnyObject } from '../../types';

type resetFormType = {
  form: AnyObject,
  open: boolean
};

export const ResetForm = ({ form, open }: resetFormType) => {
  const resetForm = useCallback((): void => {
    setTimeout(() => {
      form.reset();
    }, 0);
  }, [form]);

  useEffect(
    () => () => {
      if (open) {
        resetForm();
      }
    },
    [resetForm, open],
  );

  return null;
};
