import React from 'react';
import { DatabaseConnector } from '@phinxlab/libby-rest-web';
import { useParams } from 'react-router-dom';
import { ScreenAligned } from '../../../../components/ScreenAligned/ScreenAligned';
import { useBreadcrumbs } from '../../../../hooks';
import { LibbyObject } from '../../../../types/types';
import { TitleBreadcrumbs } from '../../../../interfaces';
import { useOrderBuyerLogs, useOrderLogs, useOrderShipmentAddressLogs } from './hook/useOrderStateLogic';
import { StateLogsTab } from './utils/StateLogsTabs';

type OrderStateLogsProps = {
  libby: LibbyObject;
  title: TitleBreadcrumbs[];
};

const OrderStateLogsRaw = ({ libby, title }: OrderStateLogsProps) => {
  const { id } = useParams();

  const { working, data } = useOrderLogs({ libby, id });
  const { dataBuyer } = useOrderBuyerLogs({ libby, id });
  const { dataShipment } = useOrderShipmentAddressLogs({ libby, id });

  const titleShow = useBreadcrumbs('Order Logs', title, `#${id}`);

  return (
    <ScreenAligned
      title={titleShow}
      working={working}
      showData={!!data}
    >
      <StateLogsTab data={data} dataBuyer={dataBuyer} dataShipment={dataShipment} />
    </ScreenAligned>
  );
};

export const OrderStateLogs = DatabaseConnector(OrderStateLogsRaw)(
  'ster_order_logs',
  'ster_order_buyer_logs',
  'ster_order_shipment_address_logs',
);
