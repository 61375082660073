import { makeValidator, ValidatorType, ValidatorRule } from '../../../utils';

export const formInitialValues = {
  cart_id: '',
  dispatch_id: '',
  account_id: '',
};

const validations: ValidatorRule[] = [
  { key: 'cart_id', validators: [ValidatorType.REQUIRED] },
  { key: 'dispatch_id', validators: [ValidatorType.REQUIRED] },
];

const validationsWithAdmin: ValidatorRule[] = [
  ...validations,
  { key: 'account_id', validators: [ValidatorType.REQUIRED] },
];

export const validate = makeValidator(validations);

export const validateWithAdmin = makeValidator(validationsWithAdmin);

export const getValidate = (keys: Array<string>) => makeValidator(
  keys.length > 0
    ? validations.filter((val) => keys.includes(val.key))
    : validations,
);
