import React, { useEffect, useCallback } from 'react';
import { Typography } from '@material-ui/core';
import RoomIcon from '@material-ui/icons/Room';
import Grid from '@material-ui/core/Grid';
import { useLibby } from '@phinxlab/libby-rest-web';
import { AnyObject } from '../../../types';
import { cartBox, stockType } from '../types/Collect';
import CustomModal from '../../../services/customFormDialog';
import ConfirmDialog from '../../../components/ConfirmDialog';
import { useTranslation } from '../../../services/translation';
import { ModalTitle } from './ModalTitle';
import { LoadingHandler } from '../components/LoadingHandler';
import { useSearchStock } from '../hook/useSearchStock';

type LabelProductProps = {
  data: {
    product: AnyObject;
    collectItem: {
      cartBox: cartBox;
      collectItemState: {
        collect_item_state_id: string;
        name: string;
      };
    };
  };
  labelStyle: string;
  locationStyle: string;
  skuAndSerialBoxStyle: string;
  skuStyle: string;
  whiteSpace: string;
  position: string;
};

const ConfirmModal = CustomModal(ConfirmDialog);

export const LabelProduct = ({
  data,
  labelStyle,
  locationStyle,
  skuAndSerialBoxStyle,
  skuStyle,
  whiteSpace,
  position,
}: LabelProductProps) => {
  const { t } = useTranslation();

  const { libby: productStockDAO } = useLibby(['ster_product_stock']);

  const { dataStock, searchStock } = useSearchStock({
    libby: productStockDAO,
    product_id: data?.product?.product?.product_id,
  });

  const stock = dataStock as stockType[] | undefined;

  useEffect(() => {
    searchStock();
  }, [searchStock]);

  const positionData = [
    {
      title: `${t('Corridor')} (X) `,
      value: stock?.[0]?.location.x,
    },
    {
      title: `${t('Shelves')} (Y) `,
      value: stock?.[0]?.location.y,
    },
    {
      title: `${t('Shelf')} (Z) `,
      value: stock?.[0]?.location.z,
    },
  ];

  const showLocationWarning = useCallback(async () => {
    await ConfirmModal.show({
      title: <ModalTitle title="Without Location" error />,
      content: `${t(
        "There're products without location inf in this collect. Please notify to your supervisor or fill the Location info in the system.",
      )}`,
      confirmText: t('Accept'),
      oneButton: true,
    });
  }, [t]);

  useEffect(() => {
    if (
      typeof stock !== 'undefined'
      && (!stock?.[0]?.location
        || stock?.[0]?.location?.x < 0
        || stock?.[0]?.location?.y < 0
        || stock?.[0]?.location?.z < 0)
    ) {
      showLocationWarning();
    }
  }, [stock, showLocationWarning]);

  if (!stock) {
    return null;
  }

  return (
    <label
      htmlFor={`productSerial-${data?.product?.product_id || ''}`}
      className={labelStyle}
    >
      <LoadingHandler loading={typeof stock === 'undefined'}>
        <>
          <Typography variant="h6" color="initial">
            {stock?.[0]?.serial_number
              ? `${t('Pick up the Serial of')}`
              : `${t('Pick up the SKU of')} `}
            {' '}
            {data.product.name}
          </Typography>
          <Typography variant="h6" color="initial" className={locationStyle}>
            <RoomIcon />
            {t('Position')}
          </Typography>
          <div className={locationStyle}>
            <div className={whiteSpace} />
            <div className={position}>
              <Grid container direction="row">
                {positionData.map(({ title, value }) => (
                  <>
                    <Grid container item xs={5}>
                      <Typography variant="h6" color="initial">
                        {title}
                      </Typography>
                    </Grid>
                    <Grid container item xs={7}>
                      <Typography variant="h6" color="initial">
                        {value}
                      </Typography>
                    </Grid>
                  </>
                ))}
              </Grid>
            </div>
          </div>
          <div className={skuAndSerialBoxStyle}>
            <Typography variant="body2" color="initial" className={skuStyle}>
              {data?.product?.sku || `${t('Without Info')}`}
            </Typography>
          </div>
        </>
      </LoadingHandler>
    </label>
  );
};
