import React, {
  useCallback, useEffect, useState, useMemo,
} from 'react';
import moment, { Moment } from 'moment';
import SearchIcon from '@material-ui/icons/Search';
import 'moment/locale/es';
import { Grid, TextField } from '@material-ui/core';
import { SelectOrderState } from '../../../../components/SelectOrderState';
import { DateClose } from '../../../../../components/DateClose';
import { FilterBar } from '../../../../../components/FilterBar';
import { gridBreakpoints, gridBreakpointsFields } from '../../../../../const/gridBreakpoints';
import useDebounce from '../../../../../hooks/useDebounce';
import { useTranslation } from '../../../../../services/translation';
import { AnyObject } from '../../../../../types/types';
import { replaceNonNumericCharacters } from '../../../../../functions';
import { makeFilter } from '../../../utils/makeFilterForDispatchOrderList';
import { ACCOUNT_ROLE, ORDER_STATE } from '../../../../../const';
import { useRolesUser } from '../../../../../hooks';
import { useOrderStateEnabled } from '../hook/useOrderStateEnabled';

const initialStartDate = null;
const initialEndDate = null;

type SearchFiltersProps = {
  onFilterChange: (makeFilter: object) => void;
  initFetch: AnyObject;
  courier: string;
};

const SearchFiltersRaw = ({
  onFilterChange,
  initFetch,
  courier,
}: SearchFiltersProps) => {
  const { t } = useTranslation();
  const [symbolsArr] = useState(['e', 'E', '+', '-', '.', ',', '`+']);
  const [search, setSearch] = useState<string>(
    initFetch?.['0']?.[0]?.value || '',
  );
  const searchDebounced = useDebounce(search, 500);
  const [orderId, setOrderId] = useState<string>(
    initFetch?.order_id?.length > 0 ? initFetch.order_id[0].value : '',
  );

  const rolAdminLogistics = useRolesUser({
    rolesEnabled: [ACCOUNT_ROLE.ADMINISTRATOR, ACCOUNT_ROLE.LOGISTICS],
  });

  const orderIdDebounced = useDebounce(orderId, 500);

  const [sku, setSku] = useState<string>(
    initFetch?.sku?.length > 0 ? initFetch.sku[0].value : '',
  );
  const skuDebounced = useDebounce(sku, 500);

  const [orderStatus, setOrderStatus] = useState<string>(
    initFetch?.orderStatus?.length > 0 ? initFetch.orderStatus[0].value : '0',
  );
  const [startDate, setStartDate] = useState<Moment | null>(
    initFetch?.startDate?.length > 0
      ? moment(initFetch.startDate[0].value)
      : initialStartDate,
  );
  const [endDate, setEndDate] = useState<Moment | null>(
    initFetch?.endDate?.length > 0
      ? moment(initFetch.endDate[0].value)
      : initialEndDate,
  );

  useEffect(() => {
    onFilterChange(
      makeFilter({
        search: searchDebounced,
        orderStatus,
        startDate,
        endDate,
        orderId: orderIdDebounced,
        source: courier,
        sku: skuDebounced,
      }),
    );
  }, [
    onFilterChange,
    searchDebounced,
    orderStatus,
    startDate,
    endDate,
    orderIdDebounced,
    courier,
    skuDebounced,
  ]);

  const handleChangeType = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setOrderStatus(event.target.value);
    },
    [],
  );

  const orderStatusInit = useOrderStateEnabled();

  const handleReset = useCallback(() => {
    setOrderId('');
    setSearch('');
    setSku('');
    setOrderStatus(orderStatusInit);
    setStartDate(initialStartDate);
    setEndDate(initialEndDate);
    onFilterChange(makeFilter({}));
  }, [onFilterChange, orderStatusInit]);

  const listStateInit = useMemo(() => {
    const listState: string[] = [ORDER_STATE.READY_FOR_DELIVERY.toString()];

    const stateLogisticsManager = [
      ORDER_STATE.WAITING_FOR_PREPARATION,
      ORDER_STATE.PREPARED,
    ];

    if (rolAdminLogistics) {
      stateLogisticsManager.forEach((orderState: number) => {
        listState.push(orderState.toString());
      });
    }
    return listState;
  }, [rolAdminLogistics]);

  return (
    <FilterBar
      defaultFilter={(
        <Grid item {...gridBreakpoints}>
          <TextField
            id="standard-search"
            placeholder={t('Search')}
            type="search"
            value={search}
            onChange={(event) => setSearch(event.target.value)}
            fullWidth
            InputLabelProps={{
              shrink: true,
            }}
            InputProps={{
              endAdornment: <SearchIcon />,
            }}
          />
        </Grid>
      )}
      handleReset={handleReset}
    >
      <Grid item {...gridBreakpointsFields}>
        <TextField
          id="generic-search"
          label={t('ID Order')}
          value={orderId}
          type="text"
          onChange={(event) => setOrderId(replaceNonNumericCharacters(event.target.value))}
          fullWidth
          onKeyDown={(e) => symbolsArr.includes(e.key) && e.preventDefault()}
          InputLabelProps={{
            shrink: true,
          }}
        />
      </Grid>
      <Grid item {...gridBreakpointsFields}>
        <TextField
          id="sku"
          label={t('Sku')}
          value={sku}
          type="text"
          onChange={(event) => setSku(event.target.value)}
          fullWidth
          InputLabelProps={{
            shrink: true,
          }}
        />
      </Grid>
      {listStateInit.length > 1 && (
      <Grid item {...gridBreakpointsFields}>
        <SelectOrderState
          title="State"
          inputProps={{ value: orderStatus, onChange: handleChangeType }}
          optionAll
          listStateInit={listStateInit}
        />
      </Grid>
      )}
      <Grid item {...gridBreakpointsFields}>
        <DateClose
          name="Start Date"
          value={startDate}
          onChange={setStartDate}
        />
      </Grid>
      <Grid item {...gridBreakpointsFields}>
        <DateClose
          name="End Date"
          value={endDate}
          minValue={startDate}
          onChange={setEndDate}
        />
      </Grid>
    </FilterBar>
  );
};

export const SearchFilters = React.memo(SearchFiltersRaw);
