import React, { useCallback } from 'react';
import {
  Grid,
  Card,
  CardHeader,
  Avatar,
  IconButton,
  Typography,
  Menu,
  MenuItem,
} from '@material-ui/core';
import { SnackbarProvider, useSnackbar } from 'notistack';
import { useParams } from 'react-router-dom';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { Theme, makeStyles } from '@material-ui/core/styles';
import { DatabaseConnector } from '@phinxlab/libby-rest-web';
import { DEFAULT_IMAGE_URL } from '../../../../../../const';
import { useTranslation } from '../../../../../../services/translation';
import { DataProduct } from '../../../../types/DataProduct';
import { LibbyObject } from '../../../../../../types/types';
import { LoadingTable } from '../../../../../../components/LoadingTable';
import { ExportDialogComponent } from '../../../../../../components/ExportDialog';
import CustomModal from '../../../../../../services/customFormDialog';
import { CarouselProduct } from '../../../../components/CarouselProduct';
import {
  Collect_item_product,
  Order_item,
  Collect_item,
} from '../../../../../../interfaces/business';
import { Data_Product } from '../../../CollectStarted/components/CollectProduct';
import { useCurrentUser } from '../../../../../../hooks/useCurrentUser';

const SearchDialogModal = CustomModal(ExportDialogComponent);

const useStyles = makeStyles((theme: Theme) => ({
  content: {
    maxHeight: 62,
    alignItems: 'center',
    '& .MuiCardHeader-content': {
      maxWidth: '70%',
    },
  },
  avatar: {
    backgroundColor: theme.palette.primary.main,
    position: 'relative',
  },
  name: {
    fontSize: 16,
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },
  sku: {
    fontSize: 12,
    color: theme.palette.grey[500],
  },
  cardStyle: {
    backgroundColor: 'rgba(255,255,255,0)',
    padding: 0,
  },
  imageBox: {
    position: 'relative',
  },
  checkOverlay: {
    backgroundColor: 'rgba(255,255,255,0.2)',
    position: 'absolute',
  },
  checkOverlayHidden: {
    display: 'none',
  },
  checkProduct: {
    color: theme.palette.primary.main,
  },
  buttonVertHidden: {
    display: 'none',
  },
  buttonVert: {
    display: 'flex',
  },
  quantities: {
    padding: '12px 12px',
  },
  menu: {
    '& ul': {
      padding: 0,
    },
  },
  listBox: {
    position: 'relative',
  },
  collected: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    top: 0,
    left: 0,
    backgroundColor: 'rgba(255, 255, 255, 0.5)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 5,
    paddingLeft: 25,
  },
  collectCircleContainer: {
    backgroundColor: 'rgb(255 255 255 / 50%)',
    position: 'absolute',
    height: '100%',
    justifyContent: 'center',
    display: 'flex',
    alignItems: 'center',
    width: '100%',
  },
  collectCircle: {
    width: 24,
    height: 24,
    backgroundColor: 'rgb(255,255,255)',
    borderRadius: '50%',
  },
  itemProductChecked: {
    color: theme.palette.primary.main,
    fontSize: 24,
  },
}));

type MobileCollectDetailItemProps = {
  data: DataProduct;
  itemQuantity?: number;
  itemQuantityAll?: number;
  productsTab?: boolean;
  picked?: boolean;
  complete: boolean;
  libby: LibbyObject;
  completedProducts?: Collect_item_product[];
  itemProductAddOrRemove?: (type: boolean, data: Collect_item_product) => void;
  collectItem?: Collect_item;
  dataProduct?: Order_item;
  assignedUser?: string;
};

const MobileCollectDetailItemRaw = ({
  data,
  itemQuantity,
  itemQuantityAll,
  productsTab = false,
  picked = false,
  complete = false,
  libby,
  completedProducts,
  itemProductAddOrRemove,
  collectItem,
  dataProduct,
  assignedUser,
}: MobileCollectDetailItemProps) => {
  const { id } = useParams<{ id: string; dispatchId: string }>();

  const userCurrentData = useCurrentUser({ assignedUser });

  const { t } = useTranslation();
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const { enqueueSnackbar } = useSnackbar();
  const handleMenu = useCallback(
    (event) => {
      setAnchorEl(event.currentTarget);
    },
    [setAnchorEl],
  );

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, [setAnchorEl]);

  const updateDataCollectDetails = useCallback(
    (collectItemProduct) => {
      if (itemProductAddOrRemove) {
        SearchDialogModal.handleCancel();
        itemProductAddOrRemove(true, collectItemProduct);
      }
    },
    [itemProductAddOrRemove],
  );

  const PickUp = async () => {
    handleClose();
    const products: Data_Product[] = [];
    if (collectItem && dataProduct) {
      for (let index = 0; index < dataProduct.quantity; index += 1) {
        products.push({
          product: dataProduct,
          collectItem,
          inItemsProducts: false,
        });
      }
    }
    try {
      await SearchDialogModal.show({
        title: 'Pick up',
        properties: ['documentNumber', 'type.name'],
        id: ' order_id',
        label: '',
        render: () => (
          <SnackbarProvider>
            <CarouselProduct
              id={id}
              products={products}
              updateData={updateDataCollectDetails}
            />
          </SnackbarProvider>
        ),
      });
    } catch (error) {
      // nothing
    }
  };

  const ReturnProductPickUp = useCallback(async () => {
    handleClose();
    if (!completedProducts) return;
    try {
      await Promise.all(
        completedProducts.map((item: Collect_item_product) => libby.ster_collect_item_product
          .aspect('delete_collect_item_product')
          .remove({
            collect_item_product_id: item.collect_item_product_id,
          })),
      );

      if (itemProductAddOrRemove && collectItem) {
        itemProductAddOrRemove(false, {
          ...completedProducts[0],
          collect_item: collectItem,
        });
      }
      enqueueSnackbar(t('$$$ returned product').replace('$$$', data.name), {
        variant: 'success',
      });
    } catch (error) {
      if (error) {
        enqueueSnackbar(
          t('Error returning product: $$$').replace('$$$', error),
          {
            variant: 'error',
          },
        );
      }
    }
  }, [
    libby,
    data,
    handleClose,
    completedProducts,
    enqueueSnackbar,
    t,
    itemProductAddOrRemove,
    collectItem,
  ]);

  if (data) {
    const name = (
      <Typography variant="h5" color="initial" className={classes.name}>
        {data.name}
      </Typography>
    );
    const sku = <small className={classes.sku}>{data.sku}</small>;

    return (
      <Grid className={classes.listBox}>
        <Card elevation={0} className={productsTab ? classes.cardStyle : ''}>
          <CardHeader
            className={classes.content}
            avatar={(
              <Grid className={classes.imageBox}>
                <Avatar variant="rounded" className={classes.avatar}>
                  {complete && (
                    <div className={classes.collectCircleContainer}>
                      <div className={classes.collectCircle}>
                        <CheckCircleIcon
                          className={classes.itemProductChecked}
                        />
                      </div>
                    </div>
                  )}

                  <img src={data.url || DEFAULT_IMAGE_URL} alt={data.name} />
                </Avatar>
                <Avatar
                  variant="rounded"
                  className={
                    picked ? classes.checkOverlay : classes.checkOverlayHidden
                  }
                >
                  <CheckCircleIcon className={classes.checkProduct} />
                </Avatar>
              </Grid>
            )}
            action={(
              <>
                {userCurrentData ? (
                  <>
                    <div className={classes.buttonVert}>
                      <IconButton
                        aria-label="product options"
                        aria-controls="product-options"
                        aria-haspopup="true"
                        onClick={handleMenu}
                      >
                        <MoreVertIcon />
                      </IconButton>
                      <Menu
                        id="product-options"
                        className={classes.menu}
                        anchorEl={anchorEl}
                        anchorOrigin={{
                          vertical: 'top',
                          horizontal: 'right',
                        }}
                        keepMounted
                        transformOrigin={{
                          vertical: 'top',
                          horizontal: 'right',
                        }}
                        open={!!anchorEl}
                        onClose={handleClose}
                      >
                        <MenuItem
                          onClick={complete ? ReturnProductPickUp : PickUp}
                        >
                          {t(complete ? 'Return' : 'Pick up')}
                        </MenuItem>
                      </Menu>
                    </div>
                  </>
                ) : typeof itemQuantity === 'number' && (
                  <div className={classes.buttonVert}>
                    <span className={classes.quantities}>
                      {`${itemQuantity}/${itemQuantityAll}`}
                    </span>
                  </div>
                )}

              </>
            )}
            title={name}
            subheader={sku}
          />
          {libby.working && (
            <Grid className={classes.collected}>
              <LoadingTable />
            </Grid>
          )}
        </Card>
      </Grid>
    );
  }
  return null;
};

export const MobileCollectDetailItem = DatabaseConnector(
  MobileCollectDetailItemRaw,
)('ster_collect_item_product');
