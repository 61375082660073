import React, { useEffect, useCallback } from 'react';
import { useSnackbar } from 'notistack';
import { DatabaseConnector } from '@phinxlab/libby-rest-web';
import { useGlobalContext } from '../../hooks';
import { userInfoType, LibbyObject } from '../../types';
import CustomModal from '../../services/customFormDialog';
import { SearchDialog } from '../SearchDialog';
import { ProcessTable } from './ProcessTable';
import { ACCOUNT_ROLE } from '../../const/accountRole';

const SearchDialogModal = CustomModal(SearchDialog);

export const AlertsRaw = ({ libby }: LibbyObject) => {
  const {
    userInfo,
  }: {
    userInfo: userInfoType;
  } = useGlobalContext();
  const { enqueueSnackbar } = useSnackbar();
  const onRemove = useCallback(
    async (processList) => {
      try {
        await SearchDialogModal.show({
          title:
             'Warning: The following processes should be checked as soon as possible.',
          id: 'ster_config_id',
          properties: ['name'],
          label: 'Name process',
          data: processList,
          renderItem: ({ data }: any) => <ProcessTable items={data} />,
        });
      } catch (error) {
        // nothing
      }
    },
    [],
  );

  const validateAlert = useCallback(async () => {
    try {
      const data = await libby.ster_process_monitor_alert.fetch({
        daoName: 'ster_process_monitor_alert',
      });
      if (data.length) { onRemove(data); }
    } catch (error) {
      enqueueSnackbar('Error consulting process monitor alert.', {
        variant: 'error',
      });
    }
  }, [libby.ster_process_monitor_alert, onRemove, enqueueSnackbar]);

  useEffect(() => {
    if (userInfo?.groups?.[0]?.id === ACCOUNT_ROLE.ADMINISTRATOR) {
      validateAlert();
    }
  }, [userInfo, validateAlert]);
  return <></>;
};

export const Alerts = DatabaseConnector(AlertsRaw)('ster_process_monitor_alert');
