import React, { useCallback, useMemo, MouseEvent } from 'react';
import LaunchIcon from '@material-ui/icons/Launch';
import { LibbyObject } from '../../../../../types/types';
import { MakeCell } from '../../../../components/MakeCell';
import CustomModal from '../../../../../services/customFormDialog';
import {
  useLibbyFetch,
  OptionsLibbyFetch,
} from '../../../../../hooks/useLibbyFetch';
import { ItemsTableSearch } from '../../../../components/ItemsTableSearch';
import { DocumentsTableSearch } from '../../../../components/DocumentsTableSearch';
import { RowsType } from '../../../../../types';
import { columnsOrder } from '../../../../../models/orders/columns';
import { useOrderToRows } from '../../../../../models/orders/rows';
import { ExportDialogComponent } from '../../../../../components/ExportDialog';
import { Order } from '../../../../../interfaces/business';
import {
  MenuActions,
  MenuItemComponentType,
} from '../../../../../components/MenuActions';
import { useStateOrder } from './useStateOrder';
import {
  ACCOUNT_ROLE, MENU, MENU_ACTIONS, OrderState, ORDER_STATE,
} from '../../../../../const';
import { useTranslation } from '../../../../../services/translation';
import { useCheckAll, useRolesUser } from '../../../../../hooks';
import { CommonOrderColumns } from '../../../../../models/orders/CommonOrderColumns';
import { ButtonDetailsType } from '../../../../../types/Button';
import { ButtonComponent } from '../../../../../components/Button';
import { reducerOrderId, searchState } from '../utils/reduceOrderId';
import { subFilterValue } from '../../../../../utils/subFilterValue';
import { useMenuAction } from '../../../../../hooks/useMenuActions';

const SearchDialogModal = CustomModal(ExportDialogComponent);

export const handleOpenItemsModal = async (e: MouseEvent, items: string) => {
  e.stopPropagation();
  try {
    await SearchDialogModal.show({
      title: 'Items',
      id: 'order_item_id',
      properties: ['name', 'sku'],
      label: 'Item/Sku',
      data: items,
      maxWidth: 'md',
      render: () => <ItemsTableSearch order_id={items} />,
    });
  } catch (error) {
    // nothing
  }
};

const handleOpenDocumentsModal = async (
  e: MouseEvent,
  order_id?: string | number,
) => {
  e.stopPropagation();
  try {
    await SearchDialogModal.show({
      title: 'Documents',
      id: 'order_document_id',
      properties: ['documentNumber', 'type.name'],
      label: 'Document',
      data: order_id,
      maxWidth: 'md',
      render: () => <DocumentsTableSearch orderId={order_id} />,
    });
  } catch (error) {
    // nothing
  }
};

export const useOrderTableLogic = (
  libby: LibbyObject,
  paramsFetch: OptionsLibbyFetch,
  detailsRedirection: (order_id: string) => void,
  columnCheckEnabled: boolean = false,
  actions: boolean = false,
  path: string,
) => {
  const {
    data, working, fetchMore, updateData, searchData, reFetch,
  } = useLibbyFetch(libby, paramsFetch);

  const rolEnabledPrepare = useRolesUser({
    rolesEnabled: [
      ACCOUNT_ROLE.ADMINISTRATOR,
      ACCOUNT_ROLE.LOGISTICS,
      ACCOUNT_ROLE.LOGISTICS_OPERATOR,
    ],
  });

  const rolEnabledReadyForDelivery = useRolesUser({
    rolesEnabled: [
      ACCOUNT_ROLE.ADMINISTRATOR,
      ACCOUNT_ROLE.FINANCE,
      ACCOUNT_ROLE.HEAD_MANAGEMENT,
    ],
  });

  const { t } = useTranslation();

  const { onChangeUpdateState, onChangeUpdateStateAll } = useStateOrder({
    libby,
  });

  const {
    checked: { all },
    columnAll,
    handleCheckId,
    resetCheck,
  } = useCheckAll(data, 'order_id');

  const updateDataField = useCallback(
    (order: Order) => {
      const searchOrder = searchData(order, 'order_id');
      const dataOrderAll = data as Order[];

      if (searchOrder > -1) {
        const orderUpdate: Order = { ...dataOrderAll[searchOrder] };
        updateData({ ...orderUpdate, ...order }, 'order_id');
      }
      resetCheck();
    },
    [updateData, data, searchData, resetCheck],
  );

  const getCommonRows = useOrderToRows(path);

  const menuArray = useCallback(
    (dataOrder: Order): MenuItemComponentType[] => {
      const state = dataOrder.state.order_state_id;
      const menu: MenuItemComponentType[] = [
        {
          title: 'Details',
          onClick: (e: MouseEvent) => {
            e.stopPropagation();
            detailsRedirection(dataOrder.order_id);
          },
          disabled: false,
        },
        {
          title: 'Cancel',
          onClick: (e: MouseEvent) => {
            e.stopPropagation();
            onChangeUpdateState({
              updateDataField,
              data: dataOrder,
              title: t('Cancel order'),
              content: `${t(
                'Are you sure you want to cancel the order $$$$?',
              ).replace('$$$$', dataOrder.order_id)}`,
              state_id:
                dataOrder.state.order_state_id
                === ORDER_STATE.OUT_FOR_DELIVERY.toString()
                  ? ORDER_STATE.RETURNING_TO_CANCEL.toString()
                  : ORDER_STATE.CANCELLED.toString(),
            });
          },
          disabled:
            state === ORDER_STATE.CANCELLED.toString()
            || state === ORDER_STATE.RETURNING_TO_CANCEL.toString(),
          color: 'error',
        },
      ];
      return menu.filter((menuItems) => menuItems.disabled === false);
    },
    [onChangeUpdateState, updateDataField, detailsRedirection, t],
  );

  const rows: RowsType[] = useMemo(() => {
    const dataOrder = data as Order[];

    const status = subFilterValue(paramsFetch?.filter?.orderStatus);
    const dataUpdateWithState = status
      ? dataOrder.filter((order: Order) => status.includes(order.state.order_state_id))
      : dataOrder;

    return dataUpdateWithState.map((order: Order) => {
      const { number_documents, number_items, order_id } = order;

      return {
        select_order_id: handleCheckId(order_id),
        actions: <MenuActions menuArray={menuArray(order)} />,
        ...getCommonRows(order),
        documents: (
          <MakeCell
            label={String(number_documents ?? 0)}
            icon={LaunchIcon}
            onClick={(e) => handleOpenDocumentsModal(e, order_id || '')}
          />
        ),
        items: (
          <MakeCell
            label={String(number_items ?? 0)}
            icon={LaunchIcon}
            onClick={(e) => handleOpenItemsModal(e, order_id || '')}
          />
        ),
      };
    });
  }, [data, getCommonRows, menuArray, handleCheckId, paramsFetch]);

  const columns = useMemo(() => {
    let columnsOrderAll = [...columnsOrder];

    if (actions) {
      columnsOrderAll = [CommonOrderColumns.actions, ...columnsOrder];
    }
    if (columnCheckEnabled) {
      columnsOrderAll = [columnAll, ...columnsOrder];
    }

    return columnsOrderAll;
  }, [actions, columnCheckEnabled, columnAll]);

  const { validateMenuActions } = useMenuAction(MENU.CANAL_OFF_LINE);

  const buttonActions = useMemo((): ButtonDetailsType[] => {
    const allOrders = all as Order[];
    const ordersWaitingForApproval = searchState(
      allOrders,
      ORDER_STATE.WAITING_FOR_APPROVAL.toString(),
    );

    const ordersReadyForPreparation = searchState(
      allOrders,
      ORDER_STATE.READY_FOR_PREPARATION.toString(),
    );

    const ordersReadyForDeliver = searchState(
      allOrders,
      ORDER_STATE.OUT_FOR_DELIVERY.toString(),
    );

    const buttons: ButtonDetailsType[] = [
      {
        id: 'modifyStateOrderdeliver',
        onClick: () => {
          onChangeUpdateStateAll({
            updateDataField,
            data: ordersReadyForDeliver,
            title: t('Change the status of orders'),
            content: `${t('Confirm delivery of selected orders')}`,
            state_id: ORDER_STATE.DELIVERED.toString(),
            typeUpdateState: 'delivered',
          });
        },
        title: 'Deliver',
        disabled: !ordersReadyForDeliver.length,
        show: validateMenuActions(MENU_ACTIONS.DELIVERED.toString()),
      },
      {
        id: 'modifyStateOrderWaitingForPrepartion',
        onClick: () => {
          const ordersReadyForPreparationId = ordersReadyForPreparation.reduce(
            reducerOrderId,
            [],
          );
          onChangeUpdateStateAll({
            updateDataField,
            data: ordersReadyForPreparation,
            title: t('Change the status of orders'),
            content: `${t(
              'Are you sure you want to move the following orders ($$$$) to the $$$ state?.',
            )
              .replace('$$$$', ordersReadyForPreparationId.join(','))
              .replace(
                '$$$',
                t(OrderState[ORDER_STATE.WAITING_FOR_PREPARATION]),
              )}`,
            state_id: ORDER_STATE.WAITING_FOR_PREPARATION.toString(),
            typeUpdateState: 'waiting for preparation',
          });
        },
        title: 'Prepare',
        disabled: !ordersReadyForPreparation.length,
        show: rolEnabledPrepare,
      },
      {
        id: 'modifyStateOrderReadyForDelivery',
        onClick: () => {
          const ordersWaitingForApprovalId = ordersWaitingForApproval.reduce(
            reducerOrderId,
            [],
          );
          onChangeUpdateStateAll({
            updateDataField,
            data: ordersWaitingForApproval,
            title: t('Change the status of orders'),
            content: `${t(
              'Are you sure you want to move the following orders ($$$$) to the ready for delivery state?.',
            ).replace('$$$$', ordersWaitingForApprovalId.join(','))}`,
            state_id: ORDER_STATE.READY_FOR_DELIVERY.toString(),
            typeUpdateState: 'ready for delivery',
          });
        },
        title: 'Ready for delivery',
        disabled: !ordersWaitingForApproval.length,
        show: rolEnabledReadyForDelivery,
      },
    ];
    return buttons.filter((button) => button.show);
  }, [
    all,
    onChangeUpdateStateAll,
    t,
    updateDataField,
    rolEnabledReadyForDelivery,
    rolEnabledPrepare,
    validateMenuActions,
  ]);

  const allButtonsActions = (
    <>
      {buttonActions.map((dataButton) => (
        <ButtonComponent
          key={dataButton.id}
          title={dataButton.title}
          onClick={dataButton.onClick}
          className={dataButton.className}
          disabled={dataButton.disabled}
        />
      ))}
    </>
  );

  return {
    working,
    rows,
    columns,
    fetchMore,
    data,
    updateData,
    updateDataField,
    allButtonsActions,
    reFetch,
  };
};
