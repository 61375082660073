import React from 'react';
import { LoadingTable } from '../../../components/LoadingTable';

type LoadingHandlerType = {
  children: React.ReactNode;
  loading: boolean;
};

export const LoadingHandler = ({ children, loading }: LoadingHandlerType) => (
  <>{loading ? <LoadingTable /> : children}</>
);
