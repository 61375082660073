import { LibbyFetchDAO } from './LibbyFetchDAO';

export class InboxOrdersSearchImportationDAO extends LibbyFetchDAO {
  constructor() {
    super('ster_inbox_order_search_importation', 'reference');
  }

  getAll() {
    return this.query().limit(0, 10000).run();
  }

  getReference({
    importation_id,
  }: {
    importation_id: number;
  }) {
    return this.query().equals('importation_id', importation_id).run();
  }
}
