import { LibbyFetchDAO } from './LibbyFetchDAO';

export class MarketPlaceDAO extends LibbyFetchDAO {
  constructor() {
    super('ster_marketplace', 'marketplace_id');
  }

  getAll() {
    return this.query()
      .limit(0, 10000)
      .run();
  }
}
