import { useGlobalContext } from './useGlobalContext';
import { userInfoType } from '../types';

type useCurrentUserType = {
  assignedUser?: string
};

export const useCurrentUser = ({ assignedUser }: useCurrentUserType) => {
  const {
    userInfo,
  }: {
    userInfo: userInfoType;
  } = useGlobalContext();

  return userInfo?.id === String(assignedUser);
};
