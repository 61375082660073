import React, {
  useMemo, useCallback,
} from 'react';
import moment, { Moment } from 'moment';
import { subFilter, Filter } from '../../../../../types';

interface UseInitFiler {
  initFetch: subFilter[];
  init?: string | null;
  onFilterChange: (makeFilter: object) => void;
  id: string;
  date?: boolean;
  makeFilter: any
}

export const useInitFilter = ({
  initFetch,
  init,
  onFilterChange,
  id,
  date,
  makeFilter,
}: UseInitFiler) => {
  const search = useMemo(
    () => (initFetch
      ? date
        ? moment(initFetch?.[0]?.value)
        : initFetch?.[0]?.value
      : init || null),
    [initFetch, init, date],
  );

  const change = useCallback(
    (valueFilter) => {
      onFilterChange((prev: Filter) => {
        const copy = { ...prev };
        delete copy[id];
        return { ...copy, ...valueFilter };
      });
    },
    [id, onFilterChange],
  );

  const handleChangeFilter = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const valueFilter = makeFilter({ [id]: event.target.value });
      change(valueFilter);
    },
    [change, id, makeFilter],
  );

  const handleChangeFilterString = useCallback(
    (value: Moment | null) => {
      const valueFilter = makeFilter({ [id]: value });
      change(valueFilter);
    },
    [change, id, makeFilter],
  );

  return { search, handleChangeFilter, handleChangeFilterString };
};
