import React from 'react';
import { GridProps } from '@material-ui/core';
import { AnyObject } from '../../types/types';
import { LabelValue } from '../../components/LabelValue';

export type GridDirection = 'row' | 'row-reverse' | 'column' | 'column-reverse';

export type Mapper = {
  key: string;
  label: string;
  formatter?: (value: any, data: any) => any;
  link?: (value: any, data: any) => any;
};

export const makeMapLabelValues = (
  t: (str: string) => string,
  direction?: GridDirection,
  container?: boolean,
  stylesChildrenContainer?: GridProps,
) => (mappers: Mapper[]) => (data: AnyObject) => mappers.map(
  (item: AnyObject) => data[item.key] !== null && (
    <LabelValue
      key={item.key}
      label={t(item.label)}
      direction={direction}
      container={container}
      link={item.link && item.link(data[item.key], data)}
      stylesChildrenContainer={stylesChildrenContainer}
    >
      {item.formatter
        ? item.formatter(data[item.key], data)
        : data[item.key]}
    </LabelValue>
  ),
);
