import { LibbyFetchDAO } from './LibbyFetchDAO';

export class DispatchListDAO extends LibbyFetchDAO {
  constructor() {
    super('ster_dispatch_list', 'dispatch_id');
  }

  getAll() {
    return this.aspect('list_not_collect').query().limit(0, 10000).run();
  }

  getState(id: string) {
    return this.aspect('list_not_collect')
      .query()
      .equals('dispatch_state.dispatch_state_id', id)
      .limit(0, 10000)
      .run();
  }
}
