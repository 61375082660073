import React, { useCallback } from 'react';
import IconButton from '@material-ui/core/IconButton';
import TranslateIcon from '@material-ui/icons/Translate';
import Menu from '@material-ui/core/Menu';
import { useTranslation, useLanguage } from '../../services/translation';
import { LanguageMenuItem } from './components/LanguageMenuItem';

const supportedLanguages = { en: 'English', es: 'Spanish' };

type LanguageButtonProps = {
  color?: 'inherit' | 'primary' | 'secondary' | 'default',
};

export const LanguageButton = ({ color = 'inherit' }: LanguageButtonProps) => {
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const { getLanguage, setLanguage } = useLanguage();
  const handleMenu = useCallback((event) => {
    setAnchorEl(event.currentTarget);
  }, [setAnchorEl]);

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, [setAnchorEl]);
  const onLanguageClick = useCallback((key) => {
    setLanguage(key);
    handleClose();
  }, [setLanguage, handleClose]);
  return (
    <div>
      <IconButton
        aria-label="change language"
        aria-controls="menu-appbar"
        aria-haspopup="true"
        onClick={handleMenu}
        color={color}
      >
        <TranslateIcon fontSize="large" />
      </IconButton>
      <Menu
        id="menu-appbar-language"
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={!!anchorEl}
        onClose={handleClose}
      >
        {Object.entries(supportedLanguages).map(([key, label]) => (
          <LanguageMenuItem
            key={key}
            label={t(label)}
            value={key}
            active={key === getLanguage()}
            onClick={onLanguageClick}
          />
        ))}
      </Menu>
    </div>
  );
};
