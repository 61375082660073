import { useCallback, useEffect, useState } from 'react';
import { useField, useForm } from 'react-final-form-hooks';
import { useSnackbar } from 'notistack';
import { formInitialValuesPublic, validatePublic as validate } from '../utils';
import { Account } from '../../../types/Account';
import { useTranslation } from '../../../../../services/translation';
import { LibbyObject } from '../../../../../types/types';

export const useAddAccountPublicForm = ({ libby }:{libby: LibbyObject }) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const [account, setAccount] = useState<Account>(null);
  const [isSearching, setIsSearching] = useState(false);
  const [duplicateAccount, setDuplicateAccount] = useState(false);

  const onSubmit = useCallback(
    async (values) => {
      try {
        const account_id = account?.account_id;
        const newAccount = await libby.public_account.save({
          account_id,
          username: values.username,
          password: values.password,
          email: values.email,
        });

        enqueueSnackbar(t('Please wait for Admin approval'), { variant: 'success' });
        return {
          account_id: String(newAccount.account_id),
        };
      } catch (e) {
        enqueueSnackbar(t('Something is wrong'), { variant: 'error' });
        return null;
      }
    },
    [libby, enqueueSnackbar, account, t],
  );

  const {
    form, handleSubmit, submitting, submitError,
  } = useForm({
    initialValues: formInitialValuesPublic,
    onSubmit,
    validate,
  });
  const username = useField('username', form);
  const password = useField('password', form);
  const email = useField('email', form);
  const onSearchChange = useCallback(
    async () => {
      const { value } = username.input;
      if (value) {
        setIsSearching(true);
        const [fetchedAccount] = await libby.public_account
          .query()
          .equals('username', value)
          .run();

        if (fetchedAccount) {
          setDuplicateAccount(true);
          setAccount(fetchedAccount);
        } else {
          setDuplicateAccount(false);
          setAccount(formInitialValuesPublic);
        }
        setIsSearching(false);
      }
    },
    [username, libby],
  );

  useEffect(() => {
    if (!account) {
      setTimeout(() => {
        form.reset(formInitialValuesPublic);
        form.resetFieldState('username');
        form.resetFieldState('password');
        form.resetFieldState('email');
      }, 0);
    }
  }, [form, account]);

  return {
    account,
    setAccount,
    handleSubmit,
    onSearchChange,
    username,
    password,
    email,
    submitting,
    submitError,
    duplicateAccount,
    setDuplicateAccount,
    isSearching,
  };
};
