import React, { useCallback, useRef } from 'react';
import { DatabaseConnector } from '@phinxlab/libby-rest-web';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { SearchFilters } from './components/SearchFilters';
import { useImportInfo } from './hooks/useImportInfo';
import InfoTable from '../../../components/InfoTable';
import LoadingData from '../../../components/LoadingData';
import { ScreenAligned } from '../../../../components/ScreenAligned/ScreenAligned';
import { useTranslation } from '../../../../services/translation';
import { AnyObject, LibbyObject } from '../../../../types/types';
import { TitleBreadcrumbs } from '../../../../interfaces';

interface ImportsListProps {
  filter: AnyObject;
  setFilter: (makeFilter: object) => void;
  addCreate: (data: object) => void;
  data: AnyObject;
  working: boolean;
  libby: ImportsListProps;
  fetchMore: () => void;
  onSortChange?: (orderBy: string, direction: 'asc' | 'desc') => void;
  orderBy?: string;
  direction?: 'asc' | 'desc';
  title: TitleBreadcrumbs[];
  importationTargetId: string | number,
}

const ImportsListRaw = ({
  libby,
  data,
  working,
  filter,
  setFilter,
  addCreate,
  fetchMore,
  onSortChange,
  orderBy,
  direction,
  title,
  importationTargetId,
}: ImportsListProps) => {
  const { t } = useTranslation();
  const history = useHistory();
  const refSearchFilters = useRef<AnyObject>();
  const { path } = useRouteMatch();
  const pushImportDetail = useCallback(
    (importation_id: number) => history.push(`/imports/${importationTargetId}/detail/${importation_id}`),
    [history, importationTargetId],
  );

  const { rows, columns, ImportButtons } = useImportInfo(
    data,
    path,
    refSearchFilters,
  );

  const goToDetails = (row: LibbyObject) => {
    history.push(`${path}/detail/${row.importation_id}`);
  };

  return (
    <ScreenAligned title={title} additionalTitle={ImportButtons}>
      <SearchFilters
        ref={refSearchFilters}
        initFetch={filter}
        onFilterChange={setFilter}
        pushImportDetail={pushImportDetail}
        libby={libby}
        addCreate={addCreate}
        importationTargetId={importationTargetId}
      />

      <InfoTable
        columns={columns}
        rows={rows}
        onBottomScroll={fetchMore}
        onSortChange={onSortChange}
        onRowClick={goToDetails}
        direction={direction}
        orderBy={orderBy}
      />
      <LoadingData
        label={`${t('Loaded registers')}`}
        working={working}
        cant={rows.length}
      />
    </ScreenAligned>
  );
};

export const ImportsList = DatabaseConnector(ImportsListRaw)('app_importation');
