type getClassNamesProps = {
  productPicked: boolean;
  cartPicked: boolean;
  collectItemState: string;
  collectItemStateConst: string;
  classes: {
    nameSKU: string;
    nameSKUApprove: string;
    check: string;
    checkApprove: string;
    approveIcon: string;
    approveIconHidden: string;
    label: string;
    pointer: string;
    sku: string;
    skuApproved: string;
    serialNumber: string;
    serialNumberApproved: string;
    textCartDisable: string;
  };
};

export const getClassNames = ({
  productPicked,
  cartPicked,
  collectItemState,
  collectItemStateConst,
  classes,
}: getClassNamesProps) => {
  const borderedBox = productPicked
    || (collectItemState === collectItemStateConst)
    ? classes.nameSKUApprove : classes.nameSKU;

  const checkGreyCircle = productPicked
    || (collectItemState === collectItemStateConst)
    ? classes.checkApprove : classes.check;

  const checkGreyCircleCart = (cartPicked && productPicked)
    || (collectItemState === collectItemStateConst)
    ? classes.checkApprove : classes.check;

  const progressIcon = productPicked
    || (collectItemState === collectItemStateConst)
    ? classes.approveIcon : classes.approveIconHidden;

  const progressIconCart = (cartPicked && productPicked)
    || (collectItemState === collectItemStateConst)
    ? classes.approveIcon : classes.approveIconHidden;

  const productLabel = productPicked
    || (collectItemState === collectItemStateConst)
    ? classes.label : classes.pointer;

  const labelCart = (!cartPicked && productPicked)
    || (collectItemState === collectItemStateConst)
    ? classes.pointer : classes.label;

  const skuText = productPicked
    || (collectItemState === collectItemStateConst)
    ? classes.skuApproved : classes.sku;

  const serialNumber = productPicked
    || (collectItemState === collectItemStateConst)
    ? classes.serialNumberApproved : classes.serialNumber;

  let textCart = labelCart;
  if (cartPicked && productPicked && (collectItemState === collectItemStateConst)) {
    textCart = classes.textCartDisable;
  }

  return {
    textCart,
    serialNumber,
    skuText,
    labelCart,
    productLabel,
    progressIcon,
    progressIconCart,
    checkGreyCircle,
    checkGreyCircleCart,
    borderedBox,
  };
};
