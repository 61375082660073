import React from 'react';
import { useTranslation } from '../services/translation';
import { format, NumberFormat } from '../util';

type ChartDataFormat = NumberFormat;

export type ChartTooltipProps = {
  type?:string,
  payload?:Array<any>,
  label?:string,
  active?:boolean,
  format:ChartDataFormat
};

export function ChartTooltip(props:ChartTooltipProps) {
  const { t } = useTranslation();

  const { active } = props;
  if (active) {
    const { payload } = props;
    if (payload) {
      const label = t(payload[0].name);
      const value = format(payload[0].value, props.format);
      return (
        <div style={{ backgroundColor: 'white', padding: 5 }}>
          <p className="label">{`${label} : ${value}`}</p>
        </div>
      );
    }
  }
  return null;
}
