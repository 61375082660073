import React, { useState, useCallback, useMemo } from 'react';
import {
  Switch, Route,
  useParams,
  useRouteMatch,
  useHistory,
} from 'react-router-dom';
import { DatabaseConnector } from '@phinxlab/libby-rest-web';
import { AddAccount } from './routes/AddAccount';
import { AccountsList } from './routes/AccountsList';
import { makeFilter } from './routes/AccountsList/utils/makeFilter';
import { MakeRows } from './routes/AccountsList/utils/makeRows';
import { OptionsLibbyFetch, useLibbyFetch } from '../../hooks/useLibbyFetch';
import { LibbyObject, AnyObject } from '../../types/types';
import { useBreadcrumbs } from '../../hooks';

export const AccountsRouter = ({ libby }: LibbyObject) => {
  const match = useRouteMatch();
  const history = useHistory();
  const [direction, setDirection] = React.useState<'asc'|'desc'>('asc');
  const [orderBy, setOrderBy] = React.useState('username');

  const { id: account_id = '' } : { id: string } = useParams();

  const [filter, setFilter] = useState(makeFilter({ account_id }));

  const onChangeFilter = (value:object) => {
    if (JSON.stringify(filter) !== JSON.stringify(value)) { setFilter(value); }
  };

  const handleRequestSort = (newOrderBy: string, newDirection: 'asc' | 'desc') => {
    setOrderBy(newOrderBy);
    setDirection(newDirection);
  };

  const paramsFetch : OptionsLibbyFetch = useMemo(() => ({
    filter,
    daoName: 'admin_account',
    limit: 10,
    orderBy,
    direction,
  }), [filter, orderBy, direction]);

  const {
    data, working, fetchMore, addCreate, setMergedData,
  } = useLibbyFetch(libby, paramsFetch);

  const updateData = useCallback((dataUpdate :AnyObject) => {
    setMergedData((prev) => {
      const copy: AnyObject[] = [...prev];

      if (dataUpdate.account_id) {
        const result = copy.findIndex((value) => value.account_id === dataUpdate.account_id);
        copy[result] = dataUpdate;
      }

      return copy;
    });
  }, [setMergedData]);

  const addCreateWithHistory = useCallback((dataAdd: AnyObject) => {
    history.push('/accounts');
    addCreate(dataAdd);
  }, [addCreate, history]);

  const fetchMoreMemo = useCallback(() => {
    if (!working) {
      fetchMore();
    }
  }, [working, fetchMore]);

  const rows = MakeRows({
    data,
    match,
  });

  const title = useBreadcrumbs('Accounts');

  return (
    <Switch>
      <Route path={`${match.path}/add`}>
        <AddAccount title={title} addCreate={addCreateWithHistory} />
      </Route>

      <Route exact path={[`${match.path}`, `${match.path}/:id/:type`]}>
        <AccountsList
          title={title}
          updateData={updateData}
          working={working}
          match={match}
          rows={rows}
          setFilter={onChangeFilter}
          initFetch={filter}
          fetchMoreMemo={fetchMoreMemo}
          account_id={account_id}
          onSortChange={handleRequestSort}
          orderBy={orderBy}
          direction={direction}
        />
      </Route>
    </Switch>
  );
};

export const Accounts = DatabaseConnector(AccountsRouter)('admin_account');
