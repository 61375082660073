import { Moment } from 'moment';
import { Filter, subFilter } from '../../../../../types';
import { endDateFormatUtc, startDateFormatUtc } from '../../../../../utils';

type MakeFilterProps = {
  search?: string;
  assigned?: string;
  dispatch?: string;
  cart?: string;
  state?: subFilter[];
  startDate?: Moment | null;
  endDate?: Moment | null;
  courier?: string;
};

export const makeFilter = ({
  search,
  assigned,
  dispatch,
  cart,
  state,
  courier,
  startDate,
  endDate,
}: MakeFilterProps) => {
  const filter: Filter = {};
  if (search) {
    const searchArray = search
      .split(' ')
      .reduce((accumulator: Filter, item, index) => {
        accumulator[index] = [
          {
            path: 'dispatch.name',
            value: search,
            method: 'includes',
          },
          {
            path: 'cart.name',
            value: item,
            method: 'includes',
          },
          {
            path: 'account.username',
            value: item,
            method: 'includes',
          },
          {
            path: 'created_by.username',
            value: item,
            method: 'includes',
          },
          {
            path: 'updated_by.username',
            value: item,
            method: 'includes',
          },
        ];

        const numberSearch = parseInt(item, 10);
        if (numberSearch) {
          accumulator[index].push({
            path: 'collect_id',
            value: numberSearch,
          });
          accumulator[index].push({
            path: 'cart.cart_id',
            value: numberSearch,
          });
          accumulator[index].push({
            path: 'dispatch.dispatch_id',
            value: numberSearch,
          });
        }
        return accumulator;
      }, {});
    filter.search = [...searchArray[0]];
  }

  if (assigned && assigned !== '0') {
    filter.assigned = [
      {
        path: 'account.account_id',
        value: assigned,
      },
    ];
  }

  if (dispatch && dispatch !== '0') {
    filter.dispatch = [
      {
        path: 'dispatch.dispatch_id',
        value: dispatch,
      },
    ];
  }

  if (cart && cart !== '0') {
    filter.cart = [
      {
        path: 'cart.cart_id',
        value: cart,
      },
    ];
  }

  if (courier && courier !== '0') {
    filter.courier = [
      {
        path: 'dispatch.courier_service.courier.courier_id',
        value: courier,
      },
    ];
  }

  if (state?.length) {
    filter.state = state?.map((stateValue: subFilter) => ({
      path: 'collectState.collect_state_id',
      value: stateValue?.value,
    }));
  }

  if (startDate) {
    filter.startDate = [
      {
        path: 'created_at',
        value: startDateFormatUtc(startDate),
        method: 'higherOrEquals',
      },
    ];
  }
  if (endDate) {
    filter.endDate = [
      {
        path: 'created_at',
        value: endDateFormatUtc(endDate),
        method: 'lowerOrEquals',
      },
    ];
  }

  return filter;
};
