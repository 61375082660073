import React, {
  useCallback, useRef, useMemo,
} from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { ThemeProvider, makeStyles } from '@material-ui/styles';
import Button from '@material-ui/core/Button';
import { FormConfig } from 'react-final-form-hooks';
import { CSVLink } from 'react-csv';
import { DialogActions, DialogContentText } from '@material-ui/core';
import { useTranslation } from '../../services/translation';
import { AnyObject } from '../../types/types';

const dummyFunc = () => { };
const useStyles = makeStyles({
  inputContainer: {
    display: 'flex',
    flexDirection: 'column',
    '& > div:nth-child(1)': {
      marginRight: '10px',
    },
  },
  itemsContainer: {
    marginBottom: 15,
    display: 'list-item',
    listStylePosition: 'outside',
    listStyleType: 'circle',
  },
  download: {
    display: 'none',
  },
});

interface ExportDialogType extends FormConfig<any> {
  open: boolean,
  onCancel: any,
  customTheme: string | null,
  data: AnyObject,
  content?: string,
  ordersToCSV: (k:AnyObject)=> Array<Array<string>>,
  ordersColumnToCSV: Array<string>
}

export const ExportDialog = ({
  open = false,
  onCancel = dummyFunc,
  customTheme = null,
  data = [],
  content,
  ordersToCSV,
  ordersColumnToCSV,
}: ExportDialogType) => {
  const styles = useStyles();
  const { t } = useTranslation();
  const csvLink = useRef<any | null>();

  const handleSubmit = useCallback((event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (csvLink && csvLink.current && csvLink.current.link) {
      csvLink.current.link.click();
    }
    onCancel();
  }, [onCancel]);

  const rowsCSV = useMemo(() => (ordersToCSV ? ordersToCSV(data) : []),
    [data, ordersToCSV]);

  const fileName = t('Orders').concat('.csv');
  const dialog = (
    <Dialog open={open} onClose={onCancel} fullWidth aria-labelledby="form-dialog-title" aria-describedby="alert-dialog-description">
      <DialogTitle id="form-dialog-title">{t('Export Orders')}</DialogTitle>
      <form noValidate onSubmit={handleSubmit}>
        <DialogContent className={styles.inputContainer}>
          <DialogContentText>{content}</DialogContentText>
          <CSVLink
            filename={fileName}
            headers={ordersColumnToCSV}
            data={rowsCSV}
            className={styles.download}
            target="_blank"
            separator=";"
            ref={csvLink}
          />

        </DialogContent>
        <DialogActions>
          <Button color="primary" type="submit" style={{ marginBottom: 16 }}>
            {t('Export')}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );

  return !customTheme ? dialog : <ThemeProvider theme={customTheme}>{dialog}</ThemeProvider>;
};
