import React, { useEffect, useState } from 'react';
import { DatabaseConnector } from '@phinxlab/libby-rest-web';
import { GeneralSelect } from '../../components/GeneralSelect';
import { AnyObject, LibbyObject } from '../../types/types';
import { OptionsLibbyFetch, useLibbyFetch } from '../../hooks';
import { Dispatch_zone } from '../../interfaces/business';

type SelectDispatchZoneProps = {
  inputProps: AnyObject;
  optionAll?: boolean;
  [k: string]: any;
  libby: LibbyObject;
  initName?: string;
};

const paramsFetch: OptionsLibbyFetch = {
  daoName: 'ster_dispatch_zone_enabled',
  orderBy: 'name',
};

export const SelectDispatchZoneRaw = ({
  libby,
  inputProps = {},
  optionAll = false,
  initName,
  ...styles
}: SelectDispatchZoneProps) => {
  const {
    data: dataRaw,
    working,
  }: { data: AnyObject; working: boolean } = useLibbyFetch(libby, paramsFetch);

  const [dataSelect, setdataSelect] = useState<
  Array<{ id: number; value: any; name?: string }>
  >(
    inputProps.value
      ? [
        {
          id: inputProps.value,
          value: initName,
        },
      ]
      : [],
  );

  useEffect(() => {
    setdataSelect((prev) => {
      const copyDataSelect = [...prev];

      dataRaw.forEach(({ dispatch_zone_id, name }: Dispatch_zone) => {
        copyDataSelect.push({
          id: parseInt(dispatch_zone_id, 10),
          value: name,
        });
      });

      return copyDataSelect;
    });
  }, [dataRaw]);

  return (
    <GeneralSelect
      working={working}
      label="Dispatch Zone"
      placeholder="Assign Dispatch Zone"
      optionAll={optionAll}
      data={dataSelect}
      inputProps={inputProps}
      {...styles}
    />
  );
};

export const SelectDispatchZone = DatabaseConnector(SelectDispatchZoneRaw)(
  'ster_dispatch_zone_enabled',
);
