import {
  useState, useEffect, useCallback,
} from 'react';
import { subFilter, Filter } from '../../../../../types';
import useDebounce from '../../../../../hooks/useDebounce';

interface UseInitFiler {
  initFetch: subFilter[];
  init?: string | null;
  onFilterChange: (makeFilter: object) => void;
  id: string;
  makeFilter: any;
  join?: boolean
}

export const useInitFilterDebounced = ({
  initFetch,
  onFilterChange,
  id,
  makeFilter,
  join = false,
}: UseInitFiler) => {
  const searchId = useCallback(
    (initFetchData) => {
      const allId = initFetchData.reduce(
        (ordersId: string[], { value }: subFilter) => {
          const copyOrdersId = [...ordersId];
          copyOrdersId.push(value.toString());
          return copyOrdersId;
        },
        [],
      );
      return allId.join();
    },
    [],
  );

  const initValue = join && initFetch?.[0]?.value ? searchId(initFetch) : initFetch?.[0]?.value;

  const [search, setSearch] = useState<string>(String(initValue || ''));

  useEffect(() => {
    if (!initValue) {
      setSearch('');
    }
  }, [initValue]);

  const searchDebounced = useDebounce(search, 500);

  const change = useCallback(
    (valueFilter) => {
      onFilterChange((prev: Filter) => {
        const copy = { ...prev };
        delete copy[id];
        return { ...copy, ...valueFilter };
      });
    },
    [id, onFilterChange],
  );

  useEffect(() => {
    const valueFilter = makeFilter({ [id]: searchDebounced });
    const valueFilterValidate = join && valueFilter[id]?.[0]?.value
      ? searchId(valueFilter[id])
      : valueFilter[id]?.[0]?.value;
    if (valueFilterValidate !== initValue) {
      change(valueFilter);
    }
  }, [searchDebounced, change, id, makeFilter, initValue, search, join, searchId]);

  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setSearch(event.target.value);
    },
    [setSearch],
  );

  return {
    search,
    handleChange,
  };
};
