import React, {
  ReactNode,
  useCallback,
  useEffect,
  useState,
  useMemo,
} from 'react';
import { useHistory } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { ORDER_STATE } from '../../../const/OrderState';
import { AnyObject, LibbyObject } from '../../../types/types';
import { useLibbyFetch, useCheckAll } from '../../../hooks';
import { useTranslation } from '../../../services/translation';
import { Collect } from '../types/Collect';
import { formatDate } from '../../../utils';
import { LaunchIconComponent } from '../../../components/Icon/LaunchIcon';
import { makeFilterCollectOrder } from '../routes/CollectOrder/utils/makeFilterCollectOrder';
import { columnsAddOrder } from '../utils';
import {
  StateRowsComponent,
  CreatedAtRowsComponent,
} from '../../../components/FilterRows';
import { DISPATCH_ITEM_STATE } from '../../../const/DispatchItemState';
import { TitleBreadcrumbs } from '../../../interfaces';
import { Dispatch_item } from '../../../interfaces/business';

interface useCollectsAddOrderProp {
  libby: LibbyObject;
  id: string;
  handleUpdateCollectList: (collect: Collect, id: string) => void;
  dataGeneral?: Array<Collect>;
  dispatchId?: string;
}

export type orderArrayItemsType = {
  id?: string;
  order_id?: string;
  order_state_id?: string;
  order_state_name?: string;
  document?: string;
  phone_number?: string;
  buyer?: string;
  itemsName?: string;
  itemsQuantity?: string;
  updated_by?: string;
  courier?: string;
  updated_at?: string;
  so_number?: string;
  Collect_item_id?: string;
  delete?: ReactNode;
};

export const useCollectsAddOrder = ({
  libby,
  id,
  dataGeneral,
  dispatchId,
  handleUpdateCollectList,
}: useCollectsAddOrderProp) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const [orderItems, setOrderItems] = useState<Dispatch_item[]>([]);
  const [data, setdata] = useState<AnyObject>();
  const history = useHistory();

  const {
    checked,
    columnAll,
    handleCheckId,
    resetCheck,
    handleCheckIdStatus,
    handleCheckChieldElement,
  } = useCheckAll(orderItems, 'dispatch_item_id');

  const [filter, setFilter] = useState(makeFilterCollectOrder({}));

  const [orderBy, setOrderBy] = useState('collect_id');
  const [direction, setDirection] = useState<'asc' | 'desc'>('asc');
  const [orderByAux, setOrderByAux] = useState('priority');
  const [directionAux, setDirectionAux] = useState<'asc' | 'desc'>('desc');
  const onChangeFilter = (value: object) => {
    if (JSON.stringify(filter) !== JSON.stringify(value)) {
      setFilter(value);
    }
  };

  const handleRequestSort = (
    newOrderBy: string,
    newDirection: 'asc' | 'desc',
  ) => {
    if (newOrderBy !== 'priority') {
      setOrderBy(newOrderBy);
      setDirection(newDirection);
    }
    setOrderByAux(newOrderBy);
    setDirectionAux(newDirection);
  };

  const paramsFetch = useMemo(
    () => ({
      daoName: 'ster_dispatch_collect_add_order',
      aspect: 'dispatch_collect_delete_items',
      filter: {
        collectId: [
          {
            path: 'collect_id',
            value: id,
          },
        ],
        collectOrderState: [
          {
            path: 'dispatch.items.order.state.order_state_id',
            value: ORDER_STATE.WAITING_FOR_PREPARATION,
          },
        ],
        dispatchItemState: [
          {
            path: 'dispatch.items.dispatch_item_state.dispatch_item_state_id',
            value: DISPATCH_ITEM_STATE.ASSIGNED,
          },
        ],
        ...filter,
      },
      orderBy,
      direction,
    }),
    [filter, orderBy, direction, id],
  );

  const { working, data: dataDetails } = useLibbyFetch(libby, paramsFetch);

  const dataNewGeneral = useMemo(
    () => dataGeneral?.find((collect: Collect) => collect.collect_id === id),
    [dataGeneral, id],
  );
  useEffect(() => {
    if (dataNewGeneral?.items.length === 0) {
      setOrderItems([]);
    }
  }, [dataNewGeneral]);

  useEffect(() => {
    if (dataDetails && dataDetails?.length) {
      setdata(dataDetails[0]);
      const addPriority = dataDetails[0].dispatch.items.map(
        (itemDispatch: Dispatch_item) => ({
          ...itemDispatch,
          order: {
            ...itemDispatch.order,
            priority: Math.floor(Math.random() * (3 + 1 - 1) + 1),
          },
        }),
      );

      setOrderItems(addPriority);
    } else {
      setOrderItems([]);
    }
  }, [dataDetails]);

  const addItemInCollect = useCallback(
    async (title: TitleBreadcrumbs[] = []) => {
      try {
        const collectData = { ...dataDetails[0] };
        const dispatchItems = checked.all;

        const collectItems = [...collectData.items];
        dispatchItems.forEach((dispatchItem) => {
          collectItems.push({
            dispatch_item: { ...dispatchItem },
          });
        });

        const addItems = await libby.ster_dispatch_collect_details
          .aspect('add')
          .save({ ...collectData, items: collectItems });

        handleUpdateCollectList(addItems, 'collect_id');

        enqueueSnackbar(t('Orders saved'), { variant: 'success' });

        history.push({
          pathname: dispatchId
            ? `/dispatches/detail/${dispatchId}/collects/detail/${id}`
            : `/collect/detail/${id}`,
          state: {
            goBack: title,
          },
        });
      } catch (error) {
        enqueueSnackbar(t('Something is wrong'), { variant: 'error' });
      }
    },
    [
      libby,
      enqueueSnackbar,
      t,
      history,
      checked,
      id,
      dispatchId,
      dataDetails,
      handleUpdateCollectList,
    ],
  );

  const rows = useMemo(
    () => orderItems.map(
      ({
        dispatch_item_id,
        order: {
          order_id,
          so_number,
          buyer,
          total,
          state,
          created_at,
          updated_at,
          number_items,
          priority,
        },
      }) => ({
        id: order_id || '',
        select_order_id: handleCheckId(dispatch_item_id),
        select_order_id_state: handleCheckIdStatus(dispatch_item_id),
        select_order_id_change: () => handleCheckChieldElement(dispatch_item_id),
        order_id,
        so_number,
        total,
        buyer: `${buyer?.first_name} ${buyer?.last_name}`,
        number_items,
        items: (
          <LaunchIconComponent
            order_id={order_id}
            number_items={number_items}
          />
        ),
        state: (
          <StateRowsComponent
            order_state_id={state?.order_state_id || ''}
            name={state?.name || ''}
          />
        ),
        created_at: (
          <CreatedAtRowsComponent created_at={updated_at || created_at} />
        ),
        date_change: formatDate(updated_at || created_at, false),
        priority,
      }),
    ),
    [orderItems, handleCheckId, handleCheckChieldElement, handleCheckIdStatus],
  );

  const columns = [columnAll, ...columnsAddOrder];

  return {
    working,
    columns,
    rows,
    data: dataNewGeneral || data,
    addItemSelect: addItemInCollect,
    handleRequestSort,
    onChangeFilter,
    filter,
    orderBy: orderByAux,
    direction: directionAux,
    checked,
    resetCheck,
  };
};
