import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { useTranslation } from '../../../services/translation';

const useStyles = makeStyles(() => ({
  titleContainer: {
    marginBottom: '32px',
  },
  separate: {
    marginBottom: 20,
  },
  title: {
    textTransform: 'uppercase',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '20px',
    lineHeight: '28px',
  },
  children: {
    width: '100%',
  },
}));

type SectionTitle = {
  title: string,
  children: React.ReactNode,
  separation?: boolean
};
export const SectionTitle = ({ title, children, separation }: SectionTitle) => {
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <Grid
      container
      direction="column"
      className={`${separation ? classes.separate : ''}`}
    >
      <Grid className={classes.titleContainer}>
        <Typography className={classes.title}>
          {t(title)}
        </Typography>
      </Grid>
      <Grid className={classes.children}>
        {children}
      </Grid>
    </Grid>
  );
};
