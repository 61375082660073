import { LibbyFetchDAO } from './LibbyFetchDAO';

export class DispatchZoneDAO extends LibbyFetchDAO {
  constructor() {
    super('ster_dispatch_zone', 'dispatch_zone_id');
  }

  getAll() {
    return this.query()
      .limit(0, 10000)
      .run();
  }
}
