import { LibbyFetchDAO } from './LibbyFetchDAO';

export class LogisticsServiceDAO extends LibbyFetchDAO {
  constructor() {
    super('courier_service', 'courier_service_id');
  }

  getAll() {
    return this.query()
      .limit(0, 10000)
      .run();
  }
}
