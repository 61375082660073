import { subFilter, Filter } from '../../../types/Filter';

type MakeFilterProps = {
  assigned_id?: string;
  collect_id?: string;
  collect_state_id?: string[];
};

export const makeFilter = ({
  assigned_id,
  collect_state_id,
}: MakeFilterProps) => {
  const filter: Filter = {};

  if (assigned_id) {
    filter.assigned_id = [
      {
        path: 'collects.account.account_id',
        value: assigned_id,
      },
    ];
  }

  if (collect_state_id?.length) {
    const state: subFilter[] = [];
    collect_state_id.forEach((state_id: string) => {
      state.push({
        path: 'collects.collectState.collect_state_id',
        value: state_id,
      });
    });
    filter.collect_state_id = state;
  }

  return filter;
};
