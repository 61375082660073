import { useCallback } from 'react';
import { LibbyObject } from '../../../types';
import { useLibbyCall } from '../../../hooks';
import confirmDialog from '../../../services/confirmDialog';
import { useTranslation } from '../../../services/translation';

interface useCartEnabledProp {
  libby: LibbyObject;
  id: string;
}

export const useCartEnabled = ({ libby, id }: useCartEnabledProp) => {
  const { data, working, recall } = useLibbyCall(libby, {
    daoName: 'ster_dispatch_cart_box',
    methodName: 'getCart',
    params: [id],
  });

  const { t } = useTranslation();

  const recallCart = useCallback(
    async (idParam?: any) => {
      if (recall && idParam) {
        recall(idParam);
      } else if (recall) {
        recall();
      }
    },
    [recall],
  );

  const alertCartEnabled = useCallback(
    async () => {
      await confirmDialog.show({
        title: t('Warning'),
        content: `${t(
          'It has $$$$ positions available in the cart. You cannot select greater than the available quantity',
        ).replace('$$$$', data.length)}`,
        confirmText: `${t('Ok')}!`,
        cancelText: t('Cancel'),
      });
    },
    [t, data],
  );

  return {
    working,
    data,
    recallCart,
    alertCartEnabled,
  };
};
