/* eslint-disable no-console */
import 'moment/locale/es';
import moment from 'moment';
import es from './files/es';

export type TranslationFile = {
  [key: string]: string,
};

export type SubscriptionFunction = (k: string) => void;
export type UnsubscriptionFunction = () => void;

export type TranslationFunction = (str: string) => string;

let activeFile: TranslationFile | null = null;
let activeKey = 'en';
let subscriptions: SubscriptionFunction[] = [];

class TranslateService {
  static initialize() {
    const language = localStorage.getItem('language') || 'es';
    if (language) {
      TranslateService.setLanguage(language);
    }
  }

  static registerMissing(key: string) {
    const missingString = localStorage.getItem('language_missing');
    const missings = missingString ? JSON.parse(missingString) : {};
    if (!missings[key]) {
      // console.log('Missing translation:', key);
      missings[key] = key;
      localStorage.setItem('language_missing', JSON.stringify(missings));
    }
  }

  static t(str: string): string {
    if (!activeFile) {
      return str;
    }
    if (!activeFile[str]) {
      TranslateService.registerMissing(str);
      return str;
    }
    return activeFile[str];
  }

  static setLanguage(key: string) {
    activeKey = key;
    if (key === 'es') {
      activeFile = es;
    } else {
      activeFile = null;
    }
    moment.locale(key || 'en');
    localStorage.setItem('language', key);
    subscriptions.forEach((func) => func(key));
  }

  static getLanguage() {
    return activeKey;
  }

  static subscribe(func: SubscriptionFunction): UnsubscriptionFunction {
    subscriptions = [...subscriptions, func];
    return () => {
      subscriptions = subscriptions.filter((item) => item !== func);
    };
  }
}

export default TranslateService;
