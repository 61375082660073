import React, { ReactNode, useRef, useMemo } from 'react';
import { Grid } from '@material-ui/core';
import InfoTable from '../../routes/components/InfoTable';
import { Screen } from '../Screen/Screen';
import { ScreenTitle } from '../ScreenTitle';
import { TitleBreadcrumbs } from '../../interfaces';
import LoadingData from '../../routes/components/LoadingData';
import { useTranslation } from '../../services/translation';
import { RowsType } from '../../utils/tables';
import { HeadCellsType } from '../../types';
import { useContainerDimensions } from '../../hooks/useContainerDimensions';
import { AnyObject } from '../../types/types';

type ScreenAlignedWithFilterAndLoaderProps = {
  title?: TitleBreadcrumbs[] | string;
  working: boolean | undefined;
  additionalTitle?: React.ReactElement | null;
  rows: Array<RowsType>;
  columns: Array<HeadCellsType>;
  fetchMore: () => void;
  onSortChange?: (orderBy: string, direction: 'asc' | 'desc') => void;
  orderBy?: string;
  direction?: 'asc' | 'desc';
  rowId: string;
  loaderMsg: string;
  children?: ReactNode;
  onRowClick?: (row: AnyObject) => void;
  RowComponent?: React.ElementType;
};

export const ScreenAlignedWithFilterAndLoader = React.memo(
  ({
    title,
    working,
    additionalTitle = null,
    rows,
    columns,
    fetchMore,
    rowId,
    onSortChange,
    direction,
    orderBy,
    loaderMsg,
    children,
    onRowClick,
    RowComponent,
  }: ScreenAlignedWithFilterAndLoaderProps) => {
    const { t } = useTranslation();
    const componentRef = useRef<HTMLInputElement>(null);
    const { width } = useContainerDimensions(componentRef);
    const mobileList = useMemo(() => width < 540 && !!RowComponent, [
      width,
      RowComponent,
    ]);

    return (
      <div ref={componentRef}>
        <Screen>
          <Grid
            container
            wrap="nowrap"
            alignItems="center"
            justify="flex-start"
          >
            <ScreenTitle title={title || ''}>
              <Grid container spacing={1}>
                {additionalTitle}
              </Grid>
            </ScreenTitle>
          </Grid>

          {/* search */}
          {!!children && children}

          <InfoTable
            columns={columns}
            rows={rows}
            onBottomScroll={fetchMore}
            rowIdKey={rowId}
            onRowClick={width < 540 ? onRowClick : () => {}}
            onSortChange={onSortChange}
            orderBy={orderBy}
            direction={direction}
            RowComponent={RowComponent}
            rowComponentShow={mobileList}
            rootBackground={!mobileList}
            elevation={!mobileList}
          />

          <LoadingData
            label={`${t(loaderMsg)}`}
            working={working}
            cant={rows.length}
          />
        </Screen>
      </div>
    );
  },
);
