import React, {
  useCallback, useState, useRef, useEffect,
} from 'react';
import { Base64 } from 'js-base64';
import {
  Grid, Typography, IconButton, TextFieldProps,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import DeleteIcon from '@material-ui/icons/Delete';
import PublishIcon from '@material-ui/icons/Publish';
import { useSnackbar } from 'notistack';
import { AnyObject } from '../../../../../types/types';
import { useTranslation } from '../../../../../services/translation';
import { getExtension } from '../utils/getExtension';
import EditModal from '../../../../../services/formDialog';
import {
  SelectDelimiter, formInitialValues, validate, inputName,
} from '../../../../../components/SelectDelimiter';

let fileReader: AnyObject;
type FileInputProps = {
  filename?: string,
  onUpdateFiles: (filename: string, base64Content: string, originalFilename: string, extension: string, delimiter: string) => void,
  onDeleteImport: (name: string) => void,
  base64?: string,
  url?: string,
  [k: string]: any,
  originalFilename?: string,
};

const useStyles = makeStyles({
  containerInput: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    height: '50px',
  },
  label: {
    display: 'flex',
    flexWrap: 'nowrap',
    alignItems: 'center',
    paddingRight: 10,
  },
  labelText: {
    paddingRight: 10,
  },
  icon: {
    minWidth: 50,
    minHeight: 50,
  },
  fileInput: {
    display: 'none',
  },
});

export const FileInput = ({
  filename: currentFilename,
  onUpdateFiles,
  onDeleteImport,
  url,
  base64,
  originalFilename,
}: FileInputProps) => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();

  const fileRef = useRef<HTMLInputElement | null>(null);
  const [userFilename, setUserFilename] = useState('');
  const handleFileRead = useCallback(async (event) => {
    const paramsModal = {
      inputType: 'select',
      inputName,
      confirmText: t('Yes'),
      cancelText: t('No'),
      title: t('CSV Delimiter'),
      content: t('What delimiter does the uploaded file use?'),
      labelContent: t('Delimiter'),
      select: ({ inputProps }: { inputProps: TextFieldProps }) => <SelectDelimiter inputProps={inputProps} />,
      formInitialValues,
      validate,
    };
    let error = false;
    // FileReader.result is always string or null when we use fileReader.readAsText() method.
    try {
      let delimiter = '';
      const base64ToSave = Base64.encode(fileReader.result as string);
      const extension = getExtension(fileReader.fileName);
      if (extension === 'csv') {
        try {
          const { select_name } = (await EditModal.show(paramsModal)) as { select_name: string };
          delimiter = +select_name === 1 ? ';' : ',';
        } catch (e) {
          error = true;
          enqueueSnackbar(`${t('Delimiter Required')}`, { variant: 'error' });
        }
      }
      if (!error) {
        onUpdateFiles(currentFilename || '', base64ToSave, fileReader.fileName, extension, delimiter);
        setUserFilename(event.target.fileName || '');
      }
    } catch (e) {
      enqueueSnackbar(`${t('Cannot upload file, reason:')} ${e.message}`, { variant: 'error' });
    }
  }, [currentFilename, onUpdateFiles, t, enqueueSnackbar]);

  const handleFileChosen = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    const target = e.target as HTMLInputElement;
    const file: File = (target.files as FileList)[0];
    fileReader = new FileReader();
    fileReader.fileName = file && file.name;
    fileReader.onloadend = handleFileRead;
    if (file) {
      fileReader.readAsText(file);
    }
  }, [handleFileRead]);
  // eslint-disable-next-line no-nested-ternary
  const userFileHtml = (!!userFilename && !!base64 && !url) ? ` (${userFilename})` : originalFilename ? `(${originalFilename})` : '';

  useEffect(() => {
    // when any input is deleted we must reset all manually
    if (!url && !base64 && fileRef && fileRef.current) {
      fileRef.current.value = '';
    }
  }, [url, base64]);

  return (
    <Grid item xs={12} className={classes.containerInput}>
      <label htmlFor={currentFilename} className={classes.label}>
        {url ? (
          <IconButton
            onClick={() => { onDeleteImport(currentFilename || ''); }}
            component="span"
          >
            <DeleteIcon />
          </IconButton>
        ) : (
          <IconButton
            component="span"
          >
            <PublishIcon />
            <input type="file" ref={fileRef} id={currentFilename} className={classes.fileInput} onChange={handleFileChosen} />
          </IconButton>
        )}
        <Typography className={classes.labelText} variant="h5">
          {`${currentFilename} ${userFileHtml}`}
        </Typography>
      </label>
    </Grid>
  );
};
