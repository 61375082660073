import React, { useState, useEffect } from 'react';
import Box from '@material-ui/core/Box';
import { orderBy as lodashOrderBy } from 'lodash';
import { HeadCellsType } from '../../../../../types';
import InfoTable from '../../../../components/InfoTable';
import { AddOrderItem } from './AddOrderItem';
import LoadingData from '../../../../components/LoadingData';
import { useTranslation } from '../../../../../services/translation';
import { RowsType } from '../../../../../utils/tables';

export interface OrderItemsProps {
  rows: Array<RowsType>;
  columns: Array<HeadCellsType>;
  working?: boolean;
  checked?: any;
  id?: string;
  children?: React.ReactNode;
  addItemInOrderItems?: (orderId: any) => void;
}

export const OrderItems = ({
  columns,
  working,
  rows,
  children,
  addItemInOrderItems,
}: OrderItemsProps) => {
  const { t } = useTranslation();
  const [orderBy, setOrderBy] = useState('order_id');
  const [direction, setDirection] = useState<'asc' | 'desc'>('asc');
  const [data, setData] = useState<RowsType[]>(rows);

  useEffect(() => {
    setData(
      lodashOrderBy(
        rows,
        (o: RowsType) => (orderBy === 'total' || orderBy === 'order_id'
          ? Number(o[orderBy as keyof typeof o])
          : o[orderBy as keyof typeof o]),
        [direction],
      ),
    );
  }, [rows, direction, orderBy]);

  const onOrderBy = (property: string, order: 'asc' | 'desc') => {
    setOrderBy(property);
    setDirection(order);
  };

  return (
    <Box my={3}>
      {children}
      { !!addItemInOrderItems && (
        <AddOrderItem
          addItemInOrderItems={addItemInOrderItems}
          working={working}
        />
      )}

      <InfoTable
        columns={columns}
        rows={data}
        height={350}
        rowIdKey="order_id"
        onSortChange={onOrderBy}
        orderBy={orderBy}
        direction={direction}
      />
      <LoadingData
        label={`${t('Loaded registers')}`}
        working={working}
        cant={data.length}
      />
    </Box>
  );
};
