import React from 'react';
import TableContainer from '@material-ui/core/TableContainer';
import Table from '@material-ui/core/Table';
import TableCell, { TableCellProps } from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/styles';
import { Button, Grid } from '@material-ui/core';
import { CSVLink } from 'react-csv';
import { useTranslation } from '../../services/translation';
import { AnyObject } from '../../types/types';
import { EnhancedTableHead } from '../../components/EnhancedTableHead/EnhancedTableHead';
import LoadingData from './LoadingData';
import { InfoTableRow } from './InfoTableRow';
import { RowsType } from '../../utils/tables';
import { DataFilterDownloadInterface, DataFilterDownloadInterfaceSpanish } from '../../interfaces/business/general/DataFilterDownload';

export type InfoTableGridSize =
  | 1
  | 2
  | 3
  | 4
  | 5
  | 6
  | 7
  | 8
  | 9
  | 10
  | 11
  | 12;
const useStyles = makeStyles({
  root: {
    width: '100%',
    marginTop: '15',
    background: (value: AnyObject) => (value.rootBackground ? '#ffffff' : 'none'),
  },
  tableContainer: {
    maxHeight: (value: AnyObject) => value.height,
    height: (value: AnyObject) => value.height,
  },
  search: {
    display: 'flex',
    justifyContent: 'center',
  },
  caption: {
    height: '15px',
  },
  cell: {
    padding: '10px 16px',
    textAlign: 'left',
  },
  cellNotPadding: {
    padding: '0px 16px',
  },
  loading: {
    width: '100%',
    height: '100%',
  },
  rowNotPadding: {
    height: '30px',
  },
  rootDownloadCSV: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginBottom: '10px',
  },
  csvLink: {
    textDecoration: 'none',
    color: 'white',
  },
});

export type Column = {
  id: string;
  minWidth?: number;
  label: string;
  align?: TableCellProps['align'];
  // TODO: fix this, adding render to the type make fails in a lot of places
  // render?: (row: any, column?: Column, t?: (str: string) => string) => any,
} & AnyObject;

export interface InfoTableProps {
  onBottomScroll?: () => void;
  columns: Array<Column>;
  rows: RowsType[];
  onRowClick?: (row: RowsType) => void;
  onDoubleClick?: (row: AnyObject) => void;
  rowIdKey?: string;
  onSortChange?: (orderBy: string, direction: 'asc' | 'desc') => void;
  onSortChangeInFront?: (orderBy: string) => void;
  orderBy?: string;
  direction?: 'asc' | 'desc';
  height?: number | 'auto';
  xs?: InfoTableGridSize;
  sm?: InfoTableGridSize;
  md?: InfoTableGridSize;
  lg?: InfoTableGridSize;
  xl?: InfoTableGridSize;
  working?: boolean;
  padding?: boolean;
  orderInFront?: boolean;
  RowComponent?: React.ElementType;
  rootBackground?: boolean;
  elevation?: boolean;
  rowComponentShow?: boolean;
  download?: boolean;
  data?: DataFilterDownloadInterface[] | DataFilterDownloadInterfaceSpanish[];
}

const InfoTable = ({
  columns,
  rows,
  onBottomScroll,
  onRowClick,
  onDoubleClick,
  rowIdKey = 'id',
  onSortChange,
  orderBy,
  direction: order = 'asc',
  height = 450,
  xs = 12,
  sm,
  md,
  lg,
  xl,
  working,
  padding = true,
  RowComponent,
  rootBackground = true,
  elevation = true,
  rowComponentShow = false,
  download,
  data,
}: InfoTableProps) => {
  const { t } = useTranslation();
  const classes = useStyles({ height, rootBackground });
  const handleScroll = (e: any) => {
    const bottom = e.target.scrollHeight - e.target.scrollTop <= e.target.clientHeight + 200;
    if (bottom) {
      onBottomScroll?.();
    }
  };

  const handleRequestSort = (event: AnyObject, property: any) => {
    if (onSortChange) {
      onSortChange(property, order === 'asc' ? 'desc' : 'asc');
    }
  };

  return (
    <Grid
      item
      {...{
        xs,
        sm,
        md,
        lg,
        xl,
      }}
    >
      {download
      && (
      <Grid className={classes.rootDownloadCSV}>
        <Button variant="contained" color="primary">
          <CSVLink className={classes.csvLink} data={data || ''}>
            {t('Download')}
          </CSVLink>
        </Button>
      </Grid>
      )}
      <Paper className={classes.root} elevation={elevation ? 1 : 0}>
        <TableContainer
          className={classes.tableContainer}
          onScroll={handleScroll}
        >
          <Table stickyHeader aria-label="sticky table">
            {!rowComponentShow && (
              <EnhancedTableHead
                headCells={columns}
                order={order}
                orderBy={orderBy}
                onRequestSort={handleRequestSort}
                rowCount={rows.length}
              />
            )}
            <TableBody>
              {rows.map((row: RowsType) => (
                <InfoTableRow
                  key={row[rowIdKey]}
                  onClick={onRowClick}
                  onDoubleClick={onDoubleClick}
                  padding={padding}
                  row={row}
                  rowBackground={row.color_row}
                >
                  <>
                    {rowComponentShow && RowComponent ? (
                      <RowComponent row={row} />
                    ) : (
                      columns.map((column) => {
                        const value = row[column.id];
                        return (
                          <TableCell
                            key={column.id}
                            align={column.align}
                            className={
                                padding ? classes.cell : classes.cellNotPadding
                              }
                          >
                            {column.render
                              ? column.render(row, column, t)
                              : column.translate
                                ? t(value)
                                : value}
                          </TableCell>
                        );
                      })
                    )}
                  </>
                </InfoTableRow>
              ))}
            </TableBody>
          </Table>
          {working && (
            <Grid
              container
              justify="center"
              alignItems="center"
              className={classes.loading}
            >
              <LoadingData working={working} />
            </Grid>
          )}
        </TableContainer>
      </Paper>
    </Grid>
  );
};

export default React.memo(InfoTable);
