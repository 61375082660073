import React, { useMemo, useCallback } from 'react';
import { DatabaseConnector } from '@phinxlab/libby-rest-web';
import { Autocomplete } from '@material-ui/lab';
import { TextField } from '@material-ui/core';
import { AnyObject, LibbyObject } from '../../types/types';
import { OptionsLibbyFetch, useLibbyFetch } from '../../hooks';
import { useTranslation } from '../../services/translation';
import { AllMarketplace } from '../../models/allMarketplace';
import { Marketplace } from '../../const/Marketplace';
import { subFilter } from '../../types';

type SelectMarketPlaceTypeProps = {
  onChange: (value: subFilter[]) => void;
  libby: LibbyObject;
  allMarketplace: any;
};

const paramsFetch: OptionsLibbyFetch = {
  daoName: 'ster_marketplace',
};

export const SelectAllMarketPlaceRaw = ({
  onChange,
  libby,
  allMarketplace,
}: SelectMarketPlaceTypeProps) => {
  const {
    data: dataRaw,
  }: { data: AnyObject; working: boolean } = useLibbyFetch(libby, paramsFetch);

  const data = useMemo(
    () => dataRaw.map(({ marketplace_id, name }: AnyObject) => ({
      id: marketplace_id,
      name,
    })),
    [dataRaw],
  );
  const { t } = useTranslation();

  const onChangeAutoComplete = useCallback(
    (event, newValue: AllMarketplace[]) => {
      onChange(newValue.map((marketplace: AllMarketplace) => ({
        value: marketplace.id,
        path: '',
      })));
    },
    [onChange],
  );

  const autocompleteValue = useMemo(
    () => (allMarketplace?.length
      ? allMarketplace.map((marketplace: any) => ({
        id: marketplace.value,
        name: Marketplace[marketplace.value],
      }))
      : []),
    [allMarketplace],
  );

  const getOptionLabel = useCallback((option:any) => option.name, []);

  return (
    <Autocomplete
      value={autocompleteValue}
      style={{ display: 'inline-block', width: '100%' }}
      multiple
      id="tags-outlined"
      options={data}
      getOptionLabel={getOptionLabel}
      filterSelectedOptions
      onChange={onChangeAutoComplete}
      getOptionSelected={(option: any, _value: any) => option.id === _value.id}
      renderInput={(params) => (
        <TextField
          {...params}
          label={t('All the marketplaces')}
          placeholder=""
        />
      )}
    />
  );
};

export const SelectAllMarketPlace = DatabaseConnector(SelectAllMarketPlaceRaw)(
  'ster_marketplace',
);
