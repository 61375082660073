import React, {
  useCallback,
  MouseEventHandler,
} from 'react';
import {
  Grid,
  Menu,
  MenuItem,
  IconButton,
  Typography,
} from '@material-ui/core';
import { MoreVert } from '@material-ui/icons';
import { createStyles, makeStyles } from '@material-ui/styles';
import { LoadingTable } from '../LoadingTable';
import { useTranslation } from '../../services/translation';

const useStyles = makeStyles(() => createStyles({
  anchor: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    margin: '0px 10px',
    width: '90%',
  },
}));

export type MenuItemComponentType = {
  onClick: MouseEventHandler;
  title: string;
  disabled: boolean;
  loading?: boolean;
  color?:
  | 'initial'
  | 'inherit'
  | 'primary'
  | 'secondary'
  | 'textPrimary'
  | 'textSecondary'
  | 'error';
  className?: string;
};

type MenuActionsType = {
  menuArray:MenuItemComponentType[],
};

export const MenuActions = ({ menuArray } :MenuActionsType) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const { t } = useTranslation();
  const classes = useStyles();
  const handleClose = useCallback(
    (e) => {
      e.stopPropagation();
      setAnchorEl(null);
    },
    [setAnchorEl],
  );

  const handleMenu = useCallback(
    (event) => {
      event.stopPropagation();
      setAnchorEl(event.currentTarget);
    },
    [setAnchorEl],
  );

  const MenuItemComponent = ({
    onClick,
    title,
    disabled,
    loading,
    color = 'initial',
  }: MenuItemComponentType) => (
    <MenuItem
      onClick={onClick}
      disabled={!disabled && loading}
      className={classes.anchor}
    >
      <Typography variant="subtitle2" color={color}>
        {t(title)}
      </Typography>
      {loading && <LoadingTable size={18} />}
    </MenuItem>
  );

  return (
    <Grid container wrap="nowrap" justify="space-between" alignItems="center">
      <IconButton>
        <MoreVert
          type="button"
          onClick={(e) => {
            e.stopPropagation();
            handleMenu(e);
          }}
        />
      </IconButton>
      <Grid>
        <Menu
          id="menu-appbar-language"
          anchorEl={anchorEl}
          getContentAnchorEl={null}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          keepMounted
          transformOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          open={!!anchorEl}
          onClose={handleClose}
        >
          {menuArray.map((item: MenuItemComponentType) => (
            <MenuItemComponent
              title={item.title}
              onClick={(e) => { item.onClick(e); handleClose(e); }}
              disabled={item.disabled}
              loading={item.loading}
              color={item.color}
            />
          ))}
        </Menu>
      </Grid>
    </Grid>
  );
};
