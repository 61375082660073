import React from 'react';
import {
  Cell,
  Legend,
  Pie,
  PieChart,
  ResponsiveContainer,
  Tooltip,
} from 'recharts';
import { Grid } from '@material-ui/core';
import { ChartTooltip } from './ChartTooltip';
import { CHART_COLORS, ChartProps } from './types';
import { useTranslation } from '../services/translation';
import LoadingData from '../routes/components/LoadingData';
import { ChartEmpty } from '../components/ChartEmpty';

interface ChartPieProps extends ChartProps {
  onChange?: (data: any) => void;
}

export const ChartPie = ({
  data,
  height,
  key,
  value,
  format,
  xs,
  sm,
  md,
  lg,
  xl,
  working = false,
  onChange,
}: ChartPieProps) => {
  const { t } = useTranslation();

  return (
    <Grid
      item
      xs={xs || 12}
      {...{
        sm,
        md,
        lg,
        xl,
      }}
    >
      {!working ? (
        data.length ? (
          <ResponsiveContainer height={height} width="100%">
            <PieChart>
              <Pie
                data={data}
                dataKey={value}
                nameKey={key}
                fill="#8884d8"
                legendType="circle"
              >
                {data.map((dataKey, index) => (
                  <Cell
                    onClick={() => {
                      if (onChange) {
                        onChange(dataKey);
                      }
                    }}
                    key={dataKey.value}
                    fill={CHART_COLORS[index % CHART_COLORS.length]}
                  />
                ))}
              </Pie>
              <Tooltip content={<ChartTooltip format={format} />} />
              <Legend formatter={(label) => t(label)} />
            </PieChart>
          </ResponsiveContainer>
        ) : (
          <ChartEmpty />
        )
      ) : (
        <LoadingData working={!!working} />
      )}
    </Grid>
  );
};
