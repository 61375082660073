import React, {
  useCallback, MouseEvent, useMemo,
} from 'react';
import { DatabaseConnector } from '@phinxlab/libby-rest-web';
import { useHistory } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { useTranslation } from '../../../../../services/translation';
import CustomModal from '../../../../../services/customFormDialog';
import ConfirmDialog from '../../../../../components/ConfirmDialog';
import { LibbyObject } from '../../../../../types/types';
import { Dispatch } from '../../../../../interfaces/business';
import { useDispatchesPrintRefer } from '../../../hook/useDispatchPrintRefer';
import { MenuActions, MenuItemComponentType } from '../../../../../components/MenuActions/MenuActions';

type SendColumnActionsProps = {
  dispatch: Dispatch;
  libby: LibbyObject;
  handleSendModal: (k?: Dispatch) => void;
  actionsDispatch: (dispatch: Dispatch, idName: string, type: string) => void;
  isReadOnly: boolean;
};

const ConfirmModal = CustomModal(ConfirmDialog);

export const SendColumnActionsRaw = ({
  libby,
  dispatch,
  handleSendModal,
  actionsDispatch,
  isReadOnly,
}: SendColumnActionsProps) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();

  const { printDispatchNote, searchPrintRefer } = useDispatchesPrintRefer({
    dispatch,
  });

  const handleCancelModal = useCallback(
    async (e: MouseEvent) => {
      e.stopPropagation();
      try {
        const cancel = await ConfirmModal.show({
          title: t('Cancel Dispatch'),
          content: `${t(
            'You are about to cancel a Dispatch with $$$$ orders, do you want to continue?',
          )}`.replace('$$$$', String(dispatch?.items?.length)),
          confirmText: t('Confirm'),
          cancelText: t('Cancel'),
        });
        if (cancel) {
          const cancelled = await libby.ster_dispatch_list
            .aspect('cancelled')
            .save({
              ...dispatch,
              dispatch_id: dispatch.dispatch_id,
              dispatch_state: {
                dispatch_state_id: 3,
              },
            });
          actionsDispatch(cancelled, 'dispatch_id', 'remove');
          enqueueSnackbar(t('Dispatch Cancelled'), { variant: 'success' });
          enqueueSnackbar(
            `${t('Dispatch Zone $$$$ was able to use')}`.replace(
              '$$$$',
              `${cancelled.dispatch_zone.name}`,
            ),
            { variant: 'success' },
          );
        }
      } catch (error) {
        if (error) {
          enqueueSnackbar(`${t('Failed to cancel')}: ${error}`, {
            variant: 'info',
          });
        }
      }
    },
    [actionsDispatch, enqueueSnackbar, t, libby, dispatch],
  );

  const menuArray: MenuItemComponentType[] = useMemo(() => {
    const menu: MenuItemComponentType[] = [
      {
        title: 'Send',
        onClick: (e: MouseEvent) => {
          e.stopPropagation();
          handleSendModal(dispatch);
        },
        disabled: isReadOnly,
      },
      {
        title: 'Print',
        onClick: (e: MouseEvent) => {
          e.stopPropagation();
          printDispatchNote();
        },
        disabled: dispatch.dispatch_state.dispatch_state_id !== '2',
        loading: searchPrintRefer,
      },
      {
        title: 'Details',
        onClick: (e: MouseEvent) => {
          e.stopPropagation();
          history.push(
            `${isReadOnly ? '/dispatches/inquiry' : 'dispatches'}/detail/${
              dispatch.dispatch_id
            }`,
          );
        },
        disabled: !(isReadOnly || !isReadOnly),
      },
      {
        title: 'Cancel',
        onClick: handleCancelModal,
        disabled: isReadOnly,
        color: 'error',
        loading: libby.working,
      },
    ];

    return menu.filter((menuItems) => menuItems.disabled === false);
  }, [
    dispatch,
    handleCancelModal,
    history,
    isReadOnly,
    handleSendModal,
    printDispatchNote,
    searchPrintRefer,
    libby,
  ]);

  return <MenuActions menuArray={menuArray} />;
};

export const SendColumnActions = DatabaseConnector(SendColumnActionsRaw)(
  'ster_dispatch_details',
  'ster_dispatch_zone',
  'ster_dispatch_item',
  'ster_dispatch_list',
  'ster_dispatch_details_update',
);
