import { useCallback, useState } from 'react';
import { Dispatch } from '../../../interfaces/business';
import { openInNewTab } from '../../../utils';
import { getDispatchManifest, printDispatchManifest } from '../utils/printDispatchManifest';

interface useDispatchesPrintReferProp {
  reset?: () => void;
  dispatch: Dispatch;
}

export const useDispatchesPrintRefer = ({
  reset,
  dispatch,
}: useDispatchesPrintReferProp) => {
  const [searchPrintRefer, setSearchPrintRefer] = useState(false);

  const printDispatchNote = useCallback(async () => {
    const { url_remito: url } = dispatch;
    setSearchPrintRefer(true);
    if (!url) {
      const { data } = await printDispatchManifest(dispatch);
      if (data) {
        openInNewTab(data);
      } else {
        const urlSearchFile = getDispatchManifest(dispatch.dispatch_id);
        openInNewTab(urlSearchFile);
      }
    } else openInNewTab(url);
    setSearchPrintRefer(false);
    if (reset) {
      reset();
    }
  }, [dispatch, reset]);

  return {
    printDispatchNote,
    searchPrintRefer,
  };
};
