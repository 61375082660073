import { useCallback, useMemo } from 'react';
import { Menu_action_account_role } from '../interfaces/business/ster/Menu_action_role';
import { userInfoType } from '../types';
import { useGlobalContext } from './useGlobalContext';

export const useMenuAction = (menu_id: number) => {
  const {
    userInfo,
  }: {
    userInfo: userInfoType;
  } = useGlobalContext();

  const menuActionsEnabled = useMemo(
    () => userInfo?.actions?.filter(
        ({ menu_action }: Menu_action_account_role) => menu_action.menu.menu_id === menu_id
      ),
    [userInfo, menu_id],
  );

  const validateMenuActions = useCallback(
    (menu_action_id_search: string):boolean => !!menuActionsEnabled?.find(
        ({ menu_action }: Menu_action_account_role) => menu_action.menu_action_id.toString() === menu_action_id_search
      ),
    [menuActionsEnabled],
  );

  return {
    menuActionsEnabled,
    validateMenuActions,
  };
};
