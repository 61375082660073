import React, { useEffect, useMemo } from 'react';
import { Grid, LinearProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { DatabaseConnector } from '@phinxlab/libby-rest-web';
import moment from 'moment';
import { formatDate, translate } from '../../../../../utils';
import { InformationSection } from '../../../../../components/InformationSection';
import { PaginatedTable } from '../../../../../components/PaginatedTable';
import { makeMapLabelValues } from '../../../../utils/mappers';
import { FileInput } from './FileInput';
import {
  importationsStatus,
  IMPORTATION_TYPES_NAME,
  IMPORTATION_STATUS_NAME,
} from '../../../../../const';
import { AnyObject, LibbyObject } from '../../../../../types/types';
import { useTranslation } from '../../../../../services/translation';
import { useSterConfigId } from '../hooks/useSterConfig';
import { useReferenceImportationId } from '../hooks/useImportRefer';
import { LoadingTable } from '../../../../../components/LoadingTable';
import {
  Importation_status,
  Importation_type,
} from '../../../../../interfaces/business';

type spType = {
  name: string;
  success: boolean;
  error: string;
};

type resultType = {
  result: object;
  upload: boolean;
  last_execution: Date;
  frequency: number;
};

const detailMap = [
  {
    key: 'creation_date',
    label: 'Creation Day',
    formatter: (value: Date) => formatDate(value, true, false),
  },
  {
    key: 'importation_type',
    label: 'Type',
    formatter: ({ name }: Importation_type) => name,
  },
  {
    key: 'importation_status',
    label: 'Status',
    formatter: ({ importation_status_id }: Importation_status) => importationsStatus[importation_status_id],
  },
];

const importationErrorsColumns = [
  {
    id: 'id',
    label: 'ID',
  },
  {
    id: 'message',
    label: 'Message',
    render: (row: any, item: any) => (typeof item === 'string' ? item : row.message),
  },
];

const importationErrorsColumnsWithRow = [
  {
    id: 'row',
    label: 'Row',
  },
  ...importationErrorsColumns,
];

const resultMap = [
  {
    key: 'success',
    label: 'Success',
  },
  {
    key: 'fails',
    label: 'Fails',
  },
];

const resultMapWithUpdate = [
  ...resultMap,
  {
    key: 'toUpload',
    label: 'To upload',
  },
];

const resultMsg = [
  {
    key: 'success',
    label: 'Message',
    formatter: (value: boolean, data: spType) => (value ? translate('Success') : data.error),
  },
];

const resultConfigMsg = [
  {
    key: 'last_execution',
    label: 'Time to run',
    formatter: (value: Date, data: resultType) => (value
      ? formatDate(
        moment(value)
          .add(data.frequency || 0, 'minutes')
          .toDate(),
      )
      : translate(
        'Error executing process. Please, notify the administrator',
      )),
  },
];

type ImportDetailDataProps = {
  data: AnyObject;
  status: number;
  currentImport: AnyObject;
  someFileWithoutTempAttachments: Boolean;
  loadingSaveImport: boolean;
  handleUpdateTempAttachments: (
    filename: string,
    base64Content: string,
    originalFilename: string,
    extension: string,
    delimiter: string
  ) => void;
  handleDeleteImport: (name: string) => void;
  libby: LibbyObject;
  working: boolean;
};
const useStyles = makeStyles({
  progressDiv: {
    height: '50px',
  },
  progress: {
    marginTop: '10px',
  },
});

const enabledCobrosAndCancelled = [
  IMPORTATION_TYPES_NAME.BNA_COBROS,
  IMPORTATION_TYPES_NAME.FRAVEGA_COBROS,
  IMPORTATION_TYPES_NAME.FRAVEGA_CANCELLED,
  IMPORTATION_TYPES_NAME.MERCADO_PAGO_COBROS,
  IMPORTATION_TYPES_NAME.TARJETA_NARANJA_COBROS,
];

const DisabledImportationState = [
  IMPORTATION_STATUS_NAME.FAILURE,
  IMPORTATION_STATUS_NAME.PENDING,
  IMPORTATION_STATUS_NAME.REJECTED,
];

export const ImportDetailDataRaw = ({
  data,
  status,
  currentImport,
  someFileWithoutTempAttachments,
  handleUpdateTempAttachments,
  handleDeleteImport,
  loadingSaveImport,
  libby,
  working,
}: ImportDetailDataProps) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const { data: dataSterConfig } = useSterConfigId(libby, {
    daoName: 'ster_ster_config',
  });

  const enabledSearchConfig = useMemo(
    () => DisabledImportationState.includes(status) || data.result.success === 0,
    [status, data.result.success],
  );

  const {
    dataResult: dataReferenceImportation,
    recallSearch,
    data: dataGeneral,
    working: workingDataGeneral,
    countResult,
  } = useReferenceImportationId(
    libby,
    {
      daoName: 'ster_inbox_order_search_importation',
      importation_id: data.importation_id,
    },
    enabledSearchConfig,
  );

  const mapperLabelValues = makeMapLabelValues(t);
  const detailMapper = mapperLabelValues(detailMap);

  const resultMapper = mapperLabelValues(resultMap);

  const resultMapperWithUpdate = mapperLabelValues(resultMapWithUpdate);

  const resultExecutionSp = mapperLabelValues(resultMsg);

  const detailTimeConfigImportation = mapperLabelValues(resultConfigMsg);

  useEffect(() => {
    if (dataReferenceImportation.toUpload > 0) {
      const interval = setInterval(() => {
        recallSearch();
      }, 100000);
      return () => clearInterval(interval);
    }
    return () => {};
  }, [recallSearch, dataReferenceImportation]);

  const validateAccepted = useMemo(
    () => !data.result.success && status === IMPORTATION_STATUS_NAME.ACCEPTED,
    [data, status],
  );

  const resultConfig = useMemo(
    () => [
      {
        title: 'Import process',
        data: {
          last_execution:
            !validateAccepted && !dataGeneral.error
              ? dataSterConfig[0]?.last_execution
              : undefined,
          result: data.result,
          frequency: dataSterConfig[0]?.frequency,
        },
        working: working || workingDataGeneral,
        message_error: data.result.errors,
        message_error_with_row: true,
      },
      {
        title: 'Process for change order status',
        data: {
          result: countResult ? dataReferenceImportation : {},
          upload: true,
          last_execution: dataGeneral?.last_execution,
          frequency: dataGeneral?.frequency,
        },
        working: workingDataGeneral,
        disabled:
          !enabledCobrosAndCancelled.includes(
            data.importation_type.importation_type_id,
          )
          || validateAccepted
          || !dataGeneral?.success,
        message_error: dataGeneral?.message_errors,
        message_error_with_row: false,
      },
    ],
    [
      dataSterConfig,
      data,
      dataGeneral,
      dataReferenceImportation,
      working,
      workingDataGeneral,
      countResult,
      validateAccepted,
    ],
  );

  return (
    <>
      <InformationSection title={t('Detail')} noPadding>
        {detailMapper(currentImport)}
      </InformationSection>

      {status === IMPORTATION_STATUS_NAME.PENDING ? (
        <InformationSection
          title={t(
            someFileWithoutTempAttachments ? 'Upload Files' : 'Uploaded Files',
          )}
          noPadding
        >
          {loadingSaveImport ? (
            <Grid xs={12} className={classes.progressDiv}>
              <LinearProgress color="primary" className={classes.progress} />
            </Grid>
          ) : (
            currentImport.files.map(
              ({ filename, importation_id, ...rest }: AnyObject) => (
                <FileInput
                  key={`${filename}${currentImport.importation_id}`}
                  onUpdateFiles={handleUpdateTempAttachments}
                  onDeleteImport={handleDeleteImport}
                  {...{ filename, importation_id, ...rest }}
                />
              ),
            )
          )}
        </InformationSection>
      ) : (
        !!dataSterConfig.length
        && resultConfig.map(
          (result) => !result.disabled && (
          <InformationSection title={t(result.title)} noPadding>
            {result.working ? (
              <LoadingTable />
            ) : Object.keys(result.data.result).length ? (
              !result.data.upload ? (
                resultMapper(result.data.result)
              ) : (
                resultMapperWithUpdate(result.data.result)
              )
            ) : (
              detailTimeConfigImportation(result.data)
            )}
            {!!result.message_error?.length && (
            <InformationSection
              title={`${t('Errors')} - ${t(result.title)}`}
              noPadding
            >
              <PaginatedTable
                headCells={
                        result.message_error_with_row
                          ? importationErrorsColumnsWithRow
                          : importationErrorsColumns
                      }
                rows={result.message_error}
                rowIdKey="id"
              />
            </InformationSection>
            )}
          </InformationSection>
          ),
        )
      )}

      {workingDataGeneral && <LoadingTable />}

      {!!data.result && !!Object.keys(data.result).length && (
        <>
          {data.importation_type.execution_sp
            && data?.result?.sp?.length
            && data.result.sp.map((sp: spType) => (
              <InformationSection
                title={`${t('Execution SP')} : ${sp.name}`}
                noPadding
              >
                {resultExecutionSp(sp)}
              </InformationSection>
            ))}
        </>
      )}
    </>
  );
};

export const ImportDetailData = DatabaseConnector(ImportDetailDataRaw)(
  'ster_ster_config',
  'ster_inbox_order_search_importation',
  'ster_process_monitor_alert',
);
