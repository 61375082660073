import React, { MouseEventHandler } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { ThemeProvider, makeStyles } from '@material-ui/styles';
import Button from '@material-ui/core/Button';

const useStyles = makeStyles(() => ({
  modalTitle: {
    '& h2.MuiTypography-h6': {
      textTransform: 'uppercase',
      fontSize: '24px !important',
      fontWeight: 700,
    },
  },
  modalContent: {
    fontSize: 16,
  },
  buttonText: {
    '& button': {
      fontSize: 16,
    },
  },

  root: {
    '& .MuiPaper-root': {
      borderRadius: '8px',
    },
  },
}));

type ConfirmDialogProps = {
  title: React.ReactNode,
  content: React.ReactNode,
  cancelText: React.ReactNode,
  confirmText: React.ReactNode,
  open: boolean,
  oneButton: boolean,
  onCancel: MouseEventHandler,
  onConfirm: MouseEventHandler,
  customTheme: { [k: string]: any } | null,
};

const dummyFunc = () => {};

const ConfirmDialog = ({
  title = 'Confirmation Dialog',
  content = 'Are you sure of perform this action?',
  cancelText = 'Cancel',
  confirmText = 'Confirm',
  open = false,
  oneButton = false,
  onCancel = dummyFunc,
  onConfirm = dummyFunc,
  customTheme = null,
}: ConfirmDialogProps) => {
  const classes = useStyles();
  const dialog = (
    <Dialog
      open={open}
      onClose={onCancel}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      className={classes.root}
    >
      <DialogTitle className={classes.modalTitle} id="alert-dialog-title">{title}</DialogTitle>
      <DialogContent>
        <DialogContentText className={classes.modalContent} id="alert-dialog-description">
          {content}
        </DialogContentText>
      </DialogContent>
      <DialogActions className={classes.buttonText}>
        {!oneButton && (
          <Button onClick={onCancel} color="primary">
            {cancelText}
          </Button>
        )}
        <Button onClick={onConfirm} color="primary" autoFocus>
          {confirmText}
        </Button>
      </DialogActions>
    </Dialog>
  );
  return !customTheme ? dialog : (
    <ThemeProvider theme={customTheme}>
      {dialog}
    </ThemeProvider>
  );
};

export default ConfirmDialog;
