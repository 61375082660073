import moment from 'moment';
import { isEmail } from './helpers';

export const getErrorMessage = (error: { message: string }) => error.message;

export type ValidatorFunction = (
  value: string,
  values: { [key: string]: any }
) => boolean;

export type Validator = {
  validator: ValidatorFunction;
  message: string;
};

export enum ValidatorType {
  REQUIRED = 'required',
  NUMBER = 'number',
  POSITIVE = 'positive',
  EMAIL = 'email',
  DATE = 'date',
  SELECT = 'select',
  LETTERS = 'letters',
  SPECIALS = 'specials',
}

export type ValidatorRule = {
  key: string;
  validators: Array<ValidatorType | Validator>;
};

const errorMessages = {
  [ValidatorType.REQUIRED]: 'Please fill out this field.',
  [ValidatorType.NUMBER]: 'The value is not a valid number.',
  [ValidatorType.POSITIVE]: 'The value must be a positive number',
  [ValidatorType.EMAIL]: 'The value must be a valid email',
  [ValidatorType.DATE]: 'The value must be a valid date',
  [ValidatorType.SELECT]: 'Please select an item in the list.',
  [ValidatorType.LETTERS]: 'The value must be only letters and spaces.',
  [ValidatorType.SPECIALS]: 'The value cant have special caracters',
};

export const makeValidator = (rules: ValidatorRule[]) => (values: { [key: string]: any }) => rules.reduce((errors, { key, validators }: ValidatorRule) => {
  const error = validators.find((validator) => {
    if (typeof validator === 'object') return validator.validator(values[key], values);
    switch (validator) {
      case ValidatorType.REQUIRED: {
        return Array.isArray(values[key])
          ? !values[key].length
          : !values[key];
      }
      case ValidatorType.NUMBER: {
        return Number.isNaN(+values[key]);
      }
      case ValidatorType.POSITIVE: {
        return Number.isNaN(+values[key]) || +values[key] <= 0;
      }
      case ValidatorType.EMAIL: {
        return !isEmail(values[key]);
      }
      case ValidatorType.DATE: {
        return !moment(values[key]).isValid();
      }
      case ValidatorType.SELECT: {
        return +values[key] === 0;
      }
      case ValidatorType.LETTERS: {
        const letters = /^[a-zA-Z\s]*$/;
        return !values[key]?.match(letters);
      }
      case ValidatorType.SPECIALS: {
        const format = /[~`!#$%^&*+=\-[\]\\';,/{}|\\":<>?]/;
        return values[key].match(format);
      }
      default:
        return false;
    }
  });
  if (error) {
    const message = typeof error === 'object'
      ? error.message
      : errorMessages[error] || error;
    return { ...errors, [key]: message };
  }
  return errors;
}, {});
