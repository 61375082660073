import React, { useState, useCallback, useMemo } from 'react';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { DatabaseConnector } from '@phinxlab/libby-rest-web';
import { useSnackbar } from 'notistack';
import { AnyObject } from '../../../../../types/types';
import { PaginatedTable } from '../../../../../components/PaginatedTable';
import { useTranslation } from '../../../../../services/translation';
import { replaceNonNumericCharacters } from '../../../../../utils';
import { Order_item } from '../../../../../interfaces/business/orders/Order_item';
import { PRODUCT_TYPE } from '../../../../../const';

const styleItemRow = {
  whiteSpace: 'nowrap',
  width: '50px',
};
export const ItemsWithoutProductTableRaw = ({
  libby,
  items = [],
  updateDataWithProducts,
  redirect,
}: AnyObject) => {
  const [weight, setonChangeWeight] = useState<any>();
  const [depth, setDepth] = useState<any>();
  const [height, setHeight] = useState<any>();
  const [width, setWidth] = useState<any>();
  const [success, setSuccess] = useState<any>();
  const { enqueueSnackbar } = useSnackbar();

  const { t } = useTranslation();

  const handleSubmit = useCallback(
    async (value: Order_item) => {
      try {
        const data = await libby.ster_order_item_product
          .aspect('list_order_item_so_custom')
          .save({
            ...value,
            product: {
              product_id: value.product?.product_id || null,
              name: value.name,
              description: value.name,
              sku: value.sku,
              attributes: {
                physical: {
                  width: parseInt(width[value.order_item_id], 10),
                  height: parseInt(height[value.order_item_id], 10),
                  depth: parseInt(depth[value.order_item_id], 10),
                  weight: parseInt(weight[value.order_item_id], 10),
                },
              },
              price: parseInt(value.unit_price, 10),
              tags: {
                tags: 'accesorio',
              },
              productType: {
                productTypeId: PRODUCT_TYPE.BNA,
              },
            },
          });

        updateDataWithProducts(data.product, items);
        setSuccess((prevItems: any) => {
          const arr = { ...prevItems };
          arr[value.order_item_id] = true;
          if (Object.keys(arr)?.length === items.length) {
            redirect();
          }
          return arr;
        });
        enqueueSnackbar(
          t('Updated sku $$$ product').replace('$$$', value.sku),
          {
            variant: 'success',
          },
        );
      } catch (error) {
        enqueueSnackbar(t('Something is wrong'), { variant: 'error' });
      }
    },
    [
      redirect,
      libby,
      depth,
      weight,
      height,
      width,
      updateDataWithProducts,
      items,
      enqueueSnackbar,
      t,
    ],
  );

  const onChangeText = useCallback(
    (
      event: React.ChangeEvent<HTMLInputElement>,
      setChangeText: Function,
      valueId: number,
    ) => {
      const { value } = event.target;
      setChangeText((prevItems: any) => {
        const arr = { ...prevItems };
        const numberValue = parseInt(replaceNonNumericCharacters(value), 10);
        arr[valueId] = numberValue > 0 ? numberValue : '';

        return arr;
      });
    },
    [],
  );

  const textFiledGlobal = useCallback(
    (
      order_item_id: number,
      value: string,
      setOnchangeTextFunction: Function,
    ) => (
      <TextField
        autoFocus
        disabled={success?.[order_item_id]}
        value={value || ''}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => onChangeText(event, setOnchangeTextFunction, order_item_id)}
      />
    ),
    [onChangeText, success],
  );

  const itemsColumns = useMemo(
    () => [
      {
        id: 'name',
        label: 'Name/Description',
        render: (value: Order_item) => value?.name || '--',
        style: styleItemRow,
      },
      {
        id: 'sku',
        label: 'Sku',
        render: (value: Order_item) => value?.sku || '',
      },
      {
        id: 'width',
        label: 'Width (cm)',
        render: ({ order_item_id }: Order_item) => textFiledGlobal(
          parseInt(order_item_id, 10),
            width?.[order_item_id],
            setWidth,
        ),
        style: styleItemRow,
      },
      {
        id: 'height',
        label: 'Height (cm)',
        render: ({ order_item_id }: Order_item) => textFiledGlobal(
          parseInt(order_item_id, 10),
            height?.[order_item_id],
            setHeight,
        ),
        style: styleItemRow,
      },
      {
        id: 'depth',
        label: 'Depth (cm)',
        render: ({ order_item_id }: Order_item) => textFiledGlobal(
          parseInt(order_item_id, 10),
            depth?.[order_item_id],
            setDepth,
        ),
        style: styleItemRow,
      },
      {
        id: 'weight',
        label: 'Weight (grms)',
        render: ({ order_item_id }: Order_item) => textFiledGlobal(
          parseInt(order_item_id, 10),
            weight?.[order_item_id],
            setonChangeWeight,
        ),
        style: styleItemRow,
      },
      {
        id: 'action',
        label: 'Action',
        render: (valueId: Order_item) => (
          <Button
            color="primary"
            type="submit"
            onClick={() => handleSubmit(valueId)}
            disabled={
              success?.[valueId.order_item_id]
              || !weight?.[valueId.order_item_id]
              || !depth?.[valueId.order_item_id]
              || !width?.[valueId.order_item_id]
              || !height?.[valueId.order_item_id]
            }
          >
            <p>
              {t(
                !success?.[valueId.order_item_id]
                  ? 'Save product'
                  : 'Updated product',
              )}
            </p>
          </Button>
        ),
      },
    ],
    [depth, weight, width, success, handleSubmit, t, textFiledGlobal, height],
  );

  return (
    <PaginatedTable
      headCells={itemsColumns}
      rows={items}
      rowIdKey="order_item_id"
    />
  );
};

export const ItemsWithoutProductTable = DatabaseConnector(
  ItemsWithoutProductTableRaw,
)('ster_order_item_product');
