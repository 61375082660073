import React from 'react';
import { DatabaseConnector } from '@phinxlab/libby-rest-web';
import { useTranslation } from '../../../../../services/translation';
import LoadingData from '../../../../components/LoadingData';
import { SearchFilters } from './SearchFilters';
import InfoTable from '../../../../components/InfoTable';

type RowsType = {
  id: string,
  [k: string]: any
};
type TagManagerTableProps = {
  working: boolean,
  filter: object,
  rows: Array<RowsType>,
  columns: Array<HeadCellsType>
  setFilter: (makeFilter: object) => void,
  fetchMore: () => void,
  onSortChange?: (orderBy: string, direction: 'asc' | 'desc') => void;
  orderBy?: string;
  direction?: 'asc' | 'desc';
};

type HeadCellsType = {
  id: string,
  label: string,
  translate?: boolean,
  render?: (row: any, item: any, t: (str: string) => string) => React.ReactNode
  [k: string]: any,
};

const TagManagerTableRaw = ({
  working,
  rows,
  columns,
  filter,
  setFilter,
  fetchMore,
  onSortChange,
  orderBy,
  direction,
}: TagManagerTableProps) => {
  const { t } = useTranslation();
  return (
    <>
      <SearchFilters
        onFilterChange={setFilter}
        initFetch={filter}
      />
      <InfoTable
        columns={columns}
        rows={rows}
        onBottomScroll={fetchMore}
        direction={direction}
        orderBy={orderBy}
        onSortChange={onSortChange}
      />
      <LoadingData
        label={`${t('Loaded registers')}`}
        working={working}
        cant={rows.length}
      />
    </>
  );
};

export const TagManagerTable = DatabaseConnector(TagManagerTableRaw)('ster_inbox_order');
