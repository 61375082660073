import {
  Libby,
  Database,
  RESTSessionStrategy,
  RESTStoreStrategy,
} from '@phinxlab/libby-rest-web';
import { OrderAreaTimeDAO } from './dao/OrderAreaTimeDAO';
import { OrderTotalTimeDAO } from './dao/OrderTotalTimeDAO';
import { OrderReportDetailDAO } from './dao/OrderReportDetailDAO';
/* eslint-disable class-methods-use-this */
import { TokenManager } from './TokenManager';
import { SessionChangeEmitter } from './session';
import { API_URL } from '../../config';
import {
  AccountRoleDAO,
  AdminGenerateReportDAO,
  AdminAccountDAO,
  PublicAccountDAO,
  SterConfigDAO,
  DispatchCollectDAO,
  DispatchCartDAO,
  DispatchCollectDetailsDAO,
  DispatchCollectItemDAO,
  DispatchCollectAddOrder,
  OrderReportDetailGroupDAO,
  InboxOrdersSearchImportationDAO,
  DispatchCartEnabledDAO,
  DispatchCartBoxDAO,
  SterProcessMonitorAlertDAO,
  DispatchCollectCloseDAO,
  DispatchZoneEnabledDAO,
  DispatchCollectState,
  DispatchCartWithCollectDAO,
  CollectItemProductSearchDAO,
  DispatchStateDAO,
  ProductStockDAO,
  DispatchUpdateDAO,
  DispatchDetailsUpdateDAO,
  DispatchDetailsDAO,
  DispatchItemSendDAO,
  DispatchSendDAO,
  InboxOrderCustomDAO,
  DispatchUploadDocumentsManifestDAO,
  // DispatchManifestDAO,
  ShippingTagsCustomDAO,
  HistoryByMarketplaceDAO,
  OrderItemsBySkuDAO,
  OrderBuyerLogsDAO,
  OrderShipmentAddressLogsDAO,
  OrderReportingSalesProductsDAO,
  OrderReportingSalesMarketplaceDAO,
} from './dao';

import { ImportationDAO } from './dao/ImportationDAO';
import { ImportationTypeDAO } from './dao/ImportationTypeDAO';
import { ImportationStatusDAO } from './dao/ImportationStatusDAO';
import { OrdersDAO } from './dao/OrdersDAO';
import { ERPOrderDAO } from './dao/ERPOrderDAO';
import { InboxOrdersDAO } from './dao/InboxOrdersDAO';
import { LogisticsServiceDAO } from './dao/LogisticsServiceDAO';
import { CitiesDAO } from './dao/CitiesDAO';
import { StateDAO } from './dao/StateDAO';

import { OrderCourierDAO } from './dao/OrderCourierDAO';
import { OrderSourceDAO } from './dao/OrderSourceDAO';
import { OrderLogsDAO } from './dao/OrderLogsDAO';
import { OrderStateDAO } from './dao/OrderStateDAO';
import { MarketPlaceDAO } from './dao/MarketPlaceDAO';
import { OrderShipmentDAO } from './dao/OrderShipmentDAO';
import { OrdersSoDAO } from './dao/OrdersSoDAO';
import { OrdersPrintOrangeStoreDAO } from './dao/OrdersPrintOrangeStoreDAO';
import { DispatchZoneDAO } from './dao/DispatchZoneDAO';
import { DispatchListDAO } from './dao/DispatchListDAO';
import { DispatchItemDAO } from './dao/DispatchItemDao';
import { CollectItemProductDAO } from './dao/CollectItemProductDAO';
import { OrderDocumentDAO } from './dao/OrderDocumentDAO';
import { OrderForDispatchDAO } from './dao/OrderForDispatchDAO';
import { OrderPrintDAO } from './dao/OrderPrintDao';
import { OrdersSoPrintTagDAO } from './dao/OrdersSoPrintTagDAO';
import { OrdersTableDAO } from './dao/OrdersTableDAO';
import { OrderItemDAO } from './dao/OrderItemDao';
import { OrderReportingPendingAndreaniIncomeDAO } from './dao/OrderReportingPendingAndreaniIncomeDAO';
import { DispatchToSaveDAO } from './dao/DistpatchToSaveDAO';
import { OrderItemWithProductDAO } from './dao/OrderItemWithProductDAO';
import { OrdersTableUpdateDAO } from './dao/OrdersTableUpdateDAO';

class DatabaseStoreRaw {
  chinoTokenEnvironmet: string;

  url: string;

  constructor() {
    this.chinoTokenEnvironmet = 'SterApp';
    // TODO: implement
    this.url = API_URL;
  }

  addSession(): void {
    const Session = RESTSessionStrategy.init(this.url);
    Session.setTokenManager(TokenManager.create());
    Database.addSessionStrategy(Session);
    Session.addSessionListener({
      // TODO: remove any type
      newSessionState: (state: any) => {
        const { user } = Session;
        const isLogged: boolean = !!user && user.isGuest === false;
        SessionChangeEmitter.emitChange({ isLogged, user, state });
      },
    });
    Database.addSessionStrategy(Session);
  }

  register(): void {
    Database.register(
      new RESTStoreStrategy(
        'admin_account_role',
        '/api/ster/accountrole',
        'account_role_id',
      ),
      AccountRoleDAO,
    );
    Database.register(
      new RESTStoreStrategy(
        'admin_generate_report',
        '/admin/generate_report',
        'generate_report_id',
      ),
      AdminGenerateReportDAO,
    );
    Database.register(
      new RESTStoreStrategy('admin_account', '/api/ster/account', 'account_id'),
      AdminAccountDAO,
    );
    // TODO: CHECK DIFFERENCE BTW PUBLIC/ADM
    Database.register(
      new RESTStoreStrategy(
        'public_account',
        '/session/ster/accountpublic',
        'account_id',
      ),
      PublicAccountDAO,
    );
    Database.register(
      new RESTStoreStrategy(
        'app_importation',
        '/api/ster/importation',
        'importation_id',
      ),
      ImportationDAO,
    );
    Database.register(
      new RESTStoreStrategy(
        'public_importation_type',
        '/api/ster/importationtype',
        'importation_type_id',
      ),
      ImportationTypeDAO,
    );
    Database.register(
      new RESTStoreStrategy(
        'public_importation_status',
        '/api/ster/importationstatus',
        'importation_status_id',
      ),
      ImportationStatusDAO,
    );
    Database.register(
      new RESTStoreStrategy(
        'ster_order_total_time',
        '/api/orders/ordertotaltime',
        'id',
      ),
      OrderTotalTimeDAO,
    );
    Database.register(
      new RESTStoreStrategy(
        'ster_order_report',
        '/api/orders/orderreportdetail',
        'order_id',
      ),
      OrderReportDetailDAO,
    );
    Database.register(
      new RESTStoreStrategy(
        'ster_order_area_time',
        '/api/orders/orderareatime',
        'id',
      ),
      OrderAreaTimeDAO,
    );
    Database.register(
      new RESTStoreStrategy(
        'ster_order_for_dispatch',
        '/api/orders/orderfordispatch',
        'order_id',
      ),
      OrderForDispatchDAO,
    );
    Database.register(
      new RESTStoreStrategy(
        'ster_order',
        '/api/orders/ordersoorder',
        'order_id',
      ),
      OrdersDAO,
    );
    Database.register(
      new RESTStoreStrategy(
        'ster_order_table',
        '/api/orders/order',
        'order_id',
      ),
      OrdersTableDAO,
    );

    Database.register(
      new RESTStoreStrategy(
        'ster_order_so',
        '/api/orders-so/ordersoprint',
        'order_id',
      ),
      OrdersSoDAO,
    );
    Database.register(
      new RESTStoreStrategy(
        'ster_inbox_order',
        '/api/inbox/inboxorder',
        'inbox_order_id',
      ),
      InboxOrdersDAO,
    );
    Database.register(
      new RESTStoreStrategy(
        'courier_service',
        '/api/courier/courierservice',
        'courier_service_id',
      ),
      LogisticsServiceDAO,
    );

    Database.register(
      new RESTStoreStrategy('ster_city', '/api/general/city', 'city_id'),
      CitiesDAO,
    );
    Database.register(
      new RESTStoreStrategy('ster_state', '/api/general/state', 'state_id'),
      StateDAO,
    );
    Database.register(
      new RESTStoreStrategy(
        'courier_order',
        '/api/courier/courier',
        'courier_id',
      ),
      OrderCourierDAO,
    );
    Database.register(
      new RESTStoreStrategy(
        'ster_order_source',
        '/api/orders/ordersource',
        'order_source_id',
      ),
      OrderSourceDAO,
    );
    Database.register(
      new RESTStoreStrategy(
        'ster_order_logs',
        '/api/orders/orderlogs',
        'order_log_id',
      ),
      OrderLogsDAO,
    );
    Database.register(
      new RESTStoreStrategy(
        'ster_order_document',
        '/api/orders/orderdocument',
        'order_document_id',
      ),
      OrderDocumentDAO,
    );
    Database.register(
      new RESTStoreStrategy(
        'ster_print_orange_store',
        '/api/orders/print/orange-store/ordersoprint',
        'order_id',
      ),
      OrdersPrintOrangeStoreDAO,
    );
    Database.register(
      new RESTStoreStrategy(
        'ster_order_shipment',
        '/api/orders/ordershipment',
        'order_shipment_id',
      ),
      OrderShipmentDAO,
    );
    Database.register(
      new RESTStoreStrategy(
        'ster_marketplace',
        '/api/marketplace/marketplace',
        'marketplace_id',
      ),
      MarketPlaceDAO,
    );
    Database.register(
      new RESTStoreStrategy(
        'order_state',
        '/api/orders/orderstate',
        'order_state_id',
      ),
      OrderStateDAO,
    );

    Database.register(
      new RESTStoreStrategy('ster_erp_order', '/api/orders/erporder', 'so_id'),
      ERPOrderDAO,
    );

    Database.register(
      new RESTStoreStrategy(
        'ster_dispatch_details',
        '/api/dispatch/dispatchdetails',
        'dispatch_id',
      ),
      DispatchDetailsDAO,
    );

    Database.register(
      new RESTStoreStrategy(
        'ster_dispatch_list',
        '/api/dispatch/dispatchlist',
        'dispatch_id',
      ),
      DispatchListDAO,
    );

    Database.register(
      new RESTStoreStrategy(
        'ster_dispatch_zone',
        '/api/dispatch/dispatchzone',
        'dispatch_zone_id',
      ),
      DispatchZoneDAO,
    );

    Database.register(
      new RESTStoreStrategy(
        'ster_dispatch_zone_enabled',
        '/api/dispatch/dispatchzoneenabled',
        'dispatch_zone_id',
      ),
      DispatchZoneEnabledDAO,
    );

    Database.register(
      new RESTStoreStrategy(
        'ster_dispatch_item',
        '/api/dispatch/dispatchitem',
        'dispatch_item_id',
      ),
      DispatchItemDAO,
    );

    Database.register(
      new RESTStoreStrategy(
        'ster_ster_config',
        '/api/ster/sterconfig',
        'ster_config_id',
      ),
      SterConfigDAO,
    );

    Database.register(
      new RESTStoreStrategy(
        'ster_order_document',
        '/api/orders/orderdocument',
        'order_document_id',
      ),
      OrderDocumentDAO,
    );

    Database.register(
      new RESTStoreStrategy(
        'ster_order_print',
        '/api/orders/printorderso',
        'order_id',
      ),
      OrderPrintDAO,
    );

    Database.register(
      new RESTStoreStrategy(
        'ster_order_print_tags',
        '/api/orders/printordersoprintpdf',
        'order_id',
      ),
      OrdersSoPrintTagDAO,
    );

    Database.register(
      new RESTStoreStrategy(
        'ster_order_item',
        '/api/orders/orderitem',
        'order_item_id',
      ),
      OrderItemDAO,
    );

    Database.register(
      new RESTStoreStrategy(
        'ster_reporting_pending_andreani_income',
        '/api/orders/reportingpendingandreaniincome',
        'order_id',
      ),
      OrderReportingPendingAndreaniIncomeDAO,
    );

    Database.register(
      new RESTStoreStrategy(
        'ster_dispatch',
        '/api/dispatch/dispatch',
        'dispatch_id',
      ),
      DispatchToSaveDAO,
    );

    Database.register(
      new RESTStoreStrategy(
        'ster_dispatch_collect',
        '/api/dispatch/collect',
        'collect_id',
      ),
      DispatchCollectDAO,
    );

    Database.register(
      new RESTStoreStrategy(
        'ster_dispatch_collect_close',
        '/api/dispatch/collect',
        'collect_id',
      ),
      DispatchCollectCloseDAO,
    );

    Database.register(
      new RESTStoreStrategy(
        'ster_dispatch_cart',
        '/api/dispatch/cart',
        'cart_id',
      ),
      DispatchCartDAO,
    );

    Database.register(
      new RESTStoreStrategy(
        'ster_dispatch_collect_details',
        '/api/dispatch/collectdetails',
        'collect_id',
      ),
      DispatchCollectDetailsDAO,
    );

    Database.register(
      new RESTStoreStrategy(
        'ster_dispatch_collect_item',
        '/api/dispatch/collectitem',
        'collect_item_id',
      ),
      DispatchCollectItemDAO,
    );

    Database.register(
      new RESTStoreStrategy(
        'ster_dispatch_collect_add_order',
        '/api/dispatch/dispatchcollectaddorder',
        'collect_id',
      ),
      DispatchCollectAddOrder,
    );

    Database.register(
      new RESTStoreStrategy(
        'ster_order_item_product',
        '/api/orders/orderitemsocustom',
        'order_id',
      ),
      OrderItemWithProductDAO,
    );

    Database.register(
      new RESTStoreStrategy(
        'ster_order_report_group',
        '/api/orders/orderreportdetailgroup',
        'mc',
      ),
      OrderReportDetailGroupDAO,
    );

    Database.register(
      new RESTStoreStrategy(
        'ster_inbox_order_search_importation',
        '/api/custom/inboxorder/test/search-importation/',
        'reference',
      ),
      InboxOrdersSearchImportationDAO,
    );

    Database.register(
      new RESTStoreStrategy(
        'ster_order_report_group',
        '/api/orders/orderreportdetailgroup',
        'mc',
      ),
      OrderReportDetailGroupDAO,
    );

    Database.register(
      new RESTStoreStrategy(
        'ster_collect_item_product',
        '/api/dispatch/collectitemproduct',
        'collect_item_product_id',
      ),
      CollectItemProductDAO,
    );

    Database.register(
      new RESTStoreStrategy(
        'ster_dispatch_cart_enabled',
        '/api/dispatch/cartenabled',
        'cart_id',
      ),
      DispatchCartEnabledDAO,
    );

    Database.register(
      new RESTStoreStrategy(
        'ster_dispatch_cart_box',
        '/api/dispatch/cartboxitemscollect',
        'cart_box_id',
      ),
      DispatchCartBoxDAO,
    );

    Database.register(
      new RESTStoreStrategy(
        'ster_process_monitor_alert',
        '/api/ster/processmonitoralert',
        'ster_config_id',
      ),
      SterProcessMonitorAlertDAO,
    );

    Database.register(
      new RESTStoreStrategy(
        'ster_dispatch_collect_state',
        '/api/dispatch/collectstate',
        'collect_state_id',
      ),
      DispatchCollectState,
    );

    Database.register(
      new RESTStoreStrategy(
        'ster_dispatch_cart_with_collect',
        '/api/dispatch/cartwithcollect',
        'cart_id',
      ),
      DispatchCartWithCollectDAO,
    );

    Database.register(
      new RESTStoreStrategy(
        'ster_collect_item_product_search',
        '/api/dispatch/collectitemproduct',
        'collect_item_product_id',
      ),
      CollectItemProductSearchDAO,
    );

    Database.register(
      new RESTStoreStrategy(
        'ster_dispatch_state',
        '/api/dispatch/dispatchstate',
        'dispatch_state_id',
      ),
      DispatchStateDAO,
    );
    Database.register(
      new RESTStoreStrategy(
        'ster_product_stock',
        '/api/product/stock',
        'stock_id',
      ),
      ProductStockDAO,
    );

    Database.register(
      new RESTStoreStrategy(
        'ster_dispatch_update',
        '/api/dispatch/dispatch',
        'dispatch_id',
      ),
      DispatchUpdateDAO,
    );

    Database.register(
      new RESTStoreStrategy(
        'ster_dispatch_details_update',
        '/api/dispatch/dispatchdetails',
        'dispatch_id',
      ),
      DispatchDetailsUpdateDAO,
    );

    Database.register(
      new RESTStoreStrategy(
        'ster_dispatch_item_send',
        '/api/custom/dispatch/dispatch_item_send',
        'dispatch_id',
      ),
      DispatchItemSendDAO,
    );

    Database.register(
      new RESTStoreStrategy(
        'ster_inbox_order_custom',
        '/api/custom/inboxorder',
        'inbox_order_id',
      ),
      InboxOrderCustomDAO,
    );

    Database.register(
      new RESTStoreStrategy(
        'ster_dispatch_send',
        '/api/custom/dispatch/dispatch_send',
        'dispatch_id',
      ),
      DispatchSendDAO,
    );

    /* Database.register(
      new RESTStoreStrategy(
        'ster_dispatch_manifest',
        '/api/custom/public/dispatch/dispatch_manifest',
        'dispatch_id',
      ),
      DispatchManifestDAO,
    );
*/
    Database.register(
      new RESTStoreStrategy(
        'ster_order_table_update',
        '/api/orders/order',
        'order_id',
      ),
      OrdersTableUpdateDAO,
    );

    Database.register(
      new RESTStoreStrategy(
        'ster_inbox_order_custom',
        '/api/custom/inboxorder',
        'inbox_order_id',
      ),
      InboxOrderCustomDAO,
    );

    Database.register(
      new RESTStoreStrategy(
        'ster_order_history_marketplace',
        '/api/custom/order/history_marketplace/:order_id?',
        'order_id',
      ),
      HistoryByMarketplaceDAO,
    );

    Database.register(
      new RESTStoreStrategy(
        'ster_order_items_sku',
        '/api/custom/order/items_by_sku/:order_id?',
        'order_id',
      ),
      OrderItemsBySkuDAO,
    );

    Database.register(
      new RESTStoreStrategy(
        'ster_dispatch_upload_documents_manifest',
        '/api/dispatch/dispatchuploaddocumentsmanifest',
        'upload_documents_manifest_id',
      ),
      DispatchUploadDocumentsManifestDAO,
    );

    Database.register(
      new RESTStoreStrategy(
        'ster_shipping_tags_custom',
        '/api/custom/shipping/tags',
        'inbox_order_id',
      ),
      ShippingTagsCustomDAO,
    );

    Database.register(
      new RESTStoreStrategy(
        'ster_order_buyer_logs',
        '/api/orders/orderbuyerlogs',
        'order_buyer_log_id',
      ),
      OrderBuyerLogsDAO,
    );

    Database.register(
      new RESTStoreStrategy(
        'ster_order_shipment_address_logs',
        '/api/orders/ordershipmentaddresslogs',
        'order_shipment_address_log_id',
      ),
      OrderShipmentAddressLogsDAO,
    );

    Database.register(
      new RESTStoreStrategy(
        'ster_order_reporting_sales_products',
        '/api/orders/orderreportingsalesproductscustom',
        'order_id',
      ),
      OrderReportingSalesProductsDAO,
    );

    Database.register(
      new RESTStoreStrategy(
        'ster_order_reporting_sales_marketplace',
        '/api/custom/order/reporting/sales/marketplace',
        'order_id',
      ),
      OrderReportingSalesMarketplaceDAO,
    );
  }

  init(): void {
    Libby.App = this.chinoTokenEnvironmet;
    this.addSession();
    this.register();
  }
}

export const DatabaseStore = new DatabaseStoreRaw();
