import { LibbyFetchDAO } from './LibbyFetchDAO';

export class OrderForDispatchDAO extends LibbyFetchDAO {
  constructor() {
    super('ster_order_for_dispatch', 'order_id');
  }

  getAll() {
    return this.query()
      .limit(0, 10000)
      .run();
  }
}
