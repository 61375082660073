import { useEffect, useMemo, useState } from 'react';
import TranslateService from './service';

export const useTranslation = () => {
  // To trigger renders
  const [count, setCount] = useState(0);
  useEffect(() => TranslateService.subscribe(() => { setCount((prev) => prev + 1); }), [setCount]);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  return useMemo(() => ({ t: TranslateService.t }), [count]);
};

export const useLanguage = () => useMemo(() => ({
  getLanguage: TranslateService.getLanguage,
  setLanguage: TranslateService.setLanguage,
}), []);
