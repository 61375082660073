import React, { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { DatabaseConnector } from '@phinxlab/libby-rest-web';
import { AccountCreateForm } from './components/AccountCreateForm';
import { useAddAccountForm } from './hooks/useAddAccountForm';
import { Account } from '../../types/Account';
import { LibbyObject } from '../../../../types/types';
import { ScreenAligned } from '../../../../components/ScreenAligned/ScreenAligned';
import { TitleBreadcrumbs } from '../../../../interfaces';
import { useBreadcrumbs } from '../../../../hooks';

type AddAccountProps = {
  title: TitleBreadcrumbs[];
  libby: LibbyObject;
  addCreate: (data: object) => void;
};

const AddAccountRaw = ({ libby, addCreate, title }: AddAccountProps) => {
  const history = useHistory();

  const [accountCreated, setAccountCreated] = useState<Account>(null);

  // when libby finish saving the account, push /acounts
  useEffect(() => {
    if (!libby.working && !!accountCreated) {
      history.push(`/accounts/${accountCreated && accountCreated.account_id}`);
    }
  }, [history, libby.working, accountCreated]);

  const {
    account,
    setAccount,
    handleSubmit,
    onSearchChange,
    username,
    password,
    email,
    account_role,
    submitError,
    duplicateAccount,
    setDuplicateAccount,
    isSearching,
  } = useAddAccountForm({ libby, addCreate });

  const onCancelClick = useCallback(() => {
    setAccount(null);
    setDuplicateAccount(false);
  }, [setDuplicateAccount, setAccount]);

  const titleShow = useBreadcrumbs(
    'Create Account',
    title,
  );

  return (
    <ScreenAligned title={titleShow}>
      <AccountCreateForm
        libby={libby}
        account={account}
        setAccountCreated={setAccountCreated}
        handleSubmit={handleSubmit}
        onSearchChange={onSearchChange}
        username={username}
        password={password}
        email={email}
        account_role={account_role}
        submitError={submitError}
        duplicateAccount={duplicateAccount}
        isSearching={isSearching}
        onCancelClick={onCancelClick}
      />
    </ScreenAligned>
  );
};

export const AddAccount = DatabaseConnector(AddAccountRaw)('admin_account');
