import palette from '../palette';

type MuiInputBaseType = { [k: string]: any };

const MuiInputBase: MuiInputBaseType = {
  root: {
    color: palette.text.secondary,
  },
};

export default MuiInputBase;
